import Loading from 'components/Loading';
import Row from 'components/Row';
import { ToastError, ToastSuccess } from 'components/Toaster';
import { BlueSolidButton, RedSolidButton } from 'components/UI/Buttons';
import AdminRecursosCostumizados from 'core/http/service/admin/AdminRecursosCostumizados.service';
import { ConteudoRecursoCustomizado } from 'models/recursos-costumizados/ConteudoRecursoCustomizado';
import { TipoAnexoConteudo } from 'models/recursos-costumizados/TipoAnexoConteudo';
import AdminBreadCrumb from 'pages/admin/components/breadcrumb/AdminBreadCrumb';
import editorConfig from 'pages/diarioBordo/components/textEditor/editorConfig';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { AdminGestaoPaginasMode } from '../../AdminGestaoPaginasContextWrapper';
import { AdminGestaoPaginasContext } from '../../context/AdminGestaoPaginasContext';
import { initialBreadCrumb } from '../../context/AdminGestaoPaginasProvider';
import AdminImageUploader from '../../section-form/imageUploader/AdminImageUploader';
import Styled from './AdminGestaoPaginaConteudoRecurso.styled';
import Select, { SelectOptionType } from 'components/Select';
import { StringHelp } from 'core/util/StringHelp';
import { MdDeleteForever } from 'react-icons/md';
import Colors from 'enums/Colors';
import Switch from 'react-bootstrap/esm/Switch';
import { URLSearchParams } from 'url';
import { RecursosCostumizadosEnum } from 'enums/RecursosCostumizados';
import ModalExclusao from '../components/modal/ModalExclusao';
import { MAX_IMAGE_SIZE_IN_BYTES } from 'constants/Dimensions';
import AllowedGroupsValidator from 'pages/admin/components/allowedGroupsValidator/AllowedGroupsValidator';
import { KcAdminGroups } from 'models/kc/KcUserInfo';
import { ArquivoPrincipalEnum } from 'enums/ArquivoPrincipalEnum';

interface IAdminGestaoPaginaConteudoRecurso {
    mode: AdminGestaoPaginasMode;
}

interface IMenuBreadCrumb {
    nomeItem: string;
    idItem: number;
    nomeEtapa: string;
    idEtapa: number;
    idConteudo: number;
}

const AdminGestaoPaginaConteudoRecurso = ({ mode }: IAdminGestaoPaginaConteudoRecurso) => {
    const params: any = useParams();
    const history = useHistory();

    const [image, setImage] = useState<string>();
    const [pdf, setPdf] = useState<string>();
    const [urlAnexo, setUrlAnexo] = useState<string>('');

    const [codigoConteudo, setCodigoConteudo] = useState<number>(-1);
    const [idEtapa, setIdEtapa] = useState<number>(-1);
    const [idItem, setIdItem] = useState<number>(-1);
    const [nameItem, setNameItem] = useState<string>('');
    const [quantidade, setQuantidade] = useState<number>(-1);
    const [nameContent, setNameContent] = useState<string>('');
    const [texto, setTexto] = useState<string>('');
    const [url, setUrl] = useState<string>('');
    const [typeModeEdit, setTypeModeEdit] = useState<boolean>(mode === 'NEW' ? false : true);
    const [oculto, setOculto] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const inputElement = useRef<HTMLInputElement>(null);
    const [valuePositionArq, setValuePositionArq] = useState<SelectOptionType>({ value: ArquivoPrincipalEnum.INICIO, label: 'Ínicio' });

    const [submitting, setSubmitting] = useState<boolean>(false);
    const [loading, setloading] = useState<boolean>(false);

    const MAX_CHAR_CONTENT = 200;
    const MAX_CHAR_CONTENT_NAME = 100;
    const MAX_PDF_SIZE_IN_BYTES = 5000000;

    const serviceRecursosCostumizados = new AdminRecursosCostumizados();

    const [dadosBreadCrumb, setDadosSetCrumb] = useState<IMenuBreadCrumb>({
        nomeItem: '',
        idItem: -1,
        nomeEtapa: '',
        idEtapa: -1,
        idConteudo: -1,
    });

    const { breadCrumb, setBreadCrumb, currentPage, selectPage, saveOrUpdatePage } = useContext(AdminGestaoPaginasContext.Context);

    const tiposAnexo: SelectOptionType[] = [
        {
            label: 'Imagem',
            value: 1,
        },
        {
            label: 'Áudio',
            value: 2,
        },
        {
            label: 'Vídeo',
            value: 3,
        },
        {
            label: 'PDF',
            value: 4,
        },
        {
            label: 'Nenhum',
            value: 0,
        },
    ];

    const positionArqPrincipal: SelectOptionType[] = [
        {
            label: 'Ínicio',
            value: ArquivoPrincipalEnum.INICIO,
        },
        {
            label: 'Final',
            value: ArquivoPrincipalEnum.FINAL,
        },
    ];

    const goToEtapa = () => {
        const dados = dadosBreadCrumb;
        const valuesURL = `${dados.nomeItem}/${dados.idItem}/${dados.idEtapa}`;

        history.push(`/admin/gestao-paginas/editar-etapa-item/` + valuesURL);
    };

    const breadCrumbInitial = (dados: IMenuBreadCrumb) => {
        const urlEtapa = `${dados?.nomeItem}/${dados?.idItem}/${dados?.idEtapa}`;

        setBreadCrumb([
            { route: 'gestao-paginas', label: 'Gestão de páginas' },
            { route: 'gestao-paginas/editar-recurso/' + currentPage?.codigo, label: 'Editar Recurso' },
            { route: 'gestao-paginas/editar-item-recurso/' + dados?.idItem, label: dados?.nomeItem ?? 'Item' },
            { route: 'gestao-paginas/editar-etapa-item/' + urlEtapa, label: dados?.nomeEtapa ?? 'Etapa' },
            { route: 'gestao-paginas/editar-item-etapa-recurso', label: nameContent ?? 'Conteúdo' },
        ]);
    };

    const [tipoAnexo, setTipoAnexo] = useState(0);

    const service = new AdminRecursosCostumizados();

    const isValidInputs = (): boolean => {
        if (!nameContent || nameContent.trim().length === 0) {
            return false;
        }

        if (!url || url.trim().length === 0) {
            return false;
        }

        return true;
    };

    const scrollToTop = () => {
        document.documentElement.scrollIntoView({
            behavior: 'smooth',
        });
    };

    const fetchConteudo = async (conteudoId: number) => {
        setloading(true);
        const { data, status } = await service.buscarConteudo(conteudoId);
        const conteudoData = new ConteudoRecursoCustomizado().fromJSON(data);

        if (status == 200 && conteudoData.anexoBase64) {
            setImage(conteudoData.anexoBase64);
            setTipoAnexo(conteudoData.tipoAnexo?.codigo!);
        }

        if (status == 200 && conteudoData.filePath) {
            setUrlAnexo(conteudoData.filePath!);
            setTipoAnexo(conteudoData.tipoAnexo?.codigo!);
        }

        const positionArq = conteudoData.positionArquivoPrincipal;

        const valuePosition = positionArqPrincipal.find(p => p.value === positionArq);

        if (valuePosition) setValuePositionArq(valuePosition);

        setNameContent(conteudoData.nome);
        setCodigoConteudo(conteudoId);
        setTexto(conteudoData.texto);
        setUrl(conteudoData.url);
        setOculto(conteudoData.oculto);
        setQuantidade(conteudoData.ordem ?? 0);
        setloading(false);
    };

    const isValidLinkAnexo = (): Boolean => {
        if (tipoAnexo === 2) {
            const soundClound = /https:\/\/w\.soundcloud\.com\/player\/\?url=https%3A\/\/api\.soundcloud\.com\/tracks\/\d+&.+=true?/g;
            const spotify = /^(?:spotify:|https:\/\/[a-z]+\.spotify\.com\/(track\/|\/(.*)\/playlist\/))(.*)$/g;
            const result =
                soundClound.test(urlAnexo.trim()) ||
                spotify.test(urlAnexo.trim()) ||
                urlAnexo.includes('institutoayrtonsenna.org.br/app/uploads/');
            return result;
        }
        if (tipoAnexo === 3) {
            const vimeo = /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/g;
            const yt = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/g;
            const result = vimeo.test(urlAnexo.trim()) || yt.test(urlAnexo.trim());
            return result;
        }

        return true;
    };

    const submit = async (returnEtapa: boolean) => {
        if (!isValidInputs()) {
            ToastError('Dados da página inválidos');
            return;
        }

        if (!isValidLinkAnexo()) {
            ToastError('Link inválido');
            return;
        }

        setSubmitting(true);

        const sanitizeUrl = StringHelp.removeAccents(url)
            .replace(/\s/g, '-')
            .replace(/[^a-z0-9-]/gi, '')
            .toLowerCase();

        const currentLink: string = (currentPage?.link ?? '').replace(/[^a-z0-9-]/gi, '');

        let isExistent = false;

        if (currentLink != sanitizeUrl) {
            const { data: value } = await service.verificarUriExistent(sanitizeUrl, codigoConteudo, dadosBreadCrumb.idEtapa, 'conteudo');
            isExistent = value;
        }

        if (isExistent) {
            ToastError('Já existe um conteúdo para a URL informada');
            setSubmitting(false);
            return;
        }

        const conteudo = new ConteudoRecursoCustomizado();
        const tipoAnexoConteudo = new TipoAnexoConteudo();

        if (tipoAnexo === 1) {
            conteudo.anexoBase64 = image;
        } else if (tipoAnexo === 2 || tipoAnexo === 3) {
            conteudo.filePath = urlAnexo;
        } else {
            conteudo.anexoBase64 = pdf;
        }

        tipoAnexoConteudo.codigo = tipoAnexo;
        conteudo.tipoAnexo = tipoAnexoConteudo;
        conteudo.codigoEtapa = dadosBreadCrumb.idEtapa;
        conteudo.nome = nameContent;
        conteudo.texto = texto;
        conteudo.url = sanitizeUrl;
        conteudo.positionArquivoPrincipal = valuePositionArq.value as ArquivoPrincipalEnum;

        if (mode == 'NEW' && codigoConteudo === -1) {
            conteudo.oculto = false;
            conteudo.ordem = quantidade;
            await saveConteudo(conteudo);
        } else {
            await updateConteudo(conteudo);
        }

        setSubmitting(false);
        scrollToTop();

        if (returnEtapa) goToEtapa();
    };

    const saveConteudo = async (conteudo: ConteudoRecursoCustomizado) => {
        if (!conteudo) {
            ToastError('Erro ao salvar!');
            return;
        }

        const { status, data } = await service.novoConteudo(conteudo);
        if (status > 300) {
            ToastError('Houve um erro ao tentar salvar o conteúdo!');
        } else {
            setCodigoConteudo(data.codigo);
            setUrl('/' + data.url);
            setTypeModeEdit(true);
            ToastSuccess('Conteúdo salvo');
        }
    };

    const updateConteudo = async (conteudo: ConteudoRecursoCustomizado) => {
        if (!conteudo) {
            ToastError('Erro ao atualizar!');
            return;
        }

        const { status, data } = await service.atualizarConteudo(conteudo, codigoConteudo);

        if (status > 300) {
            ToastError('Houve um erro ao tentar atualizar o conteúdo!');
        } else {
            setUrl('/' + data.url);
            ToastSuccess('Conteúdo salvo');
        }
    };

    const handleOcultar = async () => {
        const response = await serviceRecursosCostumizados.ocultarConteudo(codigoConteudo);

        if (response.status === 200) {
            ToastSuccess('Conteúdo ocultado.');
            fetchConteudo(codigoConteudo);
        }
    };

    const handleExibir = async () => {
        const response = await serviceRecursosCostumizados.exibirConteudo(codigoConteudo);

        if (response.status === 200) {
            ToastSuccess('Conteúdo visivel.');
            fetchConteudo(codigoConteudo);
        }
    };

    const handleDelete = async () => {
        const response = await serviceRecursosCostumizados.deleteConteudo(codigoConteudo);

        if (response.status === 200) {
            ToastSuccess('Conteúdo excluido com sucesso!');

            history.push(`/admin/gestao-paginas/editar-etapa-item/${nameItem}/${idItem}/${idEtapa}`);
        } else {
            ToastError('Houve algum problema ao tentar excluir o conteúdo.');
        }

        setShowModal(false);
    };

    const fetchInitial = () => {
        const idItem = String(params?.idItem);
        const nomeItem = String(params?.nomeItem);
        const idEtapa = String(params?.idEtapa);
        const nomeEtapa = String(params?.nomeEtapa);
        const idConteudo = String(params?.id);
        const quantidade = String(params?.quantidade);

        const dados: IMenuBreadCrumb = {
            ...dadosBreadCrumb,
            ['idItem']: parseInt(idItem),
            ['nomeItem']: nomeItem,
            ['idEtapa']: parseInt(idEtapa),
            ['nomeEtapa']: nomeEtapa,
        };
        setDadosSetCrumb(dados);
        setIdEtapa(parseInt(idEtapa));
        setIdItem(parseInt(idItem));
        setNameItem(nomeItem);
        setQuantidade(parseInt(quantidade));

        if (mode == 'EDIT') {
            fetchConteudo(parseInt(idConteudo));
        }
    };

    const chooseFile = () => {
        if (inputElement?.current) {
            inputElement.current.onchange = (e: Event) => {
                const target = e.target as HTMLInputElement;

                if (target.files && target.files[0]) {
                    if (target.files[0].size > MAX_PDF_SIZE_IN_BYTES) {
                        ToastError('O arquivo PDF excede o tamanho máximo de 5MB');
                        return;
                    }

                    const reader = new FileReader();
                    reader.onloadend = (ev: any) => {
                        const base64 = btoa(reader.result as string);
                        setPdf(base64);
                    };
                    reader.readAsBinaryString(target.files[0]);
                    setUrlAnexo(target.files[0].name);
                }
            };

            inputElement.current.accept = '.pdf';
            inputElement.current.click();
        }
    };

    useEffect(() => {
        fetchInitial();
    }, []);

    useEffect(() => {
        breadCrumbInitial(dadosBreadCrumb);
    }, [dadosBreadCrumb, nameContent]);

    return (
        <>
            <Styled.Container>
                {loading ? (
                    <Loading />
                ) : (
                    <div>
                        <ModalExclusao showModal={showModal} handleHiden={setShowModal} handleExcluxed={handleDelete} />
                        <AdminBreadCrumb crumbs={breadCrumb} />

                        <br />
                        <Styled.Title>{'Dados básicos do Conteúdo'}</Styled.Title>
                        <br />

                        <Styled.FormContainer>
                            <Styled.Divisor width="100%">
                                <Styled.Label>Nome do Conteúdo</Styled.Label>
                                <Styled.Input
                                    value={nameContent}
                                    onChange={(evn: any) => setNameContent(evn.target.value)}
                                    className={'form-control'}
                                    maxLength={MAX_CHAR_CONTENT_NAME}
                                />
                                <Styled.CountLetter danger={nameContent.length >= MAX_CHAR_CONTENT_NAME}>
                                    {nameContent.length}/{MAX_CHAR_CONTENT_NAME}
                                </Styled.CountLetter>
                            </Styled.Divisor>
                            <Styled.Divisor width="100%">
                                <Styled.Label>URL</Styled.Label>
                                <Styled.Input
                                    value={url}
                                    onChange={(evn: any) =>
                                        setUrl('/' + StringHelp.alnum(StringHelp.toSize(evn.target.value.toLowerCase().trim(), 200)))
                                    }
                                    className={'form-control'}
                                    maxLength={MAX_CHAR_CONTENT}
                                />
                                <Styled.CountLetter danger={url.length >= MAX_CHAR_CONTENT}>
                                    {url.length}/{MAX_CHAR_CONTENT}
                                </Styled.CountLetter>
                            </Styled.Divisor>
                        </Styled.FormContainer>

                        <Styled.Label style={{ margin: '10px' }}>Arquivo Principal</Styled.Label>
                        {tiposAnexo.map(tipo => {
                            return (
                                <Form.Check
                                    key={tipo.value}
                                    style={{ cursor: 'pointer', transform: 'translateX(7px)' }}
                                    inline
                                    label={tipo.label}
                                    type="radio"
                                    checked={tipoAnexo == tipo.value}
                                    onClick={() => setTipoAnexo(Number(tipo.value))}
                                />
                            );
                        })}
                        {tipoAnexo > 0 && (
                            <>
                                <Styled.Label style={{ margin: '10px', marginTop: 30 }}>Posição do ( Arquivo Principal )</Styled.Label>
                                <Select itens={positionArqPrincipal} value={valuePositionArq} onSelected={setValuePositionArq} isSmall={true} />
                            </>
                        )}

                        {tipoAnexo === 1 && (
                            <Styled.FormContainer style={{ marginTop: '50px' }}>
                                <AdminImageUploader
                                    onSelect={i => setImage(i)}
                                    setBase64={b => setImage(b)}
                                    size={'sm'}
                                    title={'Arquivo Principal'}
                                    preImageB64={image}
                                    recommendedDimension={{ w: 439, h: 470 }}
                                />
                            </Styled.FormContainer>
                        )}

                        {(tipoAnexo === 3 || tipoAnexo === 2) && (
                            <Styled.FormContainer>
                                <Styled.Divisor width="100%">
                                    <Styled.Label>URL do {tipoAnexo === 2 ? 'áudio' : 'vídeo'}</Styled.Label>
                                    <Form.Control value={urlAnexo} onChange={(evt: any) => setUrlAnexo(evt.target.value)} />
                                </Styled.Divisor>
                            </Styled.FormContainer>
                        )}

                        {tipoAnexo === 4 && (
                            <Styled.FormContainer style={{ marginTop: '50px' }}>
                                <div>
                                    <input type="file" ref={inputElement} style={{ display: 'none' }} />
                                    <Styled.ButtonPDF onClick={chooseFile}>
                                        Selecione um arquivo PDF <p>{urlAnexo}</p>
                                    </Styled.ButtonPDF>
                                </div>
                            </Styled.FormContainer>
                        )}

                        <br />

                        <ReactQuill
                            style={{ marginTop: '20px' }}
                            theme="snow"
                            modules={editorConfig.modules}
                            formats={editorConfig.formats}
                            value={texto}
                            onChange={val => {
                                setTexto(val);
                            }}
                        />

                        <Row
                            justify="end"
                            style={{
                                marginTop: 20,
                                justifyContent: 'start',
                                gap: '5rem',
                            }}
                        >
                            <BlueSolidButton disabled={submitting} onClick={() => submit(false)}>
                                {mode == 'NEW' ? (
                                    <>{submitting ? 'Salvando...' : 'Salvar conteúdo'}</>
                                ) : (
                                    <>{submitting ? 'Atualizando...' : 'Atualizar conteúdo'}</>
                                )}
                            </BlueSolidButton>

                            <BlueSolidButton disabled={submitting} onClick={() => submit(true)}>
                                {mode == 'NEW' ? (
                                    <>{submitting ? 'Salvando...' : 'Salvar e voltar'}</>
                                ) : (
                                    <>{submitting ? 'Atualizando...' : 'Atualizar e voltar'}</>
                                )}
                            </BlueSolidButton>
                            {typeModeEdit && (
                                <>
                                    <AllowedGroupsValidator allowedGroups={[KcAdminGroups.MASTER]}>
                                        <Styled.DivAround style={{ justifyContent: 'space-between', width: '200px' }}>
                                            <Row>
                                                <RedSolidButton
                                                    onClick={() => {
                                                        setShowModal(true);
                                                    }}
                                                >
                                                    Deletar
                                                </RedSolidButton>
                                            </Row>
                                        </Styled.DivAround>
                                    </AllowedGroupsValidator>
                                    <Styled.CkeckContainer>
                                        <Row>
                                            <span style={{ marginRight: '10px', fontSize: '16px', fontWeight: 600 }}>Ocultar</span>
                                            <Form>
                                                <Form.Check
                                                    type="switch"
                                                    id={'conteudo' + codigoConteudo}
                                                    checked={oculto}
                                                    onClick={() => {
                                                        !oculto ? handleOcultar() : handleExibir();
                                                    }}
                                                />
                                            </Form>
                                        </Row>
                                    </Styled.CkeckContainer>
                                </>
                            )}
                        </Row>
                    </div>
                )}
            </Styled.Container>
        </>
    );
};

export default AdminGestaoPaginaConteudoRecurso;
