import { GenericService } from './Generic.service';
import FaleConosco from '../../../models/FaleConosco';

import faqJsonData from '../../../data/FAQ.json';
import statementsJsonData from '../../../data/Statements.json';
import educationalSolutionsJsonData from '../../../data/SolucoesEducacionais.json';
import managerEducationalSolutionsJsonData from '../../../data/SolucoesEducacionaisGestor.json';

export class HomePageService extends GenericService {
    readonly URI: string = '/v1/homepage';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public getEducationalSolutions() {
        return educationalSolutionsJsonData;
    }

    public getManagerEducationalSolutions() {
        return managerEducationalSolutionsJsonData;
    }

    public getFrequentlyAskedQuestions() {
        return faqJsonData;
    }

    public getStatements() {
        return statementsJsonData;
    }

    public openTicket(model: FaleConosco) {
        return this.post('abrirChamado', model);
    }

    public getCategorias() {
        return this.get('categoriasChamado');
    }

    public pesquisa(termo: string) {
        return this.get('pesquisa', { termo });
    }
}
