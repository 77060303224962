import React, { useEffect } from 'react';
import GlobalStyled from '../../Solucoes.styled';
import DynamicHeader from 'components/dynamic_header/DynamicHeader';
import Overview from 'pages/solucoes/components/generics/Overview';
import InPractice, { TypeCard } from 'pages/solucoes/components/generics/InPractice';
import SupportMaterial from 'pages/solucoes/components/generics/SupportMaterial';
import FormSolucoes from 'pages/solucoes/components/solucoes/FormSolucoes';
import TipoSolucoesEnum from 'enums/TipoSolucoesEnum';
import Imagem from '../../assets/imagemTeste.svg';
import ImagemCircuito from '../../assets/circuito360/logo_circuito_360.svg';

//Material
import imagem_01 from '../../assets/circuito360/material/imagem_01.jpg';

//Material InPratice
import imagem_inpratice_01 from '../../assets/circuito360/inPratice/img_01.jpg';
import imagem_inpratice_02 from '../../assets/circuito360/inPratice/img_02.jpg';
import imagem_inpratice_03 from '../../assets/circuito360/inPratice/img_03.jpg';
import imagem_inpratice_04 from '../../assets/circuito360/inPratice/img_04.jpg';
import imagem_inpratice_05 from '../../assets/circuito360/inPratice/img_05.jpg';
import imagem_inpratice_06 from '../../assets/circuito360/inPratice/img_06.jpg';
import imagem_inpratice_07 from '../../assets/circuito360/inPratice/img_07.jpg';
import ContactContainer from 'pages/home/components/contact/ContactContainer';

const Circuito360 = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <DynamicHeader />
            <GlobalStyled.Container>
                <Overview
                    dados={{
                        title: 'Circuito 360',
                        descricao: `
                        Em 2023, o Instituto Ayrton Senna lança a solução Circuito 360, a partir de atualizações na solução Gestão de Política de Alfabetização (GALF), 
                        e com base no Circuito Campeão, solução que desde 2003 propõe a gestão de processos e resultados dos cinco primeiros anos do Ensino Fundamental.
                        `,
                        objetivo: `
                         O objetivo do Circuito 360 é subsidiar as secretarias de educação na elaboração e na gestão de sua política educacional para os
                         anos iniciais do Ensino Fundamental na perspectiva de educação integral.
                         `,
                        publicoAlvo: '1º ao 5º do Ensino Fundamental.',
                        avaliacao: 'Testes Diagnósticos de Alfabetização (língua portuguesa e matemática).',
                        formacao: `
                        4 ciclos e 4 encontros formativos para coordenadores pedagógicos (carga
                        horária – 54 horas).
                        `,
                        acompanhamento: `
                            Dados quantitativos e qualitativos, ancorados em indicadores e
                                    metas previamente definidas.
                        `,
                        atores: `
                        Estudante, Professor (gestão da sala de aula: ensino/ aprendizagem),
                            Coordenador Pedagógico / Diretor (gestão da rotina escolar), Secretaria de Educação
                            (gestão da política educacional).
                        `,
                        elementos: `
                           (I) Metodologias de trabalho para gerar sucesso por meio de atividades
                                cognitivas e socioemocionais inovadoras; (II) Gestão e gerenciamento dos processos e
                                resultados; (III) Formação dos profissionais, com prioridade para o coordenador
                                pedagógico; (IV) Normatização da proposta e processos; e (V) Referencial curricular
                                que dá clareza e unidade às expectativas de aprendizagem a cada ano dos anos
                                iniciais do Ensino Fundamental.
                        
                        `,
                        img: ImagemCircuito,
                    }}
                />
                <InPractice
                    items={[
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_01,
                            credits: {
                                credits: 'Julhio Cesar Pereira dos Santos',
                                local: 'Licínio de Almeida',
                                ano: '2024',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_04,
                            credits: {
                                credits: 'Julhio Cesar Pereira dos Santos',
                                local: 'Licínio de Almeida',
                                ano: '2024',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_05,
                            credits: {
                                credits: 'Julhio Cesar Pereira dos Santos',
                                local: 'Licínio de Almeida',
                                ano: '2024',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_06,
                            credits: {
                                credits: 'Julhio Cesar Pereira dos Santos',
                                local: 'Licínio de Almeida',
                                ano: '2024',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_07,
                            credits: {
                                credits: 'Inglidy Matos de Souza',
                                local: 'Boca do Acre',
                                ano: '2024',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_02,
                            credits: {
                                credits: 'Marcio Schimming',
                                local: 'Palhoça',
                                ano: '2019',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_03,
                            credits: {
                                credits: 'Marcio Schimming',
                                local: 'Palhoça',
                                ano: '2019',
                            },
                        },
                    ]}
                    description={`
                    Quer conhecer um pouco mais sobre como o Circuito 360 acontece na prática? <br /><br />
                    Navegue pelas imagens e vídeos e conheça algumas das redes onde a solução é implementada atualmente. <br /><br />
                    Sua rede pode ser a próxima a implementar a solução Circuito 360 e ter as imagens compartilhadas aqui na humane. Que tal?

                    `}
                />
                <SupportMaterial
                    description="
                Dentre os materiais disponibilizados no Circuito 360 estão:  Instrumentos diagnósticos dos níveis de 
                   alfabetização em Língua Portuguesa e Matemática; Subsídios para o trabalho pedagógico embasados em conhecimentos e avanços científicos e metodologias inovadoras e 
                   Protocolo para o monitoramento do processo educacional.
                "
                    slideToScrool={3}
                    img={imagem_01}
                />

                <FormSolucoes isBlue tipoSolucao={TipoSolucoesEnum.CIRCUITO_360} preDefinition />
                <ContactContainer isLight />
            </GlobalStyled.Container>
        </div>
    );
};

export default Circuito360;
