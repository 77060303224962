import Row from 'components/Row';
import React, { useEffect, useState } from 'react';
import Styled from './AdminGestaoPaginasPage.styled';
import { PrimaryButton } from 'components/UI/Buttons';
import AdminBreadCrumb from 'pages/admin/components/breadcrumb/AdminBreadCrumb';
import { FiEdit } from 'react-icons/fi';
import AdminGestaoPaginasPageModal from './AdminGestaoPaginasPageModal';
import { PaginaIntermediariaService } from 'core/http/service/PaginaIntermediaria.service';
import PaginaIntermediariaTipo, { PaginaIntermediariaTipoEnum } from 'models/paginas/PaginaIntermediariaTipo';
import AdminPaginaIntermediaria from 'models/admin/AdminPaginaIntermediaria';
import { DateHelp } from 'core/util/DateHelp';
import { useHistory, useLocation } from 'react-router-dom';
import { MdDeleteForever } from 'react-icons/md';
import Colors from 'enums/Colors';
import AllowedGroupsValidator from 'pages/admin/components/allowedGroupsValidator/AllowedGroupsValidator';
import { KcAdminGroups } from 'models/kc/KcUserInfo';
import ModalDelete from 'components/modal/ModalDelete';
import { ToastSuccess } from 'components/Toaster';
import Loading from 'components/Loading';
import Select from 'components/Select';
import HeaderNavigateGestaoPaginas from './components/headerNavigate/HeaderNavigateGestaoPaginas';
import CommentaryGestaoDePaginas from './comments-recurso/RecursoCommentary';
import AvaliationsGestaoDePaginas from './components/Body/Avaliations';
import ModalModifyTemplate from 'components/modal/ModalModifyTemplate';

export interface IAdminGestaoPaginasPage {}

interface SelectOptionType {
    value: string | number;
    label: string;
}

const AdminGestaoPaginasPage = ({}: IAdminGestaoPaginasPage) => {
    const history = useHistory();
    const service = new PaginaIntermediariaService();
    const [showModal, setShowModal] = useState(false);

    const [paginas, setPaginas] = useState<AdminPaginaIntermediaria[]>([]);
    // Dados selecionados para a exclusão.
    const [dadosPageSelected, setDadosPageSelected] = useState<AdminPaginaIntermediaria | null>(null);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalModify, setShowModalModify] = useState(false);
    const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
    const [tiposPagina, setTiposPagina] = useState<PaginaIntermediariaTipo[]>([]);
    const [idPage, setIdPage] = useState<number>(-1);

    //Header Gestão de páginas
    const [pageActive, setPageActive] = useState<number>(0);

    const [loading, setLoading] = useState<boolean>(false);

    const fetchPaginas = async (tipoPagina?: string | undefined) => {
        setLoading(true);
        const { data } = await service.listar(tipoPagina);

        const _paginas = Array.from(data).map((p: any) => new AdminPaginaIntermediaria().fromJSON(p));
        setPaginas(_paginas);
        setLoading(false);
    };

    const fetchTiposPaginas = async () => {
        const { data } = await service.listarTipos();

        const _tipos = Array.from(data).map((t: any) => new PaginaIntermediariaTipo().fromJSON(t));

        setTiposPagina([_tipos[0], _tipos[2]]);
    };

    useEffect(() => {
        fetchPaginas();
        fetchTiposPaginas();
    }, []);

    const tipoFilter: SelectOptionType[] = [
        {
            label: 'Todos',
            value: '',
        },
        {
            label: 'Página',
            value: '1',
        },
        {
            label: 'Recurso',
            value: '3',
        },
    ];

    const onSelectTemplate = (value: PaginaIntermediariaTipo) => {
        setShowModal(false);

        const template =
            value.codigo === 1
                ? 'nova-pagina?template=' + PaginaIntermediariaTipoEnum.BLANK
                : 'novo-recurso?template=' + PaginaIntermediariaTipoEnum.NOVOSRECURSO;

        history.push('/admin/gestao-paginas/' + template);
    };

    const openEditPage = (pageId: number) => {
        const pagina = paginas.filter(p => p.codigo === pageId);

        const tipoTemplate = pagina[0].tipoTemplate;

        if (tipoTemplate === 1) {
            history.push('/admin/gestao-paginas/editar-pagina/' + pageId);
        } else {
            history.push('/admin/gestao-paginas/editar-recurso/' + pageId);
        }
    };

    const openEdityTemplate = (pagina: AdminPaginaIntermediaria) => {
        setIdPage(pagina.codigo);
        setDadosPageSelected(pagina);
        setShowModalModify(true);
    };

    const editable = (pagina: AdminPaginaIntermediaria) => {
        return pagina.editavel && pagina.tipoTemplate != 2;
    };

    const haveTemplate = (pagina: AdminPaginaIntermediaria): string => {
        if (pagina.tipoTemplate === null && pagina.editavel === true) {
            return 'Template não encontrado';
        } else if (pagina.tipoTemplate === 1) {
            return 'Página';
        } else if (pagina.tipoTemplate === 3) {
            return 'Recursos';
        } else {
            return 'Página';
        }
    };

    const editTemplate = (pagina: AdminPaginaIntermediaria): boolean => {
        if (pagina.tipoTemplate === null && pagina.editavel === true) {
            return true;
        }

        return false;
    };

    const handleDelete = async () => {
        setIsLoadingDelete(true);
        const id = dadosPageSelected?.codigo ?? 0;

        let dados = null;

        if (dadosPageSelected?.tipoTemplate === 3) {
            dados = await service.excluirRecurso(id);
        } else {
            dados = await service.excluir(id);
        }

        if (dados.status < 300) {
            fetchPaginas();
            ToastSuccess('Página excluída com sucesso!');
        }
        setIsLoadingDelete(false);
        setShowModalDelete(false);
    };

    const onUpdateTemplate = () => {
        fetchPaginas();
        setShowModalModify(false);
    };

    return (
        <>
            <AdminGestaoPaginasPageModal show={showModal} onHide={() => setShowModal(false)} onSelect={onSelectTemplate} />
            <ModalDelete
                isLoading={isLoadingDelete}
                namePage={dadosPageSelected?.nome ?? ''}
                handleDelete={() => handleDelete()}
                onHiden={() => setShowModalDelete(false)}
                showModal={showModalDelete}
            />
            <ModalModifyTemplate
                isLoading={false}
                namePage={dadosPageSelected?.nome ?? ''}
                idPage={dadosPageSelected?.codigo ?? -1}
                handleModify={() => ''}
                onHiden={() => onUpdateTemplate()}
                showModal={showModalModify}
            />
            <HeaderNavigateGestaoPaginas pageIndex={pageActive} setPageIndex={setPageActive} />
            <Styled.Container>
                <AdminBreadCrumb crumbs={[{ route: 'gestao-paginas', label: 'Gestão de páginas' }]} />
                <br />
                {pageActive === 0 ? (
                    <>
                        <Row justify="space-between">
                            <div>
                                <Styled.Title>Gestão de páginas da humane</Styled.Title>
                                <Styled.Text>Gerencie neste painel as páginas e seus elementos da Plataforma humane.</Styled.Text>
                            </div>

                            <PrimaryButton onClick={() => setShowModal(true)}>Criar nova página</PrimaryButton>
                        </Row>
                        <Styled.RowRigth>
                            <span className="label-order">Tipo de página:</span>
                            <span className="span-select">
                                <Select
                                    width="200px"
                                    placeholder="Todos"
                                    isSmall={true}
                                    itens={tipoFilter}
                                    onSelected={o => {
                                        fetchPaginas(o.value.toString() ?? '');
                                    }}
                                />
                            </span>
                        </Styled.RowRigth>
                        <br /> <br />
                        <Styled.TableGrid>
                            <Styled.TableGridHead>
                                <Styled.TableGridHeadCell>Nome da página</Styled.TableGridHeadCell>
                                <Styled.TableGridHeadCell>URL</Styled.TableGridHeadCell>
                                <Styled.TableGridHeadCell>Editável</Styled.TableGridHeadCell>
                                <Styled.TableGridHeadCell>Tipo</Styled.TableGridHeadCell>
                                <Styled.TableGridHeadCell>Última edição</Styled.TableGridHeadCell>
                                <Styled.TableGridHeadCell>Ações</Styled.TableGridHeadCell>
                            </Styled.TableGridHead>

                            {!loading &&
                                paginas.map(pagina => {
                                    return (
                                        <Styled.TableGridRow key={pagina.codigo}>
                                            <Styled.TableGridCell>{pagina.nome}</Styled.TableGridCell>
                                            <Styled.TableGridCell>{pagina.link}</Styled.TableGridCell>
                                            <Styled.TableGridCell>
                                                <Styled.TableGridCellChip isYes={editable(pagina)}>
                                                    {editable(pagina) ? 'Sim' : 'Não'}
                                                </Styled.TableGridCellChip>
                                            </Styled.TableGridCell>
                                            <Styled.TableGridCell>{haveTemplate(pagina)}</Styled.TableGridCell>
                                            <Styled.TableGridCell>
                                                {pagina.ultimaAtualizacao && <span>{DateHelp.formatDateFull(pagina.ultimaAtualizacao)}</span>}
                                            </Styled.TableGridCell>
                                            <Styled.TableGridCell>
                                                <AllowedGroupsValidator allowedGroups={[KcAdminGroups.MASTER]}>
                                                    <MdDeleteForever
                                                        style={{ cursor: editable(pagina) ? 'pointer' : 'default', marginRight: 10 }}
                                                        onClick={() => {
                                                            editable(pagina) && setShowModalDelete(true);
                                                            setDadosPageSelected(pagina);
                                                        }}
                                                        title={editable(pagina) ? 'Excluir' : 'Não excluível'}
                                                        size={20}
                                                        color={Colors.DeleteRed}
                                                        cursor={'pointer'}
                                                    />
                                                </AllowedGroupsValidator>
                                                <FiEdit
                                                    style={{ cursor: editable(pagina) ? 'pointer' : 'default' }}
                                                    title={
                                                        editTemplate(pagina) ? 'Mudar Template' : editable(pagina) ? 'Editar' : 'Não editável'
                                                    }
                                                    onClick={() => {
                                                        editTemplate(pagina)
                                                            ? openEdityTemplate(pagina)
                                                            : editable(pagina)
                                                            ? openEditPage(pagina.codigo)
                                                            : '';
                                                    }}
                                                />
                                            </Styled.TableGridCell>
                                        </Styled.TableGridRow>
                                    );
                                })}
                        </Styled.TableGrid>
                    </>
                ) : pageActive === 1 ? (
                    <CommentaryGestaoDePaginas />
                ) : (
                    <AvaliationsGestaoDePaginas />
                )}
                {loading && <Loading />}
            </Styled.Container>{' '}
        </>
    );
};

export default AdminGestaoPaginasPage;
