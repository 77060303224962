import { gotoSection } from 'pages/intermediarias/criatividadeDetail/versions/components/sections/Inicio';
import React from 'react';
import { BiChevronLeft } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';

import Styled from '../Alfabetizacao360Detail.styled';
import image1 from './image2042.png';

export interface ITematica {}

const Tematica = ({}: ITematica) => {
    const history = useHistory();

    return (
        <Styled.Main id="tematica">
            <Styled.SectionTematica.Container>
                <div style={{ maxWidth: '1380px' }}>
                    <Styled.GoBackButton onClick={() => history.push('/')}>
                        <span>
                            <BiChevronLeft />
                        </span>
                        Voltar à página Inicial
                    </Styled.GoBackButton>
                </div>

                <Styled.SectionTematica.Content>
                    <Styled.SectionTematica.ContentTexts>
                        <Styled.SectionTematica.PreTitle>TEMÁTICA</Styled.SectionTematica.PreTitle>
                        <Styled.SectionTematica.Title>Alfabetização</Styled.SectionTematica.Title>

                        <Styled.SectionTematica.Image src={image1} className="mobile-only" />

                        <Styled.SectionTematica.Text>
                            Há tempos falamos dos desafios da alfabetização no Brasil. Mas você sabe o que está envolvido nesta busca pela
                            alfabetização e por que temos ainda esse grande desafio a ser superado? Venha percorrer essa trilha sobre
                            Alfabetização e conheça nossos conteúdos para te ajudarem nessa missão.
                        </Styled.SectionTematica.Text>
                        <br />
                        <Styled.SectionTematica.PrimaryButton onClick={() => gotoSection('curso_livre')}>
                            Conheça os conteúdos
                        </Styled.SectionTematica.PrimaryButton>
                    </Styled.SectionTematica.ContentTexts>

                    <Styled.SectionTematica.Image src={image1} className="desktop-only" />
                </Styled.SectionTematica.Content>
            </Styled.SectionTematica.Container>
            <div id="audimaWidget"></div>

        </Styled.Main>
    );
};

export default Tematica;
