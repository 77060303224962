import { CertificacoesModel } from 'models/certificacoes/CertificacoesModel';
import { GenericService } from './Generic.service';

export class UsuariosCertificadosService extends GenericService {
    readonly URI: string = '/v1/usuariosCertificados';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public listarAll(id: number) {
        return this.get('list/' + id);
    }

    public atualizarTodosUsersEmited(listIds: Number[]) {
        return this.put('update/emited', listIds);
    }

    public associarUser(id: number) {
        return this.post('inserir', { codigoCertificacao: id });
    }

    public verificarEmissao(id: number) {
        return this.get('verifyEmited/' + id);
    }
}
