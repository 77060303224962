import Row from 'components/Row';
import { BlueSolidButton, NavyButton, PrimaryButton, RedSolidButton } from 'components/UI/Buttons';
import { PaginaIntermediariaTipoEnum } from 'models/paginas/PaginaIntermediariaTipo';
import AdminBreadCrumb from 'pages/admin/components/breadcrumb/AdminBreadCrumb';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Route, useHistory, useLocation, useParams } from 'react-router-dom';
import AdminSecaoPaginaData, {
    AdminSecaoPaginaBanner,
    AdminSecaoPaginaCards,
    AdminSecaoPaginaCarousel,
    AdminSecaoPaginaImagemSimples,
    AdminSecaoPaginaSimplesTexto,
    ModeloBase,
} from '../AdminSecaoPaginaData';
import AdminGestaoPaginasModeloSecaoModel, {
    AdminModeloDadosComplementares,
    AdminModeloSecaoModelEnum,
} from './AdminGestaoPaginasModeloSecaoModal';
import Styled from './AdminGestaoPaginasNewEdit.styled';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import hash from 'object-hash';
import arrowIcon from '../../../images/admin/arrow-r.svg';
import { AdminGestaoPaginasContext } from '../context/AdminGestaoPaginasContext';
import { initialBreadCrumb } from '../context/AdminGestaoPaginasProvider';
import { AdminGestaoPaginasMode } from '../AdminGestaoPaginasContextWrapper';
import AdminGestaoPaginaService from 'core/http/service/admin/AdminGestaoPagina.service';
import AdminPaginaIntermediariaResumida, { AdminPaginaIntermediariaSecaoResumida } from 'models/admin/AdminPaginaIntermediariaResumida';
import { cloneDeep } from 'lodash';
import { ToastError, ToastSuccess } from 'components/Toaster';
import { PaginaIntermediariaService } from 'core/http/service/PaginaIntermediaria.service';

import { GiHamburgerMenu } from 'react-icons/gi';
import { StringHelp } from 'core/util/StringHelp';
import { AdminGestaoPaginasSecaoListDropzone, AdminGestaoPaginasSecaoListItem } from './AdminGestaoPaginasSecaoList';
import AdminPaginaIntermediaria from 'models/admin/AdminPaginaIntermediaria';
import AllowedGroupsValidator from 'pages/admin/components/allowedGroupsValidator/AllowedGroupsValidator';
import { KcAdminGroups } from 'models/kc/KcUserInfo';
import { RecursosCostumizadosEnum } from 'enums/RecursosCostumizados';
import ModalDelete from 'components/modal/ModalDelete';
import { PaginaBrancoGestao } from 'enums/PaginaBrancoGestao';
import { MdDeleteForever } from 'react-icons/md';
import Colors from 'enums/Colors';
import ModalExclusao from '../new-edit-recurso/components/modal/ModalExclusao';

export interface IAdminGestaoPaginasNovapagina {
    mode: AdminGestaoPaginasMode;
}

const AdminGestaoPaginasNewEdit = ({ mode }: IAdminGestaoPaginasNovapagina) => {
    const location = useLocation();
    const history = useHistory();
    const params: any = useParams();

    const {
        breadCrumb,
        setBreadCrumb,
        selectSection,
        sections: secoes,
        setSections: setSecoes,
        currentPage,
        selectPage,
        saveOrUpdatePage,
        setLoadingCreate,
        loadingCreate,
    } = useContext(AdminGestaoPaginasContext.Context);
    const [templateType, setTemplateType] = useState<PaginaIntermediariaTipoEnum>(PaginaIntermediariaTipoEnum.UNKNOW);
    const [showModelModal, setShowModelModal] = useState<boolean>(false);
    const [showModalDeleteSecao, setShowModalDeleteSecao] = useState<boolean>(false);

    const service = new AdminGestaoPaginaService();
    const servicePageIntermiaria = new PaginaIntermediariaService();

    const [codigoPage, setCodigoPage] = useState<number>(0);
    const [modePage, setModePage] = useState<boolean>(false);
    const [namePage, setNamePage] = useState<string>('');

    const [url, setUrl] = useState('');

    const [typeSecao, setTypeSecao] = useState<PaginaBrancoGestao>(PaginaBrancoGestao.DEFAULT);
    const [idSecao, setIdSecao] = useState<number>(0);

    const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
    const [showModalDelete, setShowModalDelete] = useState(false);

    const getDataModelo = (
        modelId: AdminModeloSecaoModelEnum,
        dadosComplementares?: AdminModeloDadosComplementares
    ): AdminSecaoPaginaData | null => {
        const paginaId = currentPage?.codigo ?? dadosComplementares?.paginaId ?? -1;
        let modelo: ModeloBase | null = null;

        if (modelId == AdminModeloSecaoModelEnum.BANNER) modelo = new AdminSecaoPaginaBanner('', '', '', '', '', false);
        else if (modelId == AdminModeloSecaoModelEnum.SIMPLES_IMAGEM_D) modelo = new AdminSecaoPaginaImagemSimples('', '', '', '', 'D');
        else if (modelId == AdminModeloSecaoModelEnum.SIMPLES_IMAGEM_E) modelo = new AdminSecaoPaginaImagemSimples('', '', '', '', 'E');
        else if (modelId == AdminModeloSecaoModelEnum.SIMPLES_TEXTO) modelo = new AdminSecaoPaginaSimplesTexto('', '', '', '');
        else if (modelId == AdminModeloSecaoModelEnum.CAROUSEL) modelo = new AdminSecaoPaginaCarousel('', '');
        else if (modelId == AdminModeloSecaoModelEnum.CARTOES) modelo = new AdminSecaoPaginaCards('', '');

        if (modelo) {
            const dataModel: AdminSecaoPaginaData = new AdminSecaoPaginaData(
                paginaId,
                modelo.modeloNome,
                dadosComplementares?.ordem ?? secoes.length + 1,
                dadosComplementares?.oculto ?? false,
                modelo
            );

            if (dadosComplementares) dataModel.setCodigo(dadosComplementares.secaoId);

            return dataModel;
        }

        return null;
    };

    const onSelectModelo = (modelId: AdminModeloSecaoModelEnum) => {
        const dataModelo = getDataModelo(modelId);
        if (dataModelo) openNewSection(dataModelo);

        setShowModelModal(false);
    };

    const openNewSection = (section: AdminSecaoPaginaData) => {
        selectSection(section);
        history.push('/admin/gestao-paginas/editar-secao');
    };

    const openEditSection = (section: AdminSecaoPaginaData) => {
        selectSection(section);
        history.push('/admin/gestao-paginas/editar-secao/' + section.codigo);
    };

    const isValidInputs = (): boolean => {
        return url.trim().length > 0 && namePage.trim().length > 0;
    };

    const fetchPage = async (pageId: number) => {
        const { data } = await service.buscarPagina(pageId);
        const pagedata = new AdminPaginaIntermediariaResumida().fromJSON(data);
        setNamePage(pagedata.nome);
        setUrl(pagedata.link);
        selectPage(pagedata);
        setTemplateType(pagedata.tipoTemplate ?? PaginaIntermediariaTipoEnum.UNKNOW);

        const { data: dataSecao } = await service.buscarSecoes(pageId);
        const secoesResumidas = Array.from(dataSecao).map((s: any) => new AdminPaginaIntermediariaSecaoResumida().fromJSON(s));

        const _secoes: AdminSecaoPaginaData[] = [];

        for (const secao of secoesResumidas) {
            const dadosComplementares: AdminModeloDadosComplementares = {
                secaoId: secao.idSecao,
                ordem: secao.ordem,
                oculto: secao.oculto,
                paginaId: secao.idPagina,
            };
            const datamodelo = getDataModelo(secao.idModelo, dadosComplementares);

            if (datamodelo) _secoes.push(datamodelo);
        }

        setSecoes(_secoes);
    };

    const submitPage = async () => {
        if (!isValidInputs()) {
            ToastError('Dados da página inválidos');
            return;
        }

        setLoadingCreate(true);

        const sanitizeUrl = StringHelp.removeAccents(url)
            .replace(/\s/g, '-')
            .replace(/[^a-z0-9-]/gi, '')
            .toLowerCase();

        const currentLink: string = (currentPage?.link ?? '').replace(/[^a-z0-9-]/gi, '');

        let isExistent = false;

        if (currentLink != sanitizeUrl) {
            const { data: value } = await servicePageIntermiaria.verificarUriPageExistent('/' + sanitizeUrl);
            isExistent = value;
        }

        if (!isExistent) {
            const onsuccess = (paginasalva: AdminPaginaIntermediaria) => {
                setNamePage(paginasalva.nome);
                setUrl(paginasalva.link);
                setCodigoPage(paginasalva.codigo);
                setModePage(true);
                ToastSuccess('Página salva');
            };

            const onerror = () => {
                ToastError('Error ao salvar a página');
            };

            saveOrUpdatePage(namePage, sanitizeUrl, templateType, '', onsuccess, onerror);
        } else {
            ToastError('Já existe uma página para a URL informada');
            setLoadingCreate(false);
        }
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const _templateTypeRaw = queryParams.get('template');

        if (_templateTypeRaw) {
            setTemplateType(parseInt(_templateTypeRaw));
        }

        setBreadCrumb(initialBreadCrumb(mode));

        if (currentPage) {
            setNamePage(currentPage.nome);
            setUrl(currentPage.link);
        }

        if (mode == 'EDIT') {
            const id = String(params.id);
            fetchPage(parseInt(id));
            setCodigoPage(parseInt(id));
            setModePage(true);
        }
    }, []);

    const setupOrder = async (item: AdminSecaoPaginaData, neworder: number) => {
        //console.log(item.ordem, '>', neworder);

        const { data, status } = await service.updateOrder(item.codigo, item.modelData.modeloId, neworder, item.paginaId);

        if (status == 200) {
            const secoesResumidas = Array.from(data).map((s: any) => new AdminPaginaIntermediariaSecaoResumida().fromJSON(s));

            const _secoes: AdminSecaoPaginaData[] = [];

            for (const secao of secoesResumidas) {
                const dadosComplementares: AdminModeloDadosComplementares = {
                    secaoId: secao.idSecao,
                    ordem: secao.ordem,
                    oculto: secao.oculto,
                    paginaId: secao.idPagina,
                };
                const datamodelo = getDataModelo(secao.idModelo, dadosComplementares);

                if (datamodelo) _secoes.push(datamodelo);
            }

            setSecoes(_secoes);

            ToastSuccess('Ordem alterada com sucesso!');
        } else {
            ToastError('Houve um erro ao tentar ordenar as seções.');
        }
    };

    const handleDelete = async () => {
        const type = typeSecao;
        const codigo = idSecao;

        setIsLoadingDelete(true);
        let status = 0;
        let message = '';
        let deletedPage = false;

        switch (type) {
            case PaginaBrancoGestao.PAGINABRANCO: {
                const response = await servicePageIntermiaria.excluir(codigoPage);
                status = response.status;
                message = 'Página exluída com sucesso!';
                deletedPage = true;
                break;
            }
            case PaginaBrancoGestao.BANNER: {
                const response = await service.deleteBanner(codigo ?? 0);
                status = response.status;
                message = 'Banner exluído com sucesso!';
                break;
            }
            case PaginaBrancoGestao.SIMPLESCOMIMAGEM: {
                const response = await service.deleteSecaoSimplesComImagem(codigo ?? 0);
                status = response.status;
                message = 'Simples com imagem exluído com sucesso!';
                break;
            }
            case PaginaBrancoGestao.SIMPLES: {
                const response = await service.deleteSecaoSimplesSemImagem(codigo ?? 0);
                status = response.status;
                message = 'Simples sem imagem exluído com sucesso!';
                break;
            }
            case PaginaBrancoGestao.CARDCATEGORIA: {
                const response = await service.deleteCategoria(codigo ?? 0);
                status = response.status;
                message = 'Cartões com categoria exluído com sucesso!';
                break;
            }
            case PaginaBrancoGestao.CARROSSEL: {
                const response = await service.deleteCarrossel(codigo ?? 0);
                status = response.status;
                message = 'Carrossel com categoria exluído com sucesso!';
                break;
            }
        }

        if (status === 200) {
            ToastSuccess(message);

            if (deletedPage) return history.push('/admin/gestao-paginas');

            fetchPage(codigoPage);
        }
        setShowModalDeleteSecao(false);
        setShowModelModal(false);
        setIsLoadingDelete(false);
    };

    const handleUpdadeOculto = async (codigo: number, type: PaginaBrancoGestao, oculto: boolean, paginaId: Number) => {
        let secaoModelo = '';

        switch (type) {
            case PaginaBrancoGestao.BANNER: {
                secaoModelo = 'codigo_modelosecao_banner';
                break;
            }
            case PaginaBrancoGestao.SIMPLESCOMIMAGEM: {
                secaoModelo = 'codigo_modelosecao_imagemsimples';
                break;
            }
            case PaginaBrancoGestao.SIMPLES: {
                secaoModelo = 'codigo_modelosecao_simples';
                break;
            }
            case PaginaBrancoGestao.CARDCATEGORIA: {
                secaoModelo = 'codigo_modelosecao_cardcategoria';
                break;
            }
            case PaginaBrancoGestao.CARROSSEL: {
                secaoModelo = 'codigo_modelosecao_carrossel';
                break;
            }
        }

        const response = await service.atualizarOcultacaoSessao(codigo, secaoModelo, !oculto);

        if (response.status === 200) {
            fetchPage(codigoPage);
            ToastSuccess(response.data.oculto ? 'Seção ocultada com sucesso!' : 'Seção visível com sucesso!');
        }
    };

    return (
        <>
            <AdminGestaoPaginasModeloSecaoModel show={showModelModal} onHide={() => setShowModelModal(false)} onSelect={onSelectModelo} />
            <ModalDelete
                isLoading={isLoadingDelete}
                namePage={namePage}
                handleDelete={() => handleDelete()}
                onHiden={() => {
                    setShowModalDelete(false);
                }}
                showModal={showModalDelete}
            />
            <Styled.Container>
                <AdminBreadCrumb crumbs={breadCrumb} />

                <br />

                <Styled.Title>Dados básicos da página</Styled.Title>

                <br />

                <Styled.FormContainer>
                    <Styled.Label>Nome da página</Styled.Label>
                    <Form.Control value={namePage} onChange={evt => setNamePage(evt.target.value)} />

                    <br />
                    <Styled.Label>URL</Styled.Label>
                    <Form.Control value={url} onChange={evt => setUrl(evt.target.value)} />
                    <Row style={{ justifyContent: 'space-between' }}>
                        <Styled.DivAround style={{ width: '200px', marginTop: 20 }}>
                            {modePage && (
                                <AllowedGroupsValidator allowedGroups={[KcAdminGroups.MASTER]}>
                                    <RedSolidButton
                                        onClick={() => {
                                            setTypeSecao(PaginaBrancoGestao.PAGINABRANCO), setShowModalDelete(true);
                                        }}
                                    >
                                        Deletar
                                    </RedSolidButton>
                                </AllowedGroupsValidator>
                            )}
                        </Styled.DivAround>
                        <BlueSolidButton disabled={loadingCreate} style={{ marginTop: 20 }} onClick={submitPage}>
                            {loadingCreate ? 'Salvando...' : 'Salvar Página'}
                        </BlueSolidButton>
                    </Row>
                </Styled.FormContainer>

                <Styled.BottomContainer>
                    <hr style={{ margin: ' 30px 0' }} />
                    {currentPage != null && (
                        <>
                            <Row justify="space-between" style={{ maxWidth: '1100px', alignItems: 'center' }}>
                                <Styled.Title>Seções da página</Styled.Title>
                            </Row>

                            <br />

                            <Styled.SecoesContainer key={hash(secoes)}>
                                <DndProvider backend={HTML5Backend}>
                                    {secoes
                                        .sort((s1, s2) => s1.ordem - s2.ordem)
                                        .map((secao, index) => {
                                            let type = PaginaBrancoGestao.DEFAULT;

                                            switch (secao.nomeModelo) {
                                                case 'Banner': {
                                                    type = PaginaBrancoGestao.BANNER;
                                                    break;
                                                }
                                                case 'Simples com Imagem à esquerda': {
                                                    type = PaginaBrancoGestao.SIMPLESCOMIMAGEM;
                                                    break;
                                                }
                                                case 'Simples com Imagem à direita': {
                                                    type = PaginaBrancoGestao.SIMPLESCOMIMAGEM;
                                                    break;
                                                }
                                                case 'Simples sem imagem': {
                                                    type = PaginaBrancoGestao.SIMPLES;
                                                    break;
                                                }
                                                case 'Cartões com categoria': {
                                                    type = PaginaBrancoGestao.CARDCATEGORIA;
                                                    break;
                                                }
                                                case 'Carrossel': {
                                                    type = PaginaBrancoGestao.CARROSSEL;
                                                    break;
                                                }
                                            }

                                            return (
                                                <React.Fragment key={index}>
                                                    <ModalExclusao
                                                        showModal={showModalDeleteSecao}
                                                        handleHiden={setShowModalDeleteSecao}
                                                        handleExcluxed={handleDelete}
                                                        isLoading={isLoadingDelete}
                                                    />
                                                    <AdminGestaoPaginasSecaoListDropzone thisOrder={secao.ordem} onDropItem={setupOrder} />

                                                    <AdminGestaoPaginasSecaoListItem secao={secao}>
                                                        <Row>
                                                            <Styled.Handler>
                                                                <GiHamburgerMenu />
                                                            </Styled.Handler>

                                                            <Styled.RedirectButton>
                                                                <div
                                                                    onClick={() => openEditSection(secao)}
                                                                    style={{ width: '100%', padding: 18, cursor: 'pointer' }}
                                                                >
                                                                    <span>Editar {secao.nomeModelo}</span>
                                                                </div>
                                                                <Styled.CkeckContainer>
                                                                    <Row
                                                                        style={{
                                                                            width: '170px',
                                                                            justifyContent: 'space-between',
                                                                        }}
                                                                    >
                                                                        <Row>
                                                                            <span style={{ marginRight: '10px', fontSize: '14px' }}>
                                                                                Ocultar
                                                                            </span>
                                                                            <Form>
                                                                                <Form.Check
                                                                                    type="switch"
                                                                                    id={'conteudo' + secao.codigo}
                                                                                    checked={secao.oculto}
                                                                                    onClick={() => {
                                                                                        handleUpdadeOculto(
                                                                                            secao.codigo,
                                                                                            type,
                                                                                            secao.oculto,
                                                                                            secao.paginaId ?? 0
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            </Form>
                                                                        </Row>

                                                                        <AllowedGroupsValidator allowedGroups={[KcAdminGroups.MASTER]}>
                                                                            <MdDeleteForever
                                                                                onClick={() => {
                                                                                    setTypeSecao(type);
                                                                                    setIdSecao(secao.codigo);
                                                                                    setShowModalDeleteSecao(true);
                                                                                }}
                                                                                size={25}
                                                                                color={Colors.DeleteRed}
                                                                                cursor={'pointer'}
                                                                            />
                                                                        </AllowedGroupsValidator>
                                                                        <img src={arrowIcon} />
                                                                    </Row>
                                                                </Styled.CkeckContainer>
                                                            </Styled.RedirectButton>
                                                        </Row>
                                                    </AdminGestaoPaginasSecaoListItem>

                                                    {index == secoes.length - 1 && (
                                                        <AdminGestaoPaginasSecaoListDropzone
                                                            thisOrder={secao.ordem + 1}
                                                            onDropItem={setupOrder}
                                                        />
                                                    )}
                                                </React.Fragment>
                                            );
                                        })}
                                </DndProvider>
                            </Styled.SecoesContainer>

                            <br />
                        </>
                    )}
                    {modePage && <PrimaryButton onClick={() => setShowModelModal(true)}>Adicionar nova seção</PrimaryButton>}
                </Styled.BottomContainer>
            </Styled.Container>
        </>
    );
};

export default AdminGestaoPaginasNewEdit;
