import { ArquivoPrincipalEnum } from 'enums/ArquivoPrincipalEnum';
import { TipoAnexoConteudo } from './TipoAnexoConteudo';
import { jsonProperty, Serializable } from 'ts-serializable';

export class ConteudoOrder extends Serializable {
    @jsonProperty(Number, null)
    codigo: number | null = -1;

    @jsonProperty(Number)
    codigoEtapa: number | null = -1;

    @jsonProperty(Number)
    ordem: number | null = -1;
}

export class AvaliacaoRecursosCustomizado extends Serializable {
    @jsonProperty(Number, null)
    codigoConteudo: number | null = -1;

    @jsonProperty(Number, null)
    nota: number | null = -1;

    @jsonProperty(String, null)
    comentario: string = '';
}

export class ConteudoRecursoCustomizado extends Serializable {
    @jsonProperty(Number)
    codigo: number = -1;

    @jsonProperty(Number, null)
    codigoEtapa?: number | null = -1;

    @jsonProperty(String, null)
    nome: string = '';

    @jsonProperty(String, null)
    url: string = '';

    @jsonProperty(String, null)
    texto: string = '';

    @jsonProperty(String, null)
    usuarioResponsavel: string = '';

    @jsonProperty(String, null)
    ultimaAtualizacao: string = '';

    @jsonProperty(String, null)
    anexoBase64?: string = '';

    @jsonProperty(String, null)
    filePath: string = '';

    @jsonProperty(String, null)
    positionArquivoPrincipal: ArquivoPrincipalEnum = ArquivoPrincipalEnum.INICIO;

    @jsonProperty(TipoAnexoConteudo, null)
    tipoAnexo?: TipoAnexoConteudo;

    @jsonProperty(Boolean, null)
    isConcluido: boolean = false;

    @jsonProperty(Boolean, null)
    oculto: boolean = false;

    @jsonProperty(Number, null)
    ordem: number | null = -1;
}
