import React, { useContext, useEffect, useState } from 'react';
import Styled from './AdminEditor.styled';

import { GradientButton, RedSolidButton } from 'components/UI/Buttons';
import AdminHomeConteudoService from 'core/http/service/admin/AdminHomeConteudo.service';
import AdminImageUploader from '../imageUploader/AdminImageUploader';

import { IAdminEditSection } from './AdminEditBanner';
import AdminSecaoPaginaData, { AdminSecaoPaginaImagemSimples } from 'pages/gestao_paginas/AdminSecaoPaginaData';
import { AdminGestaoPaginasContext } from 'pages/gestao_paginas/context/AdminGestaoPaginasContext';
import { useParams } from 'react-router-dom';
import AdminGestaoPaginaService from 'core/http/service/admin/AdminGestaoPagina.service';
import { ToastError, ToastSuccess } from 'components/Toaster';
import Loading from 'components/Loading';
import { isStringsValid, isValidLink } from './isValidNewContentInput';
import AllowedGroupsValidator from 'pages/admin/components/allowedGroupsValidator/AllowedGroupsValidator';
import { KcAdminGroups } from 'models/kc/KcUserInfo';
import ModalExclusao from 'pages/gestao_paginas/new-edit-recurso/components/modal/ModalExclusao';
import TipoRedirecionamento from 'enums/TipoRedirecionamento';
import Select from 'components/Select';
import { getOptionFromValue, tipoRedirecionamentoOptions } from './tipoRedirecionamento';

const AdminEditSimpleImage = ({ onSaved, onUpdate, onDelete, mode }: IAdminEditSection) => {
    const params: any = useParams();
    const [idSecao, setIdSecao] = useState<number>(0);
    const [loading, setloading] = useState(false);
    const [cover, setCover] = useState<string | null>(null);
    const service = new AdminGestaoPaginaService();

    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [texto_botao_a, setTextoBotaoA] = useState('');
    const [link_interno, setLinkInterno] = useState('');
    const [tipoRedirecionamento, setTipoRedirecionamento] = useState<TipoRedirecionamento>(TipoRedirecionamento.LOGIN);

    const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
    const [showModalDeleteSecao, setShowModalDeleteSecao] = useState(false);
    const [modePage, setModePage] = useState<boolean>(false);

    const [submitting, setSubmitting] = useState(false);

    const { currentSection, sections, setSections } = useContext(AdminGestaoPaginasContext.Context);

    const MAX_CHAR_CONTENT = 1000;

    const isValidInput = (): boolean => {
        return (
            isValidLink(link_interno, false) && cover != null && cover.length > 0 && isStringsValid([title, text, texto_botao_a, link_interno])
        );
    };

    const saveSection = async (section: AdminSecaoPaginaData, holder: AdminSecaoPaginaImagemSimples) => {
        if (!section) {
            alert('Erro ao salvar!');
            return;
        }

        const { status, data } = await service.inserirSecao(holder, section.paginaId, section.ordem);

        if (status < 300) {
            section.setCodigo(data.codigo);
            onSaved(section);
            setModePage(true);
        } else ToastError('Houve um erro ao tentar salvar o seção!');
    };

    const updateSection = async (section: AdminSecaoPaginaData, holder: AdminSecaoPaginaImagemSimples) => {
        if (!section) {
            alert('Erro ao atualizar!');
            return;
        }

        const { status, data } = await service.atualizarSimplesImagem(section.codigo, holder);

        if (status < 300) {
            section.setCodigo(data.codigo);
            onUpdate(section);
        } else {
            ToastError('Houve um erro ao tentar atualizar o seção!');
        }
    };

    const handleDelete = async () => {
        setShowModalDeleteSecao(true);
        setIsLoadingDelete(true);

        try {
            const response = await service.deleteSecaoSimplesComImagem(idSecao);

            if (response.status === 200) {
                ToastSuccess('Simples com imagem excluído com sucesso!');
                setShowModalDeleteSecao(false);
                setIsLoadingDelete(false);
                onDelete();
            }
        } catch (error) {
            ToastError('Ocorreu um erro na hora da exclusão.');
        }
    };

    const submit = async () => {
        if (!currentSection) {
            alert('Erro ao salvar!');
            return;
        }

        setSubmitting(true);

        const holder = currentSection.modelData as AdminSecaoPaginaImagemSimples;

        holder.titulo = title;
        holder.textoBotaoA = texto_botao_a;
        holder.link = link_interno;
        holder.descricao = text;
        holder.anexoBase64 = cover;
        holder.tipoRedirecionamento = tipoRedirecionamento;

        currentSection.modelData = holder;

        setSections([...sections, currentSection]);

        console.log(holder);

        if (mode == 'NEW') await saveSection(currentSection, holder);
        else await updateSection(currentSection, holder);

        setSubmitting(false);
    };

    const fetchItem = async () => {
        if (mode == 'EDIT') {
            setloading(true);
            const id = String(params.id);
            const { data } = await service.buscaSecaoSimplesImagemPorId(parseInt(id));
            setIdSecao(parseInt(id));
            setText(data.descricao);
            setTitle(data.titulo);
            setLinkInterno(data.link);
            setTextoBotaoA(data.textoBotaoA);
            setCover(data.anexoBase64);
            setModePage(true);
            setTipoRedirecionamento(data.tipoRedirecionamento);
            setloading(false);
        }
    };

    useEffect(() => {
        fetchItem();
    }, []);

    return (
        <>
            <Styled.Container>
                <ModalExclusao
                    showModal={showModalDeleteSecao}
                    handleHiden={setShowModalDeleteSecao}
                    handleExcluxed={handleDelete}
                    isLoading={isLoadingDelete}
                />
                {!loading ? (
                    <>
                        <div>
                            <Styled.Label>Título da Seção</Styled.Label>
                            <Styled.Input value={title} onChange={(evt: any) => setTitle(evt.target.value)} />
                            <br />
                            <Styled.Label>Texto da Seção</Styled.Label>
                            <Styled.Input
                                value={text}
                                onChange={(evn: any) => setText(evn.target.value)}
                                rows={8}
                                as="textarea"
                                className={'form-control'}
                                maxLength={MAX_CHAR_CONTENT}
                            />
                            <Styled.CountLetter danger={text?.length >= MAX_CHAR_CONTENT}>
                                {text.length}/{MAX_CHAR_CONTENT}
                            </Styled.CountLetter>

                            <Styled.Label>Botão primário</Styled.Label>
                            <Styled.Input value={texto_botao_a} onChange={(evt: any) => setTextoBotaoA(evt.target.value)} />

                            <Styled.Label>Link do botão primario</Styled.Label>
                            <Styled.Input
                                className={link_interno && link_interno.length > 0 && !isValidLink(link_interno, false) && 'is-invalid'}
                                value={link_interno}
                                onChange={(evt: any) => setLinkInterno(evt.target.value)}
                            />
                            <Styled.SubLabel>
                                OBS: Para redirecionar o usuário para os <strong>links externos</strong>, é necessário seguir o padrão:{' '}
                                <strong>http://url</strong>
                                ou <strong>https://url</strong>. Para <strong>links internos</strong>, temos dois exemplos de opções:
                                <strong>http://ambiente/recursos/trilhas</strong>
                                ou <strong>/recursos/trilhas</strong>.
                            </Styled.SubLabel>

                            <Styled.Label>Tipo de redirecionamento</Styled.Label>
                            <Select
                                isSmall
                                value={getOptionFromValue(tipoRedirecionamento)}
                                itens={tipoRedirecionamentoOptions}
                                onSelected={o => setTipoRedirecionamento(o.value as TipoRedirecionamento)}
                            />
                            <Styled.SubLabel>
                                {`OBS: A opção "Tipo de Redirecionamento" foi projetada para uso em páginas que não possuem validação automática,
                            redirecionando o usuário para login ou cadastro caso ele esteja deslogado. Portanto, o comportamento pode variar caso
                            ele seja usado em fluxos que já são automatizados.`}
                            </Styled.SubLabel>
                        </div>

                        <Styled.RigthArea style={{ justifyContent: 'space-between' }}>
                            <AdminImageUploader
                                onSelect={() => {}}
                                setBase64={b => setCover(b)}
                                size={'sm'}
                                title={'Imagem do banner'}
                                preImageB64={cover}
                                recommendedDimension={{ w: 460, h: 530 }}
                            />
                            <br />
                            {modePage && (
                                <AllowedGroupsValidator allowedGroups={[KcAdminGroups.MASTER]}>
                                    <RedSolidButton style={{ marginBottom: 20 }} onClick={() => setShowModalDeleteSecao(true)}>
                                        Deletar
                                    </RedSolidButton>
                                </AllowedGroupsValidator>
                            )}
                            <GradientButton disabled={submitting || !isValidInput()} onClick={submit}>
                                {submitting ? 'Salvando...' : 'Salvar'}
                            </GradientButton>
                        </Styled.RigthArea>
                    </>
                ) : (
                    <Loading />
                )}
            </Styled.Container>
        </>
    );
};

export default AdminEditSimpleImage;
