import React, { useEffect, useState } from 'react';
import Styled from '../AdminGestaoPaginasPage.styled';
import { ComentarioGestaoAdmin } from 'models/recursos-costumizados/ComentariosRecursosCostumizados';
import AdminRecursosCostumizados from 'core/http/service/admin/AdminRecursosCostumizados.service';
import Badge from 'components/badge/Badge';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import eyeIcon from '../../../images/admin/eye.svg';
import CommentModal from './CommentModal';
import Select, { SelectOptionType } from 'components/Select';
import AdminPagination from 'pages/admin/components/pagination/AdminPagination';
import AdminSwitch from 'pages/admin/components/switch/AdminSwitch';
import { cloneDeep } from 'lodash';

const CommentaryGestaoDePaginas = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [recursoComentario, setRecursoComentario] = useState<ComentarioGestaoAdmin[]>([]);
    const [showIdItemModal, setShowIdItemModal] = useState(-1);
    const [page, setPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState(1);
    const [filterResponse, setFilterResponse] = useState('');

    const changePage = (page: number) => {
        setPage(page);
    };

    const answeredFilter: SelectOptionType[] = [
        {
            label: 'Selecione',
            value: '',
        },
        {
            label: 'Respondido',
            value: 'true',
        },
        {
            label: 'Não Respondido',
            value: 'false',
        },
    ];

    const service = new AdminRecursosCostumizados();

    const fetchComentarios = async (filtro?: string, respondido?: string | undefined) => {
        setLoading(true);
        const { data } = await service.getAllComments(page, filtro, respondido);
        setTotalPages(data.totalPages);
        setRecursoComentario(data.rows);
        setLoading(false);
    };

    const enableComment = async (idx: number) => {
        const clone = cloneDeep(recursoComentario);
        clone[idx].ativo = !clone[idx].ativo;
        const { data } = await service.habilitarComentario(clone[idx].codigo, { comentario: clone[idx].comentario, ativo: clone[idx].ativo });
        setRecursoComentario(clone);
    };

    useEffect(() => {
        fetchComentarios();
    }, []);

    useEffect(() => {
        fetchComentarios();
    }, [page]);

    return (
        <>
            <CommentModal
                show={showIdItemModal != -1}
                onHide={() => setShowIdItemModal(-1)}
                comment={recursoComentario.find(i => i.codigo == showIdItemModal)}
                updateData={() => fetchComentarios('', filterResponse)}
            />
            <Styled.RowRigth>
                <span className="label-order">Respondido</span>
                <span className="span-select">
                    <Select
                        width="200px"
                        isSmall={true}
                        itens={answeredFilter}
                        onSelected={o => {
                            fetchComentarios('', o.value.toString() ?? '');
                            setFilterResponse(o.value.toString() ?? '');
                        }}
                    />
                </span>
            </Styled.RowRigth>
            <Styled.TableGrid>
                <Styled.TableGridHead>
                    <Styled.TableGridHeadCell>Nome da página</Styled.TableGridHeadCell>
                    <Styled.TableGridHeadCell>Nome do conteúdo</Styled.TableGridHeadCell>
                    <Styled.TableGridHeadCell>Autor</Styled.TableGridHeadCell>
                    <Styled.TableGridHeadCell>E-mail</Styled.TableGridHeadCell>
                    <Styled.TableGridHeadCell>Respondido</Styled.TableGridHeadCell>
                    <Styled.TableGridHeadCell>Ações</Styled.TableGridHeadCell>
                </Styled.TableGridHead>
                {!loading &&
                    recursoComentario.map((comentario, idx) => {
                        return (
                            <Styled.TableGridRow key={comentario.codigo}>
                                <Styled.TableGridCell>{comentario.nomePagina}</Styled.TableGridCell>
                                <Styled.TableGridCell>{comentario.nomeConteudo}</Styled.TableGridCell>
                                <Styled.TableGridCell>{comentario.nomeUsuario}</Styled.TableGridCell>
                                <Styled.TableGridCell>
                                    {comentario.email ?? (comentario.nomeUsuario === 'humane' && 'adminestruturante@gmail.com')}
                                </Styled.TableGridCell>
                                <Styled.TableGridCell>
                                    <Badge color={comentario.respostaAdmin ? '#229645' : '#D64545'}>
                                        {comentario.respostaAdmin ? 'Sim' : 'Não'}
                                    </Badge>
                                </Styled.TableGridCell>

                                <Styled.TableGridCell>
                                    <OverlayTrigger
                                        overlay={<Tooltip id={'tooltip-action-view_' + comentario.codigo}>Visualizar</Tooltip>}
                                        placement={'top'}
                                    >
                                        <span>
                                            <Styled.ViewButton
                                                style={{ marginRight: '5px' }}
                                                src={eyeIcon}
                                                onClick={() => setShowIdItemModal(comentario.codigo)}
                                            />
                                        </span>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip id={'tooltip-action-edit_' + comentario.codigo}>
                                                {`${comentario.ativo ? 'Desabilitar ' : 'Habilitar '}`}
                                                publicação do comentário
                                            </Tooltip>
                                        }
                                        placement={'top'}
                                    >
                                        <span>
                                            <AdminSwitch value={comentario.ativo} onChange={() => enableComment(idx)} />
                                        </span>
                                    </OverlayTrigger>
                                </Styled.TableGridCell>
                            </Styled.TableGridRow>
                        );
                    })}
            </Styled.TableGrid>
            <AdminPagination onChangePage={changePage} total={totalPages} />
        </>
    );
};

export default CommentaryGestaoDePaginas;
