import CardModulos, { StatusCardModulo } from 'models/secoes/CardModulos';
import instanciaCardModulo from './TrilhaApronfundadaInfoCard';

export const CardCursoLivre: CardModulos[] = [
    instanciaCardModulo({
        title: 'Histórico das políticas públicas e o cenário atual',
        description:
            'Neste módulo, falaremos sobre a história da escrita e do alfabeto, o olhar para a educação integral, os dados atuais sobre o cenário da alfabetização no Brasil e as políticas públicas já propostas e implementadas.',
        status: StatusCardModulo.DISPONIVEL,
        ordem: 1,
        time: '1h30',
        link: '/recursos/trilhas/trilha-de-alfabetizacao',
    }),
    instanciaCardModulo({
        title: 'Conceitos e estratégias de Alfabetização',
        description:
            'Conheça os sentidos e as práticas da alfabetização considerando diferentes abordagens na perspectiva da BNCC e outros documentos orientadores.',
        status: StatusCardModulo.DISPONIVEL,
        ordem: 2,
        time: '2h',
        link: '/recursos/trilhas/trilha-de-alfabetizacao',
    }),
    instanciaCardModulo({
        title: 'Alfabetização Matemática',
        description:
            'Saiba mais sobre o que é a alfabetização matemática e sobre como potencializar o processo de aprendizagem afim de apoiar os estudantes a se apropriarem da linguagem matemática e a utilizá-la em sua vida cotidiana.',
        status: StatusCardModulo.DISPONIVEL,
        ordem: 3,
        time: '1h30',
        link: '/recursos/trilhas/trilha-de-alfabetizacao',
    }),
    instanciaCardModulo({
        title: 'Educação Integral com foco em Alfabetização nos anos iniciais',
        description:
            'Conheça mais sobre educação integral com foco na alfabetização, considerando a formação plena do estudante e o desenvolvimento socioemocional intencional desde o início de seu processo escolar.',
        status: StatusCardModulo.DISPONIVEL,
        ordem: 4,
        time: '2h',
        link: '/recursos/trilhas/trilha-de-alfabetizacao',
    }),
];
