import React from 'react';
import Styled from './ResultadoPesquisa.styled';

export interface IResultadoPesquisaPagination {
    page: number;
    perPage: number;
    totalItems: number;

    onSelectPage: (page: number) => void;
}

const ResultadoPesquisaPagination = ({ totalItems, page, perPage, onSelectPage }: IResultadoPesquisaPagination) => {
    const getTotalPages = (): number => {
        return Math.ceil(totalItems / perPage);
    };

    const stepPage = (step: number): void => {
        onSelectPage(page + step);
    };

    return (
        <>
            <Styled.PaginationContainer>
                <span>
                    Visualizando {1 * page + 1} - {page * perPage + perPage} de {totalItems} resultados
                </span>
                <Styled.PaginationButtons>
                    {page > 0 && (
                        <Styled.PaginationButton onClick={() => stepPage(-1)} clickable={true}>
                            ←
                        </Styled.PaginationButton>
                    )}

                    {[...Array(getTotalPages())].map((_, i) => {
                        return (
                            <Styled.PaginationButton key={i} clickable={i != page} onClick={() => onSelectPage(i)}>
                                {i + 1}
                            </Styled.PaginationButton>
                        );
                    })}

                    {page + 1 < getTotalPages() && (
                        <Styled.PaginationButton onClick={() => stepPage(1)} clickable={true}>
                            →
                        </Styled.PaginationButton>
                    )}
                </Styled.PaginationButtons>
            </Styled.PaginationContainer>
        </>
    );
};

export default ResultadoPesquisaPagination;
