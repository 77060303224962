import styled from 'styled-components';
import Colors from '../../../../../enums/Colors';
import { MobileMaxWidth } from '../../../../../constants/Dimensions';
import FullCss, { MediaQuery } from 'core/util/FullCss';
import { mediaOnlyScreenMaxWidthStr } from 'core/util/cssHelp';
import { propTypes } from 'react-bootstrap/esm/Image';

export const Wrapper = styled.div(() => {
    return new FullCss(
        {},
        new MediaQuery(mediaOnlyScreenMaxWidthStr(MobileMaxWidth), {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '0px',
            paddingBottom: '15px',
        })
    ).getCss();
});

export const CardStyle2 = styled.div(() => {
    return new FullCss(
        {
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '5px',
            height: '500px',
            width: `440px`,
            boxShadow: '2px 2px 20px 6px rgba(0, 0, 0, 0.15)',
        },
        new MediaQuery(mediaOnlyScreenMaxWidthStr(MobileMaxWidth), {
            width: '289px',
            height: '470px',
            marginRight: '0',
        })
    ).getCss();
});

export const CardStyle = styled.div<{ width: number }>`
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    height: 500px;
    width: ${props => props.width}px;
    box-shadow: 2px 2px 20px 6px rgba(0, 0, 0, 0.15);
    background-color: #FFFFFF;


   

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        width: 289px;
        height: 470px;
        marginright: 0;
    }
`;

export const CardImgContainer = styled.div(() => {
    return new FullCss(
        {
            width: '100%',
            height: '174px',
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
            position: 'relative',
        },
        [
            new MediaQuery(mediaOnlyScreenMaxWidthStr(MobileMaxWidth), {
                height: '124px',
            }),
            new MediaQuery(':hover', {
                background: 'rgba(0, 0, 0, 0.85)',
            }),
        ]
    ).getCss();
});

export const CardImg = styled.img(() => {
    return new FullCss(
        {
            width: '100%',
            height: '174px',
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
            objectFit: 'cover',
            transition: '0.5s ease',
            display: 'block',
            backfaceVisibility: 'hidden',
        },
        new MediaQuery(mediaOnlyScreenMaxWidthStr(MobileMaxWidth), {
            height: '124px',
        })
    ).getCss();
});

export const Title = styled.a(() => {
    return new FullCss(
        {
            paddingTop: '24px',
            color: Colors.DarkBlue,
            fontWeight: '700',
            fontSize: '20px',
            display: 'inline-block',
            cursor: 'pointer',
        },
        new MediaQuery(mediaOnlyScreenMaxWidthStr(MobileMaxWidth), {
            fontSize: '20px',
            lineHeight: '24px',
            paddingTop: '20px',
        })
    ).getCss();
});

export const TextBox = styled.div(() => {
    const mq = [
        new MediaQuery('&::-webkit-scrollbar', {
            width: '12px',
            boxSizing: 'content-box',
        }),
        new MediaQuery('&::-webkit-scrollbar-thumb', {
            backgroundColor: '#d8d8d8',
            borderRadius: '20px',
            margin: '10px',
            border: '4px solid white',
            boxSizing: 'content-box',
        }),
        new MediaQuery('&::-webkit-scrollbar-track', {
            background: 'transparent',
        }),
    ];
    return new FullCss(
        {
            height: '80%',
            overflow: 'auto',
            // border: '1px blue solid',
            marginBottom: '10px',
        },
        mq
    ).getCss();
});

export const DescriptionText = styled.p(() => {
    return new FullCss(
        {
            fontFamily: "'Inter', sans-serif",
            paddingTop: '24px',
            fontWeight: '400',
            fontSize: '16px',
            lineHeight: '20px',
            color: Colors.DarkGrey,
        },
        new MediaQuery(mediaOnlyScreenMaxWidthStr(MobileMaxWidth), {
            fontSize: '14px',
            lineHeight: '130%',
            paddingTop: '15px',
        })
    ).getCss();
});

export const ContentStyle = styled.div(() => {
    return new FullCss(
        {
            flex: '1',
            width: '100%',
            height: '50%',
            paddingLeft: '25px',
            paddingRight: '25px',
            lineHeight: '18px',
        },
        new MediaQuery(mediaOnlyScreenMaxWidthStr(MobileMaxWidth), {
            height: '45%',
            paddingRight: '33px',
            paddingLeft: '33px',
        })
    ).getCss();
});

export const PrimaryButton = styled.button(() => {
    return new FullCss(
        {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '150px',
            height: '50px',
            border: `2px solid ${Colors.Blue}`,
            color: Colors.Blue,
            borderRadius: '6px',
            background: 'transparent',
        },
        new MediaQuery(':hover', {
            background: Colors.Blue,
            color: Colors.White,
        })
    ).getCss();
});

export const CardTagsContainer = styled.div(() => {
  return new FullCss (
    {
      display: 'flex',
    }
  ).getCss();
})

export interface ISecondaryButton {
    isActive: boolean;
}

export const SecondaryButton = styled.button<ISecondaryButton>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 480px;
    height: 50px;
    border: 2px solid ${props => (props.isActive ? Colors.Blue : Colors.LightGrey)};
    border-radius: 6px;
    background: ${props => (props.isActive ? Colors.Blue : Colors.LightGrey)};
    color: ${Colors.White};
    font-weight: 700;

    &:hover {
        background: ${props => (props.isActive ? Colors.White : '')};
        color: ${props => (props.isActive ? Colors.Blue : '')};
        cursor: ${props => (!props.isActive ? 'auto' : '')};
    }
`;

export const TerciaryButton = styled(PrimaryButton)(() => {
    return new FullCss({
        width: '480px',
    }).getCss();
});

export const HoverButton = styled.button(() => {
    return new FullCss({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '180px',
        height: '45px',
        border: `2px solid ${Colors.White}`,
        color: Colors.White,
        borderRadius: '6px',
        background: 'transparent',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        gap: '5px',
    }).getCss();
});

export const SvgIcon = styled.img(() => {
    return new FullCss({
        height: '15px',
        width: '15px',
    }).getCss();
});

export const FooterStyle = styled.div(() => {
    return new FullCss({
        width: '100%',
        borderRadius: '0px 0px 5px 5px',
        padding: '0 20px 20px 20px',
        gap: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '20px',
    }).getCss();
});

export const DefaultText = styled.p(() => {
    return new FullCss({
        fontWeight: 'normal',
        fontSize: '15px',
        lineHeight: '137%',
        letterSpacing: '0.3px',
        color: 'white',
        textAlign: 'center',
    }).getCss();
});

export const CardTag = styled.div<{ bgColor: string; textColor: string; twoCards: boolean; exibirAbaixo?: boolean }>`
    min-width: 170px;
    position: relative;
    height: 23px;
    width: fit-content;
    padding: 2px 8px 2px 8px;
    border-radius: 5px;
    gap: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => (props.bgColor ? props.bgColor : Colors.Grey12)};
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -0.01em;
    color: ${props => (props.textColor ? props.textColor : Colors.UncleanWhite)};
    top: -160px;
    left: ${props => (props.twoCards ? '12%' : '58%')};
    margin-left: ${props => (props.twoCards ? '5px' : '0px')};

    @media only screen and (max-width: 760px) {
        left: 35%;
    }
`;
