import React, { ReactText, useEffect, useState } from 'react';
import Styled from './AdminGestaoCertificadosUsuarios.styled';
import { Divider } from 'pages/profile/components/identificacaoImagem/components/ImageSelectMobile.styled';
import { YellowButton } from 'components/UI/Buttons';
import { useHistory, useParams } from 'react-router-dom';
import { DatePicker, Radio, Table, TableColumnsType } from 'antd';
import { UsuariosCertificadosService } from 'core/http/service/UsuariosCertificados.service';
import { UsuariosCertificadosModel } from 'models/certificacoes/UsuariosCertificadosModel';
import { SearchProps } from 'antd/es/input';
import Search from 'antd/es/input/Search';
import { MdCheckBox } from 'react-icons/md';
import { IoMdHelpCircleOutline } from 'react-icons/io';
import { IoReload } from 'react-icons/io5';
import moment from 'moment';
import { CertificacoesModel } from 'models/certificacoes/CertificacoesModel';

const { RangePicker } = DatePicker;

const formatCPF = (cpf: string): string => {
    if (!cpf) return '';
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
};

const EmitedStatus: React.FC<{ emited: boolean }> = ({ emited }) => {
    return (
        <span>
            {emited ? (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem',
                    }}
                >
                    <MdCheckBox size={18} style={{ color: 'green' }} />
                    Emitido
                </div>
            ) : (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem',
                    }}
                >
                    <IoMdHelpCircleOutline size={18} style={{ color: 'orange' }} />
                    Pendente
                </div>
            )}
        </span>
    );
};

const columns: TableColumnsType<UsuariosCertificadosModel> = [
    {
        title: 'Nome',
        dataIndex: 'nome',

        onFilter: (value, record) => record.nome.indexOf(value as string) === 0,
        sorter: (a, b) => a.nome.length - b.nome.length,
        sortDirections: ['descend'],
    },
    {
        title: 'CPF',
        dataIndex: 'cpf',
        render: (cpf: string) => formatCPF(cpf),
    },
    {
        title: 'E-mail',
        dataIndex: 'email',
    },
    {
        title: 'Status',
        dataIndex: 'emited',
        key: 'status',
        filters: [
            {
                text: 'Pendente',
                value: 'pendente',
            },
            {
                text: 'Emitido',
                value: 'emitido',
            },
        ],
        onFilter: (value, record) => {
            if (value === 'emitido') {
                return record.emited === true;
            }
            if (value === 'pendente') {
                return record.emited === false;
            }
            return true;
        },
        render: emited => <EmitedStatus emited={emited} />,
    },
    {
        title: 'Data de Solicitação',
        dataIndex: 'dataCreate',
        render: (dataCreate: string) => moment(dataCreate).format('DD/MM/YYYY HH:mm:ss'),
    },
];

const AdminGestaoCertificadosUsuarios = () => {
    const history = useHistory();
    const [filteredUsers, setFilteredUsers] = useState<UsuariosCertificadosModel[]>([]);
    const [listUsers, setListUsers] = useState<UsuariosCertificadosModel[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [dateRange, setDateRange] = useState<string[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<ReactText[]>([]);
    const [dadosCertificado, setDadosCertificado] = useState<CertificacoesModel>();

    const [searchNome, setSearchNome] = useState('');
    const [searchCpf, setSearchCpf] = useState('');
    const [searchEmail, setSearchEmail] = useState('');

    const service = new UsuariosCertificadosService();


    const [filter, setFilter] = useState({
        filterNome: '',
        filterCpf: '',
        filterEmail: '',
    });

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys: ReactText[], selectedRows: any) => {
            setSelectedRowKeys(selectedRowKeys);
        },
    };

    const atualizarTodosUsuarios = async (ids: any[]) => {

        await service.atualizarTodosUsersEmited(ids);
        await fetchListAll();
        
    };

    const handleDownloadCSV = () => {
        const selectedUsers = filteredUsers.filter((user: UsuariosCertificadosModel) => {
            if (selectedRowKeys === null || user.codigo === null) {
                return false;
            } else {
                return selectedRowKeys.includes(user.codigo);
            }
        });

        const csvData = selectedUsers.map(user => ({
            Nome: user.nome,
            CPF: formatCPF(user.cpf),
            Email: user.email,
        }));

        const listUserAtt = selectedUsers.map(user => user.codigo);

        const csvContent = [['Nome', 'CPF', 'Email'], ...csvData.map(user => [user.Nome, user.CPF, user.Email])]
            .map(e => e.join(','))
            .join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${dadosCertificado?.nome}-${new Date().getMilliseconds()}-${new Date().getSeconds()}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        atualizarTodosUsuarios(listUserAtt);
    };

    const goToBack = () => history.push('/admin/gestao-certificados');
    const { id } = useParams<{ id: string }>();


    const onSearchNome: SearchProps['onSearch'] = (value, _e, info) => {
        setFilter({ ...filter, filterNome: value });
        setSearchNome(value);
    };

    const onSearchCpf: SearchProps['onSearch'] = (value, _e, info) => {
        setFilter({ ...filter, filterCpf: value });
        setSearchCpf(value);
    };

    const onSearchEmail: SearchProps['onSearch'] = (value, _e, info) => {
        setFilter({ ...filter, filterEmail: value });
        setSearchEmail(value);
    };

    const handleRangePickerChange = (dates: any, dateStrings: any) => {
        if (!dates) {
            setDateRange([]);
        } else {
            setDateRange(dateStrings);
        }
    };

    const fetchListAll = async () => {
        try {
            setLoading(true);
            const response = await service.listarAll(parseInt(id));
            const data = response.data;

            const dataWithKeys = data.usuarios.map((item: UsuariosCertificadosModel, index: number) => ({
                ...item,
                key: item.codigo || index,
            }));

            setListUsers(dataWithKeys);
            setDadosCertificado(data.certificacao);
        } catch (error) {}

        setLoading(false);
    };

    const resetFilter = () => {
        setFilter({ filterNome: '', filterCpf: '', filterEmail: '' });
        setDateRange([]);
        setFilteredUsers(listUsers);
        setSearchNome('');
        setSearchCpf('');
        setSearchEmail('');
    };
    useEffect(() => {
        setFilteredUsers(applyFilters(listUsers, filter));
    }, [filter, listUsers, dateRange]);

    const applyFilters = (users: UsuariosCertificadosModel[], filter: any) => {
        if (!filter) {
            return users;
        }

        return users.filter(user => {
            const matchNome = user.nome.toLowerCase().includes(filter.filterNome.toLowerCase());
            const matchCpf = user.cpf.includes(filter.filterCpf);
            const matchEmail = user.email.toLowerCase().includes(filter.filterEmail.toLowerCase());

            let matchDate = true;

            if (dateRange.length > 0) {
                const formattedStartDate = moment(dateRange[0], 'DD-MM-YYYY').startOf('day');
                const formattedEndDate = moment(dateRange[1], 'DD-MM-YYYY').endOf('day');
                const userDate = moment(user.dataCreate);

                matchDate = userDate.isSameOrAfter(formattedStartDate) && userDate.isSameOrBefore(formattedEndDate);
            }

            return matchNome && matchCpf && matchEmail && matchDate;
        });
    };

    useEffect(() => {
        setListUsers([]);
        fetchListAll();
    }, [id]);

    return (
        <Styled.Container>
            <Styled.Column style={{ gap: '0.5rem' }}>
                <Styled.ButtonGoBack onClick={goToBack}>Voltar</Styled.ButtonGoBack>
                <Styled.RowBetween>
                    <Styled.Title>Certificação: {dadosCertificado?.nome}</Styled.Title>
                    <YellowButton
                        onClick={handleDownloadCSV}
                        style={{
                            width: '200px',
                            height: '40px',
                        }}
                    >
                        Gerar relatório
                    </YellowButton>
                </Styled.RowBetween>
            </Styled.Column>
            <Divider
                style={{
                    margin: '10px',
                }}
            />
            <Styled.Column style={{ gap: '0.5rem' }}>
                <Styled.RowBetween>
                    <Styled.Row style={{ gap: '0.5rem' }}>
                        <RangePicker
                            placeholder={['Inicio', 'Fim']}
                            format={'DD-MM-YYYY'}
                            style={{ width: '250px' }}
                            onChange={handleRangePickerChange}
                        />
                        <Search
                            placeholder="Pesquisar por nome"
                            onSearch={onSearchNome}
                            value={searchNome}
                            onChange={e => setSearchNome(e.target.value)}
                            style={{ width: 200, marginRight: 8 }}
                        />
                        <Search
                            placeholder="Pesquisar por CPF"
                            onSearch={onSearchCpf}
                            value={searchCpf}
                            onChange={e => setSearchCpf(e.target.value)}
                            style={{ width: 200, marginRight: 8 }}
                        />
                        <Search
                            placeholder="Pesquisar por E-mail"
                            onSearch={onSearchEmail}
                            value={searchEmail}
                            onChange={e => setSearchEmail(e.target.value)}
                            style={{ width: 200, marginRight: 8 }}
                        />
                    </Styled.Row>
                    <Styled.ButtonFilterReset onClick={resetFilter}>
                        <IoReload />
                        <p>Limpar Filtros</p>
                    </Styled.ButtonFilterReset>
                </Styled.RowBetween>
                <div>
                    <Divider />

                    <Table
                        rowSelection={{
                            type: 'checkbox',
                            ...rowSelection,
                        }}
                        loading={loading}
                        columns={columns}
                        dataSource={filteredUsers}
                    />
                </div>
            </Styled.Column>
        </Styled.Container>
    );
};

export default AdminGestaoCertificadosUsuarios;
