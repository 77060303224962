import Colors from 'enums/Colors';
import { Container } from 'react-bootstrap';
import { colors } from 'react-select/src/theme';
import styled from 'styled-components';

const StyledInPractice = {
    Container: styled.div<{ bgColor?: Colors; color?: Colors; isNotArray?: boolean }>`
        width: 100%;
        height: ${({ isNotArray }) => (isNotArray ? 'auto' : '600px')};
        display: flex;
        flex-direction: row;
        padding-top: 70px;
        background-color: ${({ bgColor }) => (bgColor ? bgColor : Colors.Blue)};
        color: ${({ color }) => color};

        @media screen and (max-width: 1090px) {
            flex-direction: column-reverse;
            height: 900px;
            padding-top: 0px;
        }
    `,

    CardInPractice: {
        Container: styled.div`
            position: relative;
            width: 900px;
            height: 420px;
            object-fit: cover;
            border-radius: 20px;

            @media screen and (max-width: 1980px) {
                width: 700px;
            }

            @media screen and (max-width: 1500px) {
                width: 500px;
                height: 380px;
            }

            @media screen and (max-width: 1040px) {
                width: 600px;
            }

            @media screen and (max-width: 940px) {
                width: 500px;
            }

            @media screen and (max-width: 640px) {
                width: 450px;
                height: 280px;
            }

            @media screen and (max-width: 480px) {
                width: 100%;
                height: 230px;
            }
        `,
    },

    Column: styled.div`
        min-height: 350px;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        width: 100%;
        display: flex;
        align-items: center;
        padding-left: 70px;
        margin: 0;

        @media screen and (max-width: 1250px) {
            padding-top: 0px;
        }

        @media screen and (max-width: 1090px) {
            align-items: start;
        }
        @media screen and (max-width: 600px) {
            padding-left: 20px;
        }
    `,

    Title: styled.h1`
        font-family: 'Barlow';
        font-size: 56px;
        font-weight: 700;
        line-height: 61.6px;
        margin-right: 200px;

        @media screen and (max-width: 480px) {
            padding-left: 20px;
        }
    `,

    Carrousel: styled.div`
        width: 50%;
        display: flex;
        height: 600px;
        margin-left: 120px;

        @media screen and (max-width: 1280px) {
            margin-left: 20px;
        }

        @media screen and (max-width: 1090px) {
            width: 100%;
            margin-left: 0px;
            height: auto;
            margin-bottom: 100px;
            margin-top: 20px;
        }
    `,

    CarouselCardContainer: styled.div`
        width: 100%;
        .slick-slide {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .slick-dots {
            li {
                button::before {
                    color: #4781ae; /* Define a cor das dots */
                    font-size: 20px;
                }
                &.slick-active {
                    button::before {
                        color: #b1e9ff; /* Define a cor para a dot selecionada */
                    }
                }
            }
        }
    `,

    Description: styled.p<{ isNotArray?: boolean }>`
        font-family: 'Barlow';
        font-size: 20px;
        font-weight: 400;
        line-height: 28.8px;
        max-width: ${({ isNotArray }) => (isNotArray ? '50%' : '500px')};
        text-align: left;

        @media screen and (max-width: 1090px) {
            max-width: 80%;
        }
        @media screen and (max-width: 480px) {
            padding-left: 20px;
        }
    `,

    Dots: styled.div`
        width: 10px;
        height: 10px;
        margin-top: 20px;
        margin: 10px;
        background-color: ${Colors.White};
        border-radius: 100%;
    `,

    Img: styled.img`
        width: 100%;
        height: 100%;
        border-radius: 20px;
        object-fit: cover;
    `,

    Play: styled.div`
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        padding: 20px;
        top: 45%;
        left: 45%;
        background-color: ${Colors.DeleteRed};
        width: 100px;
        cursor: pointer;
        transition: all 0.5s;

        &:hover {
            background-color: ${Colors.Red};
        }
    `,
};

export default StyledInPractice;
