import { MobileMaxWidth } from 'constants/Dimensions';
import Colors from 'enums/Colors';
import styled from 'styled-components';

const StyledAdminGestaoCertificacaoUsuarios = {
    Container: styled.div`
        padding: 20px;
        margin-bottom: 50px;
        margin-left: 100px;
        display: flex;
        flex-direction: column;
        max-width: 1200px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            padding: 5px;
        }
    `,

    Title: styled.h1`
        font-size: 24px;
        font-weight: bold;
        color: ${Colors.Blue};
    `,

    Row: styled.div`
        display: flex;
        align-items: center;
    `,

    RowBetween: styled.div`
        display: flex;
        justify-content: space-between;
    `,

    ButtonGoBack: styled.button`
        width: 80px;
        margin-right: 10px;
        height: 30px;
        border-radius: 10px;
        font-weight: 500;
        border: 2px solid ${Colors.Blue};

        &:hover {
            background-color: ${Colors.Blue};
            color: ${Colors.White};
        }
    `,

    Column: styled.div`
        display: flex;
        flex-direction: column;
        padding-left: 10px;
    `,

    ButtonFilterReset: styled.button`
        display: flex;
        align-items: center;
        height: 30px;
        margin-top: 2px;
        gap: 0.5rem;
        padding: 5px;
        border-radius: 5px;
        border: 1px solid #d9d9d9;
        background-color: ${Colors.White};

        &:hover {
            border: 1px solid #4096ff;
        }
    `,
};

export default StyledAdminGestaoCertificacaoUsuarios;
