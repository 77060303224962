import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    height: fit-content;
    gap: 25px;

    @media screen and (max-width: 950px) {
        flex-direction: column;
    }
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 10px;
`;

export const Flexbox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
`;

export const DescText = styled.p`
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #747474;
    max-width: 140px;
`;

export const PreviewImage = styled.img`
    height: 150px;
    max-width: 150px;
    object-fit: cover;
    border-radius: 50%;
`;

export const ImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: fit-content;
    position: relative;

    p {
        text-align: right;
    }
`;

export const Hidden = styled.input`
    display: none;
`;
