import styled, { keyframes } from 'styled-components';
import Colors from '../../../../enums/Colors';
import { MobileBiggerWidth, MobileMaxWidth } from '../../../../constants/Dimensions';
import FullCss, { MediaQuery } from 'core/util/FullCss';
import { mediaOnlyScreenMaxWidthStr } from 'core/util/cssHelp';

export const ObjectivesContainerStyle = styled.div`
    display: flex;
    flex-wrap: wrap;
    min-height: 580px;
    align-items: center;
    justify-content: center;
    background-color: ${Colors.Blue};
    padding-top: 20px;
    padding-bottom: 20px;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        flex-direction: column;
        flex-wrap: unset;
        min-height: unset;
        align-items: center;
        padding-top: 50px;
        padding-bottom: 60px;
    }
`;

export const TitleContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        margin-top: 0px;
    }
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 2.5vw;

    @media only screen and (max-width: ${MobileBiggerWidth}px) {
        margin-right: unset;
    }

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-top: 50px;
        margin-right: unset;
        padding: 0 12vw 0 12vw;
    }
`;

export const TitleText = styled.p`
    font-size: 32px;
    color: white;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    line-height: 42px;
    letter-spacing: 0.1px;
    transform: translate(40px, -70px);

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        transform: translate(0px, -20px);
    }
`;

export const ItemContainer = styled.div`
    flex: 1 1 0%;
    height: 100%;
    display: flex;
    -webkit-box-pack: center;
    -webkit-box-align: center;
    align-items: flex-start;
    justify-content: space-around;

    @media only screen and (max-width: ${MobileBiggerWidth}px) {
        margin-right: unset;
    }

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-top: 50px;
        margin-right: unset;
        padding: 0 12vw 0 12vw;
    }
`;

const fadeIn = keyframes`
  0% { opacity: 0.6; }
  80% {opacity: 0.8;}
  100% {opacity: 1;}

`;

export const CarouselDots = styled.div`
    bottom: -40px;
    .slick-active div {
        background: ${Colors.White};
    }
`;

export const CarouselCustomPaging = styled.div`
    width: 12px;
    height: 12px;
    border: 1px solid ${Colors.Grey5};
    border-radius: 50%;
`;

export const CardsContainer = styled.div`
    @media only screen and (min-width: ${MobileMaxWidth - 1}px) {
        display: flex;
        align-items: center;
        margin: 0px 0px 3vh 0px;
        padding: 20px 0px 20px 9vw;
        overflow-x: auto;
        gap: 25px;

        scroll-behavior: smooth;

        &::-webkit-scrollbar {
            width: 12px;
            box-sizing: content-box;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #d8d8d8; /* color of the scroll thumb */
            border-radius: 20px; /* roundness of the scroll thumb */
            margin: 10px;
            border: 4px solid white;
            box-sizing: content-box;
        }

        &::-webkit-scrollbar-track {
            background: transparent; /* color of the tracking area */
        }
    }

    animation: ${fadeIn} 0.3s linear;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        .slick-slide {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
`;

export const SliderContainer = styled.div`
    max-width: 100%;
    height: fit-content;
    padding-bottom: 10px;
`;
