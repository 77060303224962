import React, { useEffect, useState } from 'react';
import Styled from './SupportMaterial.styled';
import Imagem from '../../assets/imagemTeste.svg';
import Colors from 'enums/Colors';
import { IsCustomMaxWidth, IsMobileMaxWidth } from 'core/util/WindowSize';
import Slider from 'react-slick';

interface ISupportMaterial {
    isBlue?: boolean;
    description: string;
    img: string;
    slideToScrool?: number;
    isBorderBlue?: boolean;
}

const SupportMaterial = ({ isBlue, description, img, slideToScrool, isBorderBlue }: ISupportMaterial) => {
    const isMobile = IsMobileMaxWidth();

    return (
        <Styled.Container style={{ backgroundColor: isBlue ? Colors.Blue : Colors.White, color: isBlue ? Colors.White : Colors.Blue }}>
            <Styled.Content>
                <Styled.Column>
                    <Styled.Title style={{ color: isBlue ? Colors.White : Colors.Blue }}>MATERIAL DE APOIO</Styled.Title>
                    <div
                        style={{
                            maxWidth: '80%',
                            lineHeight: '40px',
                            fontFamily: 'Barlow',
                            fontSize: '20px',
                            fontWeight: 400,
                        }}
                        dangerouslySetInnerHTML={{
                            __html: description,
                        }}
                    ></div>
                </Styled.Column>
            </Styled.Content>
            <div style={{ width: IsCustomMaxWidth(900) ? '100%' : '50%', display: "flex", alignItems: "center", justifyContent: "center"}}>
                <img
                    style={{
                        height: isMobile ? '300px' : '450px',
                    }}
                    src={img}
                />
            </div>
        </Styled.Container>
    );
};

export default SupportMaterial;
