import { GTM_Page_Home } from 'components/GoogleTagManager';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, ButtonContainer, DescriptionContainer, TitleText, MainContainer, Text, TitleContainer } from './SuporteContainer.styled';

interface ISuporteContainer {
    padding?: string;
}

const SuporteContainer = ({ padding }: ISuporteContainer) => {
    const history = useHistory();

    const goToSuporte = () => {
        GTM_Page_Home('secao_suporte', 'btn_click_acessar_suporte');
        history.push('/suporte');
    };

    return (
        <MainContainer id="suporteContainer">
            <TitleContainer padding={padding}>
                <TitleText>{`Suporte`}</TitleText>
            </TitleContainer>
            <DescriptionContainer padding={padding}>
                <Text>{`Está com problemas ou precisa de ajuda para navegar na humane?`}</Text>
                <Text>{`Acesse nossa página de suporte.`}</Text>
            </DescriptionContainer>
            <ButtonContainer padding={padding}>
                <Button onClick={goToSuporte}>Acessar suporte</Button>
            </ButtonContainer>
        </MainContainer>
    );
};

export default SuporteContainer;
