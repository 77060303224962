import React from 'react';

import { ObjectiveItemStyle, DefaultText, Icon, IconContainer } from './ObjectiveItem.styled';

interface IObjectiveItem {
    icon: any;
    description: string;
}

const ObjectiveItem = ({ ...props }: IObjectiveItem) => {
    return (
        <ObjectiveItemStyle>
            <IconContainer>
                <Icon src={props.icon} />
            </IconContainer>

            <DefaultText>{props.description}</DefaultText>
        </ObjectiveItemStyle>
    );
};

export default ObjectiveItem;
