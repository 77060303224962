import Colors from 'enums/Colors';
import styled from 'styled-components';

const BannerStyled = {
    Button: styled.button`
        display: flex;
        flex-direction: row;
        width: 218px;
        height: 49px;
        border: none;
        background: transparent;
        color: ${Colors.White};
        font-weight: 700;
        font-size: 14px;
        letter-spacing: 0.3px;
        text-decoration: underline;

        &:hover {
            color: ${Colors.Blue};
        }
    `,
};

export default BannerStyled;
