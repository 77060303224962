import AdminBiblioItemSave from 'models/admin/AdminBiblioItemSave';
import AdminSubAtividade from 'models/admin/AdminSubAtividade';
import { AdminBiblioAnexoType } from 'pages/admin/components/newContent/submitAttach';
import { GenericService } from '../Generic.service';

import AdminBiblioAvaliacaoModel from '../../../../models/admin/AdminBiblioAvaliacao';
import AdminBiblioItemPreview from 'models/admin/AdminBiblioItemPreview';
import { categoriaToCode } from 'models/biblioteca/BiblioItemCategoria';
import BiblioItem from 'models/biblioteca/BiblioItem';

export default class AdminBiblioService extends GenericService {
    readonly URI: string = '/v1/biblioteca';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public buscarTags(pagina: number, filtro?: string) {
        const por_pagina = 6;
        return this.get('tags', { pagina, por_pagina, filtro });
    }

    public buscarTagsInicial(pagina: number, filtro?: string) {
        const por_pagina = 6;
        return this.get('tags', { pagina, por_pagina, filtro, inicial: true });
    }

    public buscarAllTags() {
        return this.get('tags');
    }

    public buscarTagsPublica() {
        // const por_pagina = 100;
        return this.get('public/tags-itens-publicos');
    }

    public criarTag(tag: string) {
        return this.post('tag', { descricao: tag });
    }

    public apagarTag(id: number) {
        return this.deleteByParams('tag/' + id);
    }

    public editarTag(id: number, tag: string) {
        return this.put('tag/' + id, { descricao: tag });
    }

    public buscarPalavrasProibidas(pagina: number, filtro?: string) {
        const por_pagina = 6;
        return this.get('palavrasProibidas', { pagina, por_pagina, filtro });
    }

    public buscarAvaliacoes(pagina: number, filtro?: string, nota?: string, tipo?: string, date?: Date, dataFinal?: Date, ordem?: string) {
        const por_pagina = 20;
        const data = date?.toLocaleDateString();
        const dataFim = dataFinal?.toLocaleDateString();
        return this.get('avaliacoes', {
            pagina,
            por_pagina,
            filtro,
            nota,
            tipo,
            data,
            dataFim,
            ordem: ordem !== null ? ordem : 'ultima_atualizacao:desc',
        });
    }

    public habilitarAvaliacao(avaliacao: AdminBiblioAvaliacaoModel, value: boolean) {
        avaliacao.enabled = value;
        return this.put('avaliacao/' + avaliacao.idItem, avaliacao.toJSON());
    }

    public criarPalavraProibida(palavra: string, variacao: string[], motivacao: string) {
        return this.post('palavraProibida', { motivacao, descricao: palavra, variacao });
    }

    public editarPalavraProibida(id: number, palavra: string, variacao: string[], motivacao: string) {
        return this.put('palavraProibida/' + id, { motivacao, descricao: palavra, variacao });
    }

    public apagarPalavraProibida(id: number) {
        return this.deleteByParams('palavraProibida/' + id);
    }

    public downloadXLSAvaliacoes() {
        return this.get('avaliacoes/xls');
    }

    public downloadXLSComentarios() {
        return this.get('comentarios/xls');
    }

    public downloadXLSItens() {
        return this.get('buscar/xls');
    }

    public criarItem(itemSave: AdminBiblioItemSave) {
        return this.post('item', itemSave.toJSON());
    }

    public criarSubItem(itemSave: AdminSubAtividade) {
        return this.post('item/atividade', {
            numeroPaginas: itemSave.pagesNumber,
            idioma: itemSave.language,
            itemBiblioteca: itemSave.itemParent,
            descricao: itemSave.title,
            anoPublicacao: itemSave.yearPublication,
        });
    }

    public editarSubItem(id: number, itemSave: AdminSubAtividade) {
        return this.put('item/atividade/' + id, {
            numeroPaginas: itemSave.pagesNumber,
            idioma: itemSave.language,
            itemBiblioteca: itemSave.itemParent,
            descricao: itemSave.title,
            anoPublicacao: itemSave.yearPublication,
        });
    }

    public buscarItem = (item_id: number) => {
        return this.get('buscarItem/' + item_id);
    };

    public buscarCategorias = () => {
        return this.get('categorias');
    };

    public inserirAnexo = (item_id: number, tipo_anexo: number, data: FormData) => {
        return this.post('inserirAnexo', data, { item_id, tipo_anexo });
    };

    public buscarAnexoTipo = (item_id: number, tipo: AdminBiblioAnexoType) => {
        return this.get(`${item_id}/tipo/anexo`, { tipo });
    };

    public inserirSubAnexo = (item_id: number, tipo_anexo: number, data: FormData) => {
        return this.post('item/atividade/inserirAnexo', data, { atividade_id: item_id, tipo_anexo });
    };

    public removerItem(content_id: number) {
        return this.deleteByParams(`item/${content_id}`);
    }

    public atualizarStatus(content_id: number, status: string) {
        return this.put(`item/changeStatus/${content_id}`, { status });
    }

    public buscarComentarios(pagina: number, respondido?: string, ordem?: string) {
        const por_pagina = 20;
        return this.get(`comentarios`, { respondido, por_pagina, pagina, ordem });
    }

    public habilitarComentario(id: number, data: any) {
        return this.put(`comentario/${id}`, data);
    }

    public atualizarItem(id: number, data: AdminBiblioItemPreview, tags: string[]) {
        const dataJson: any = data.toJSON();
        dataJson.tipoItemBiblioteca = categoriaToCode(data.category);
        dataJson.tags = tags;
        return this.put(`item/${id}`, dataJson);
    }

    public atualizarItem2(id: number, data: AdminBiblioItemSave, tags: string[]) {
        const dataJson: any = data.toJSON();
        dataJson.tags = tags;
        return this.put(`item/${id}`, dataJson);
    }

    public atualizarItem3(id: number, data: BiblioItem, tags: string[], publico: boolean, status: string) {
        const dataJson: any = data.toJSON();
        dataJson.tipoItemBiblioteca = categoriaToCode(data.category);
        dataJson.publico = publico;
        dataJson.tags = tags;
        dataJson.status = status;
        return this.put(`item/${id}`, dataJson);
    }

    public atualizarStatusVisualizacao(id: number, status: Boolean) {
        return this.patch(`item/visualizacao/${id}/${status}`);
    }

    public adicionarResposta = (comentarioId: number, conteudo: string) => {
        return this.post('comentario/resposta', { comentario_id: comentarioId, conteudo });
    };

    public excluirResposta = (comentarioId: number) => {
        return this.deleteByParams('comentario/resposta/' + comentarioId, {});
    };

    public validarUnicidadeSlug = (id: number | null, slug: string) => {
        return this.get('validarUnicidadeSlug', { id: id, slug: slug });
    };
}
