import React from 'react';
import Styled from './CartaoMultiSolucoes.styled';
import TagSolucoes from './TagSolucoes';
import { TagSolucoesEnum } from 'enums/TagSolucoesEnum';
import { YellowButton } from 'components/UI/Buttons';
import { MdArrowRight } from 'react-icons/md';
import { MdArrowForwardIos } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

export type TypeCard = {
    tag: TagSolucoesEnum[];
    img?: string;
    imgWith?: string;
    imgHeight?: string;
    titulo: string;
    descricao: string;
    link: string;
};

interface ICartaoMultiSolucoes {
    dados: TypeCard;
    isButtonOculto?: boolean;
}
const CartaoMultiSolucoes = ({ dados, isButtonOculto }: ICartaoMultiSolucoes) => {
    const history = useHistory();

    const goToPush = () => {
        history.push(dados.link);
    };

    return (
        <Styled.Container>
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '0.2rem',
                    height: '60px',
                }}
            >
                {dados.tag.map((dados, index) => (
                    <TagSolucoes key={index} type={dados} />
                ))}
            </div>

            <Styled.ContentImg>
                <Styled.Img
                    width={dados.imgWith}
                    height={dados.imgHeight}
                    src={
                        dados.img ??
                        'https://estado.sc.gov.br/noticias/wp-content/uploads/sites/3/2019/12/reuniao_sao_paulo_instituto_ayrton_senna_20191203_2072413675.jpeg'
                    }
                />
            </Styled.ContentImg>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', height: 150 }}>
                <Styled.Title>{dados.titulo}</Styled.Title>
                <Styled.Description dangerouslySetInnerHTML={{ __html: dados.descricao }} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {!isButtonOculto && (
                    <YellowButton
                        onClick={goToPush}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                            marginTop: 22,
                        }}
                    >
                        QUERO SABER MAIS <MdArrowForwardIos />
                    </YellowButton>
                )}
            </div>
        </Styled.Container>
    );
};

export default CartaoMultiSolucoes;
