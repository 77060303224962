import React, { FunctionComponent } from 'react';
import { MobileMaxWidth } from '../../../../constants/Dimensions';

import styled from 'styled-components';
import Colors from 'enums/Colors';
import { IsMobileMaxWidth } from 'core/util/WindowSize';
import BibliotecaBanner from '../biblioBanner/biblioBanner';
import SpaceTeacher from '../spaceTeacher/SpaceTeacher';

interface IOtherResourcesContainer {}
const OtherResourcesContainer = styled.div(({ ...props }: React.PropsWithChildren<IOtherResourcesContainer>) => ({
    width: '100%',
    paddingBottom: '20px',
    backgroundColor: `${Colors.White}`,
}));

export const TitleContainer = styled.div`
    display: flex;
    flex: 1;
    padding: 0 0 50px 100px;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        padding: 40px 20px 40px 20px;
        justify-content: center;
    }
`;

export const DescriptionContainer = styled.div`
    flex: 1;
    padding-left: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media only screen and (max-width: ${MobileMaxWidth + 150}px) {
        margin: 20px 0 60px 0;
        padding-left: 85px;
        padding-right: 40px;
    }
`;

export const OtherResourceHeader = styled.header`
    margin-bottom: 68px;
    max-width: 50px;
`;

export const DesktopTitleText = styled.p`
    font-size: 32px;
    color: ${Colors.Blue};
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    line-height: 42px;
    letter-spacing: 0.1px;
`;

interface IOtherResources {}

const OtherResources: FunctionComponent<IOtherResources> = ({ ...props }: IOtherResources) => {
    const IsMobile = () => {
        return IsMobileMaxWidth();
    };
    const TransformRule = (): string => {
        const retorno = IsMobile() ? 'translate(60px,25px);' : 'translate(140px, 50px);';
        return retorno;
    };

    return (
        <OtherResourcesContainer id="outrosRecursos">
            <TitleContainer>
                <DesktopTitleText>{`Outros recursos`}</DesktopTitleText>
            </TitleContainer>
            <BibliotecaBanner />
            <SpaceTeacher />
        </OtherResourcesContainer>
    );
};

export default OtherResources;
