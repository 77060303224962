import React, { useState, useEffect } from 'react';

import Slider from 'react-slick';

import Card from './cardComponent/Card';
import { IsMobileMaxWidth } from '../../../../core/util/WindowSize';

import {
    EducationalSolutionsContainerStyle,
    TitleContainer,
    DescriptonText,
    CardsContainer,
    CarouselDots,
    CarouselCustomPaging,
    TitleText,
} from './EducationalSolutionsContainer.styled';
import EducationalSolution from 'models/EducationalSolution';
import defaultContentHome from 'data/defaultContentHome';
import AdminHomeConteudoService from 'core/http/service/admin/AdminHomeConteudo.service';
import { clone } from 'lodash';
import HoverCard from './cardComponent/HoverCard';
import CardsContainerSolutionsCarrousel from 'pages/intermediarias/educationalSolutionsDetail/components/cardsContainerSolutionsCarrousel/CardsContainerSolutionsCarrousel';
import useLogged from 'hooks/useLogged';

const IsMobile = () => {
    return IsMobileMaxWidth();
};

const EducationalSolutionsContainer = () => {
    const [educationalSolutions, setEducationalSolutions] = useState<EducationalSolution[]>(defaultContentHome.educationalSolutions);
    const { authenticated } = useLogged();

    const TransformRule = (): string => {
        const retorno = IsMobile() ? 'translate(85px,25px);' : 'translate(200px, 50px);';
        return retorno;
    };

    const mobileCarouselSettings = {
        dots: true,
        infinite: false,
        focusOnSelect: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        // eslint-disable-next-line react/display-name
        appendDots: (dots: any) => (
            <CarouselDots>
                <ul style={{ margin: '0px' }}> {dots} </ul>
            </CarouselDots>
        ),
        // eslint-disable-next-line react/display-name
        customPaging: (i: number) => <CarouselCustomPaging />,
    };

    const fetchContent = async () => {
        const service = new AdminHomeConteudoService();
        let { data, status } = await service.buscaConteudo('SOLUCOES_EDUCACIONAIS');

        if (status == 200) {
            const _solucoes: EducationalSolution[] = clone(educationalSolutions);
            const newSols = Array.from(data).map((sol: any) =>
                new EducationalSolution().fromJSON({
                    id: sol.id,
                    title: sol.titulo,
                    text: sol.conteudo,
                    active: sol.ativo,
                    image: '',
                    imagePath: '',
                    followRoute: sol.link,
                    followText: '',
                    linkExterno: sol.linkExterno,
                    defaultData: false,
                    complement: parseInt(sol.complemento),
                })
            );

            for (let i = 0; i < newSols.length; i++) {
                if (newSols[i].complement > -1) _solucoes[newSols[i].complement] = newSols[i];
            }

            setEducationalSolutions(_solucoes);
        }
    };

    useEffect(() => {
        fetchContent();
    }, []);

    const CardsContainerComponent = () => {
        return (
            <>
                {IsMobile() ? (
                    <CardsContainer>
                        <Slider {...mobileCarouselSettings}>
                            {educationalSolutions.map(item => {
                                return <HoverCard key={item.id} educationalSolutionItem={item} />;
                            })}
                        </Slider>
                    </CardsContainer>
                ) : (
                    <CardsContainer>
                        {educationalSolutions.map(item => {
                            return <HoverCard key={item.id} educationalSolutionItem={item} />;
                        })}
                    </CardsContainer>
                )}
            </>
        );
    };

    return (
        <EducationalSolutionsContainerStyle id="jornadas-de-desenvolvimento">
            <TitleContainer auth={authenticated}>
                <TitleText style={{ color: 'white' }}>{`Jornadas de desenvolvimento`}</TitleText>
                <DescriptonText style={{ color: 'white' }}>
                    Vivencie experi&#234;ncias, acesse ferramentas e atividades estruturadas{'\n'}para potencializar o seu processo de
                    desenvolvimento!
                </DescriptonText>
            </TitleContainer>

            {/*    <CardsContainerComponent /> */}
            <CardsContainerSolutionsCarrousel />
        </EducationalSolutionsContainerStyle>
    );
};

export default EducationalSolutionsContainer;
