import React, { useCallback, useRef } from 'react';
import EducationalSolution, { EducationalSolutionCategory } from 'models/EducationalSolution';
import { clone } from 'lodash';
import { useEffect, useState } from 'react';
import Styled, { mobileCarouselSettings } from './CardsContainerSolutionsCarrousel.styled';
import HoverCard from 'pages/home/components/educationalSolutions/cardComponent/HoverCard';
import AdminHomeConteudoService from 'core/http/service/admin/AdminHomeConteudo.service';
import arrowYellow from '../../../../../images/homepage/arrow-yellow.svg';

import ReactTooltip from 'react-tooltip';
import { IsCustomMaxWidth, IsMobileMaxWidth } from 'core/util/WindowSize';
import Slider from 'react-slick';
import { CardsContainer } from 'pages/home/components/educationalSolutions/EducationalSolutionsContainer.styled';
import ABTest from 'components/ab_tests/ABTest';
import TestVersion from 'enums/TestVersions';

export interface ICardsContainer {}

const categoriesDefault: EducationalSolutionCategory[] = [
    new EducationalSolutionCategory().fromJSON({
        label: 'Para me desenvolver',
        descricao: 'Jornadas para desenvolvimento socioemocional e caminhos para lidar com questões do cotidiano escolar.',
        id: 1,
        desativado: true,
    }),
    new EducationalSolutionCategory().fromJSON({
        label: 'Para desenvolver meus estudantes',
        descricao: 'Jornadas, instrumentos e materiais para promover desenvolvimento aos estudantes da sua escola.',
        id: 2,
        desativado: true,
    }),
];

const IsMobile = () => {
    return IsMobileMaxWidth();
};

const CardsContainerSolutionsCarrousel = ({}: ICardsContainer) => {
    // const [educationalSolutions, setEducationalSolutions] = useState<EducationalSolution[]>(defaultContentHome.educationalSolutions);
    const [educationalSolutions, setEducationalSolutions] = useState<EducationalSolution[]>([]);
    const [categorias, setCategorias] = useState<EducationalSolutionCategory[]>(categoriesDefault);
    const [activeTab, setActiveTab] = useState<EducationalSolutionCategory | null>(categoriesDefault[0]);
    const [showAll, setShowAll] = useState<boolean>(false);
    const [showScroll, setShowSroll] = useState<boolean>(false);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [loading, setLoading] = useState(false);
    const [loadingCategorias, setLoadingCategorias] = useState(true);
    const [showTabs, setShowTabs] = useState(false);
    const [allCategoriesFirst, setAllCategoriesFirst] = useState(false);

    const scrow = useRef<HTMLDivElement>(null);
    const scrollContainer = useRef<HTMLDivElement>(null);

    const fetchCategory = async () => {
        setLoadingCategorias(true);
        const service = new AdminHomeConteudoService();
        let { data, status } = await service.buscarCategoriaConteudo();

        const categories = Array.from(data).map((c: any) => new EducationalSolutionCategory().fromJSON(c));

        setCategorias(categories);
        // setActiveTab(categories[0]);

        // const currentVersion = ABTest.getCurrentVersion();

        // if (currentVersion == TestVersion.VersionD) {
        setAllCategoriesFirst(true);
        setShowAll(true);
        setActiveTab(null);
        // }

        setShowTabs(categories.findIndex(cat => !cat.desativado) === -1 ? false : true);
        setLoadingCategorias(false);
    };

    const fetchContent2 = async () => {
        setLoading(true);
        const service = new AdminHomeConteudoService();
        let { data } = await service.buscaConteudo('SOLUCOES_EDUCACIONAIS');

        let _solutions = Array.from(data).map((sol: any) =>
            new EducationalSolution().fromJSON({
                id: sol.id,
                title: sol.titulo,
                text: sol.conteudo,
                active: sol.ativo,
                image: '',
                imagePath: '',
                followRoute: sol.link,
                followText: '',
                linkExterno: sol.linkExterno,
                defaultData: false,
                disabled: sol.desabilitado,
                complement: parseInt(sol.complemento),
                categorias: sol.categorias,
                proximoLancamento: sol.proximoLancamento,
                novidade: sol.novidade,
                order: sol.ordem,
            })
        );

        setEducationalSolutions(_solutions);
        setLoading(false);
    };

    const selectTab = (category: EducationalSolutionCategory) => {
        setShowAll(false);
        setActiveTab(category);
    };

    const scrollTo = (orientation: 'R' | 'L') => {
        const increaseScroll = 472 * 2;

        if (orientation == 'R' && scrow.current) scrow.current.scrollBy(increaseScroll, 0);
        else if (scrow.current) scrow.current.scrollBy(-1 * increaseScroll, 0);
    };

    const scrollToZero = () => {
        scrow.current?.scrollTo(0, 0);
    };

    useEffect(() => {
        fetchCategory();
        fetchContent2();
    }, []);

    useEffect(() => {
        if (scrollContainer.current && scrow.current) {
            const containerW = scrollContainer.current.clientWidth;
            const scrowW = scrow.current.scrollWidth;
            setShowSroll(containerW <= scrowW);
        }
    }, [scrollContainer.current, scrow.current, activeTab, showAll, educationalSolutions]);

    useEffect(() => {
        if (scrow.current) {
            scrow.current.addEventListener('scroll', e => {
                setScrollLeft(scrow.current?.scrollLeft ?? 0);
            });
        }

        return () => scrow.current?.removeEventListener('scroll', () => {});
    }, [scrow.current]);

    const sortEducationalSolutions = (a: EducationalSolution, b: EducationalSolution) => {
        if (a.order == b.order) return a.complement - b.complement;
        else return a.order - b.order;
    };

    const getCategorias = (): EducationalSolutionCategory[] => categorias;

    const getCards = useCallback(() => {
        let outcome: EducationalSolution[] = [];

        if (showAll) outcome = educationalSolutions.filter(s => s.disabled == false).sort(sortEducationalSolutions);
        else
            outcome = educationalSolutions
                .filter(s => s.categorias && s.categorias.includes(String(activeTab?.id)))
                .filter(s => s.disabled == false)
                .sort(sortEducationalSolutions);

        // console.log('outcome', outcome);

        return outcome;
    }, [activeTab, showAll, categorias, educationalSolutions]);

    useEffect(() => {
        scrollToZero();
    }, [activeTab, showAll]);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <Styled.Container>
            <Styled.TabsContiner>

                {allCategoriesFirst && (
                    <Styled.Tab
                        active={showAll}
                        visivel={showTabs}
                        onClick={() => {
                            setShowAll(true);
                            setActiveTab(null);
                        }}
                    >
                        Todas as jornadas
                    </Styled.Tab>
                )}

                {getCategorias().map((c, idx) => {
                    return (
                        <React.Fragment key={idx}>
                            <Styled.Tab
                                active={c.label == activeTab?.label}
                                onClick={() => selectTab(c)}
                                key={idx}
                                visivel={!c.desativado}
                                data-tip={c.descricao}
                                data-for={`tooltip-id-${idx}`}
                            >
                                {c.label}
                            </Styled.Tab>

                            {!IsCustomMaxWidth(1250) && (
                                <ReactTooltip
                                    id={`tooltip-id-${idx}`}
                                    className="max-toptip"
                                    effect="solid"
                                    place="top"
                                    delayShow={0}
                                    textColor={'#003C78'}
                                    backgroundColor={'#FFF'}
                                    multiline={true}
                                />
                            )}
                        </React.Fragment>
                    );
                })}

                {!allCategoriesFirst && (
                    <Styled.Tab
                        active={showAll}
                        visivel={showTabs}
                        onClick={() => {
                            setShowAll(true);
                            setActiveTab(null);
                        }}
                    >
                        Todas as jornadas
                    </Styled.Tab>
                )}
            </Styled.TabsContiner>

            <br />

            <Styled.ScrollContainer ref={scrollContainer}>
                {showScroll && <Styled.ArrowButton src={arrowYellow} onClick={() => scrollTo('L')} active={scrollLeft > 0} />}

                {IsMobile() ? (
                    <Styled.CarrouselContainer>
                        {!loading && (
                            <Slider {...settings}>
                                {getCards().map(item => {
                                    return <HoverCard key={item.id} educationalSolutionItem={item} />;
                                })}
                            </Slider>
                        )}
                    </Styled.CarrouselContainer>
                ) : (
                    <Styled.Scroll ref={scrow}>
                        {getCards().map(item => (
                            <Styled.ScrollItem key={item.id}>
                                <HoverCard educationalSolutionItem={item} />
                            </Styled.ScrollItem>
                        ))}
                    </Styled.Scroll>
                )}

                {showScroll && (
                    <Styled.ArrowButton
                        className="flip"
                        src={arrowYellow}
                        style={{ visibility: showScroll ? 'visible' : 'hidden' }}
                        onClick={() => scrollTo('R')}
                        active={!(scrow.current && scrow.current?.scrollLeft >= scrow.current?.scrollWidth - scrow.current?.clientWidth) ?? true}
                    />
                )}
            </Styled.ScrollContainer>
        </Styled.Container>
    );
};

export default CardsContainerSolutionsCarrousel;
