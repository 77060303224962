import React, { useCallback, useEffect, useState } from 'react';
import Styled from './Avaliations.styled';
import Select, { SelectOptionType } from 'components/Select';
import { GP_defaultOption, GP_optionOrderNota, GP_optionOrderType, GP_optionsOrder } from './OptionsFilter';
import AdminEditorStyled from 'pages/admin/components/pageEditors/AdminEditor.styled';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import TableAvaliation from './tables/TableAvaliation';
import AdminRecursosCostumizados from 'core/http/service/admin/AdminRecursosCostumizados.service';
import AdminGestaoPaginaNotas from 'models/admin/AdminGestaoPaginaNotas';
import AdminPagination from 'pages/admin/components/pagination/AdminPagination';
import moment from 'moment';
import AdminGestaoPaginaMedia from 'models/admin/AdminGestaoPaginaMedia';
import Loading from 'components/Loading';

export interface IFilterAvaliacao {
    pagina: number;
    ordem: string;
    tipo: string;
    nota: string;
    por_pagina: number;
    data: Date | string | undefined;
}

export interface IFilterMedia {
    pagina: number;
    ordem: string;
    por_pagina: number;
}

const AvaliationsGestaoDePaginas = () => {
    const [loading, setLoading] = useState(false);
    const [avaliations, setAvaliations] = useState<AdminGestaoPaginaNotas[]>([]);
    const [avaliationsMedia, setAvaliationsMedia] = useState<AdminGestaoPaginaMedia[]>([]);

    const [totalPages, setTotalPages] = useState(1);
    const [isAvarage, setIsAvarage] = useState(false);

    const service = new AdminRecursosCostumizados();

    const [pathFilterMedia] = useState<IFilterMedia>({
        ordem: '',
        pagina: 1,
        por_pagina: 10,
    });

    const [pathFilter, setArrayFilter] = useState<IFilterAvaliacao>({
        ordem: '',
        pagina: 1,
        tipo: '',
        nota: '',
        por_pagina: 10,
        data: undefined,
    });

    const changePage = (page: number) => {
        isAvarage ? fetchAverage(page) : fetchAvaliations(page);
    };

    const fetchAverage = async (pagina?: number) => {
        setLoading(true);
        const { data } = await service.fetchGestaoDePaginaAverage({ ...pathFilterMedia, pagina: pagina ?? pathFilter.pagina });
        setAvaliationsMedia(data.rows ?? []);
        setTotalPages(data.totalPages);
        setLoading(false);
    };

    const fetchAvaliations = async (pagina?: number) => {
        setLoading(true);
        const dataFormat = pathFilter.data !== undefined ? moment(pathFilter.data).format('L') : undefined;

        const { data } = await service.fetchGestaoDePaginaAvaliations({ ...pathFilter, pagina: pagina ?? pathFilter.pagina, data: dataFormat });
        setAvaliations(data.rows ?? []);
        setTotalPages(data.totalPages);
        setLoading(false);
    };

    useEffect(() => {
        if (isAvarage) {
            fetchAverage();
        } else {
            fetchAvaliations();
        }
    }, [isAvarage]);

    useEffect(() => {
        if (!isAvarage) fetchAvaliations();
    }, [pathFilter]);

    useEffect(() => {
        if (isAvarage) fetchAverage();
    }, [pathFilterMedia]);

    const CustomDatePickerInput = React.forwardRef<
        HTMLInputElement,
        React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
    >((props, ref) => <AdminEditorStyled.Input ref={ref} {...props} />);
    CustomDatePickerInput.displayName = 'ReactDatePickerInput';

    const DatePickerWrapper = styled(({ ...props }) => (
        <DatePicker
            {...props}
            locale="ptBR"
            dateFormat={'dd/MM/yyyy'}
            selected={pathFilter.data}
            onChange={(date: Date) => {
                setArrayFilter({
                    ...pathFilter,
                    data: date ?? undefined,
                });
            }}
            customInput={<CustomDatePickerInput />}
        />
    ))`
        width: 200px;
        margin-left: 5px;
    `;

    return (
        <Styled.Container>
            <Styled.Title>Avaliações</Styled.Title>
            <Styled.ContainerRow style={{ marginBottom: '20px' }}>
                <Styled.SectionChip enabled={!isAvarage} onClick={() => setIsAvarage(false)}>
                    Notas
                </Styled.SectionChip>
                <Styled.SectionChip enabled={isAvarage} onClick={() => setIsAvarage(true)}>
                    Média notas
                </Styled.SectionChip>
            </Styled.ContainerRow>

            <Styled.ContainerRow style={{ marginBottom: '30px' }}>
                {!isAvarage ? (
                    <>
                        <Styled.RowRigth>
                            <span className="label-order">Ordenar por:</span>
                            <span className="span-select">
                                <Select
                                    width="200px"
                                    isSmall={true}
                                    itens={GP_optionsOrder}
                                    defaultValue={GP_optionsOrder[0]}
                                    onSelected={selected => {
                                        setArrayFilter({
                                            ...pathFilter,
                                            ordem: selected.value.toString(),
                                        });
                                    }}
                                />
                            </span>
                        </Styled.RowRigth>

                        <Styled.RowRigth>
                            <span className="label-order">Nota:</span>
                            <span className="span-select">
                                <Select
                                    width="200px"
                                    isSmall={true}
                                    itens={GP_optionOrderNota}
                                    defaultValue={GP_defaultOption}
                                    onSelected={selected => {
                                        setArrayFilter({
                                            ...pathFilter,
                                            nota: selected.value.toString(),
                                        });
                                    }}
                                />
                            </span>
                        </Styled.RowRigth>
                        <Styled.RowRigth>
                            <span className="label-order">Tipo:</span>
                            <span className="span-select">
                                <Select
                                    width="200px"
                                    isSmall={true}
                                    itens={GP_optionOrderType}
                                    defaultValue={GP_defaultOption}
                                    onSelected={selected => {
                                        setArrayFilter({
                                            ...pathFilter,
                                            tipo: selected.value.toString(),
                                        });
                                    }}
                                />
                            </span>
                        </Styled.RowRigth>
                        <Styled.ContainerRow>
                            <Styled.RowRigth>
                                <span className="label-order">Data:</span>
                                <DatePickerWrapper />
                            </Styled.RowRigth>
                        </Styled.ContainerRow>
                    </>
                ) : (
                    <></>
                )}
            </Styled.ContainerRow>
            {loading ? (
                <Loading />
            ) : (
                <React.Fragment>
                    <Styled.GridAvaliacao isAvarage={isAvarage}>
                        <Styled.GridHead>Tipo</Styled.GridHead>
                        <Styled.GridHead>Página</Styled.GridHead>
                        <Styled.GridHead>Título do conteúdo</Styled.GridHead>
                        {!isAvarage && <Styled.GridHead>Autor</Styled.GridHead>}
                        <Styled.GridHead>{isAvarage ? 'Média' : 'Nota'}</Styled.GridHead>
                        <Styled.GridHead>{isAvarage ? 'Total' : 'Ações'}</Styled.GridHead>

                        <TableAvaliation avaliations={avaliations} avarages={avaliationsMedia} isAverage={isAvarage} />
                    </Styled.GridAvaliacao>
                </React.Fragment>
            )}
            <AdminPagination onChangePage={changePage} total={totalPages} />
        </Styled.Container>
    );
};

export default AvaliationsGestaoDePaginas;
