import AdminRecursosCostumizados from 'core/http/service/admin/AdminRecursosCostumizados.service';
import { PaginaIntermediariaService } from 'core/http/service/PaginaIntermediaria.service';
import { FilterRecursosCostumizados } from 'enums/FilterRecursosCostumizados';
import PaginaIntermediaria from 'models/admin/AdminPaginaIntermediaria';
import { ItemRecursosCostumizado } from 'models/recursos-costumizados/ItemRecursosCostumizado';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { RecursosContext } from './RecursosContext';
import { SelectOptionType } from 'components/Select';
import { ItemRecursosCostumizadoPublic } from 'models/recursos-costumizados/ItemRecursosCostumizadoPublic';
import path from 'path';
import { CertificacoesService } from 'core/http/service/Certificacoes.service';
import { CertificacoesModel } from 'models/certificacoes/CertificacoesModel';

export interface IRecursosProvider { }


const initialValuesCertificacao = {
    codigo: -1,
    nome: '',
    textoSolicitacao: '',
    precisaSolicitar: true,
    link: ''
    // Add other initial values as needed
};
 const RecursosProvider = ({ children }: React.PropsWithChildren<IRecursosProvider>) => {
    //Hooks
    const location = useLocation();

    // Services
    const paginaIntermediariaService = new PaginaIntermediariaService();
    const recursosCostumizadosService = new AdminRecursosCostumizados();
    const certificacaoService = new CertificacoesService();

    //States
    const [loading, setLoading] = useState(false);
    const [itensResumidos, setItensResumidos] = useState<ItemRecursosCostumizadoPublic[]>([]);
    const [item, setItem] = useState<ItemRecursosCostumizado | null>(null);
    const [paginaIntermediaria, setPaginaIntermediaria] = useState<PaginaIntermediaria | null>(null);
    const [filterSelected, setFilterSelected] = useState<SelectOptionType>({ label: 'Todos', value: FilterRecursosCostumizados.TODOS });
    const [certificacao, setCertificacao] = useState<CertificacoesModel>(initialValuesCertificacao as CertificacoesModel);

    const getIdByLink = async (): Promise<number> => {
        const link: string = location.pathname.split('/')[2];
        const { data, status } = await paginaIntermediariaService.buscarPaginaPorLink(link);
        const _pagina = new PaginaIntermediaria().fromJSON(data);
        setPaginaIntermediaria(_pagina);
        return _pagina.codigo;
    };

    const fetchPage = async (status: FilterRecursosCostumizados | string = FilterRecursosCostumizados.TODOS) => {
        setLoading(true);
        const codigo = await getIdByLink();
        
        if (codigo !== -1) {
            const { data } = await recursosCostumizadosService.listByPaginaOnlySimple(codigo);
            const itens = Array.from(data).map((item: any) => new ItemRecursosCostumizadoPublic().fromJSON(item));
            setItensResumidos(itens);
        }


        setLoading(false);
    };

    const fetchEtapasAndConteudos = async () => {
        const link: string = location.pathname.split('/')[3];
        if (link !== undefined) {
            setLoading(true);
            
            const codigo = itensResumidos.find(item => item.url === `/${link}`)?.codigo;

            const { data } = await recursosCostumizadosService.listByEtapasAndConteudosResumidos(codigo ?? -1);
            const certificacao = await certificacaoService.listarById(data.codigoCertificacao);
            const item = new ItemRecursosCostumizado().fromJSON(data);
            setCertificacao(certificacao.data)
            setItem(item);
            setLoading(false);
        }
    }



    const updateWithStatus = async (status: FilterRecursosCostumizados | string) => {
        await fetchPage(status), FilterSelecionado(status);
    };

    const FilterSelecionado = (status: FilterRecursosCostumizados | string) => {
        switch (status) {
            case FilterRecursosCostumizados.TODOS:
                return setFilterSelected({ label: 'Todos', value: FilterRecursosCostumizados.TODOS });
            case FilterRecursosCostumizados.INICIADOS:
                return setFilterSelected({ label: 'Iniciados', value: FilterRecursosCostumizados.INICIADOS });
            case FilterRecursosCostumizados.FINALIZADOS:
                return setFilterSelected({ label: 'Finalizados', value: FilterRecursosCostumizados.FINALIZADOS });
            case FilterRecursosCostumizados.NAO_INICIADOS:
                return setFilterSelected({ label: 'Não iniciados', value: FilterRecursosCostumizados.NAO_INICIADOS });
        }
    };


    useEffect(() => {
        fetchPage();
    }, [location.pathname]);

    useEffect(() => {
        fetchEtapasAndConteudos();
    }, [itensResumidos, location.pathname]);


    const context: RecursosContext.IContext = {
        loading,
        updateWithStatus,
        itemRecurso: item,
        itensRecursoResumido: itensResumidos,
        filterSelected,
        pagina: paginaIntermediaria,
        certificacao: certificacao
    };

    return <RecursosContext.Context.Provider value={context}>{children}</RecursosContext.Context.Provider>;
};
export default RecursosProvider;
