import React from 'react';
import Title from 'components/UI/Title';
import { Content, PrivacyPolicyContainer } from './PrivacyPolicy.styled';

export interface IPrivacyPolicy {}

const PrivacyPolicy = ({}: IPrivacyPolicy) => {
    const LINK_IAS: string = 'https://www.institutoayrtonsenna.org.br/';
    const LINK_BRIGHTSPACE: string = 'https://ias.brightspace.com/';

    const SUPPORT_CHROME: string = 'https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;oco=1&amp;hl=pt-BR';
    const SUPPORT_FIREFOX: string = 'https://support.mozilla.org/pt-BR/kb/gerencie-configuracoes-de-armazenamento-local-de-s';
    const SUPPORT_SAFARI: string = 'https://support.apple.com/pt-br/guide/safari/sfri11471/mac';
    const SUPPORT_EXPLORER: string = 'https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies';
    const SUPPORT_EDGE: string = 'https://support.microsoft.com/pt-br/help/4027947/microsoft-edge-delete-cookies';
    const SUPPORT_OPERA: string = 'https://help.opera.com/en/latest/web-preferences/#cookies';

    const ENCARREGADO_EMAIL: string = 'encarregado@ias.org.br';

    return (
        <PrivacyPolicyContainer>
            <Title style={{ color: '#003C78' }} size={'large'}>
                POLÍTICA DE PRIVACIDADE
            </Title>
            <Content>
                <p>
                    O Instituto Ayrton Senna (“IAS”) há mais de 25 anos procura desenvolver o potencial de crianças e jovens no Brasil, tendo
                    eleito a educação integral como motriz desse desenvolvimento. É com prazer que recebemos você no nosso site institucional e
                    nas nossas plataformas para compartilhar o conteúdo e o conhecimento desenvolvidos pela nossa equipe ao longo de nossa
                    história.
                </p>
                <br />
                <p>
                    Para que a sua experiência no site institucional e plataformas administradas pelo IAS seja a melhor possível, o IAS apresenta
                    a sua POLÍTICA DE PRIVACIDADE (“Política”), documento que tem por objetivo esclarecer quais os termos e condições que guiam
                    as nossas atividades de tratamento de seus dados pessoais.
                </p>
                <br />
                <p>
                    Este documento está dividido em doze partes: (A) introdução; (B) glossário; (C) coleta de dados pessoais; (D) uso dos dados
                    pessoais; (E) compartilhamento de dados pessoais; (F) armazenamento de dados pessoais; (G) direitos dos titulares de dados
                    pessoais; (H)
                    <span>r</span>egistro de atividades; (I) transferência internacional de dados pessoais; (J) medidas de segurança; e (K)
                    disposições gerais.
                </p>
                <p>
                    <br />
                </p>
                <ol>
                    <li>
                        <h1>A. Introdução</h1>
                        <br />
                        <p>
                            A presente Política foi editada em atenção à legislação aplicável, qual seja, o Marco Civil da Internet (Lei nº
                            12.965/2014) e a Lei Geral de Proteção de Dados Pessoais (Lei nº 13.709/2018 ou “LGPD”), e está pautada no nosso
                            compromisso necessário de transparência junto aos nossos apoiadores e seguidores.
                        </p>
                        <br />
                        <p>
                            As diretrizes contidas nesta Política apresentam a visão do IAS sobre privacidade e proteção de dados pessoais, e
                            englobam as atividades desenvolvidas e relacionadas ao site institucional e demais plataformas administradas pelo
                            IAS. Outros sites que possam ser acessados através de links disponibilizados neste site, podem ter políticas de
                            privacidade diferentes desta, e, portanto, sujeitos a outras regras e condições.
                        </p>
                        <br />
                        <br />
                    </li>
                    <li>
                        <h1>B. Glossário</h1>
                        <br />
                        <p>
                            Cadastro: é o registro feito no site institucional pelo usuário de assinatura do newsletter do IAS e por meio do qual
                            o IAS é autorizado a enviar comunicados ao usuário;
                        </p>
                        <br />
                        <p>
                            Conta de Usuário: registro e identificação do usuário, para a qual são definidos login e senha que permitem o acesso
                            à área logada das plataformas do IAS;
                        </p>
                        <br />
                        <p>
                            Cookies: são pequenos arquivos com dados que os sites solicitam que os navegadores armazenem, permitindo que se
                            identifique o seu dispositivo de acesso. Esses arquivos são usados, por exemplo, para protocolos de segurança,
                            ativação de funcionalidades e customização de navegação;
                        </p>
                        <br />
                        <p>
                            Dados Anonimizados: são dados que passaram por uma série de tratamentos de modo que não podem ser mais associados
                            direta ou indiretamente a uma pessoa, considerando os meios técnicos disponíveis e razoáveis;
                        </p>
                        <br />
                        <p>
                            Dados Pessoais: são informações relacionadas à pessoa natural identificada ou identificável, incluindo, mas sem se
                            limitar a, nome, e-mail, endereço IP, geolocalização, dados sensíveis, etc.;
                        </p>
                        <br />
                        <p>
                            Dados Sensíveis: são dados pessoais sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a
                            sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual,
                            dado genético ou biométrico, quando vinculado a uma pessoa natural;
                        </p>
                        <br />
                        <p>
                            Encarregado (DPO): pessoa, física ou jurídica, indicada pelo IAS como responsável pelo canal de comunicação entre o
                            IAS, você e as autoridades governamentais em relação à presente Política e ao uso, coleta e tratamento de dados
                            pessoais pelo IAS;
                        </p>
                        <br />
                        <p>
                            IAS: é o Instituto Ayrton Senna, associação de caráter filantrópico e responsável pela administração do site
                            institucional, cujo endereço principal é, atualmente,{' '}
                            <a href={LINK_IAS} target="_blank" rel="noreferrer">
                                {LINK_IAS}
                            </a>
                            , e demais plataformas relacionadas às suas atividades sociais;
                        </p>
                        <br />
                        <p>
                            Logs: registros de atividades dos usuários ou visitantes efetuados a partir do acesso ao site institucional e
                            plataformas administrados pelo IAS;
                        </p>
                        <br />
                        <p>
                            Newsletter: ação oferecida pelo IAS no seu site institucional, de envio de boletim informativo ao usuário cadastrado
                            com informações relacionadas, diretamente ou não, às atividades do IAS;
                        </p>
                        <br />
                        <p>
                            Plataformas: são plataformas digitais, administradas pelo IAS, destinadas, entre outros, ao oferecimento de
                            experiências de aprendizagem digitais, como cursos e certificações na área da educação, bem como de sistemas de
                            avaliação e acompanhamento de soluções educacionais, como instrumentos de avaliação socioemocional, incluindo sem se
                            limitar a Plataforma Espaço Educador, cujo endereço principal é, atualmente,{' '}
                            <a href={LINK_BRIGHTSPACE} target="_blank" rel="noreferrer">
                                {LINK_BRIGHTSPACE}
                            </a>{' '}
                            e outras desenvolvidas e lançadas pelo IAS; e,
                        </p>
                        <br />
                        <p>
                            Titular dos dados: é toda pessoa natural que visita o site institucional ou acessa as plataformas administrados pelo
                            IAS, mediante registro da conta de usuário, ou interage com as funcionalidades oferecidas por esses ambientes.
                        </p>
                        <br />
                    </li>
                    <li>
                        <h1>C. Coleta de dados pessoais</h1>
                        <br />
                        <p>
                            Ao visitar o nosso site institucional e plataformas, bem como utilizar as funcionalidades e ações por meio deles
                            oferecidas, o IAS coleta automaticamente, ou você nos fornece diretamente, alguns dos seus dados pessoais. Essa
                            coleta é realizada a partir do momento em que você acessa o ambiente do site e plataformas.
                        </p>
                        <p>
                            O IAS, igualmente, pode receber seus dados pessoais de parceiros e apoiadores que apoiam na manutenção e
                            operacionalização do site e plataformas do IAS, e suas respectivas funcionalidades e ações, como a newsletter e
                            comunicações eletrônicas, bem como na contínua melhoria das atividades apresentadas a você.
                        </p>
                        <br />
                        <p>Os dados pessoais coletados pelo IAS são:</p>
                        <br />
                        <ul style={{ paddingLeft: '20px' }}>
                            <li>
                                <p>
                                    <h1>&#8226; Dados informados por você – </h1>
                                    <span>
                                        dados cadastrais e de contato, como nome, documento de identificação, endereço, data de nascimento,
                                        gênero e e-mail, assim como dados socioeconômicos e dados de ensino;
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <h1>&#8226; Dados sensíveis – </h1>
                                    <span>
                                        dados fornecidos por você na utilização de ferramentas avaliativas disponibilizadas pelas plataformas do
                                        IAS, como dados referentes à saúde e dados psicométricos;
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <h1> &#8226; Dados de navegação – </h1>
                                    <span>
                                        cookies, endereço IP, interações realizadas e perfil de uso do site, das plataformas e de suas
                                        respectivas funcionalidades, e dados técnicos, como informações de URL, de conexão de rede, do provedor;
                                        e
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <h1> &#8226; Dados do dispositivo – </h1>
                                    <span>
                                        atributos do dispositivo, tais como ID, sistema operacional, navegador e modelo, e dados de
                                        geolocalização do dispositivo caso autorizada a coleta pelo visitante/usuário.
                                    </span>
                                </p>
                                <br />
                            </li>
                        </ul>
                    </li>
                    <li>
                        <h1>D. Uso de dados pessoais</h1>
                        <br />
                        <p>
                            Os dados pessoais coletados nas formas indicadas acima são utilizados pelo IAS com o principal objetivo de oferecer a
                            você uma experiência de qualidade no acesso ao site institucional e demais plataformas, e no uso das
                            funcionalidades/ações por eles oferecidas, assim como o de melhorar e aprimorar o conteúdo e materiais
                            disponibilizados nesses ambientes e/ou enviados em comunicados pelo IAS.
                        </p>
                        <br />
                        <p>Os usos de dados pessoais pelo IAS são:</p>
                        <ul style={{ paddingLeft: '20px' }}>
                            <li>
                                <p>
                                    <h1>&#8226; Dados informados por você – </h1>
                                    <span>
                                        execução de ações e atividades oferecidas no site e plataformas, contato para envio de newsletter e
                                        outras comunicações do IAS, inclusive para envio de comunicações e/ou notificações, identificação,
                                        autenticação e verificação de cadastro e/ou conta, aprimoramento dos conteúdos e das atividades do IAS,
                                        atendimento de solicitações e dúvidas, prevenção e resolução de problemas técnicos ou de segurança,
                                        exercício regular de direitos do IAS, colaboração ou cumprimento de ordem judicial ou administrativa, de
                                        autoridade competente ou de órgão fiscalizador, e cumprimento de obrigação legal ou regulatória;
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <h1>&#8226; Dados sensíveis – </h1>
                                    <span>
                                        execução de ações e atividades oferecidas nas plataformas, aprimoramento dos conteúdos e das atividades
                                        do IAS, geração de estatísticas, estudos, pesquisas e levantamentos pertinentes às atividades das
                                        plataformas, suas funcionalidades e ações oferecidas pelo IAS, exercício regular de direitos do IAS,
                                        colaboração ou cumprimento de ordem judicial ou administrativa, de autoridade competente ou de órgão
                                        fiscalizador, e cumprimento de obrigação legal ou regulatória; e,
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <h1>&#8226; Dados de navegação e do dispositivo – </h1>
                                    <span>
                                        execução de ações e atividades oferecidas no site e plataformas, aprimoramento dos conteúdos e das
                                        atividades do IAS, geração de estatísticas, estudos, pesquisas e levantamentos pertinentes às atividades
                                        e comportamento no uso do site e plataformas, suas funcionalidades e ações oferecidas pelo IAS, prevenção
                                        e resolução de problemas técnicos ou de segurança, exercício regular de direitos do IAS, colaboração ou
                                        cumprimento de ordem judicial ou administrativa, de autoridade competente ou de órgão fiscalizador, e
                                        cumprimento de obrigação legal ou regulatória.
                                    </span>
                                </p>
                                <p>
                                    <br />
                                </p>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <h1>E. Compartilhamento de dados pessoais</h1>
                        <br />
                        <p>
                            Os dados pessoais coletados pelo IAS poderão ser compartilhados com empresas e terceiros parceiros e/ou apoiadores,
                            bem como com autoridades públicas e órgãos reguladores, sempre que necessário e, também, nas hipóteses listadas
                            abaixo.
                        </p>
                        <p>
                            De acordo com os termos desta Política, os dados pessoais serão compartilhados pelo IAS em cumprimento aos seus
                            objetivos e atividades sociais, com uma base legal válida, e em atenção aos termos e condições das normativas e
                            regramentos aplicáveis, inclusive, sem se limitar, os termos desta Política.
                        </p>
                        <br />
                        <p>O compartilhamento de dados pessoais pelo IAS pode ocorrer com:</p>
                        <br />
                        <ul style={{ paddingLeft: '20px' }}>
                            <li>
                                <p>
                                    <h1> &#8226; Parceiros e/ou apoiadoras – </h1>
                                    <span>
                                        empresas e instituições com as quais o IAS tenha relacionamento em razão dos seus objetivos e atividades
                                        sociais (incluindo, sem se limitar, a Ayrton Senna Empreendimentos Ltda. e a L&amp;V Licensing Ltda.),
                                        para fins de execução de ações e atividades oferecidas no site e plataformas, colaboração no envio de
                                        newsletter e outras comunicações do IAS, inclusive para envio de notificações, envio de comunicações de
                                        natureza comercial, relacionadas aos parceiros e/ou apoiadores institucionais do IAS, aprimoramento dos
                                        conteúdos e das atividades do IAS, geração de estatísticas, estudos, pesquisas e levantamentos
                                        pertinentes às atividades e comportamento no uso do site e plataformas, suas funcionalidades e ações
                                        oferecidas pelo IAS, exercício regular de direitos do IAS, cumprimento de ordem judicial ou
                                        administrativa, de autoridade competente ou de órgão fiscalizador, e cumprimento de obrigação legal ou
                                        regulatória;
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <h1> &#8226; Terceiros prestadores de serviços – </h1>
                                    <span>
                                        empresas que fornecem serviços de hospedagem, de tecnologia da informação, de comunicação, de serviços
                                        estatísticos e de consultoria especializada em dados, para fins de apoio técnico na disponibilização do
                                        site institucional, das plataformas e de funcionalidades/ações oferecidas pelo IAS, envio de newsletter e
                                        outras comunicações do IAS, inclusive para envio de notificações e/ou comunicações, execução de ações e
                                        atividades oferecidas no site e plataformas, identificação, autenticação e verificação de cadastro e/ou
                                        conta, aprimoramento dos conteúdos e das atividades do IAS, atendimento de solicitações e dúvidas,
                                        geração de estatísticas, estudos, pesquisas e levantamentos pertinentes às atividades e comportamento no
                                        uso do site e plataformas, suas funcionalidades e ações oferecidas pelo IAS, exercício regular de
                                        direitos do IAS, cumprimento de ordem judicial ou administrativa, de autoridade competente ou de órgão
                                        fiscalizador, e cumprimento de obrigação legal ou regulatória; e,
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <h1>&#8226; Autoridades públicas e órgãos reguladores –</h1>
                                    <span>
                                        entes fiscalizadores das atividades do IAS e do cumprimento das legislações aplicáveis, para fins de
                                        exercício regular de direitos do IAS, cumprimento de ordem judicial ou administrativa, de autoridade
                                        competente ou de órgão fiscalizador, e cumprimento de obrigação legal ou regulatória.
                                    </span>
                                </p>
                            </li>
                        </ul>
                        <br />
                        <br />
                        <h1>
                            IMPORTANTE: O IAS compartilhará os seus dados pessoais dentro dos limites e propósito dos seus objetivos e atividades
                            sociais, e em conformidade com os termos desta Política e da legislação aplicável. O compartilhamento respeitará,
                            além disso, as melhores práticas de segurança e privacidade disponíveis para garantir a integridade e
                            confidencialidade dos seus dados pessoais.
                        </h1>
                    </li>
                    <br />
                    <br />
                    <li>
                        <h1>F. Armazenamento de dados pessoais</h1>
                        <br />
                        <p>
                            Os dados pessoais coletados no site institucional e plataformas administrados pelo IAS ou fornecidos diretamente por
                            você ao IAS serão transferidos e armazenados em ambientes seguros e controlados pelo tempo necessário para cumprir
                            com as finalidades para as quais foram coletados. Para fins de armazenamento, o IAS utilizará servidores internos
                            e/ou de terceiros prestadores de serviços ao IAS, podendo ainda realizar o armazenamento por meio de tecnologia de
                            computação em nuvem e/ou outras que surjam futuramente, sempre em respeito aos termos desta Política e aos objetivos
                            e atividades sociais do IAS.
                        </p>
                        <br />
                        <p>
                            O IAS tem o direito de excluir os dados pessoais conforme a sua conveniência ou se exigidos por lei. Não obstante, em
                            caráter de exceção, poderá realizar a manutenção desses dados e do seu histórico de registro mesmo após esse período
                            para fins de auditoria, cumprimento de obrigações legais ou regulatórias, para o exercício regular de direitos do IAS
                            ou pelo prazo legal que justifique referida retenção.
                        </p>
                        <p>
                            Conforme indicado no item G, abaixo, você também poderá solicitar ao IAS a exclusão dos seus dados pessoais, sendo
                            certo que, mesmo nessa hipótese, o IAS poderá continuar a manter os seus dados armazenados para fins de cumprimento
                            de obrigações legais ou regulatórias, para o exercício regular de direitos do IAS ou pelo prazo legal que justifique
                            referida retenção. A eliminação desses dados não abrangerá atividades passadas nas quais o IAS tenha utilizado
                            referidos dados.
                        </p>
                        <br />
                    </li>
                    <li>
                        <h1>G. Direitos dos titulares de dados pessoais</h1>
                        <br />
                        <p>
                            Por meio do canal indicado no item K, abaixo, você poderá exercer os direitos garantidos aos titulares de dados
                            pessoais pela legislação aplicável. Nesse sentido, garantindo maior transparência possível ao usuário, o IAS lista a
                            seguir quais são esses direitos:
                        </p>
                        <br />
                        <ul style={{ paddingLeft: '20px' }}>
                            <li>
                                <p>
                                    <h1>&#8226; Acesso aos dados – </h1>
                                    <span>permite que você solicite ao IAS acesso aos dados que armazena em relação a você;</span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <h1>&#8226; Correção dos dados – </h1>
                                    <span>
                                        permite que, em caso de dados incompletos, inexatos ou desatualizados, você solicite ao IAS a correção ou
                                        complementação dos dados pessoais;
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <h1>&#8226; Anonimização, Bloqueio ou Eliminação dos dados – </h1>
                                    <span>
                                        permite que você solicite (a) a anonimização dos dados, de forma a que eles não possam mais ser
                                        relacionados a você, (b) o bloqueio dos seus dados, suspendendo temporariamente o tratamento, ou (c) a
                                        eliminação dos seus dados, caso em que apagaremos todos os seus dados pessoais sem possibilidade de
                                        reversão, salvo os casos previstos em lei e conforme previsto nesta Política;
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <h1>&#8226; Confirmação de tratamento – </h1>
                                    <span>permite que você solicite ao IAS a confirmação do tratamento dos seus dados pessoais;</span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <h1>&#8226; Informações sobre compartilhamento – </h1>
                                    <span>
                                        permite que você solicite ao IAS confirmação sobre com quais terceiros seus dados pessoais foram
                                        compartilhados;
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <h1>&#8226; Informações sobre consentimento e revogação – </h1>
                                    <span>
                                        permite que você (a) tenha informações claras sobre a possibilidade e as consequências de não dar o seu
                                        consentimento, e (b) solicite ao IAS a revogação do seu consentimento em relação às atividades de
                                        tratamento que se baseiam no consentimento, sendo certo que você reconhece que a revogação não afetará a
                                        legalidade de qualquer tratamento realizado anteriormente pelo IAS e que a mesma poderá te impedir de
                                        utilizar o site, as plataformas e as funcionalidades/ações oferecidas pelo IAS em sua plenitude, ou mesmo
                                        inviabilizar por completo referido uso;
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <h1>&#8226; Portabilidade de dados – </h1>
                                    <span>
                                        quando possível, e partir da respectiva regulamentação pela Autoridade Nacional de Proteção de Dados
                                        (ANPD), permite que você solicite ao IAS a portabilidade dos seus dados; e,
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <h1>&#8226; Oposição – </h1>
                                    <span>
                                        permite que você solicite a interrupção de determinado tratamento de dados pessoais realizado pelo IAS
                                        sem o seu consentimento.
                                    </span>
                                </p>
                                <br />
                            </li>
                        </ul>
                    </li>
                    <p>
                        Para o exercício dos direitos listados, acima, o IAS poderá solicitar a você comprovação da sua identidade, como uma
                        medida de segurança. E, uma vez recebida a sua solicitação, o IAS responderá imediatamente ou terá o prazo de até 15
                        (quinze) dias para o envio de uma resposta, de acordo com a complexidade da solicitação. Os pedidos serão analisados
                        conforme previsto em legislação vigente e, por motivos legítimos, algumas solicitações podem não ser atendidas.
                    </p>
                    <br />
                    <li>
                        <h1>H. Registro de atividades</h1>
                        <br />
                        <p>
                            O IAS pode registrar as atividades que você realiza quando utiliza o site institucional e as plataformas, ou quando
                            acessa os comunicados e notificações enviados, criando, quando possível e aplicável, logs (registros de atividades
                            efetuadas) que conterão: o endereço IP, acesso e ações realizadas, data e hora de cada ação realizada e informações
                            sobre o dispositivo utilizado, tais como a versão de sistema operacional, navegador e geolocalização.
                        </p>
                        <br />
                        <p>
                            Além disso, sempre em respeito aos termos desta Política, o IAS utiliza ou poderá utilizar outras tecnologias,
                            próprias ou de terceiros, para o registro das suas atividades, conforme indicado a seguir:
                        </p>
                        <br />
                        <br />
                        <ul style={{ paddingLeft: '20px' }}>
                            <li>
                                <p>
                                    <h1>&#8226; Cookies – </h1>
                                    <span>
                                        o IAS utiliza cookies próprios e de terceiros para diversas funções, como possibilitar e facilitar a
                                        utilização do site institucional e das plataformas, bem como de suas funcionalidades/ações, acompanhar as
                                        suas preferências, persistir informações relacionadas a suas atividades nos ambientes visitados, coletar
                                        informações para o envio de comunicações e conteúdo mais personalizados, diminuir a ocorrência de falhas
                                        durante o acesso, mitigar o risco de atividades fraudulentas, garantir uma navegação mais personalizável,
                                        oferecer recursos de segurança durante a navegação, e analisar de forma estatística as preferências
                                        gerais do conjunto de visitantes/usuários; e,
                                    </span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <h1>&#8226; Ferramentas de analytics – </h1>
                                    <span>
                                        o IAS utiliza essas ferramentas para identificar os seus padrões de navegação no site e nas plataformas,
                                        incluindo quais páginas e quando você visita tais páginas, e gera relatórios sobre essas atividades, para
                                        que possamos melhorar o desenvolvimento das atividades pelo IAS.
                                    </span>
                                </p>
                                <br />
                            </li>
                        </ul>

                        <p>
                            <h1>
                                IMPORTANTE: Caso não queira utilizar cookies, você poderá a qualquer momento bloquear o uso dos cookies ativando
                                uma configuração no seu navegador de internet, sendo que a capacidade desse bloqueio dependerá das configurações
                                e limitações do seu navegador. Em caso de bloqueio de cookies, o site institucional e as plataformas
                                administradas pelo IAS poderão não funcionar corretamente e as suas funcionalidades também poderão estar parcial
                                ou totalmente indisponíveis.
                            </h1>
                            <p>
                                <p style={{ display: 'inline' }}>
                                    Para administrar os seus cookies, recomendamos que clique nas configurações do seu navegador, e confira as
                                    instruções relacionadas ao uso, bloqueio e exclusão de cookies. Caso tenha interesse em encontrar mais
                                    detalhes, abaixo, estão listadas as instruções dos navegadores mais populares:{' '}
                                </p>
                                <a href={SUPPORT_CHROME} target="_blank" rel="noreferrer">
                                    (A) Google Chrome;{' '}
                                </a>
                                <a href={SUPPORT_FIREFOX} target="_blank" rel="noreferrer">
                                    (B) Mozilla Firefox;{' '}
                                </a>
                                <a href={SUPPORT_SAFARI} target="_blank" rel="noreferrer">
                                    (C) Safari;{' '}
                                </a>
                                <a href={SUPPORT_EXPLORER} target="_blank" rel="noreferrer">
                                    (D) Internet Explorer;{' '}
                                </a>
                                <a href={SUPPORT_EDGE} target="_blank" rel="noreferrer">
                                    (E) Microsoft Edge;{' '}
                                </a>
                                <span>e </span>
                                <a href={SUPPORT_OPERA} target="_blank" rel="noreferrer">
                                    (F) Opera
                                </a>
                                <span>.</span>
                            </p>
                        </p>
                        <br />
                    </li>
                    <li>
                        <h1>I. Transferência internacional de dados pessoais</h1>
                        <p>
                            Como indicado nesta Política, o IAS utiliza servidores de terceiros contratados e pode, inclusive, armazenar os seus
                            dados pessoais em servidores de computação em nuvem, localizados fora do Brasil.
                        </p>
                        <br />
                        <p>
                            Além disso, em alguns casos, o IAS poderá compartilhar os seus dados pessoais com parceiro e/ou apoiadores
                            institucionais localizados no exterior, para fins de apoio e/ou colaboração na execução das ações e/ou
                            funcionalidades oferecidas nos site e plataformas administrados pelo IAS.
                        </p>
                        <br />
                        <p>
                            Nesses casos, em atenção à legislação vigente, o IAS respeitará as regras estabelecidas para esse envio e demandará
                            desses terceiros o cumprimento dos termos desta Política e demais regras e legislações eventualmente aplicáveis, bem
                            como adotará as melhores práticas de segurança e privacidade disponíveis para garantir a integridade e
                            confidencialidade dos seus dados pessoais.
                        </p>
                        <br />
                    </li>
                    <li>
                        <h1>J. Medidas de segurança</h1>
                        <br />
                        <br />
                        <p>
                            O IAS toma as medidas técnicas e organizacionais adequadas para garantir a integridade dos seus dados pessoais. Para
                            isso, utilizamos práticas-padrão do mercado para manter a segurança das atividades de coleta, tratamento e
                            armazenamento, desde que comercialmente razoáveis como, criptografia, firewalls e Secure Sockets Layer (SSL).
                        </p>
                        <br />
                        <p>
                            Ocorre que, por mais eficaz que seja a tecnologia de segurança, nenhum sistema é impenetrável, e, infelizmente, não
                            podemos garantir completamente a segurança dos bancos de dados, da mesma forma que não podemos garantir que seus
                            dados pessoais não serão interceptados. Nesse sentido, o IAS se exime de quaisquer responsabilidades por eventuais
                            danos e/ou prejuízos decorrentes de falhas, vírus ou invasões do banco de dados, salvo nos casos em que estiverem
                            comprovados seu exclusivo dolo ou culpa.
                        </p>
                        <br />
                        <p>
                            Por isso, para manter a segurança e proteção dos seus dados pessoais fornecidos no cadastro e/ou conta de usuário,
                            recomendamos que você, em nenhuma hipótese,
                        </p>
                        <p>
                            compartilhe o seu login e senha com terceiros, e que tome o cuidado necessário ao postar seus dados pessoais em redes
                            sociais ou qualquer outro ambiente público.
                        </p>
                        <br />
                        <h1>
                            IMPORTANTE: Caso o IAS identifique que alguma dessas situações ocorreu, quando exigido pela legislação aplicável, o
                            IAS notificará os titulares eventualmente afetados, informando sobre a violação de seus dados pessoais e indicando
                            que adotem os procedimentos cabíveis.
                        </h1>
                    </li>
                    <br />
                    <li>
                        <h1>K. Disposições gerais</h1>
                    </li>
                    <br />
                    <ol>
                        <li>
                            <p>1. Alterações a essa Política</p>
                            <p>
                                Poderemos alterar a presente Política ao longo do tempo. Toda vez que alguma condição relevante desta Política
                                for alterada, essas alterações serão válidas, eficazes e vinculantes a partir da publicação e divulgação da nova
                                versão em nosso site institucional e plataformas.
                            </p>
                            <br />
                            <p>
                                Em compromisso com os nossos valores de transparência, em caso de atualização relevante da presente, o IAS dará
                                publicidade a tais modificações. É recomendado que você consulte regularmente este documento para estar sempre
                                atualizado com relação aos seus termos.
                            </p>
                            <br />
                            <h1>
                                IMPORTANTE: Esta Política foi alterada pela última vez e publicada no site institucional e plataformas
                                administrados pelo IAS em dezembro de 2020.
                            </h1>
                            <br />
                        </li>
                        <br />
                        <li>
                            <p>2. Fale conosco</p>
                            <br />
                            <p>
                                <p>
                                    Em caso de dúvidas, sugestões ou comentários sobre as nossas práticas de privacidade e tratamento de dados
                                    pessoais, ou, ainda, sobre os termos deste documento, por favor, entre em contato conosco pelo e-mail{'  '}
                                    <a href={'mailto:' + ENCARREGADO_EMAIL} target="_blank" rel="noreferrer">
                                        {ENCARREGADO_EMAIL}
                                    </a>
                                    .
                                </p>
                            </p>
                            <p>
                                O IAS segue à disposição dos visitantes e usuários do nosso site institucional e plataformas, em contínuo
                                compromisso com a sua privacidade, autodeterminação informativa e liberdade de expressão e informação.
                            </p>
                        </li>
                    </ol>
                </ol>

                <br />
            </Content>
        </PrivacyPolicyContainer>
    );
};

export default PrivacyPolicy;
