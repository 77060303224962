import Select from 'components/Select';
import { MobileMaxWidth } from 'constants/Dimensions';
import Colors from 'enums/Colors';
import { Form, Modal } from 'react-bootstrap';
import styled from 'styled-components';

const AdminEditorStyled = {
    Container: styled.div`
        display: grid;
        grid-template-columns: 1fr 400px;
        padding: 20px 10px;
        max-width: 100%;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            display: flex;
            flex-direction: column;
        }
    `,

    Row: styled.div`
        display: flex;
        align-items: center;
    `,

    Cover: styled.img`
        width: 100%;
    `,

    RigthArea: styled.div`
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        height: 100%;
    `,

    Label: styled.p`
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #00a8e9;
        margin-top: 25px;
        margin-bottom: 5px;
        text-transform: capitalize;
        margin-right: 10px;
    `,

    SubLabel: styled.p`
        font-weight: 600;
        font-style: italic;
        font-size: 14px;
        line-height: 18px;
        color: ${Colors.PureOrange};
        margin-top: 5px;
    `,

    Input: styled(Form.Control)`
        width: 100%;
    `,

    AlignRight: styled.div`
        text-align: right;
    `,

    SelectContainer: styled.div`
        display: flex;
        align-items: center;
        margin-top: 20px;
        justify-content: space-between;
    `,

    CountLetter: styled.p<{ danger: boolean }>`
        font-weight: 300;
        font-size: 14px;
        line-height: 17px;
        color: ${props => (props.danger ? 'red' : '#747474')};
        text-align: right;
    `,

    ContainerTestemonial: styled.div`
        display: grid;
        grid-template-columns: 70px 750px 1fr;
        margin-top: 20px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            display: flex;
            flex-direction: column;
        }
    `,

    ContainerCards: styled.div`
        padding-top: 20px;
    `,

    ContentCard: styled.div`
        display: flex;
        align-items: flex-start;
        margin-top: 20px;
    `,

    ContentCardForm: styled.div`
        flex-grow: 1;
        margin: 0 25px;
    `,

    ContentTestemonial: styled.div`
        @media only screen and (max-width: ${MobileMaxWidth}px) {
            border: 1px green solid;
            margin-bottom: 20px;
        }
    `,

    NumberIndex: styled.div`
        height: 40px;
        width: 40px;
        background: rgba(83, 173, 34, 0.1);
        border-radius: 50%;
        color: #183f73;
        font-weight: bold;

        display: flex;
        justify-content: center;
        align-items: center;
    `,

    Title: styled.p`
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        color: #183f73;
    `,

    RightContainer: styled.div`
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin-top: 40px;
    `,

    ExcludeLink: styled.p`
        color: red;
    `,

    RightColumn: styled.div`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: 40px;
    `,

    CardsContainer: styled.div`
        //order-bottom: 1px #eee solid;
        margin-top: 35px;
        padding-bottom: 20px;
    `,

    SoluctionsContainer: styled.div`
        display: grid;
        grid-template-columns: 1fr 400px;
        padding: 10px;
        padding-top: 0;
        border-bottom: 1px #ddd solid;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            display: flex;
            flex-direction: column;
        }
    `,

    RowSol: styled.div`
        display: flex;
        justify-content: space-between;

        span {
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #747474;
        }
    `,

    CkeckContainer: styled.div`
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        label {
            cursor: pointer;
        }
    `,

    BottomButton: styled.div`
        padding: 10px 30px;
    `,

    ImageContainer: styled.div`
        padding-top: 50px;
        display: flex;
        justify-content: center;
    `,

    Modal: styled(Modal)``,

    Center: styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 20px;
    `,

    TextModal: styled.p`
        font-weight: bold;
        font-size: 22px;
        text-align: center;
        color: #2e2e2e;
        width: 100%;
    `,

    ContainerBottom: styled.div`
        text-align: center;
        display: flex;
        justify-content: center;
    `,

    ContainerWhite: styled.div`
        display: grid;
        grid-template-columns: 1fr 400px;
        padding: 30px;
        background-color: white;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            display: flex;
            flex-direction: column-reverse;
            padding: 10px;
        }
    `,

    TopBar: styled.div`
        width: 100%;
        box-shadow: 1px 1px 10px rgba(0, 60, 120, 0.1);
        border-radius: 10px;
        padding: 20px;
        display: flex;
        align-items: center;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            flex-direction: column;
        }
    `,

    TextTop: styled.p`
        font-weight: 500;
        font-size: 18px;
        line-height: 32px;
        color: #747474;
        margin-right: 20px;
    `,

    Select: styled(Select)``,

    HeadFormContainer: styled.div`
        max-width: 720px;
    `,
};

export default AdminEditorStyled;
