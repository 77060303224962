import React, { useContext, useState, useEffect } from 'react';
import * as yup from 'yup';
import Menu from 'pages/menu/Menu';
import Styled from './EditProfile.styled';
import img from '../../images/profile/people-edit.svg';
import imgExclude from '../../images/profile/octagon-x.svg';

import { Formik } from 'formik';
import { ProfileService } from 'core/http/service/Profile.service';
import UsuarioDTO from 'models/UsuarioDTO';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import { useHistory } from 'react-router-dom';
import SuporteDialog from 'components/suporteDialog/SuporteDialog';
import { ToasterDefault, ToastError, ToastSuccess } from 'components/Toaster';
import ContactContainer from 'pages/home/components/contact/ContactContainer';
import { HiOutlineChevronLeft } from 'react-icons/hi';
import { PesquisaContext } from 'pages/home/context/PesquisaContext';
import BarraPesquisa from 'pages/pesquisa/barraPesquisa/BarraPesquisa';
import PerfilEditDTO from 'models/perfil/PerfilEditDTO';
import Loading from 'components/Loading';
import { MaskMobile, maskOnlyNumbers } from 'core/util/MaskHelper';
import ImageProfile from './ImageProfile';
import { ProfileWithAvatar } from 'models/perfil/ProfileWithAvatar';
import fetchUserInfo from 'pages/painelSocioemocional/home/utils/fetchUserInfo';
import PerfilUsuario from 'models/perfil/PerfilUsuario';
import { useKeycloak } from '@react-keycloak/web';
import { useDispatch } from 'react-redux';
import { KeycloakService } from 'core/http/service/Keycloak.service';
import { FetchUtils } from 'core/util/FetchUtils';
import * as authenticationActions from '../../store/actions/Authentication';
import DynamicHeader from 'components/dynamic_header/DynamicHeader';
import { PrimaryButton } from 'components/UI/Buttons';
import Select from 'components/Select';

export interface IEditProfile {}

const EditProfile = ({}: IEditProfile) => {
    const { keycloak } = useKeycloak();
    const dispatch = useDispatch();
    const service = new ProfileService();
    const kcService = new KeycloakService();
    const hystory = useHistory();
    const userProfile: PerfilUsuario | null = useAppSelector(state => state.profileReducer.userProfile);
    const [usuarioLogado, setUsuarioLogado] = useState<UsuarioDTO | null>(null);
    const [profile, setProfile] = useState<ProfileWithAvatar>();
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const { isSearchView } = useContext(PesquisaContext.Context);
    const [previewImage, setPreviewImage] = useState<string | null>(null);
    const [imageBase64, setImageBase64] = useState<string>('');

    // loadings
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [submittingaPass, setSubmittingPass] = useState(false);

    const [pass, setPass] = useState('');
    const [confirmpass, setConfirmpass] = useState('');

    const [initialValues, setInitialValues] = useState<IValues>({
        nome: '',
        sobrenome: '',
        email: '',
        whatsapp: '',
    });

    interface IValues {
        nome: string;
        sobrenome: string;
        email: string;
        whatsapp: string;
    }

    const loadUser = async () => {
        const response: any = await kcService.getUsuarioLogado();
        if (response.status === 200) {
            const responseUsuario = new UsuarioDTO().fromJSON(response.data);
            dispatch(authenticationActions.setUsuarioLogado(responseUsuario));
            if (responseUsuario) {
                setUsuarioLogado(responseUsuario);
                await fetchUserInfo(responseUsuario.username, userProfile?.codigo || -1).then((perfilData: ProfileWithAvatar) => {
                    setProfile(perfilData);
                });

                let { data } = await service.getUserEdit(responseUsuario.username);
                const dados = new PerfilEditDTO().fromJSON(data);
                setPreviewImage(dados.imageBase64);
                setInitialValues({
                    nome: dados.nome ?? '',
                    sobrenome: dados.sobrenome ?? '',
                    email: dados.email ?? '',
                    whatsapp: dados.whatsapp ?? '',
                });
            }
        } else {
            ToastError('Erro ao recuperar informações do usuário');
        }
    };

    useEffect(() => {
        FetchUtils.loadingHof(setLoading, loadUser);
    }, []);

    const yupValidation = yup.object().shape({
        nome: yup.string().min(1).required(),
        sobrenome: yup.string().min(1).required(),
        email: yup.string().email().required(),
        whatsapp: yup.string().min(13),
    });

    const deleteProfile = () => {
        setOpenDialog(true);
    };

    const submit = async (data: any) => {
        const changingPass = data.pass != undefined;

        try {
            if (imageBase64) {
                data.imageBase64 = imageBase64;
            }

            changingPass ? setSubmittingPass(true) : setSubmitting(true);
            const { status } = await service.editProfile(usuarioLogado?.username ?? '', data);

            if (status == 200) {
                keycloak.updateToken(-1).then(refreshed => {
                    console.log('TOKEN REFRESHED? ', refreshed);
                });

                setTimeout(() => {
                    window.location.reload();
                }, 500);

                ToastSuccess(changingPass ? 'Senha atualizada!' : 'Perfil atualizado!');
            } else if (status == 409) {
                ToastError('Perfil não atualizado! Email já em uso no sistema');
            }
        } catch (error) {
            ToastError('Erro ao atualizar perfil');
        } finally {
            changingPass ? setSubmittingPass(false) : setSubmitting(false);
        }
    };

    const gotoHome = () => {
        hystory.push('/');
    };

    const validPass = () => {
        const re = /[A-Za-z\d@$!%*#?&]/g;
        return pass.length >= 3 && confirmpass == pass && re.test(pass);
    };

    return !loading ? (
        <>
            {/*  <Menu homeIntermediate={true} homepage={true} /> */}

            <DynamicHeader />

            <Styled.Container>
                <Styled.HeadCyan>
                    <Styled.Title className="mobile-only">
                        <HiOutlineChevronLeft onClick={gotoHome} />
                    </Styled.Title>
                    <Styled.Title>Editar Meu perfil</Styled.Title>
                    <Styled.TitleImage src={img} />
                </Styled.HeadCyan>

                <Styled.Content>
                    <Styled.Row>
                        <Styled.Column style={{ margin: 0, gap: '30px' }}>
                            <Styled.BigLabel>Foto de perfil</Styled.BigLabel>
                            <ImageProfile
                                profile={profile}
                                username={usuarioLogado?.primeiroNome ?? ''}
                                onSelect={(base64: string, file: File) => setImageBase64(base64)}
                                preImageB64={previewImage}
                            />

                            <Styled.Separator />
                        </Styled.Column>
                    </Styled.Row>

                    <Formik initialValues={initialValues} onSubmit={submit} validationSchema={yupValidation}>
                        {formik => {
                            return (
                                <>
                                    <Styled.Row>
                                        <Styled.BigLabel>Dados básicos</Styled.BigLabel>
                                    </Styled.Row>

                                    <Styled.Row>
                                        <Styled.Column>
                                            <Styled.Label>Primeiro Nome</Styled.Label>
                                            <Styled.Input
                                                value={formik.values['nome']}
                                                onChange={e => {
                                                    formik.setFieldValue('nome', e.target.value);
                                                }}
                                            />
                                        </Styled.Column>

                                        <Styled.Column>
                                            <Styled.Label>Sobrenome</Styled.Label>
                                            <Styled.Input
                                                value={formik.values['sobrenome']}
                                                onChange={e => {
                                                    formik.setFieldValue('sobrenome', e.target.value);
                                                }}
                                            />
                                        </Styled.Column>
                                    </Styled.Row>

                                    <Styled.Row>
                                        <Styled.Column>
                                            <Styled.Label>E-mail</Styled.Label>
                                            <Styled.Input
                                                value={formik.values['email']}
                                                onChange={e => {
                                                    formik.setFieldValue('email', e.target.value);
                                                }}
                                            />
                                        </Styled.Column>

                                        <Styled.Column>
                                            <Styled.Label>Whatsapp</Styled.Label>
                                            <Styled.Input
                                                style={{ background: '#ebebeb' }}
                                                placeholder="(00) 00000-0000"
                                                maxLength={14}
                                                value={formik.values['whatsapp']}
                                                onKeyDown={e => {
                                                    if (e.key == 'Backspace') {
                                                        formik.setFieldValue('whatsapp', maskOnlyNumbers(formik.values['whatsapp']));
                                                    }
                                                }}
                                                onChange={e => {
                                                    formik.setFieldValue('whatsapp', MaskMobile(e.target.value));
                                                }}
                                                onPaste={e => {
                                                    e.preventDefault();
                                                    return false;
                                                }}
                                            />
                                        </Styled.Column>
                                    </Styled.Row>
                                    <br />

                                    <Styled.PrimaryButton onClick={() => formik.handleSubmit()} disabled={submitting}>
                                        {submitting ? 'Salvando' : 'Salvar dados'}
                                    </Styled.PrimaryButton>
                                </>
                            );
                        }}
                    </Formik>

                    <Styled.Row3>
                        <Styled.Divider />
                    </Styled.Row3>

                    <Styled.BigLabel>Editar prefil de acesso</Styled.BigLabel>
                    <Styled.Row>
                        <Styled.Column>
                            <Styled.Label style={{ marginTop: '20px', marginBottom: '10px' }}>Novo perfil</Styled.Label>
                            <Select disabled itens={[]} />
                        </Styled.Column>

                        <Styled.Column>
                            <Styled.Label style={{ marginTop: '20px', marginBottom: '10px' }}>Confirmar novo perfil</Styled.Label>
                            <Select disabled itens={[]} />
                        </Styled.Column>
                    </Styled.Row>

                    <Styled.PrimaryButton onClick={() => {}} disabled={true}>
                        Salvar perfil
                    </Styled.PrimaryButton>
                    <br />

                    <Styled.Row3>
                        <Styled.Divider />
                    </Styled.Row3>

                    <Styled.Row>
                        <Styled.BigLabel>Senha de acesso</Styled.BigLabel>
                    </Styled.Row>

                    <Styled.Row>
                        <Styled.Column>
                            <Styled.Label>Nova senha</Styled.Label>
                            <Styled.Input
                                type={'password'}
                                autoComplete={'new-password'}
                                value={pass}
                                onChange={(e: any) => {
                                    setPass(e.target.value);
                                }}
                            />

                            {/* <Styled.SansCript>A senha deve ter no mínimo 8 caracteres</Styled.SansCript> */}
                        </Styled.Column>

                        <Styled.Column>
                            <Styled.Label>Confirmar nova senha</Styled.Label>
                            <Styled.Input
                                type={'password'}
                                autoComplete={'new-password'}
                                value={confirmpass}
                                onChange={(e: any) => {
                                    setConfirmpass(e.target.value);
                                }}
                            />
                        </Styled.Column>
                    </Styled.Row>

                    <Styled.BottomContainer>
                        <Styled.ActionButton onClick={() => submit({ senha: pass })} disabled={!validPass() || submittingaPass}>
                            {submittingaPass ? 'Salvando' : 'Salvar Senha'}
                        </Styled.ActionButton>
                    </Styled.BottomContainer>

                    <br />

                    <Styled.Divider />
                    <Styled.BigLabel style={{ marginTop: '40px' }}>Excluir conta</Styled.BigLabel>
                    <Styled.TextAlert>
                        Atenção. Essa ação é irreversível e irá excluir sua conta e todo o seu progresso nas atividades humane. Se deseja excluir
                        sua conta, <b onClick={deleteProfile}>clique aqui</b>.
                    </Styled.TextAlert>
                </Styled.Content>

                <SuporteDialog
                    iframeUrl="https://ias-suporte-ead.tomticket.com/formulario?id=EP07602&account=486528P06072022104059"
                    setShowDialog={setOpenDialog}
                    showDialog={openDialog}
                />
                {ToasterDefault()}
            </Styled.Container>

            <ContactContainer />

            {isSearchView && <BarraPesquisa />}
        </>
    ) : (
        <Loading />
    );
};

export default EditProfile;
