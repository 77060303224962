import React from 'react';
import { PaginasEnum } from 'enums/PaginasEnum';
import TagManager from 'react-gtm-module';
import keycloak from 'core/config/keycloak';
import ABTest from './ab_tests/ABTest';
import TestVersion, { getEventFromVersion } from 'enums/TestVersions';

/**
 * * Eventos
 * @returns cl_btn_click_logged - Cursos livres: inscreva-se logado\
 *          cl_btn_click_logged_out - Cursos livres: inscreva-se deslogado\
 *          meu_painel_share_click - Meu painel: botoes de compartilhamento das jornadas\
 *          meu_painel_redirect_click - Meu painel: botoes de acesso das jornadas\
 *          home_btn_click_logged - Home: botoes da home logado\
 *          home_btn_click_logged_out - Home: botoes da home deslogado\
 *          menu_btn_click_logged - Menu: botoes do menu logado\
 *          menu_btn_click_logged_out - Menu: botoes do menu deslogado\
 *          intermediaria_btn_click_logged - Intermediarias: botoes das paginas intermediarias logado\
 *          intermediaria_btn_click_logged_out - Intermediarias: botoes das paginas intermediarias deslogado\
 *          realizando_escolhas_btn_click_logged - Realizando Escolhas: clique de botoes das paginas\
 *          jornada_desenv_btn_click_logged - Jornada de Desenvolvimento: clique de botoes das paginas logado\
 *          jornada_desenv_btn_click_logged_out - Jornada de Desenvolvimento: clique de botoes das paginas deslogado
 *          biblioteca_btn_click_logged - Biblioteca: clique de botoes das paginas deslogado
 *          biblioteca_btn_click_logged_out - Biblioteca: clique de botoes das paginas deslogado
 */
const Events = () => {
    return {
        cl_btn_click_logged: 'cl_btn_click_logged',
        cl_btn_click_logged_out: 'cl_btn_click_logged_out',
        meu_painel_share_click: 'meu_painel_share_click',
        meu_painel_redirect_click: 'meu_painel_redirect_click',
        home_btn_click_logged: 'home_btn_click_logged',
        home_btn_click_logged_out: 'home_btn_click_logged_out',
        menu_btn_click_logged: 'menu_btn_click_logged',
        menu_btn_click_logged_out: 'menu_btn_click_logged_out',
        intermediaria_btn_click_logged: 'intermediaria_btn_click_logged',
        intermediaria_btn_click_logged_out: 'intermediaria_btn_click_logged_out',
        realizando_escolhas_btn_click: 'realizando_escolhas_btn_click',
        jornada_desenv_btn_click_logged: 'jornada_desenv_btn_click_logged',
        jornada_desenv_btn_click_logged_out: 'jornada_desenv_btn_click_logged_out',
        biblioteca_btn_click_logged: 'jornada_desenv_btn_click_logged',
        biblioteca_btn_click_logged_out: 'jornada_desenv_btn_click_logged_out',

        test_ab_versao_A_usuario: getEventFromVersion(TestVersion.VersionA),
        test_ab_versao_B_usuario: getEventFromVersion(TestVersion.VersionB),
        test_ab_versao_C_usuario: getEventFromVersion(TestVersion.VersionC),
        test_ab_versao_D_usuario: getEventFromVersion(TestVersion.VersionD),
    };
};

/**
 * * Evento padrão
 * @param event Nome do evento
 * @param category Localizacao/Tipo do evento
 * @param action Acao do evento
 * @param description Descricao do evento
 */
const GTM_Page_Default = (event: string, category: string, action: string, description: string) => {
    TagManager.dataLayer({
        dataLayer: {
            event: event,
            data: {
                category: category,
                action: action,
                description: description,
                version: "",
            },
        },
    });
};

/**
 * * Evento de definição de versão do teste A/B
 * @param version Versão
 */
const GTM_Event_ABTest = (version: TestVersion) => {
    TagManager.dataLayer({
        dataLayer: {
            event: getEventFromVersion(version),
            data: version.toString(),
        },
    });
};

/**
 * * GTM com condição de logado ou deslogado
 * @param event_logged Nome do evento logado
 * @param event_logged_out Nome do evento deslogado
 * @param category_logged Tipo do evento logado
 * @param category_logged_out Tipo do evento deslogado
 * @param action acao do evento ou titulo do evento se tiver um id
 * @param description localizacao/descricao do evento
 * @param id id do evento caso exista
 */
const GTM_Page_Authentication = (
    event_logged: string,
    event_logged_out: string,
    category_logged: string,
    category_logged_out: string,
    action: string,
    description: string,
    id?: string
) => {
    if (keycloak.authenticated) {
        TagManager.dataLayer({
            dataLayer: {
                event: event_logged,
                data: {
                    ...(id ? { id: id } : {}),
                    ...(id ? { title: action } : { action: action }),
                    category: category_logged,
                    description: description,
                    version: "",
                },
            },
        });
    } else {
        TagManager.dataLayer({
            dataLayer: {
                event: event_logged_out,
                data: {
                    ...(id != null ? { id: id } : {}),
                    category: category_logged_out,
                    description: description,
                    version: "",
                    action: action,
                },
            },
        });
    }
};

/**
 * * Evento do menu e header
 * @param action acao do evento
 * @param description localizacao do evento
 */
const GTM_Menu = (description: string, action: string) => {
    GTM_Page_Authentication('menu_btn_click_logged', 'menu_btn_click_logged_out', 'menu_logado', 'menu_deslogado', action, description);
};

/**
 * * Eventos da pagina inicial logada ou deslogada
 * @param action acao do evento
 * @param description localizacao do evento
 */
const GTM_Page_Home = (description: string, action: string) => {
    GTM_Page_Authentication('home_btn_click_logged', 'home_btn_click_logged_out', 'home_logada', 'home_deslogada', action, description);
};

/**
 * * Eventos das paginas intermediarias logadas ou deslogadas
 * @param action acao do evento
 * @param description localizacao do evento
 */
const GTM_Page_Intermediaria = (description: string, action: string) => {
    GTM_Page_Authentication(
        'intermediaria_btn_click_logged',
        'intermediaria_btn_click_logged_out',
        'intermediaria_logado',
        'intermediaria_deslogado',
        action,
        description
    );
};

/**
 * * Evento para card de curso livre
 * @param id id do curso livre
 * @param title titulo do curso
 * @param description descricao do curso
 */
const GTM_Page_Curso_Livre = (id: number, title: string, description: string) => {
    GTM_Page_Authentication(
        'cl_btn_click_logged',
        'cl_btn_click_logged_out',
        'Cursos Livres',
        'Cursos Livres',
        title,
        description,
        id.toString()
    );
};

/**
 * * Evento do Meu Painel
 * @param category gatilho que disparou a acao do evento
 * @param action localizacao do evento
 * @param description descricao da acao
 */
const GTM_Page_Meu_Painel = (category: string, action: string, description: string) => {
    GTM_Page_Default('meu_painel_redirect_click', action, category, description);
};

/**
 * * Meu Painel Compartilhamentos
 * @param action acao do evento
 * @param category localizacao do evento
 */
const GTM_Page_Meu_Painel_Sharebox = (action: string, category: string) => {
    GTM_Page_Default(
        'meu_painel_share_click',
        category == PaginasEnum.JORNADA_DESENVOLVIMENTO || category == PaginasEnum.CURSOS_LIVRES || category == PaginasEnum.BIBLIOTECA
            ? 'principal_' + category.toLowerCase()
            : category.toLowerCase(),
        action,
        'share_button'
    );
};

/**
 *
 * @param description localizacao do evento
 * @param action acao do evento
 */
const GTM_Page_Jornada_Desenvolvimento = (description: string, action: string) => {
    GTM_Page_Authentication(
        'jornada_desenv_btn_click_logged',
        'jornada_desenv_btn_click_logged_out',
        'jornada_desenv_logado',
        'jornada_desenv_deslogado',
        action,
        description
    );
};

/**
 * Evento para pagina de Realizando Escolhas
 * @param description localizacao da acao
 * @param action acao do gatilho da acao
 */
const GTM_Page_Realizando_Escolhas = (description: string, action: string) => {
    GTM_Page_Default('realizando_escolhas_btn_click', 'realizando_escolhas', action, description);
};

/**
 * Evento para pagina de Realizando Escolhas
 * @param description localizacao da acao
 * @param action acao do gatilho da acao
 */
const GTM_Page_Competencias_Socioemocionais = (description: string, action: string) => {
    GTM_Page_Default('compotencias_socioemocionais_btn_click', 'competencias_socioemocionais', action, description);
};

/**
 *
 * @param id id do item da biblioteca
 * @param title titulo do item da biblioteca
 * @param action acao disparada ao clicar no item
 */
const GTM_Page_Biblioteca = (id: string, title: string, action: string) => {
    GTM_Page_Authentication(
        'biblioteca_btn_click_logged',
        'biblioteca_btn_click_logged_out',
        'biblioteca_logada',
        'biblioteca_deslogada',
        action,
        title,
        id
    );
};

/**
 * * Google Analytics 4: Ecommerce
 * * - PURCHASE \
 * ? https://developers.google.com/tag-manager/ecommerce-ga4#measure_purchases
 * @param id_keycloak id referente ao usuario logado
 * @param id id do item do produto
 * @param description descricao do produto
 */
const GTM_GA4_Ecommerce_Purchase = (id_keycloak: string, id: string, description: string) => {
    if (keycloak.authenticated) {
        TagManager.dataLayer({ dataLayer: { ecommerce: null } });
        TagManager.dataLayer({
            dataLayer: {
                event: 'purchase',
                ecommerce: {
                    currency: 'BRL',
                    value: 0,
                    transaction_id: id_keycloak,
                    items: [
                        {
                            item_name: description,
                            item_id: id,
                            price: '0',
                        },
                    ],
                },
            },
        });
    }
};

/**
 * * Google Analytics 4: Ecommerce
 * * - SELECT ITEM \
 * ? https://developers.google.com/tag-manager/ecommerce-ga4#measure_productitem_list_clicks
 * @param id id do item do produto
 * @param description descricao do produto
 */
const GTM_GA4_Ecommerce_Select_Item = (id: string, description: string) => {
    if (!keycloak.authenticated) {
        TagManager.dataLayer({ dataLayer: { ecommerce: null } });
        TagManager.dataLayer({
            dataLayer: {
                event: 'select_item',
                ecommerce: {
                    items: [
                        {
                            item_name: description,
                            item_id: id,
                            price: '0',
                        },
                    ],
                },
            },
        });
    }
};

/**
 * * Google Analytics 4: Ecommerce
 * * - VIEW ITEM LIST \
 * ? https://developers.google.com/tag-manager/ecommerce-ga4#measure_productitem_list_viewsimpressions
 * @param item_list lista de itens parametrizados
 */
const GTM_GA4_Ecommerce_View_Item_List = (item_list: {}) => {
    TagManager.dataLayer({ dataLayer: { ecommerce: null } });
    if (!keycloak.authenticated) {
        TagManager.dataLayer({
            dataLayer: {
                event: 'view_item_list',
                ecommerce: {
                    items: item_list,
                },
            },
        });
    } else {
        TagManager.dataLayer({
            dataLayer: {
                event: 'view_item_list_logged',
                ecommerce: {
                    items: item_list,
                },
            },
        });
    }
};

export {
    Events,
    GTM_Menu,
    GTM_Page_Home,
    GTM_Page_Intermediaria,
    GTM_Page_Curso_Livre,
    GTM_Page_Meu_Painel,
    GTM_Page_Meu_Painel_Sharebox,
    GTM_Page_Jornada_Desenvolvimento,
    GTM_Page_Realizando_Escolhas,
    GTM_Page_Biblioteca,
    GTM_GA4_Ecommerce_Purchase,
    GTM_GA4_Ecommerce_Select_Item,
    GTM_GA4_Ecommerce_View_Item_List,
    GTM_Event_ABTest,
    GTM_Page_Competencias_Socioemocionais
};
