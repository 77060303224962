import React from 'react';

import Modal from 'react-bootstrap/Modal';
import { IoMdClose } from 'react-icons/io';

import { VideoDialogStyle, IFrameStyle, IFrameContainer, CloseButtonContainer, CloseButton } from './VideoDialog.styled';

interface IVideoDialog {
    videoUrl: string;
    visible: boolean;
    onCloseCallback: () => void;
}

const VideoDialog = ({ videoUrl, visible, onCloseCallback }: IVideoDialog) => {
    return (
        <>
            {visible && (
                <VideoDialogStyle>
                    <Modal show={visible} onHide={onCloseCallback} size="lg" backdrop="static" keyboard={false}>
                        <Modal.Body>
                            <CloseButtonContainer>
                                <CloseButton onClick={onCloseCallback}>
                                    <IoMdClose />
                                </CloseButton>
                            </CloseButtonContainer>
                            <IFrameContainer>
                                <IFrameStyle
                                    width="853"
                                    height="480"
                                    src={`https://www.youtube.com/embed/${videoUrl}`}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    title="Embedded youtube"
                                ></IFrameStyle>
                            </IFrameContainer>
                        </Modal.Body>
                    </Modal>
                </VideoDialogStyle>
            )}{' '}
        </>
    );
};

export default VideoDialog;
