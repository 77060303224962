import styled from 'styled-components';
import Colors from '../../../../enums/Colors';
import { MobileBiggerWidth, MobileMaxWidth, ResizeMediumWidth } from '../../../../constants/Dimensions';
import FullCss, { MediaQuery } from 'core/util/FullCss';
import { mediaBetweenScreenMinMaxWidth, mediaOnlyScreenMaxWidthStr } from 'core/util/cssHelp';
import { NavHashLink } from 'react-router-hash-link';
import { DefaultButton } from 'components/UI/Buttons';

export const BannerContainerStyle = styled.div``;

interface IBannerStyle {
    svgSource: string;
}
export const BannerStyle = styled.div<IBannerStyle>`
    background: url('${props => props.svgSource}');
    background-position: center right;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 600px;
`;

export const Layer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(96.92deg, #031522 41.59%, #0a3c61 46.76%, #23608d 51.21%, #71b2e3 60.98%, #d1ebff 69.03%);
    mix-blend-mode: multiply;
    opacity: 0.4;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const ContentContainer = styled.div`
    position: relative;
    height: 100%;
    padding-top: 100px;
    padding-left: 8vw;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        padding-top: 49px;
    }
`;

export const ButtonsContainer = styled.div`
    margin-top: 7vh;
    display: flex;
    align-items: center;
`;

export const QueroConhecerButton = styled.button`
    border: 1px solid ${Colors.White};
    box-sizing: border-box;
    border-radius: 50px;
    padding: 12px 60px;
    background-color: rgba(76, 175, 80, 0);
    &:hover {
        background-color: ${Colors.SoftBlue};
    }
    outline: none;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        padding: 12px 30px;
    }
`;

export const PlayIcon = styled.img`
    margin-right: 7px;
`;

//Textos

interface IDefaultText {
    fontWeight: string;
    fontSize: string;
}
export const DefaultText = styled.p<IDefaultText>`
    font-size: ${props => props.fontSize};
    font-weight: ${props => props.fontWeight};
    color: white;
`;

export const TitleStyle = styled(DefaultText).attrs({
    fontSize: '32px',
    fontWeight: '600',
})`
    text-transform: uppercase;
    line-height: 42px;
    white-space: pre-wrap;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        font-size: 18px;
        line-height: 28px;
        font-weight: bold;
    }
`;

export const ContentStyle = styled(DefaultText).attrs({
    fontSize: 'clamp(14px, 2vw, 16px)',
    fontWeight: '300',
})`
    font-family: Inter;
    margin-top: 36px;
    line-height: 22px;
    letter-spacing: 0.3px;
    max-width: 50vw;

    @media only screen and (max-width: 1040px) {
        max-width: 70vw;
    }

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        line-height: 18px;
        max-width: 100vw;
        padding-right: 15vw;
    }
`;

export const QueroConhecerText = styled(DefaultText).attrs({
    fontWeight: 'bold',
    fontSize: '14px',
})``;

export const AssistirVideoButton = styled.button`
    height: 40px;
    display: flex;
    align-items: center;
    margin-left: 0;
    background-color: rgba(76, 175, 80, 0);
    border: none;
    outline: none;
    margin-right: 30px;
`;

export const SvgIcon = styled.svg.attrs({
    version: '1.1',
    xmlns: 'http://www.w3.org/2000/svg',
    xmlnsXlink: 'http://www.w3.org/1999/xlink',
})`
    height: 40px;
    width: 40px;
    fill: transparent;

    &:hover {
        fill: ${Colors.SoftBlue};
    }
`;

export const SvgContainer = styled.div(() => {
    const mediaQuery = [
        new MediaQuery(mediaOnlyScreenMaxWidthStr(MobileMaxWidth), {
            paddingTop: '50px !important',
        }),
        new MediaQuery(mediaOnlyScreenMaxWidthStr(MobileBiggerWidth), {
            paddingTop: '0px',
        }),
    ];

    return new FullCss(
        {
            position: 'relative',
            marginBottom: '10px',
        },
        mediaQuery
    ).getCss();
});

export const ImgSvgLogo = styled.img(() => {
    const mediaQuery = [
        new MediaQuery(mediaOnlyScreenMaxWidthStr(MobileMaxWidth), {
            height: '40px !important',
        }),
        new MediaQuery(mediaOnlyScreenMaxWidthStr(MobileBiggerWidth), {
            height: '70px',
        }),
    ];

    return new FullCss(
        {
            position: 'relative',
            height: '90px',
        },
        mediaQuery
    ).getCss();
});

export const SaibaMaisButton = styled.button(() => {
    return new FullCss(
        {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '150px',
            height: '40px',
            border: '2px solid #FCFCFC',
            borderRadius: '50px',
            background: 'transparent',
            color: Colors.White,
            fontWeight: '700',
            fontSize: '14px',
            letterSpacing: '0.3px',
        },
        new MediaQuery('&:hover', {
            background: Colors.White,
            color: Colors.Blue,
        })
    ).getCss();
});

export const AcessarPlataformaButton = styled.button(() => {
    return new FullCss(
        {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '218px',
            height: '49px',
            border: 'none',
            background: 'transparent',
            color: Colors.White,
            fontWeight: '700',
            fontSize: '14px',
            letterSpacing: '0.3px',
            textDecorationLine: 'underline',
        },
        new MediaQuery('&:hover', {
            color: Colors.Blue,
        })
    ).getCss();
});

export const CirculoBanner = styled.button`
    position: relative;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 64px;
    height: 64px;
    background: #fcfcfc;
    border-radius: 50%;
    box-shadow: 3px 3px 0px rgba(255, 255, 255, 0.25);
    border: 2px solid rgb(255 255 255 / 25%);

    &:hover {
        cursor: pointer;
    }

    &:active {
        border: 2px solid ${Colors.Grey3};
    }
`;

export const NavHashLinkStyle = styled(NavHashLink)`
    text-decoration: none !important;
    &:hover {
        text-decoration: none !important;
    }
`;

export const ArrowSvg = styled.img`
    position: absolute;
    height: 23.33px;
    top: 19px;
    left: 19px;
`;

export const TextContainer = styled.div(() => {
    const mq = [
        new MediaQuery(mediaBetweenScreenMinMaxWidth(0, 320), {
            width: '100%',
        }),
        new MediaQuery(mediaBetweenScreenMinMaxWidth(320, MobileMaxWidth), {
            width: '85%',
        }),
        new MediaQuery(mediaBetweenScreenMinMaxWidth(MobileMaxWidth, MobileBiggerWidth), {
            width: '75%',
        }),
        new MediaQuery(mediaBetweenScreenMinMaxWidth(MobileBiggerWidth, 1820), {
            width: '45%',
        }),
    ];
    return new FullCss(
        {
            width: '33%',
        },
        mq
    ).getCss();
});

export const BannerSection = styled.section`
    position: relative;
    height: 600px;

    display: flex;
    flex-direction: column;
    padding: 80px 5% 0 5%;

    @media only screen and (max-width: 1200px) {
        padding: 50px 0 50px 0;
        height: 100%;
    }

    @media only screen and (max-width: 760px) {
        padding: 50px 5% 50px 5%;
    }
`;

export const SplitContainer = styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;

    @media only screen and (max-width: 1200px) {
        justify-content: center;
        padding: 0 30px 0 30px;
    }
`;

export const LeftContainer = styled.div`
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 1200px) {
        align-items: center;
    }
`;

export const ButtonsBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 35px;
    justify-content: flex-start;
    gap: 32px;

    @media only screen and (max-width: 1200px) {
        flex-direction: column;
        align-items: center;
        gap: 15px;
    }
`;

export const YellowButton = styled(DefaultButton)`
    background: #ffca00;
    border-radius: 50px;
    color: #003c78;

    &:hover {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
`;

export const TransparentButton = styled(DefaultButton)`
    background: transparent;
    border: 2px solid #003c78;
    border-radius: 50px;
    color: #003c78;

    &:hover {
        background: #003c78;
        color: white;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
`;

export const LogoContainer = styled.div`
    display: flex;
    position: relative;
    margin-bottom: 10px;

    @media only screen and (max-width: 1200px) {
        justify-content: center;
    }
`;

export const LogoImg = styled.img`
    position: relative;
    height: 90px;

    @media only screen and (max-width: ${ResizeMediumWidth}px) {
        height: 115px;
    }

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        width: 100%;
        height: 100%;
    }
`;

export const ImgLateral = styled.img`
    width: 100%;
    max-width: 480px;
    height: 100%;
    object-fit: cover;
    position: relative;
    bottom: 50px;

    @media only screen and (max-width: 1200px) {
        display: none;
    }
`;

export const ImgMobile = styled.img`
    width: 100%;
    max-width: 480px;
    height: 100%;
    object-fit: cover;
    position: relative;
    bottom: 15px;

    @media only screen and (min-width: 1200px) {
        display: none;
    }
`;

export const BlueCircle = styled.button`
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 64px;
    height: 64px;
    background: #00a8e9;
    border-radius: 50%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: 2px solid transparent;

    &:hover {
        cursor: pointer;
    }

    &:active {
        border: 2px solid ${Colors.White};
    }
`;
