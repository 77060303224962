import BiblioConteudoService from 'core/http/service/biblioteca/BiblioConteudo.service';

const serviceConteudoBiblioteca = new BiblioConteudoService();

export const fetchPalavrasProibidas = async () => {
    const response = await serviceConteudoBiblioteca.listaPalavrasProibidas();

    if (response.status === 200) {
        return response.data;
    }

    return null;
};

export const validatePalavraProibida = (ppl: string[], content: string) => {
    if (content.trim().length > 2 && ppl) {
        const regex = new RegExp('\\b' + ppl.join('\\b|\\b') + '\\b', 'i');
        const isProibid = regex.test(content);
        return isProibid;
    }

    return false

};
