import styled from "styled-components";

export const VideoDialogStyle = styled.div``;

export const IFrameStyle = styled.iframe``;

export const CloseButtonContainer = styled.div`
  padding: 0 0 10px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const CloseButton = styled.button`
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
`;

export const IFrameContainer = styled.div`
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;

  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
`;
