import React, { useEffect, useState } from 'react';

import Slider from 'react-slick';
import { useDispatch } from 'react-redux';

import {
    StatementContainerStyle,
    CarouselContainer,
    ArrowButton,
    StatementCarouselCardContainer,
    Wrapper,
    CarouselDots,
    CarouselCustomPaging,
    SvgIcon,
    TitleText,
} from './StatementsContainerStyle.styled';
import Colors from '../../../../enums/Colors';
import useFetch from '../../../../hooks/useFetch';
import { IsMobileMaxWidth } from '../../../../core/util/WindowSize';
import * as homePageActions from '../../../../store/actions/HomePage';
import StatementCarouselCard from './statementCarouselCard/StatementCarouselCard';

import AdminHomeConteudo from 'models/admin/AdminHomeConteudo';
import { cloneDeep } from 'lodash';
import AdminHomeConteudoService from 'core/http/service/admin/AdminHomeConteudo.service';
import { bucketS3, caminhoAnexosHomepage } from 'constants/EnvironmentData';

const IsMobile = () => {
    return IsMobileMaxWidth();
};

const service = new AdminHomeConteudoService();

const StatementsContainer = () => {
    const [isLoading, setIsLoading] = useState(true);

    const dispatch = useDispatch();

    const [carouselCardIndex, setCarouselCardIndex] = useState(0);
    const [statements, setStatements] = useState<AdminHomeConteudo[]>([]);

    const getS3BaseUrl = (): string => {
        return bucketS3() + caminhoAnexosHomepage();
    };

    const startActions = async () => {
        await dispatch(homePageActions.fetchStatements());
    };

    useFetch(startActions, [], setIsLoading);

    const fetchItens = async () => {
        const { data } = await service.buscaConteudo('DEPOIMENTOS');

        const result = data.map((s: any) => new AdminHomeConteudo().fromJSON(s));
        setStatements(result);
        fetchImg(result);
    };

    const fetchImg = async (_solutions: AdminHomeConteudo[]) => {
        const clone = cloneDeep(_solutions);

        for (let i = 0; i < clone.length; i++) {
            try {
                const { data } = await service.buscarAnexoConteudo(clone[i].id, false);
                clone[i].precover = getS3BaseUrl() + data.filePath;
            } catch (e: any) {
                console.error(e);
            }
        }

        setStatements(clone);
        setIsLoading(false);
    };

    useEffect(() => {
        fetchItens();
    }, []);

    const NextArrow = ({ ...props }: any) => {
        return (
            <ArrowButton next={true} onClick={props.onClick}>
                <SvgIcon>
                    <circle r="27.5" transform="matrix(-1 0 0 1 28 28)" stroke={Colors.SoftBlue} />
                    <path
                        d="M39.3719 27.6903L39.3712 27.6896C39.3701 27.6886 39.3691 27.6875 39.3681 27.6865L34.4729 22.8149M39.3719 27.6903L34.4729 22.8149M39.3719 27.6903C39.5419 27.86 39.5429 28.137 39.3737 28.3079C39.3715 28.3101 39.3694 28.3122 39.3674 28.3142C39.3671 28.3146 39.3667 28.3149 39.3663 28.3153L34.4729 33.1851C34.3016 33.3555 34.0246 33.3549 33.8542 33.1836L33.8541 33.1836C33.6837 33.0123 33.6843 32.7353 33.8556 32.5649C33.8556 32.5649 33.8556 32.5649 33.8556 32.5649L37.1444 29.2919L38.003 28.4375H36.7917H16.9375C16.6959 28.4375 16.5 28.2416 16.5 28C16.5 27.7584 16.6959 27.5625 16.9375 27.5625H36.7918H38.003L37.1445 26.7081L33.8556 23.4351C33.8556 23.4351 33.8555 23.4351 33.8555 23.4351M39.3719 27.6903L33.8555 23.4351M34.4729 22.8149C34.3016 22.6445 34.0246 22.6451 33.8541 22.8164C33.6837 22.9877 33.6843 23.2646 33.8555 23.4351M34.4729 22.8149L33.8555 23.4351"
                        fill={Colors.Grey4}
                        stroke={Colors.SoftBlue}
                    />
                </SvgIcon>
            </ArrowButton>
        );
    };

    const PreviousArrow = ({ ...props }: any) => {
        return (
            <ArrowButton next={false} onClick={props.onClick}>
                <SvgIcon>
                    <circle r="27.5" transform="matrix(1 1.74846e-07 1.74846e-07 -1 28 28)" stroke={Colors.SoftBlue} />
                    <path
                        d="M16.6281 28.3097L16.6288 28.3104C16.6299 28.3114 16.6309 28.3125 16.6319 28.3135L21.5271 33.1851M16.6281 28.3097L21.5271 33.1851M16.6281 28.3097C16.4581 28.14 16.4571 27.863 16.6263 27.6921C16.6285 27.6899 16.6306 27.6878 16.6326 27.6858C16.6329 27.6854 16.6333 27.6851 16.6337 27.6847L21.5271 22.8149C21.6984 22.6445 21.9754 22.6451 22.1458 22.8164L22.1459 22.8164C22.3163 22.9877 22.3157 23.2647 22.1444 23.4351C22.1444 23.4351 22.1444 23.4351 22.1444 23.4351L18.8556 26.7081L17.997 27.5625L19.2083 27.5625L39.0625 27.5625C39.3041 27.5625 39.5 27.7584 39.5 28C39.5 28.2416 39.3041 28.4375 39.0625 28.4375L19.2082 28.4375L17.997 28.4375L18.8555 29.2919L22.1444 32.5649C22.1444 32.5649 22.1445 32.5649 22.1445 32.5649M16.6281 28.3097L22.1445 32.5649M21.5271 33.1851C21.6984 33.3555 21.9754 33.3549 22.1459 33.1836C22.3163 33.0123 22.3157 32.7354 22.1445 32.5649M21.5271 33.1851L22.1445 32.5649"
                        fill={Colors.Grey4}
                        stroke={Colors.SoftBlue}
                    />
                </SvgIcon>
            </ArrowButton>
        );
    };

    const TransformRule = (): string => {
        const retorno = IsMobile() ? 'translate(30px,29px);' : 'translate(200px,50px);';
        return retorno;
    };

    const settings = {
        dots: true,
        infinite: true,
        className: 'center',
        centerMode: true,
        focusOnSelect: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerPadding: '50px',
        arrows: true,
        nextArrow: <NextArrow />,
        prevArrow: <PreviousArrow />,
        beforeChange: (current: number, next: number) => {
            setCarouselCardIndex(next);
        },
        // eslint-disable-next-line react/display-name
        appendDots: (dots: any) => (
            <CarouselDots>
                <ul style={{ margin: '0px' }}> {dots} </ul>
            </CarouselDots>
        ),
        // eslint-disable-next-line react/display-name
        customPaging: (i: number) => <CarouselCustomPaging />,
    };

    const mobileSettings = {
        dots: true,
        infinite: false,
        focusOnSelect: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        // eslint-disable-next-line react/display-name
        appendDots: (dots: any) => (
            <CarouselDots>
                <ul style={{ margin: '0px' }}> {dots} </ul>
            </CarouselDots>
        ),
        // eslint-disable-next-line react/display-name
        customPaging: (i: number) => <CarouselCustomPaging />,
    };

    const defaultSettings = IsMobile() ? mobileSettings : settings;

    return (
        <StatementContainerStyle id="statementsContainer">
            <TitleText>{`Depoimentos de Educadores`}</TitleText>
            {!isLoading && (
                <Wrapper>
                    <CarouselContainer>
                        <Slider {...defaultSettings}>
                            {statements.map((item, index) => {
                                return (
                                    <StatementCarouselCardContainer
                                        key={`statements_container_card_${index}`}
                                        className={index === carouselCardIndex ? 'slide activeSlide' : 'slide'}
                                    >
                                        <StatementCarouselCard statement={item} isActive={index === carouselCardIndex} />
                                    </StatementCarouselCardContainer>
                                );
                            })}
                        </Slider>
                    </CarouselContainer>
                </Wrapper>
            )}
        </StatementContainerStyle>
    );
};

export default StatementsContainer;
