import { FilterRecursosCostumizados } from 'enums/FilterRecursosCostumizados';
import { ComentarioSimplificado } from 'models/recursos-costumizados/ComentariosRecursosCostumizados';
import { EtapaItemRecursosCostumizado, ConteudoOrderEtapa } from 'models/recursos-costumizados/EtapaItemRecursosCostumizado';
import { ItemRecursosCostumizado, ConteudoOrderItem } from 'models/recursos-costumizados/ItemRecursosCostumizado';
import { LogAcaoRecursoCustomizado } from 'models/recursos-costumizados/LogAcaoRecursoCustomizado';
import { ResumoRecursoCustomizado } from 'models/recursos-costumizados/ResumoRecursoCustomizado';

import {
    ConteudoRecursoCustomizado,
    ConteudoOrder,
    AvaliacaoRecursosCustomizado,
} from '../../../../models/recursos-costumizados/ConteudoRecursoCustomizado';
import { GenericService } from '../Generic.service';
import { IFilterAvaliacao, IFilterMedia } from 'pages/gestao_paginas/components/Body/Avaliations';

export type TypeVerifyInterface = 'item' | 'etapa' | 'conteudo';

export default class AdminRecursosCostumizados extends GenericService {
    readonly URI: string = '/v1/recursosCustomizados';

    constructor() {
        super();
        this.setUri(this.URI);
    }

    public novoItem(data: ItemRecursosCostumizado) {
        return this.post('item/insert', data.toJSON());
    }

    public novoConteudo(data: ConteudoRecursoCustomizado) {
        return this.post('conteudo/insert', data.toJSON());
    }

    public atualizarConteudo(data: ConteudoRecursoCustomizado, id: number) {
        return this.put(`conteudo/${id}`, data.toJSON());
    }

    public buscarConteudo(idConteudo: number, publicRead: boolean = false) {
        return this.get('conteudo/getById', { id: idConteudo, publicRead });
    }

    public adicionarResposta = (comentarioId: number, conteudo: string) => {
        return this.post('conteudo/comentario/resposta', { codigoComentario: comentarioId, conteudo });
    };

    public excluirResposta = (comentarioId: number) => {
        return this.deleteByParams('conteudo/comentario/resposta/' + comentarioId, {});
    };

    public habilitarComentario(id: number, data: any) {
      return this.put(`conteudo/comentario/${id}`, data);
  }

    public verificarUriExistenteConteudo(link: string, id: number | undefined) {
        return this.get('conteudo/checarUrl', { link: link, id: id });
    }

    public getAllComments( pagina: number, filtro?: string, respondido?: string | undefined) {
        const por_pagina = 20;
        return this.get('conteudo/comentarios', { filtro, respondido, por_pagina, pagina });
    }

    public novaEtapa(data: EtapaItemRecursosCostumizado) {
        return this.post('etapa/insert', data.toJSON());
    }

    public buscarListaResumida(PageId: number) {
        return this.get('item/listResumido?idPagina=' + PageId);
    }

    public buscarListaEtapaResumida(itemId: number) {
        return this.get('etapa/listResumido?idItem=' + itemId);
    }

    public buscarListaConteudoResumido(etapaId: number) {
        return this.get('conteudo/listResumido?idEtapa=' + etapaId);
    }

    public listarComentariosPorConteudo(conteudoId: number) {
        return this.get('conteudo/comentario/listByConteudo/' + conteudoId);
    }

    public itemGetById(itemId: number) {
        return this.get('item/getById?id=' + itemId);
    }

    public getSenquencial(itemId: number) {
        return this.get('item/getSequencial?id=' + itemId);
    }

    public etapaGetById(itemId: number) {
        return this.get('etapa/getById?id=' + itemId);
    }

    public atualizarItem(itemId: number, body: ItemRecursosCostumizado) {
        return this.put('item/' + itemId, body);
    }

    public atualizarEtapa(itemId: number, body: EtapaItemRecursosCostumizado) {
        return this.put('etapa/' + itemId, body);
    }

    public verificarUriExistent(link: string, id: number, idRelacionado: number | null, tipo: TypeVerifyInterface) {
        const idVerify = id == -1 ? '' : id;
        return this.get(tipo + `/checarUrl?id=${idVerify}&idRelacionado=${idRelacionado}`, { link });
    }

    public listConteudosFavoritos() {
        return this.get('conteudoFavoritado/listFavoritados');
    }

    public desfavoritarConteudo(itemId: number) {
        return this.deleteByParams('conteudoFavoritado/desfavoritar/' + itemId);
    }

    public favoritarConteudo(itemId: number) {
        return this.post('conteudoFavoritado/favoritar/' + itemId, {});
    }

    public buscaFavoritado(idConteudo: number) {
        return this.get('conteudoFavoritado/favoritado', { idConteudo });
    }

    public avaliarConteudo(data: AvaliacaoRecursosCustomizado) {
        return this.post('conteudo/avaliacao', data.toJSON());
    }

    public enviarComentario(data: ComentarioSimplificado) {
        return this.post('conteudo/comentario', data.toJSON());
    }

    public atualizarComentario(comentarioId?: number, comentario?: string) {
        return this.put('conteudo/comentario/' + comentarioId, { comentario: comentario });
    }

    public deletarComentario(comentarioId?: number) {
        return this.deleteByParams('conteudo/comentario/' + comentarioId);
    }

    public uptadeOrdemConteudo(data: ConteudoOrder) {
        return this.post('conteudo/updateOrder', data);
    }

    public uptadeOrdemEtapa(data: ConteudoOrderEtapa) {
        return this.post('etapa/updateOrder', data);
    }

    public uptadeOrdemItem(data: ConteudoOrderItem) {
        return this.post('item/updateOrder', data);
    }

    // OCULTAR

    public exibirConteudo(idConteudo?: number) {
        return this.patch('conteudo/desocultar/' + idConteudo);
    }

    public ocultarConteudo(idConteudo?: number) {
        return this.patch('conteudo/ocultar/' + idConteudo);
    }

    public exibirEtapa(idEtapa?: number) {
        return this.patch('etapa/desocultar/' + idEtapa);
    }

    public ocultarEtapa(idEtapa?: number) {
        return this.patch('etapa/ocultar/' + idEtapa);
    }

    public exibirItem(idItem?: number) {
        return this.patch('item/desocultar/' + idItem);
    }

    public ocultarItem(idItem?: number) {
        return this.patch('item/ocultar/' + idItem);
    }

    // DELETE

    public deleteConteudo(idConteudo?: number) {
        return this.deleteByParams('conteudo/deletar/' + idConteudo);
    }

    public deleteEtapa(idEtapa?: number) {
        return this.deleteByParams('etapa/deletar/' + idEtapa);
    }

    public deleteItem(idItem?: number) {
        return this.deleteByParams('item/deletar/' + idItem);
    }

    // PUBLIC

    public listByPaginaComStatus(itemId: number, status: string | number) {
        return this.get(`item/public/listByPaginaComStatus?id=${itemId}`, { status: status });
    }
    public listByPaginaOnlySimple(itemId: number) {
        return this.get(`item/public/listByPaginaOnlySimple?id=${itemId}`);
    }
    public listByEtapasAndConteudosResumidos(itemId: number) {
        return this.get(`item/public/listByItem?id=${itemId}`);
    }

    public marcarConcluido(itemId: number) {
        return this.post(`registroConclusao/registrar`, { codigoConteudo: itemId });
    }

    public jaConcluido(itemId: number) {
        return this.get(`registroConclusao/conteudoJaConcluido/` + itemId);
    }

    public fetchPDF(path: string) {
        return this.get(`conteudo/public/download/pdf`, { path: path });
    }

    //LOG

    public registrarAcao(log: LogAcaoRecursoCustomizado) {
        return this.post('conteudo/public/log', log.toJSON());
    }

    //Avaliations

    public fetchGestaoDePaginaAvaliations(path?: IFilterAvaliacao) {
        return this.get(`conteudo/avaliacoes`, path);
    }

    public fetchGestaoDePaginaAverage(path?: IFilterMedia) {
        return this.get(`conteudo/avaliacoesMedia`, path);
    }
}
