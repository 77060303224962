import React, { useEffect, useState } from 'react';
import Styled from './FormSolucoes.styled';
import Imagem from '../../assets/fotoFormSolucoes.svg';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import EstadoDTO from 'models/perfil/EstadoDTO';
import { ProfileService } from 'core/http/service/Profile.service';
import LocalidadeDTO from 'models/perfil/LocalidadeDTO';

import { TipoSolucoesService } from 'core/http/service/TipoSolucoes.service';
import TipoSolucoes from 'models/solucoes/TipoSolucoes';
import useFetch from 'hooks/useFetch';
import { PrimaryButton } from 'components/UI/Buttons';
import { SolucoesPerfilUsuarioService } from 'core/http/service/SolucoesPerfilUsuario.service';
import { notification } from 'antd';
import PerfilUsuario from 'models/perfil/PerfilUsuario';
import TipoSolucoesEnum from 'enums/TipoSolucoesEnum';
import { SmileOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Alert } from 'antd';
import { CloseSquareFilled } from '@ant-design/icons';

export type TypeForm = {
    nome: string;
    email: string;
    estado: string;
    municipio: string;
    interesse: number;
};

interface IFormSolucoes {
    tipoSolucao?: TipoSolucoesEnum;
    preDefinition: boolean;
    isBlue?: boolean;
}

const FormSolucoes = ({ tipoSolucao, preDefinition, isBlue }: IFormSolucoes) => {
    const profileData: PerfilUsuario | null = useAppSelector(state => state.profileReducer.userProfile);

    const profileService = new ProfileService();
    const tipoSolucoesService = new TipoSolucoesService();
    const solucoesPerfilUsuarioService = new SolucoesPerfilUsuarioService();

    const [cidades, setCidades] = useState<LocalidadeDTO[]>([]);
    const [estados, setEstados] = useState<EstadoDTO[]>([]);
    const [tipoSolucoes, setTipoSolucoes] = useState<TipoSolucoes[]>([]);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [userLogged, setUserLogged] = useState<boolean>(false);

    const [api, contextHolder] = notification.useNotification();

    const [visible, setVisibleAlert] = useState(false);

    const handleClose = () => {
        setVisibleAlert(false);
    };

    //Form
    const [values, setValues] = useState<TypeForm>({
        nome: '',
        email: '',
        estado: '',
        municipio: '',
        interesse: tipoSolucao ?? -1,
    });

    interface IOpenNotification {
        title: string;
        description: string;
        enableIcon: boolean;
        icon?: any;
    }

    const openNotification = ({ title, description, enableIcon, icon }: IOpenNotification) => {
        api.open({
            message: title,
            description: description,
            icon: enableIcon ? icon ? icon : <SmileOutlined style={{ color: '#108ee9' }} /> : '',
            duration: 5,
        });
    };

    const loadCidadesPorUf = async (uf: string) => {
        try {
            setCidades([]);

            const response: any = await profileService.consultarCidadesPorUF(uf);
            const _cidades: LocalidadeDTO[] = response.data.map((item: any) => new LocalidadeDTO().fromJSON(item));

            setCidades(_cidades);
        } catch (error) {
            console.error(error);
        } finally {
        }
    };

    const isValidForm = (): boolean => {
        if (values.nome === '' || values.nome.length < 3) return true;
        if (values.email === '' || values.email.length < 3) return true;
        if (values.estado === '') return true;
        if (values.municipio === '') return true;
        if (values.interesse === -1) return true;

        return false;
    };

    const onSubmitHandler = async () => {
        if (isValidForm()) {
            setVisibleAlert(true);
            return;
        }

        const response = await solucoesPerfilUsuarioService.inserir(values);

        if (response.status === 201) {
            openNotification({
                title: 'Ficamos Felizes!',
                description: 'Seu interesse foi cadastrado com sucesso!',
                enableIcon: true,
            });
        } else if (response.status === 409) {
            openNotification({
                title: 'Atenção!',
                description: 'Você já realizou o interesse desejado.',
                enableIcon: true,
                icon: <ExclamationCircleOutlined />,
            });
        }

        if (profileData && profileData?.keycloakId !== '') {
            setValues({
                ...values,
                nome: '',
            });
        }
    };

    useFetch(async () => {
        const response = await tipoSolucoesService.getAll();

        const dados: TipoSolucoes[] = response.data;

        // Ordena os dados pelo nome (alfabeticamente)
        dados.sort((a, b) => {
            if (a.nome < b.nome) return -1; // a vem antes de b
            if (a.nome > b.nome) return 1; // a vem depois de b
            return 0; // a e b são iguais
        });

        setTipoSolucoes(dados);
    }, []);

    useFetch(async () => {
        const response = await profileService.findAllEstados();

        setEstados(response.data);
    }, []);

    useFetch(async () => {
        if (profileData && profileData?.keycloakId !== '') {
            setUserLogged(true);

            const responseCidades = await profileService.consultarCidadesPorUF(profileData.estado);

            const data: LocalidadeDTO[] = responseCidades.data;

            setCidades(data);

            const cidade = data.find((c: LocalidadeDTO) => c.nome.toLowerCase().includes(profileData.cidade.toLowerCase()));

            setValues({
                ...values,
                email: profileData.email,
                estado: profileData.estado,
                municipio: cidade?.nome ?? '',
            });
        }
    }, [profileData]);

    useEffect(() => {
        if (tipoSolucao) {
            setValues({ ...values, interesse: tipoSolucao });
        }
    }, [tipoSolucao]);

    return (
        <Styled.Container isBlue={isBlue}>
            {contextHolder}
            <Styled.Content>
                <Styled.Form>
                    <Styled.Title>{`Quer levar essa solução até a sua região?`.toLocaleUpperCase()}</Styled.Title>
                    <Styled.Description>
                        {`Se você faz parte da equipe gestora da Secretaria de Educação e tem interesse em levar as soluções do Instituto Ayrton Senna para o seu território/ região, deixe seus dados e entraremos em contato.`}
                    </Styled.Description>
                    {visible && (
                        <Alert
                            style={{
                                marginTop: 20,
                                position: 'absolute',
                                top: 0,
                            }}
                            message="Atenção!"
                            description="Por favor, preencha todos os campos para conseguir efetuar o interesse sobre a solução desejada!"
                            type="error"
                            closable
                            afterClose={handleClose}
                        />
                    )}
                    <Styled.LabelForm>Nome</Styled.LabelForm>
                    <Styled.InputForm
                        value={values.nome}
                        onChange={(e: any) => setValues({ ...values, nome: e.target.value })}
                        type="text"
                        id="nomeForm"
                        placeholder="Nome completo"
                    />
                    <Styled.LabelForm>E-mail</Styled.LabelForm>
                    <Styled.InputForm
                        value={values.email}
                        onChange={(e: any) => setValues({ ...values, email: e.target.value })}
                        type="text"
                        id="nomeForm"
                        placeholder="Seu email"
                        disabled={userLogged}
                    />
                    <Styled.LabelForm>Estado</Styled.LabelForm>
                    <Styled.InputFormSelect
                        value={values.estado}
                        as={'select'}
                        onChange={e => {
                            setValues({ ...values, estado: e.target.value });
                            loadCidadesPorUf(e.target.value);
                        }}
                        disabled={userLogged}
                    >
                        <option>Selecione</option>
                        {estados.map((dados, index) => (
                            <option key={index} value={dados.id}>
                                {dados.nome}
                            </option>
                        ))}
                    </Styled.InputFormSelect>
                    <Styled.LabelForm>Município</Styled.LabelForm>
                    <Styled.InputFormSelect
                        value={values.municipio}
                        onChange={(e: any) => setValues({ ...values, municipio: e.target.value })}
                        as={'select'}
                        disabled={cidades.length === 0 || userLogged}
                    >
                        <option>Selecione</option>
                        {cidades.map((dados, index) => (
                            <option key={index} value={dados.nome}>
                                {dados.nome}
                            </option>
                        ))}
                    </Styled.InputFormSelect>
                    <Styled.LabelForm>Por qual solução você se interessou?</Styled.LabelForm>
                    <Styled.InputFormSelect
                        value={tipoSolucao ?? values.interesse}
                        onChange={(e: any) => setValues({ ...values, interesse: e.target.value })}
                        as={'select'}
                        disabled={preDefinition}
                    >
                        <option value={-1}>Selecione</option>
                        {tipoSolucoes.map((dados, index) => (
                            <option key={index} value={dados.id ?? -1}>
                                {dados.nome}
                            </option>
                        ))}
                    </Styled.InputFormSelect>

                    <PrimaryButton
                        style={{ padding: '10px 10px 10px 10px', borderRadius: 5, marginTop: 12 }}
                        type="button"
                        disabled={submitting}
                        onClick={onSubmitHandler}
                    >
                        {submitting ? 'Enviando...' : 'Enviar'}
                    </PrimaryButton>
                </Styled.Form>
            </Styled.Content>
            <Styled.Img src={Imagem} />
        </Styled.Container>
    );
};

export default FormSolucoes;
