import Row from 'components/Row';
import { OutlineButton, PrimaryButton } from 'components/UI/Buttons';
import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import Styled from '../AdminGestaoPaginasPage.styled';
import img1 from '../images/banner-model.svg';
import img2 from '../images/banner-simples-e.svg';
import img3 from '../images/banner-simples-d.svg';
import img4 from '../images/banner-cartoes-categoria.svg';
import img5 from '../images/banner-simples-sem-img.svg';
import img6 from '../images/banner-carroussel.svg';
import { CSSProperties } from 'styled-components';
import Colors from 'enums/Colors';

export enum AdminModeloSecaoModelEnum {
    UNKNOW = -1,
    BANNER = 1,
    SIMPLES_IMAGEM_E,
    SIMPLES_IMAGEM_D,
    CARTOES,
    SIMPLES_TEXTO,
    CAROUSEL,
}

export interface AdminModeloDadosComplementares {
    secaoId: number;
    ordem: number;
    oculto: boolean;
    paginaId: number;
}

export interface IAdminGestaoPaginasModeloSecaoModel {
    show: boolean;
    onHide: () => void;
    onSelect: (value: AdminModeloSecaoModelEnum) => void;
}

const AdminGestaoPaginasModeloSecaoModal = ({ show, onHide, onSelect }: IAdminGestaoPaginasModeloSecaoModel) => {
    const [selectOption, setselectOption] = useState(AdminModeloSecaoModelEnum.UNKNOW);

    const confirmSelect = () => {
        onSelect(selectOption);
    };

    function selectedStyle(modal: number): CSSProperties {
        let style: CSSProperties = {};

        if (modal === selectOption.valueOf()) {
            style = {
                backgroundColor: Colors.LightGrey,
            };
        }

        return style;
    }

    return (
        <Modal show={show} onHide={onHide} size="lg">
            <Styled.Modal.CloseContainer>
                <p onClick={onHide}>
                    <AiOutlineClose />
                </p>
            </Styled.Modal.CloseContainer>

            <Styled.Title style={{ fontSize: '24px', marginBottom: '20px', marginLeft: '20px' }}>Selecione o modelo da seção</Styled.Title>

            <Row justify="space-around" style={{ marginBottom: '20px' }}>
                <Styled.Modal.FrameOption
                    style={selectedStyle(AdminModeloSecaoModelEnum.BANNER)}
                    onClick={() => setselectOption(AdminModeloSecaoModelEnum.BANNER)}
                >
                    <Styled.Modal.SubTitle>Banner humane</Styled.Modal.SubTitle>
                    <Styled.Modal.Image src={img1} />
                    <Form.Check
                        type="radio"
                        onChange={() => setselectOption(AdminModeloSecaoModelEnum.BANNER)}
                        checked={selectOption == AdminModeloSecaoModelEnum.BANNER}
                    />
                </Styled.Modal.FrameOption>

                <Styled.Modal.FrameOption
                    style={selectedStyle(AdminModeloSecaoModelEnum.SIMPLES_IMAGEM_E)}
                    onClick={() => setselectOption(AdminModeloSecaoModelEnum.SIMPLES_IMAGEM_E)}
                >
                    <Styled.Modal.SubTitle>Simples com imagem (E)</Styled.Modal.SubTitle>
                    <Styled.Modal.Image src={img2} />
                    <Form.Check
                        type="radio"
                        onChange={() => setselectOption(AdminModeloSecaoModelEnum.SIMPLES_IMAGEM_E)}
                        checked={selectOption == AdminModeloSecaoModelEnum.SIMPLES_IMAGEM_E}
                    />
                </Styled.Modal.FrameOption>

                <Styled.Modal.FrameOption
                    style={selectedStyle(AdminModeloSecaoModelEnum.SIMPLES_IMAGEM_D)}
                    onClick={() => setselectOption(AdminModeloSecaoModelEnum.SIMPLES_IMAGEM_D)}
                >
                    <Styled.Modal.SubTitle>Simples com imagem (D)</Styled.Modal.SubTitle>
                    <Styled.Modal.Image src={img3} />
                    <Form.Check
                        type="radio"
                        onChange={() => setselectOption(AdminModeloSecaoModelEnum.SIMPLES_IMAGEM_D)}
                        checked={selectOption == AdminModeloSecaoModelEnum.SIMPLES_IMAGEM_D}
                    />
                </Styled.Modal.FrameOption>
            </Row>

            <Row justify="space-around" style={{ marginBottom: '20px' }}>
                <Styled.Modal.FrameOption
                    style={selectedStyle(AdminModeloSecaoModelEnum.CARTOES)}
                    onClick={() => setselectOption(AdminModeloSecaoModelEnum.CARTOES)}
                >
                    <Styled.Modal.SubTitle>Cartões com categorias</Styled.Modal.SubTitle>
                    <Styled.Modal.Image src={img4} />
                    <Form.Check
                        type="radio"
                        onChange={() => setselectOption(AdminModeloSecaoModelEnum.CARTOES)}
                        checked={selectOption == AdminModeloSecaoModelEnum.CARTOES}
                    />
                </Styled.Modal.FrameOption>

                <Styled.Modal.FrameOption
                    style={selectedStyle(AdminModeloSecaoModelEnum.SIMPLES_TEXTO)}
                    onClick={() => setselectOption(AdminModeloSecaoModelEnum.SIMPLES_TEXTO)}
                >
                    <Styled.Modal.SubTitle>Simples sem imagem</Styled.Modal.SubTitle>
                    <Styled.Modal.Image src={img5} />
                    <Form.Check
                        type="radio"
                        onChange={() => setselectOption(AdminModeloSecaoModelEnum.SIMPLES_TEXTO)}
                        checked={selectOption == AdminModeloSecaoModelEnum.SIMPLES_TEXTO}
                    />
                </Styled.Modal.FrameOption>

                <Styled.Modal.FrameOption
                    style={selectedStyle(AdminModeloSecaoModelEnum.CAROUSEL)}
                    onClick={() => setselectOption(AdminModeloSecaoModelEnum.CAROUSEL)}
                >
                    <Styled.Modal.SubTitle>Carrossel</Styled.Modal.SubTitle>
                    <Styled.Modal.Image src={img6} />
                    <Form.Check
                        type="radio"
                        onChange={() => setselectOption(AdminModeloSecaoModelEnum.CAROUSEL)}
                        checked={selectOption == AdminModeloSecaoModelEnum.CAROUSEL}
                    />
                </Styled.Modal.FrameOption>
            </Row>

            <br />

            <Row justify="space-around" style={{ paddingBottom: '30px' }}>
                <OutlineButton onClick={onHide}>Cancelar</OutlineButton>
                <PrimaryButton onClick={confirmSelect}>Selecionar</PrimaryButton>
            </Row>
        </Modal>
    );
};

export default AdminGestaoPaginasModeloSecaoModal;
