import Loading from 'components/Loading';
import Row from 'components/Row';
import Select, { SelectOptionType } from 'components/Select';
import { ToastError, ToastSuccess } from 'components/Toaster';
import { GradientButton, OutlineButton, RedSolidButton } from 'components/UI/Buttons';
import AdminGestaoPaginaService from 'core/http/service/admin/AdminGestaoPagina.service';
import { cloneDeep, isNumber } from 'lodash';
import { CardCategoria } from 'models/generic-components/CardCategoria';
import SecaoCardCategoria from 'models/generic-components/SecaoCardCategoria';
import AdminSecaoPaginaData, {
    AdminSecaoPaginaCardItem,
    AdminSecaoPaginaCardItemCategory,
    AdminSecaoPaginaCards,
} from 'pages/gestao_paginas/AdminSecaoPaginaData';
import { AdminGestaoPaginasContext } from 'pages/gestao_paginas/context/AdminGestaoPaginasContext';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Switch from 'react-switch';
import { useParams } from 'react-router-dom';
import AdminImageUploader from '../imageUploader/AdminImageUploader';
import { IAdminEditSection } from './AdminEditBanner';
import Styled from './AdminEditor.styled';
import { isStringsValid, isValidLink } from './isValidNewContentInput';
import AllowedGroupsValidator from 'pages/admin/components/allowedGroupsValidator/AllowedGroupsValidator';
import { KcAdminGroups } from 'models/kc/KcUserInfo';
import ModalExclusao from 'pages/gestao_paginas/new-edit-recurso/components/modal/ModalExclusao';
import TipoRedirecionamento from 'enums/TipoRedirecionamento';
import { getOptionFromValue, tipoRedirecionamentoOptions } from './tipoRedirecionamento';

const MAX_CHAR_TITLE = 60;
const MAX_CHAR_CONTENT = 240;

const initialContent = (idx: number) => new AdminSecaoPaginaCardItem('', '', '', false, '', '', { codigo: 1 }, true, false, '', idx);

// Baseado no componente original AdminEditEducationcards
const AdminEditCategoryCards = ({ mode, onDelete }: IAdminEditSection) => {
    const params: any = useParams();
    const service = new AdminGestaoPaginaService();

    const [cards, setCards] = useState<AdminSecaoPaginaCardItem[]>([]);

    const [idSecao, setIdSecao] = useState<number>(0);
    const [idCardSelected, setIdCardSelected] = useState<number>(0);
    const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
    const [showModalDeleteSecao, setShowModalDeleteSecao] = useState(false);
    const [showModalDeleteCard, setShowModalDeleteCard] = useState(false);
    const [modePage, setModePage] = useState<boolean>(false);

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    const [submitting, setSubmitting] = useState(false);
    const [submittingIndex, setSubmittingIndex] = useState(-1);

    const [categorias, setCategorias] = useState<CardCategoria[]>([]);
    const [loading, setloading] = useState(false);
    const { currentSection, selectSection } = useContext(AdminGestaoPaginasContext.Context);

    const ALL_CATEGORIES: CardCategoria = new CardCategoria().fromJSON({
        codigo: 0,
        descricao: 'Todas as categorias',
        hoverMenu: 'Exibir em todas as categorias',
    });

    const getCategoriesOptions = (): SelectOptionType[] => {
        return categorias.map((c: CardCategoria) => ({ value: c.codigo, label: c.descricao } as SelectOptionType));
    };

    const isValidInputs = (idx: number): boolean => {
        const card = cards[idx];

        if (!card.categoria) return false;

        if (!(isValidLink(card.link, false) && isValidLink(card.linkSaibaMais, false))) return false;

        return isStringsValid([card.titulo, card.conteudo, card.anexoBase64]);
    };

    const isValidSection = (): boolean => {
        if (!title || title.trim().length === 0) {
            return false;
        }

        if (!description || description.trim().length === 0) {
            return false;
        }

        return true;
    };

    const fetchCategories = async () => {
        const { data } = await service.buscarCategoriasCartoes();
        const _categorias = Array.from(data).map((c: any) => new CardCategoria().fromJSON(c));
        _categorias.unshift(ALL_CATEGORIES);
        setCategorias(_categorias);
    };

    const fetchItem = async () => {
        if (mode == 'EDIT') {
            setloading(true);
            const id = String(params.id);
            const { data } = await service.buscaSecaoCartoesPorId(parseInt(id));
            const secao = new SecaoCardCategoria().fromJSON(data);

            setIdSecao(parseInt(id));
            setDescription(secao.descricao);
            setModePage(true);
            setTitle(secao.titulo);

            const _cards: AdminSecaoPaginaCardItem[] = [];

            for (const card of secao.cards) {
                const item = new AdminSecaoPaginaCardItem(
                    card.titulo,
                    card.conteudo,
                    card.link,
                    true,
                    '',
                    card.linkSaibaMais,
                    { codigo: card.categoria?.codigo ?? 0 },
                    card.ativo,
                    card.desabilitado,
                    card.anexoBase64,
                    card.ordem,
                    card.tipoRedirecionamento
                );

                item.setCodigo(card.codigo);
                _cards.push(item);
            }

            const _cardsSorted = _cards.sort((a, b) => a.ordem - b.ordem);

            setCards(_cardsSorted);
            setloading(false);
        }
    };

    const saveSection = async (section: AdminSecaoPaginaData, holder: AdminSecaoPaginaCards) => {
        if (!section) {
            alert('Erro ao salvar!');
            return;
        }

        debugger;

        const { status, data } = await service.inserirSecao(holder, section.paginaId, section.ordem);

        if (status < 300) {
            section.setCodigo(data.secaoCardCategoria.codigo);
            selectSection(section);
            setIdSecao(data.secaoCardCategoria.codigo);
            setModePage(true);
            ToastSuccess('Seção salva');
        } else {
            ToastError('Houve um erro ao tentar salvar o seção!');
        }
    };

    const updateSection = async (section: AdminSecaoPaginaData) => {
        if (!section) {
            alert('Erro ao atualizar!');
            return;
        }

        const { status, data } = await service.atualizarSecaoCartoes(section.codigo, title, description, section.ordem);

        if (status < 300) {
            ToastSuccess('Seção atualizada');
        } else {
            ToastError('Houve um erro ao tentar atualizar essa seção!');
        }
    };

    const submitSection = async () => {
        if (!currentSection) {
            alert('Erro ao enviar!');
            return;
        }

        setSubmitting(true);

        const holder = currentSection.modelData as AdminSecaoPaginaCards;
        holder.titulo = title;
        holder.conteudo = description;

        currentSection.modelData = holder;

        if (!currentSection.isPersist) await saveSection(currentSection, holder);
        else await updateSection(currentSection);

        setSubmitting(false);
    };

    const saveCard = async (secao: AdminSecaoPaginaData, idx: number) => {
        const item = cards[idx];

        const dataItem: AdminSecaoPaginaCardItem = new AdminSecaoPaginaCardItem(
            item.titulo,
            item.conteudo,
            item.link,
            item.linkExterno,
            item.linkInterno,
            item.linkSaibaMais,
            item.categoria,
            item.ativo,
            item.desabilitado,
            item.anexoBase64,
            idx + 1,
            item.tipoRedirecionamento
        );

        const { status, data } = await service.inserirCartaoCategoria(secao.codigo, dataItem);

        if (status < 300) {
            setCodigo(idx, data.codigo);
            ToastSuccess('Cartão salvo');
        } else {
            ToastError('Houve um erro ao tentar salvar o seção!');
        }
    };

    const updateCard = async (idx: number, novaOrdem?: /*Mundial*/ number) => {
        const item = cards[idx];

        if (novaOrdem) {
            item.ordem = novaOrdem;
        }

        const dataItem: AdminSecaoPaginaCardItem = new AdminSecaoPaginaCardItem(
            item.titulo,
            item.conteudo,
            item.link,
            item.linkExterno,
            item.linkInterno,
            item.linkSaibaMais,
            item.categoria,
            item.ativo,
            item.desabilitado,
            item.anexoBase64,
            item.ordem,
            item.tipoRedirecionamento
        );

        const { status, data } = await (isNumber(novaOrdem)
            ? service.atualizarOrdemCartaoCategoria(item.codigo, dataItem)
            : service.atualizarCartaoCategoria(item.codigo, dataItem));

        if (status < 300) {
            ToastSuccess('Cartão atualizado');

            if (novaOrdem) await fetchItem();
        } else {
            ToastError('Houve um erro ao tentar salvar o cartão!');
        }
    };

    const handleDeleteCard = async () => {
        setIsLoadingDelete(true);

        try {
            const response = await service.deleteCardCategoria(idCardSelected);

            if (response.status === 200) {
                ToastSuccess('Cartão excluído com sucesso!');
                fetchItem();
            }
        } catch (error) {
            ToastError('Ocorreu um erro na hora da exclusão.');
        }
        setShowModalDeleteCard(false);
        setIsLoadingDelete(false);
    };

    const handleDelete = async () => {
        setIsLoadingDelete(true);

        try {
            const response = await service.deleteCategoria(idSecao);

            if (response.status === 200) {
                ToastSuccess('Cartões com categorias excluído com sucesso!');
                onDelete();
            }
        } catch (error) {
            ToastError('Ocorreu um erro na hora da exclusão.');
        }
        setShowModalDeleteSecao(false);
        setIsLoadingDelete(false);
    };

    const submitCard = async (idx: number) => {
        if (!currentSection) {
            alert('Seção não encontrada');
            return;
        }

        setSubmittingIndex(idx);

        const item = cards[idx];

        if (!item.isPersist) await saveCard(currentSection, idx);
        else await updateCard(idx);

        setSubmittingIndex(-1);
    };

    const addNewCard = () => {
        const newCard = cloneDeep(initialContent(cards.length + 1));
        setCards([...cards, newCard]);
    };

    // ====== setters ===========
    const setCodigo = (idx: number, value: number) => {
        const clone = cloneDeep(cards);
        clone[idx].setCodigo(value);
        setCards(clone);
    };

    const setTitleCard = (idx: number, value: string) => {
        const clone = cloneDeep(cards);
        clone[idx].titulo = value;
        setCards(clone);
    };

    const setTextCard = (idx: number, value: string) => {
        const clone = cloneDeep(cards);
        clone[idx].conteudo = value;
        setCards(clone);
    };

    const setLink = (idx: number, value: string) => {
        const clone = cloneDeep(cards);
        clone[idx].link = value;
        setCards(clone);
    };

    const setLinkSaibaMais = (idx: number, value: string) => {
        const clone = cloneDeep(cards);
        clone[idx].linkSaibaMais = value;
        setCards(clone);
    };

    const setCategoria = (idx: number, value: number) => {
        const clone = cloneDeep(cards);
        clone[idx].categoria = { codigo: value };
        setCards(clone);
    };

    const setAtivo = (idx: number) => {
        const clone = cloneDeep(cards);
        clone[idx].ativo = !clone[idx].ativo;
        setCards(clone);
    };

    const setDisabilitado = (idx: number) => {
        const clone = cloneDeep(cards);
        clone[idx].desabilitado = !clone[idx].desabilitado;
        setCards(clone);
    };

    const setCover = (idx: number, value: string) => {
        const clone = cloneDeep(cards);
        clone[idx].anexoBase64 = value;
        setCards(clone);
    };

    const setTipoRedirecionamento = (idx: number, value: TipoRedirecionamento) => {
        const clone = cloneDeep(cards);
        clone[idx].tipoRedirecionamento = value;
        setCards(clone);
    };
    // =========================

    const itemsOrder = useCallback(
        (orderThis: number) => {
            const options: SelectOptionType[] = [];

            for (let i = 0; i < cards.length; i++) {
                if (i != orderThis) {
                    const option = {
                        value: i,
                        label: String(i + 1) + 'º',
                    } as SelectOptionType;

                    options.push(option);
                }
            }

            return options;
        },
        [cards]
    );

    const setupOrder = (idx: number, value: number) => {
        updateCard(idx, value + 1);
    };

    useEffect(() => {
        fetchCategories();
        fetchItem();
    }, []);

    return (
        <>
            <ModalExclusao
                showModal={showModalDeleteSecao}
                handleHiden={setShowModalDeleteSecao}
                handleExcluxed={handleDelete}
                isLoading={isLoadingDelete}
            />
            <ModalExclusao
                showModal={showModalDeleteCard}
                handleHiden={setShowModalDeleteCard}
                handleExcluxed={handleDeleteCard}
                isLoading={isLoadingDelete}
            />
            {loading ? (
                <Loading />
            ) : (
                <>
                    <Styled.HeadFormContainer>
                        <Styled.Label>Título da Seção</Styled.Label>
                        <Styled.Input value={title} onChange={(evt: any) => setTitle(evt.target.value)} />
                        <br />
                        <Styled.Label>Texto da Seção</Styled.Label>
                        <Styled.Input
                            value={description}
                            onChange={(evn: any) => setDescription(evn.target.value)}
                            rows={8}
                            as="textarea"
                            className={'form-control'}
                            maxLength={MAX_CHAR_CONTENT}
                        />
                        <Styled.CountLetter danger={description.length >= MAX_CHAR_CONTENT}>
                            {description.length}/{MAX_CHAR_CONTENT}
                        </Styled.CountLetter>

                        <Styled.RightContainer>
                            {modePage && (
                                <AllowedGroupsValidator allowedGroups={[KcAdminGroups.MASTER]}>
                                    <RedSolidButton style={{ marginRight: 20 }} onClick={() => setShowModalDeleteSecao(true)}>
                                        Deletar
                                    </RedSolidButton>
                                </AllowedGroupsValidator>
                            )}
                            <GradientButton onClick={submitSection} disabled={submitting || !isValidSection()}>
                                {submitting ? 'Salvando' : 'Salvar'} Seção
                            </GradientButton>
                        </Styled.RightContainer>
                    </Styled.HeadFormContainer>

                    {currentSection?.isPersist && (
                        <>
                            {/* <Styled.Title style={{ marginTop: '30px' }}>Cards</Styled.Title> */}
                            <br />
                            <hr />

                            {cards.map((card, idx) => {
                                return (
                                    <Styled.CardsContainer key={idx}>
                                        <Styled.Title style={{ paddingTop: '0' }}>Cartão {idx + 1}</Styled.Title>
                                        <Styled.SoluctionsContainer key={card.codigo}>
                                            <div>
                                                <Styled.Label>Título</Styled.Label>
                                                <Styled.Input value={card.titulo} onChange={(evt: any) => setTitleCard(idx, evt.target.value)} />

                                                <Styled.Label>Texto</Styled.Label>
                                                <Styled.Input
                                                    value={card.conteudo}
                                                    onChange={(evt: any) => setTextCard(idx, evt.target.value)}
                                                    rows={8}
                                                    as="textarea"
                                                    className={'form-control'}
                                                />

                                                <Styled.Label>Link</Styled.Label>

                                                <Styled.Input
                                                    className={
                                                        card.link && card.link.length > 3 && !isValidLink(card.link ?? '', false) && 'is-invalid'
                                                    }
                                                    value={card.link}
                                                    onChange={(evt: any) => setLink(idx, evt.target.value)}
                                                />
                                                <Styled.SubLabel>
                                                    OBS: Para redirecionar o usuário para os <strong>links externos</strong>, é necessário seguir
                                                    o padrão: <strong>http://url</strong>
                                                    ou <strong>https://url</strong>. Para <strong>links internos</strong>, temos dois exemplos de
                                                    opções:
                                                    <strong>http://ambiente/recursos/trilhas</strong>
                                                    ou <strong>/recursos/trilhas</strong>.
                                                </Styled.SubLabel>

                                                <Row justify="end" style={{ marginTop: '10px' }}>
                                                    <Styled.CkeckContainer>
                                                        <span style={{ marginRight: '10px' }}>Ativado</span>
                                                        <Switch
                                                            height={18 * 0.7}
                                                            width={40 * 0.7}
                                                            handleDiameter={22 * 0.7}
                                                            onHandleColor={'#0B78C8'}
                                                            offHandleColor={'#0B78C8'}
                                                            onColor={'#BFDFF7'}
                                                            offColor={'#dfdfdf'}
                                                            checkedIcon={false}
                                                            uncheckedIcon={false}
                                                            checked={card.ativo ?? false}
                                                            onChange={value => setAtivo(idx)}
                                                        />
                                                    </Styled.CkeckContainer>
                                                </Row>

                                                <Styled.Label>Link de &#34;Saiba mais&#34;</Styled.Label>
                                                <Styled.Input
                                                    className={
                                                        card.linkSaibaMais &&
                                                        card.linkSaibaMais.length > 3 &&
                                                        !isValidLink(card.linkSaibaMais ?? '', false) &&
                                                        'is-invalid'
                                                    }
                                                    value={card.linkSaibaMais}
                                                    onChange={(evt: any) => setLinkSaibaMais(idx, evt.target.value)}
                                                />
                                                <Styled.SubLabel>
                                                    OBS: Para redirecionar o usuário para os <strong>links externos</strong>, é necessário seguir
                                                    o padrão: <strong>http://url</strong>
                                                    ou <strong>https://url</strong>. Para <strong>links internos</strong>, temos dois exemplos de
                                                    opções:
                                                    <strong>http://ambiente/recursos/trilhas</strong>
                                                    ou <strong>/recursos/trilhas</strong>.
                                                </Styled.SubLabel>

                                                <Styled.SelectContainer>
                                                    <Styled.Row>
                                                        <Styled.Label style={{ marginRight: '15px', marginTop: 0 }}>Categoria: </Styled.Label>
                                                        <Select
                                                            itens={getCategoriesOptions()}
                                                            onSelected={(o: SelectOptionType) => setCategoria(idx, parseInt(String(o.value)))}
                                                            value={{
                                                                value: categorias.find(c => c.codigo == card.categoria.codigo)?.codigo ?? '',
                                                                label: categorias.find(c => c.codigo == card.categoria.codigo)?.descricao ?? '',
                                                            }}
                                                            defaultValue={{ value: ALL_CATEGORIES.codigo ?? 0, label: ALL_CATEGORIES.descricao }}
                                                            isSmall={true}
                                                        />
                                                    </Styled.Row>

                                                    <Styled.Row>
                                                        <span style={{ marginRight: '10px' }}>Ocultar cartão</span>
                                                        <Switch
                                                            height={18 * 0.7}
                                                            width={40 * 0.7}
                                                            handleDiameter={22 * 0.7}
                                                            onHandleColor={'#0B78C8'}
                                                            offHandleColor={'#0B78C8'}
                                                            onColor={'#BFDFF7'}
                                                            offColor={'#dfdfdf'}
                                                            checkedIcon={false}
                                                            uncheckedIcon={false}
                                                            checked={card.desabilitado ?? false}
                                                            onChange={value => setDisabilitado(idx)}
                                                        />
                                                    </Styled.Row>
                                                </Styled.SelectContainer>

                                                {cards.length > 1 && (
                                                    <Styled.Row style={{ marginTop: '10px' }}>
                                                        <Styled.Label style={{ marginRight: '15px', marginTop: 0 }}>Ordem</Styled.Label>
                                                        <Select
                                                            itens={itemsOrder(idx)}
                                                            value={{
                                                                value: idx,
                                                                label: String(idx + 1),
                                                            }}
                                                            width={'85px'}
                                                            onSelected={o => setupOrder(idx, parseInt(String(o.value)))}
                                                            disabled={submitting || cards.length < 2}
                                                            isSmall={true}
                                                        />
                                                    </Styled.Row>
                                                )}

                                                <Row alignVertical="center">
                                                    <Styled.Label style={{ margin: '10px 10px 0 0' }}>Tipo de redirecionamento</Styled.Label>
                                                    <Select
                                                        isSmall
                                                        value={getOptionFromValue(cards[idx].tipoRedirecionamento ?? TipoRedirecionamento.LOGIN)}
                                                        itens={tipoRedirecionamentoOptions}
                                                        onSelected={o => setTipoRedirecionamento(idx, o.value as TipoRedirecionamento)}
                                                    />
                                                </Row>
                                            </div>

                                            <Styled.RightColumn>
                                                <AdminImageUploader
                                                    size={'sm'}
                                                    title={'Imagem de capa'}
                                                    onSelect={() => {}}
                                                    setBase64={(base64: string) => setCover(idx, base64)}
                                                    preImageB64={cards[idx].anexoBase64}
                                                    recommendedDimension={{ w: 440, h: 175 }}
                                                />

                                                <br />
                                                {cards[idx].codigo > 0 && (
                                                    <AllowedGroupsValidator allowedGroups={[KcAdminGroups.MASTER]}>
                                                        <RedSolidButton
                                                            onClick={() => {
                                                                setIdCardSelected(cards[idx].codigo);
                                                                setShowModalDeleteCard(true);
                                                            }}
                                                        >
                                                            Deletar
                                                        </RedSolidButton>
                                                    </AllowedGroupsValidator>
                                                )}
                                                <GradientButton
                                                    onClick={() => submitCard(idx)}
                                                    disabled={!isValidInputs(idx) || submittingIndex == idx}
                                                >
                                                    {submittingIndex == idx ? 'Salvando...' : 'Salvar'}
                                                </GradientButton>
                                            </Styled.RightColumn>
                                        </Styled.SoluctionsContainer>
                                    </Styled.CardsContainer>
                                );
                            })}

                            <br />
                            <Row justify="start">
                                <OutlineButton style={{ width: 'fit-content' }} onClick={addNewCard}>
                                    <span style={{ fontSize: '0.95rem' }}>+ Adicionar novo cartão</span>
                                </OutlineButton>
                            </Row>
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default AdminEditCategoryCards;
