export enum RoleEnum {
    Professor = 'ROLE_TEACHER',
    GestorRede = 'ROLE_NETWORK_MANAGER',
    GestorEscolar = 'ROLE_SCHOOL_MANAGER',
    Administrador = 'ROLE_ADMINISTRATOR',
    Estudante = 'ROLE_EXAMINEE',
    CoordenadorRegional = 'ROLE_REGIONAL_COORDINATOR',
    Pesquisador = 'ROLE_RESEARCHER',
    ColaboradorParceria = 'ROLE_PARTNERSHIP_COLLABORATOR',
    CoordenadorNaoParceiro = 'ROLE_EXTERNAL_COORDINATOR',
    Diretor = 'ROLE_DIRECTOR',
    TecnicoEducacao = 'ROLE_EDUCATOR_TECHNICIAN',
    AgenteTecnico = 'ROLE_TECHNICAL_AGENT',
    LMS = 'ROLE_LMS',
    Outro = 'ROLE_OTHER',
    Unknown = '_',
}
