import React, { useState } from 'react';

import { IsMobileMaxWidth } from '../../../../core/util/WindowSize';
import { FiLinkedin, FiYoutube, FiInstagram, FiTwitter, FiFacebook, FiMapPin, FiMail, FiPhone, FiGlobe } from 'react-icons/fi';
import {
    ContactContainerStyle,
    ContentContainer,
    Title,
    ContactItem,
    ItemText,
    Link,
    SocialIcon,
    Img,
    Contents,
    AcessButton,
    ContentItemsContainer,
    FlexContainerSpaceBetween,
    HumaneLogoStyle,
    HeaderContainer,
    LogRodapeContainer,
    FlexContainerFooter,
    LinkFooter,
    LinkContainer,
} from './ContactContainer.styled';

import HumaneLogo from '../../../../images/homepage/contact/humane.svg';
import HumaneLogoBlue from '../../../../images/homepage/contact/humaneBlue.svg';
import { useHistory } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import Termos from 'pages/profile/components/regrasConvivencia/Termos';
import ModalTerms from 'pages/profile/components/regrasConvivencia/ModalTerms';
import LogoIAS from '../../../../images/homepage/contact/LogoIAS.png';
import Selo30Anos from './assets/selo-30-anos.webp';
import PrivacyPolicy from 'pages/home/components/contact/PrivacyPolicy';
import { GTM_Page_Home } from 'components/GoogleTagManager';
import Colors from 'enums/Colors';

const IsMobile = () => {
    return IsMobileMaxWidth();
};

interface IContactContainer {
    buttonDisabled?: boolean;
    isLight?: boolean;
}

const ContactContainer = ({ buttonDisabled, isLight }: IContactContainer) => {
    const history = useHistory();
    const [showTermsModal, setShowTermsModal] = useState(false);
    const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState(false);
    const anoAtual = new Date().getFullYear();
    const { keycloak } = useKeycloak();

    const login = () => {
        GTM_Page_Home('secao_contato', 'btn_click_acessar_plataforma');
        history.push('/login');
    };

    const register = () => {
        GTM_Page_Home('secao_contato', 'btn_click_fazer_cadastro');
        const url = keycloak.createRegisterUrl({
            redirectUri: window.location.origin + '/login',
        });
        location.assign(url);
    };

  

    const registrarAcessarConta = () => {
        return (
            <ContentContainer>
                <Title color={isLight ? Colors.Blue : Colors.White}>Conta humane</Title>
                <ContentItemsContainer>
                    <ContactItem style={{ marginBottom: '22px' }}>
                        <Link isLight={isLight} onClick={login}>
                            Acessar Minha Conta
                        </Link>
                    </ContactItem>

                    <ContactItem>
                        <Link isLight={isLight} onClick={register}>
                            Fazer Cadastro
                        </Link>
                    </ContactItem>
                </ContentItemsContainer>
            </ContentContainer>
        );
    };

    return (
        <ContactContainerStyle isLight={isLight} id="contactContainer">
            <HeaderContainer>
                <HumaneLogoStyle src={isLight ? HumaneLogoBlue : HumaneLogo} />
                {!keycloak.authenticated && !buttonDisabled && <AcessButton onClick={login}>Acessar a plataforma</AcessButton>}
            </HeaderContainer>
            <Contents>
                {!keycloak.authenticated && IsMobile() && registrarAcessarConta()}
                <ContentContainer>
                    <Title color={isLight ? Colors.Blue : Colors.White}>Conecte-se conosco</Title>

                    <ContentItemsContainer>
                        <SocialIcon
                            href="https://www.facebook.com/institutoayrtonsenna"
                            target="_blank"
                            onClick={() => {
                                GTM_Page_Home('secao_contato', 'btn_click_facebook');
                            }}
                        >
                            <FiFacebook color={isLight ? Colors.Blue : Colors.White} style={{ width: '22px', height: '22px' }} />
                            <p style={{ color: isLight ? Colors.Blue : Colors.White }}>Facebook</p>
                        </SocialIcon>

                        <SocialIcon
                            href="https://www.instagram.com/institutoayrtonsenna/"
                            target="_blank"
                            onClick={() => {
                                GTM_Page_Home('secao_contato', 'btn_click_instagram');
                            }}
                        >
                            <FiInstagram color={isLight ? Colors.Blue : Colors.White} style={{ width: '22px', height: '22px' }} />
                            <p style={{ color: isLight ? Colors.Blue : Colors.White }}>Instagram</p>
                        </SocialIcon>

                        <SocialIcon
                            href="https://www.linkedin.com/company/institutoayrtonsenna/mycompany/"
                            target="_blank"
                            onClick={() => {
                                GTM_Page_Home('secao_contato', 'btn_click_linkedin');
                            }}
                        >
                            <FiLinkedin style={{ width: '22px', height: '22px' }} color={isLight ? Colors.Blue : Colors.White} />
                            <p style={{ color: isLight ? Colors.Blue : Colors.White }}>LinkedIn</p>
                        </SocialIcon>

                        <SocialIcon
                            href="https://www.youtube.com/channel/UCJCq5ZlBsXYnhAbbZnwwjoA"
                            target="_blank"
                            onClick={() => {
                                GTM_Page_Home('secao_contato', 'btn_click_youtube');
                            }}
                        >
                            <FiYoutube color={isLight ? Colors.Blue : Colors.White} style={{ width: '22px', height: '22px' }} />
                            <p style={{ color: isLight ? Colors.Blue : Colors.White }}>Youtube</p>
                        </SocialIcon>

                        <SocialIcon
                            href="https://twitter.com/instayrtonsenna"
                            target="_blank"
                            onClick={() => {
                                GTM_Page_Home('secao_contato', 'btn_click_twitter');
                            }}
                        >
                            <FiTwitter color={isLight ? Colors.Blue : Colors.White} style={{ width: '22px', height: '22px' }} />
                            <p style={{ color: isLight ? Colors.Blue : Colors.White }}>Twitter</p>
                        </SocialIcon>
                    </ContentItemsContainer>
                </ContentContainer>

                <ContentContainer>
                    <Title color={isLight ? Colors.Blue : Colors.White}>Onde nos encontrar?</Title>
                    <ContentItemsContainer>
                        <ContactItem isLight={isLight}>
                            <SocialIcon
                                href="https://institutoayrtonsenna.org.br"
                                target="_blank"
                                onClick={() => {
                                    GTM_Page_Home('secao_contato', 'btn_click_website');
                                }}
                            >
                                <FiGlobe color={isLight ? Colors.Blue : Colors.White} style={{ width: '22px', height: '22px' }} />
                                <p style={{ color: isLight ? Colors.Blue : Colors.White }}>institutoayrtonsenna.org.br</p>
                            </SocialIcon>
                        </ContactItem>
                        <ContactItem isLight={isLight}>
                            <SocialIcon
                                href="mailto:humane@ias.org.br"
                                target="_blank"
                                onClick={() => {
                                    GTM_Page_Home('secao_contato', 'btn_click_email');
                                }}
                            >
                                <FiMail color={isLight ? Colors.Blue : Colors.White} style={{ width: '22px', height: '22px' }} />
                                <p style={{ color: isLight ? Colors.Blue : Colors.White }}>humane@ias.org.br</p>
                            </SocialIcon>
                        </ContactItem>
                        <ContactItem isLight={isLight}>
                            <FiMapPin style={{ width: '22px', height: '22px' }} />
                            <ItemText
                                color={isLight ? Colors.Blue : Colors.White}
                            >{`R. Dr. Fernandes Coelho, 85 - 15°. ${'\n'}São Paulo - Brasil`}</ItemText>
                        </ContactItem>
                    </ContentItemsContainer>
                </ContentContainer>

                {!keycloak.authenticated && !IsMobile() && registrarAcessarConta()}
            </Contents>

            <FlexContainerSpaceBetween>
                <div id="linksContainer" style={{ display: 'flex', gap: '15px' }}>
                    <LinkFooter isLight={isLight}>
                        <p
                            onClick={() => {
                                GTM_Page_Home('secao_contato', 'btn_click_politica_privacidade');
                                setShowPrivacyPolicyModal(true);
                            }}
                        >
                            Política de Privacidade
                        </p>
                    </LinkFooter>

                    <LinkFooter isLight={isLight}>
                        <p
                            onClick={() => {
                                GTM_Page_Home('secao_contato', 'btn_click_termos_uso');
                                setShowTermsModal(true);
                            }}
                        >
                            Termos de Uso
                        </p>
                    </LinkFooter>

                    <LinkFooter
                        isLight={isLight}
                        href={'https://institutoayrtonsenna.gupy.io/'}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => {
                            GTM_Page_Home('secao_contato', 'btn_click_trabalhe_conosco');
                        }}
                    >
                        Trabalhe com a gente
                    </LinkFooter>
                </div>

                <LogRodapeContainer style={{}}>
                    <LinkContainer href={'https://institutoayrtonsenna.org.br'} target="_blank" rel="noopener noreferrer">
                        <Img
                            style={{
                                width: 110,
                                marginRight: 10,
                                borderRadius: '0px 10px 0px 0px',
                            }}
                            src={Selo30Anos}
                        />
                        {/* <Img src={LogoIAS} /> */}
                    </LinkContainer>
                    {!IsMobile() && (
                        <ItemText
                            color={isLight ? Colors.Blue : Colors.White}
                        >{`@ ${anoAtual} Instituto Ayrton Senna. Todos direitos reservados.`}</ItemText>
                    )}
                </LogRodapeContainer>
            </FlexContainerSpaceBetween>

            {IsMobile() && (
                <FlexContainerFooter>
                    <ItemText
                        color={isLight ? Colors.Blue : Colors.White}
                        style={{ paddingTop: '20px', marginLeft: '0', whiteSpace: 'nowrap', fontSize: '10px' }}
                    >{`@ ${Date.now()} Instituto Ayrton Senna. Todos direiros reservados.`}</ItemText>
                </FlexContainerFooter>
            )}

            <ModalTerms show={showPrivacyPolicyModal} setShow={setShowPrivacyPolicyModal}>
                <PrivacyPolicy />
            </ModalTerms>

            <ModalTerms show={showTermsModal} setShow={setShowTermsModal}>
                <Termos
                    agree={() => {
                        setShowTermsModal(false);
                    }}
                    decline={() => {
                        setShowTermsModal(false);
                    }}
                    isLight={isLight}
                />
            </ModalTerms>
        </ContactContainerStyle>
    );
};

export default ContactContainer;
