import React, { useCallback, useRef } from 'react';
import EducationalSolution, { EducationalSolutionCategory } from 'models/EducationalSolution';
import { clone } from 'lodash';
import { useEffect, useState } from 'react';
import Styled, { mobileCarouselSettings } from './CardsContainerTematicaCarrousel.styled';
import HoverCard from './HoverCard';
import AdminHomeConteudoService from 'core/http/service/admin/AdminHomeConteudo.service';
import arrowYellow from '../../../../../images/homepage/arrow-yellow.svg';

import { IsCustomMaxWidth, IsMobileMaxWidth } from 'core/util/WindowSize';
import Slider from 'react-slick';

export interface ICardsContainer {
    tematicasList: EducationalSolution[];
}

const IsMobile = () => {
    return IsMobileMaxWidth();
};

const CardsContainerSolutionsCarrousel = ({ tematicasList }: ICardsContainer) => {
    const [showScroll, setShowSroll] = useState<boolean>(true);
    const [scrollLeft, setScrollLeft] = useState(0);

    const scrow = useRef<HTMLDivElement>(null);
    const scrollContainer = useRef<HTMLDivElement>(null);

    const scrollTo = (orientation: 'R' | 'L') => {
        const increaseScroll = 472 * 2;

        if (orientation == 'R' && scrow.current) scrow.current.scrollBy(increaseScroll, 0);
        else if (scrow.current) scrow.current.scrollBy(-1 * increaseScroll, 0);
    };

    useEffect(() => {
        if (scrollContainer.current && scrow.current) {
            const containerW = scrollContainer.current.clientWidth;
            const scrowW = scrow.current.scrollWidth;

            setShowSroll(containerW <= scrowW);
        }
    }, [scrollContainer.current, scrow.current, tematicasList]);

    useEffect(() => {
        if (scrow.current) {
            scrow.current.addEventListener('scroll', e => {
                setScrollLeft(scrow.current?.scrollLeft ?? 0);
            });
        }

        return () => scrow.current?.removeEventListener('scroll', () => {});
    }, [scrow.current]);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <Styled.Container>
            <Styled.ScrollContainer ref={scrollContainer}>
                {showScroll && <Styled.ArrowButton src={arrowYellow} onClick={() => scrollTo('L')} active={scrollLeft > 0} />}

                {IsMobile() ? (
                        <Styled.CarrouselContainer>
                            <Slider {...settings}>
                                {tematicasList.map(item => {
                                    return <HoverCard key={item.id} tematicaItem={item} />;
                                })}
                            </Slider>
                        </Styled.CarrouselContainer>
                ) : (
                    <Styled.Scroll ref={scrow}>
                        {tematicasList.map(item => (
                            <Styled.ScrollItem key={item.id}>
                                <HoverCard tematicaItem={item} />
                            </Styled.ScrollItem>
                        ))}
                    </Styled.Scroll>
                )}

                {showScroll && (
                    <Styled.ArrowButton
                        className="flip"
                        src={arrowYellow}
                        style={{ visibility: showScroll ? 'visible' : 'hidden' }}
                        onClick={() => scrollTo('R')}
                        active={!(scrow.current && scrow.current?.scrollLeft >= scrow.current?.scrollWidth - scrow.current?.clientWidth) ?? true}
                    />
                )}
            </Styled.ScrollContainer>
        </Styled.Container>
    );
};

export default CardsContainerSolutionsCarrousel;
