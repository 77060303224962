import DynamicHeader from 'components/dynamic_header/DynamicHeader';
import { PesquisaContext } from 'pages/home/context/PesquisaContext';
import BarraPesquisa from 'pages/pesquisa/barraPesquisa/BarraPesquisa';
import React, { useContext, useEffect, useState } from 'react';

import Outros from '../criatividadeDetail/versions/components/sections/Outros';
import { Section } from '../criatividadeDetail/versions/CriatividadeUpdated.styled';
import Contato from './subcomponents/Contato';
import CursoLivre from './subcomponents/CursoLivre';
import Guia from './subcomponents/Guia';
import Tematica from './subcomponents/Tematica';
import Promoter from './subcomponents/Promoter';
import TrilhaApronfundada from './subcomponents/TrilhaAprofundada';
import UsuarioDTO from 'models/UsuarioDTO';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import { Modal } from 'antd';
import { ProfileService } from 'core/http/service/Profile.service';
import { ToastSuccess } from 'components/Toaster';
import { SmileOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import useFetch from 'hooks/useFetch';
import ModalPlayAudio from './subcomponents/ModalPlayAudio';

export interface IAlfabetizacao360Detail {}

const Alfabetizacao360Detail = ({}: IAlfabetizacao360Detail) => {
    const { isSearchView } = useContext(PesquisaContext.Context);
  
    return (
        <>
            <DynamicHeader />

            <ModalPlayAudio />
         

            {isSearchView && <BarraPesquisa />}

            <Tematica />

            <TrilhaApronfundada />

            <Promoter />

            <CursoLivre />

            <Guia />

            <Section id="contato" color="#003C78;" style={{ height: 'fit-content' }}>
                <Contato />
            </Section>

            <Section id="outros">
                <Outros />
            </Section>
        </>
    );
};

export default Alfabetizacao360Detail;
