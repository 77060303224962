import Colors from 'enums/Colors';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';

const StyledConteudoRecurso = {
    Container: styled.div`
        border-radius: 10px;
        padding: 15px 45px;
        background-color: white;
        margin-left: 120px;
    `,
    Title: styled.div`
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 24px;
        color: #003c78;
    `,

    Label: styled.div`
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        color: #00a8e9;
        margin-bottom: 10px;
    `,

    FormContainer: styled.div`
        display: flex;
        max-width: 700px;
        margin-top: 20px;
    `,

    Input: styled(Form.Control)`
        width: 100%;
    `,

    Divisor: styled.div<{ width?: string }>`
        display: flex;
        flex-direction: column;
        width: ${props => (props.width ? props.width : 'auto')};
        margin: 10px;
    `,

    FormSections: styled.div`
        max-width: 820px;
        margin-top: 20px;
    `,

    AlignRight: styled.div`
        text-align: right;
    `,

    CountLetter: styled.p<{ danger: boolean }>`
        font-weight: 300;
        font-size: 14px;
        line-height: 17px;
        color: ${props => (props.danger ? 'red' : '#747474')};
        text-align: right;
        margin-top: 3px;
    `,

    DivAround: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 25%;
    `,

    CkeckContainer: styled.div`
        display: flex;
        align-items: center;

        label {
            cursor: pointer;
        }
    `,

    ButtonPDF: styled.button`
        width: 400px;
        height: 80px;
        padding: 20px;
        border: 5px dashed ${Colors.Blue};

        p {
            margin-top: 10px;
            color: ${Colors.SoftBlue};
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 350px;
        }
    `,
};

export default StyledConteudoRecurso;
