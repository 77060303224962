import React, { useEffect, useState } from 'react';

import {
    TematicaContainerStyle,
    TitleContainer,
    DescriptonText,
    CardsContainer,
    CarouselDots,
    CarouselCustomPaging,
    TitleText,
} from './../educationalSolutions/EducationalSolutionsContainer.styled';
import EducationalSolution from 'models/EducationalSolution';
import AdminHomeConteudoService from 'core/http/service/admin/AdminHomeConteudo.service';
import { clone } from 'lodash';
import useLogged from 'hooks/useLogged';
import HoverCard from './cardComponent/HoverCard';
import CardsContainerSolutionsCarrousel from './cardComponent/CardsContainerTematicaCarrousel';
import { IsCustomMaxWidth } from 'core/util/WindowSize';

const TematicasContainer = () => {
    const [tematicas, setTematicas] = useState<EducationalSolution[]>([]);
    const { authenticated } = useLogged();

    const resolution = IsCustomMaxWidth(800);

    const fetchContent = async () => {
        const service = new AdminHomeConteudoService();
        let { data, status } = await service.buscaConteudo('TEMATICAS');

        if (status == 200) {
            const temas = Array.from(data).map((sol: any) =>
                new EducationalSolution().fromJSON({
                    id: sol.id,
                    title: sol.titulo,
                    text: sol.conteudo,
                    active: sol.ativo,
                    image: '',
                    imagePath: '',
                    followRoute: sol.link,
                    followText: '',
                    linkExterno: sol.linkExterno,
                    proximoLancamento: sol.proximoLancamento,
                    novidade: sol.novidade,
                    disabled: sol.desabilitado,
                    defaultData: false,
                    order: sol.ordem,
                    complement: parseInt(sol.complemento),
                })
            );

            setTematicas(temas);
        }
    };

    useEffect(() => {
        fetchContent();
    }, []);

    return (
        <div>
            {tematicas.filter(tema => !tema.disabled).length !== 0 && (
                <TematicaContainerStyle isMobile={resolution} id="tematicas-container">
                    <TitleContainer auth={authenticated}>
                        <TitleText style={{ color: '#003C78' }}>{`Nossas Temáticas`}</TitleText>
                    </TitleContainer>
                    <CardsContainerSolutionsCarrousel tematicasList={tematicas.sort((a, b) => a.order - b.order).filter(tema => !tema.disabled)} />
                </TematicaContainerStyle>
            )}
        </div>
    );
};

export default TematicasContainer;
