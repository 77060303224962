import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import Styled from './biblioBanner.styled';

import AdminHomeConteudoService from 'core/http/service/admin/AdminHomeConteudo.service';
import { IsMobileMaxWidth } from 'core/util/WindowSize';
import { bucketS3, caminhoAnexosHomepage } from 'constants/EnvironmentData';
import { GTM_Page_Home } from 'components/GoogleTagManager';

export interface IbiblioBanner {}

const BibliotecaBanner = ({}: IbiblioBanner) => {
    const history = useHistory();

    const [title, setTitle] = useState<string | null>(null);
    const [imgBanner, setImgBanner] = useState<string | null>(null);
    const [content, setContent] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [texto_botao_a, setTextoBotaoA] = useState(null);
    const [link_interno, setLinkInterno] = useState(null);

    const goToLibrary = (location: string) => {
        GTM_Page_Home('secao_outros_recursos', `btn_click_${location.replace('/', '').toLowerCase()}`);
        history.push(location);
    };

    const getS3BaseUrl = (): string => {
        return bucketS3() + caminhoAnexosHomepage();
    };

    const fetchContent = async () => {
        const service = new AdminHomeConteudoService();
        let { data, status } = await service.buscaConteudo('BIBLIOTECA');
        data = data[0];

        try {
            if (status == 200 && data) {
                setTitle(data.titulo);
                setContent(data.conteudo);
                setTextoBotaoA(data.textoBotaoA);
                setLinkInterno(data.linkInterno);

                const response = await service.buscarAnexoConteudo(data.id, false);
                setImgBanner(getS3BaseUrl() + response.data.filePath);
            }
        } catch (e: any) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchContent();
    }, []);

    const buttonMobileText: string = IsMobileMaxWidth() ? 'Acessar' : 'Acessar biblioteca de conteúdos';

    return (
        <Styled.Container id="bibliotecaContainer">
            {!loading ? <Styled.PaddingImage src={imgBanner ?? ''} /> : <></>}

            <Styled.ContentContainer>
                <Styled.Title>{title}</Styled.Title>
                <Styled.TextBox>
                    <Styled.Text>{content}</Styled.Text>
                </Styled.TextBox>

                <Styled.ButtonContainer>
                    <Styled.Button
                        onClick={() => {
                            goToLibrary(link_interno ?? '/biblioteca');
                        }}
                    >
                        {texto_botao_a ?? buttonMobileText}
                    </Styled.Button>
                </Styled.ButtonContainer>
            </Styled.ContentContainer>
        </Styled.Container>
    );
};

export default BibliotecaBanner;
