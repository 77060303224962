import AdminPaginaIntermediaria from 'models/admin/AdminPaginaIntermediaria';
import AdminPaginaIntermediariaResumida from 'models/admin/AdminPaginaIntermediariaResumida';
import { AdminBreadCrumbType } from 'pages/admin/components/breadcrumb/AdminBreadCrumb';
import { createContext } from 'react';
import AdminSecaoPaginaData, { ModeloBase } from '../AdminSecaoPaginaData';

export namespace AdminGestaoPaginasContext {
    export interface IContext {
        currentSection: AdminSecaoPaginaData | null;
        selectSection: (value: AdminSecaoPaginaData) => void;

        breadCrumb: AdminBreadCrumbType[];
        setBreadCrumb: (value: AdminBreadCrumbType[]) => void;

        sections: AdminSecaoPaginaData[];
        setSections: (value: AdminSecaoPaginaData[]) => void;

        setCodigoRecurso: (value: number) => void;
        codigoRecurso: number;

        loadingCreate: boolean;
        setLoadingCreate: (value: boolean) => void;

        currentPage: AdminPaginaIntermediariaResumida | null;
        selectPage: (value: AdminPaginaIntermediariaResumida | null) => void;
        saveOrUpdatePage: (
            namePage: string,
            url: string,
            tipoTemplate: number,
            descricao: string,
            onSuccess: (pagina: AdminPaginaIntermediaria) => void,
            onError: Function
        ) => void;
    }

    export const Context = createContext({} as IContext);
}
