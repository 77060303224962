import React, { useEffect, useState } from 'react';
import Styled from './TagSolucoes.styled';
import { TagSolucoesEnum } from 'enums/TagSolucoesEnum';
import Colors from 'enums/Colors';

type TypeSolucoes = {
    bgColor: string;
    text: string;
    color: string;
};

interface ITagSolucoes {
    type: TagSolucoesEnum;
}

const TagSolucoes = ({ type }: ITagSolucoes) => {
    const [dados, setDados] = useState<TypeSolucoes>();

    useEffect(() => {
        let data: TypeSolucoes = {
            bgColor: '',
            color: '',
            text: '',
        };

        switch (type) {
            case TagSolucoesEnum.CORRE_DE_FLUXO: {
                data.bgColor = '#03aee2';
                (data.color = '#FFFFFF'), (data.text = 'Correção de fluxo');
                break;
            }
            case TagSolucoesEnum.ALFABETIZACAO: {
                data.bgColor = '#0170c1';
                (data.color = '#FFFFFF'), (data.text = 'Alfabetização');
                break;
            }
            case TagSolucoesEnum.RECUPERACAO_APRENDIZAGEM: {
                data.bgColor = '#002978';
                (data.color = '#FFFFFF'), (data.text = 'Recuperação de Aprendizagem');
                break;
            }
            case TagSolucoesEnum.GESTAO_ESCOLAR: {
                data.bgColor = Colors.Yellow2;
                (data.color = Colors.Blue), (data.text = 'Gestão escolar');
                break;
            }
            case TagSolucoesEnum.INTERESSES_PROFISSIONAIS: {
                data.bgColor = '#91d253';
                (data.color = '#201e1e'), (data.text = 'Interesses profissionais');
                break;
            }
            case TagSolucoesEnum.COMPETENCIAS_SOCIEMOCIONAL: {
                data.bgColor = '#6d9e3e';
                (data.color = '#FFFFFF'), (data.text = 'Competências Socioemocionais');
                break;
            }
            case TagSolucoesEnum.GESTAO_POLITICA_EDUCACIONAL: {
                data.bgColor = Colors.Yellow;
                (data.color = Colors.Blue), (data.text = 'Gestão de Política Educacional');
                break;
            }
            case TagSolucoesEnum.FORMACAO_LIDERANCA: {
                data.bgColor = Colors.LightYellow;
                (data.color = Colors.Blue), (data.text = 'Formação de Lideranças');
                break;
            }
        }

        setDados(data);
    }, [type]);

    return (
        <Styled.Container>
            <Styled.Tag color={dados?.color ?? ''} bgColor={dados?.bgColor ?? ''}>
                {dados?.text ?? 'Nenhuma'}
            </Styled.Tag>
        </Styled.Container>
    );
};

export default TagSolucoes;
