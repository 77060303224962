import { jsonIgnore, jsonName, jsonProperty, Serializable } from 'ts-serializable';

class AdminPaginaIntermediaria extends Serializable {
    @jsonProperty(Number)
    codigo: number = 0;

    @jsonName('pagina')
    @jsonProperty(String, null)
    pagina: string = '';

    @jsonName('titulo')
    @jsonProperty(String, null)
    titulo: string = '';

    @jsonName('descricao')
    @jsonProperty(String, null)
    descricao: string = '';

    @jsonName('link')
    @jsonProperty(String, null)
    link: string = '';

    @jsonName('nome')
    @jsonProperty(String, null)
    nome: string = '';

    @jsonName('tipoTemplate')
    @jsonProperty(Number, null)
    tipoTemplate: number | null = -1;

    @jsonName('ultimaAtualizacao')
    @jsonProperty(String, null)
    ultimaAtualizacao: string | null = '';

    @jsonName('editavel')
    @jsonProperty(Boolean, null)
    editavel: boolean = false;

    @jsonName('tituloSecaoConteudosRelacionados')
    @jsonProperty(String, null)
    tituloSecaoConteudosRelacionados: string = '';
}

export default AdminPaginaIntermediaria;
