import { MobileMaxWidth } from 'constants/Dimensions';
import { mediaOnlyScreenMaxWidthStr } from 'core/util/cssHelp';
import FullCss, { MediaQuery } from 'core/util/FullCss';
import styled from 'styled-components';

export const Container = styled.div`
    width: 266px;
    height: 296px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    padding: 25px 10px 25px 10px;
    display: flex;
    flex-direction: column;
    position: relative;
`;

export const Wrapper = styled.div(() => {
    return new FullCss(
        {},
        new MediaQuery(mediaOnlyScreenMaxWidthStr(MobileMaxWidth), {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '0px',
            padding: '15px',
        })
    ).getCss();
});
