import React, { useEffect, useRef, useState } from 'react';

import BannerContainer from './banner/BannerContainer';
import ContactContainer from './contact/ContactContainer';
import { MobileMaxWidth } from '../../../constants/Dimensions';
import ObjectivesContainer from './objectives/ObjectivesContainer';
import StatementsContainer from './statements/StatementsContainer';
import EducationalSolutionsContainer from './educationalSolutions/EducationalSolutionsContainer';

import styled from 'styled-components';
import BibliotecaBanner from './biblioBanner/biblioBanner';
import SpaceTeacher from './spaceTeacher/SpaceTeacher';
import OtherResources from './otherResources/OtherResources';
import { useKeycloak } from '@react-keycloak/web';
import PerfilUsuario from 'models/perfil/PerfilUsuario';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import HeadInfo from 'pages/painelSocioemocional/home/components/HeadInfo/HeadInfo';
import { ProfileWithAvatar } from 'models/perfil/ProfileWithAvatar';
import fetchUserInfo from 'pages/painelSocioemocional/home/utils/fetchUserInfo';
import HomePageHeader from './header/HomePageHeaderContainer';
import Dashboard from 'pages/painelSocioemocional/home/components/Dashboard/Dashboard';
import UsuarioDTO from 'models/UsuarioDTO';
import SuporteContainer from './FAQ/SuporteContainer';
import TematicasContainer from './tematicas/TematicasContainer';
import { ToasterDefault, ToastError } from 'components/Toaster';
import MultiSolucoes from 'pages/solucoes/components/solucoes/MultiSolucoes';
import Colors from 'enums/Colors';

const MainStyle = styled.div`
    margin-top: 86px;
    position: relative;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        margin-top: 60px;
    }
`;

const Main = () => {
    const { keycloak } = useKeycloak();
    const userProfile: PerfilUsuario | null = useAppSelector(state => state.profileReducer.userProfile);
    const usuarioLogado: UsuarioDTO = useAppSelector(state => state.authenticationReducer.usuarioLogado);
    const serachparams = new URLSearchParams(location.search);
    const [profile, setProfile] = useState<ProfileWithAvatar>();
    const [loading, setLoading] = useState<boolean>(true);
    const targetDivRef = useRef<HTMLDivElement>(null);
    const targetDivRef2 = useRef<HTMLDivElement>(null);
    

    const loadData = async () => {
        try {
            setLoading(true);
            if (keycloak.authenticated) {
                await fetchUserInfo(userProfile?.username ?? usuarioLogado.username ?? '', userProfile?.codigo || -1).then(
                    (perfilData: ProfileWithAvatar) => {
                        setProfile(perfilData);
                    }
                );
            }
        } catch (err: any) {
            console.log('err, fetch user info');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        const linkParam = serachparams.get('link');
        // setLinkParams(linkParam ?? '');

        if (linkParam === 'jornadas') {
            if (targetDivRef.current) {
                targetDivRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }
        }
    }, [serachparams]);

    return (
        <MainStyle>
            {keycloak.authenticated && !loading && (
                <HomePageHeader>
                    <HeadInfo profile={profile} />
                    {/* <Dashboard /> */}
                </HomePageHeader>
            )}
            {!keycloak.authenticated && <BannerContainer />}
            {!keycloak.authenticated && <ObjectivesContainer />}
            <TematicasContainer />
            <MultiSolucoes previewSolucoes backgroundColor={Colors.Yellow} paddingLeft='100px' height={'720px'}/>
            <div style={{ position: 'relative', top: -70, left: 0, width: '100%' }} ref={targetDivRef}></div>
            <EducationalSolutionsContainer /> {/* Jornada de desenvolvimento */}
            <OtherResources />
            {!keycloak.authenticated && <StatementsContainer />}
            <SuporteContainer />
            <ContactContainer />
            {ToasterDefault()}
        </MainStyle>
    );
};

export default Main;
