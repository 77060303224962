import { ButtonCPC } from 'components/UI/Buttons';
import styled from 'styled-components';

const Container = styled.div`
    gap: 120px;
    display: flex;
    flex-direction: column;
    padding: 40px 10% 0 0;

    @media only screen and (min-width: 1200px) {
        gap: 15vw;
    }

    @media only screen and (max-width: 1200px) {
        padding: 20px 0 50px 0;
    }
`;

const SplitContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media only screen and (max-width: 1200px) {
        justify-content: center;
        padding: 0 30px 0 30px;
    }
`;

const BackgroundImg = styled.div<{ src?: string }>`
    background: url(${props => (props.src ? props.src : '')}) no-repeat top left transparent;
    width: 100%;
    max-width: 640px;
    height: 540px;
    position: relative;
    bottom: 0;

    @media only screen and (max-width: 1200px) {
        display: none;
    }
`;

const VideoContainer = styled.div`
    position: absolute;
    top: 115px;
    left: 160px;

    @media only screen and (max-width: 1200px) {
        position: inherit;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 15px;

    @media only screen and (max-width: 1200px) {
        flex-direction: column;
        align-items: center;
        gap: 15px;
    }
`;

const BlueButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 30px;
    background: #003c78;
    border-radius: 50px;

    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #fcfcfc;
    max-width: 209px;
    border: none;

    @media only screen and (max-width: 1200px) {
        width: 100%;
        max-height: 38px;
        max-width: none;
    }

    &:hover {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
`;

const TransparentButton = styled(ButtonCPC)`
    background: transparent;
    border: 1px solid #003c78;
    border-radius: 50px;
    color: #003c78;

    &:hover {
        border: 1px solid white;
        color: white;
    }
`;

export { Container, SplitContainer, BackgroundImg, VideoContainer, ButtonContainer, BlueButton, TransparentButton };
