import AdminGestaoPaginaService from 'core/http/service/admin/AdminGestaoPagina.service';
import { StringHelp } from 'core/util/StringHelp';
import AdminPaginaIntermediaria from 'models/admin/AdminPaginaIntermediaria';
import AdminPaginaIntermediariaResumida from 'models/admin/AdminPaginaIntermediariaResumida';
import { AdminBreadCrumbType } from 'pages/admin/components/breadcrumb/AdminBreadCrumb';
import React, { useState, createContext } from 'react';

import { AdminGestaoPaginasMode } from '../AdminGestaoPaginasContextWrapper';
import AdminSecaoPaginaData, { ModeloBase } from '../AdminSecaoPaginaData';
import { AdminGestaoPaginasContext } from './AdminGestaoPaginasContext';

export interface IAdminGestaoPaginasProvider {}

const IDENTIFIER_PAGE = 'DYNAMIC_PAGE';

export const initialBreadCrumb = (mode: AdminGestaoPaginasMode, pageId?: number) => [
    { route: 'gestao-paginas', label: 'Gestão de páginas' },
    {
        route: `gestao-paginas/${mode == 'NEW' ? 'nova' : mode == 'EDIT' ? 'editar' : '-pagina'}${pageId ? '/' + pageId : ''}`,
        label: `${mode == 'NEW' ? 'Nova' : mode == 'EDIT' ? 'Editar' : 'Página'}`,
    },
];

const AdminGestaoPaginasProvider = ({ children }: React.PropsWithChildren<IAdminGestaoPaginasProvider>) => {
    const service = new AdminGestaoPaginaService();

    const [sections, _setSections] = useState<AdminSecaoPaginaData[]>([]);
    const [currentPage, setCurrentPage] = useState<AdminPaginaIntermediariaResumida | null>(null);
    const [currentSection, setCurrentSection] = useState<AdminSecaoPaginaData | null>(null);
    const [codigoRecurso, _setCodigoRecurso] = useState<number>(0);
    const [loadingCreate, _setLoadingCreate] = useState<boolean>(false);

    const [breadCrumb, _setBreadCrumb] = useState<AdminBreadCrumbType[]>([]);

    const setBreadCrumb = (value: AdminBreadCrumbType[]) => {
        _setBreadCrumb(value);
    };

    const selectSection = (value: AdminSecaoPaginaData) => {
        setCurrentSection(value);
    };

    const selectPage = (value: AdminPaginaIntermediariaResumida | null) => {
        setCurrentPage(value);
    };

    const setCodigoRecurso = (value: number) => {
        _setCodigoRecurso(value);
    };

    const setLoadingCreate = (value: boolean) => {
        _setLoadingCreate(value);
    };

    const saveOrUpdatePage = async (
        namePage: string,
        url: string,
        tipoTemplate: number,
        descricao: string,
        onSuccess: Function,
        onError: Function
    ) => {
        const payload = new AdminPaginaIntermediaria().fromJSON({
            nome: namePage,
            link: '/' + url.replace(/^\//g, ''),
            pagina: IDENTIFIER_PAGE,
            editavel: true,
            descricao: descricao,
        });

        payload.tipoTemplate = tipoTemplate;

        if (currentPage != null) {
            payload.codigo = currentPage.codigo;
            const { data, status } = await service.atualizarPagina(currentPage.codigo, payload);

            if (status < 300) {
                const paginaSalva = new AdminPaginaIntermediaria().fromJSON(data);
                setCurrentPage(new AdminPaginaIntermediariaResumida().fromJSON({ ...paginaSalva }));
                onSuccess(paginaSalva);
            } else onError();
        } else {
            const { data, status } = await service.criarPagina(payload);

            if (status < 300) {
                const paginaSalva = new AdminPaginaIntermediaria().fromJSON(data);
                setCurrentPage(new AdminPaginaIntermediariaResumida().fromJSON({ ...paginaSalva }));
                onSuccess(paginaSalva);
            } else onError();
        }

        setLoadingCreate(false);
    };

    const context: AdminGestaoPaginasContext.IContext = {
        breadCrumb,
        setBreadCrumb,
        currentSection,
        selectSection,

        setCodigoRecurso,
        codigoRecurso,

        loadingCreate,
        setLoadingCreate,

        sections,
        setSections: (value: AdminSecaoPaginaData[]) => {
            _setSections(value);
        },

        saveOrUpdatePage,
        currentPage,
        selectPage,
    };

    return <AdminGestaoPaginasContext.Context.Provider value={context}>{children}</AdminGestaoPaginasContext.Context.Provider>;
};

export default AdminGestaoPaginasProvider;
