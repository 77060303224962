import React from 'react';
import { Route } from 'react-router-dom';
import Styled from '../Admin.styled';
import AdminBiblioteca from '../subpages/biblio/AdminBiblioteca';
import AdminHome from '../subpages/home/AdminHome';
import AdminPages from '../subpages/pages/AdminPages';
import AdminSocioEmocional from '../subpages/socio/AdminSocioEmocional';
import AdminSocioEmocionalEdit from '../subpages/socioEdit/AdminSocioEmocionalEdit';
import AdminCursosPage from 'pages/cursos/AdminCursosPage';
import AdminGestaoPaginasPage from 'pages/gestao_paginas/AdminGestaoPaginasPage';
import AdminFaqPage from 'pages/faq/AdminFaqPage';
import AdminGestaoPaginasNovapagina from 'pages/gestao_paginas/new-edit-pagina/AdminGestaoPaginasNewEdit';
import AdminGestaoPaginasProvider from 'pages/gestao_paginas/context/AdminGestaoPaginasProvider';
import AdminGestaoPaginasSectionForm from 'pages/gestao_paginas/section-form/AdminGestaoPaginasSectionForm';
import AdminGestaoPaginasContextWrapper from 'pages/gestao_paginas/AdminGestaoPaginasContextWrapper';
import AllowedGroupsValidator from './allowedGroupsValidator/AllowedGroupsValidator';
import { KcAdminGroups } from 'models/kc/KcUserInfo';
import AdminHeader from '../subpages/header/AdminHeader';
import AdminUsers from '../subpages/users/AdminUsers';
import AdminGestaoCertificados from 'pages/gestaoCertificados/AdminGestaoCertificados';
import AdminGestaoCertificadosUsuarios from 'pages/gestaoCertificados/subpages/AdminGestaoCertificadosUsuarios';

export interface IAdminContent {
    contractMenu: boolean;
}

const AdminContent = ({ contractMenu }: IAdminContent) => {
    return (
        <Styled.ContainerContent>
            <br />

            <Route exact path="/admin" component={() => <AdminHome contractMenu={contractMenu} />} />

            <Route exact path="/admin/socioemocional" component={AdminSocioEmocional} />

            <Route exact path="/admin/socioemocional/edit" component={AdminSocioEmocionalEdit} />

            <Route path="/admin/biblioteca" component={AdminBiblioteca} />

            <Route exact path="/admin/paginas" component={AdminPages} />

            <Route exact path="/admin/cursos" component={AdminCursosPage} />

            <Route exact path="/admin/gestao-paginas" component={AdminGestaoPaginasPage} />

            <Route
                path={[
                    '/admin/gestao-paginas/nova-pagina',
                    '/admin/gestao-paginas/editar-pagina',
                    '/admin/gestao-paginas/novo-recurso',
                    '/admin/gestao-paginas/editar-recurso',
                    '/admin/gestao-paginas/editar-secao',
                    '/admin/gestao-paginas/novo-item-etapa-recurso',
                    '/admin/gestao-paginas/editar-item-etapa-recurso',
                    '/admin/gestao-paginas/novo-item-recurso',
                    '/admin/gestao-paginas/editar-item-recurso',
                    '/admin/gestao-paginas/nova-etapa-item',
                    '/admin/gestao-paginas/editar-etapa-item',
                ]}
                component={AdminGestaoPaginasContextWrapper}
            />

            <Route
                exact
                path="/admin/gestao-certificados"
                component={AdminGestaoCertificados}
            />
            <Route
                exact
                path="/admin/gestao-certificados/certificado/:id"
                component={AdminGestaoCertificadosUsuarios}
            />

            <Route exact path="/admin/faq" component={AdminFaqPage} />

            <Route exact path="/admin/header" component={AdminHeader} />

            <Route exact path="/admin/gestao-usuarios" component={AdminUsers} />


        </Styled.ContainerContent>
    );
};

export default AdminContent;
