import styled from 'styled-components';
import Colors from '../../../../../enums/Colors';
import { MobileBiggerWidth, MobileMaxWidth } from '../../../../../constants/Dimensions';

export const ObjectiveItemStyle = styled.div`
    max-width: 260px;
    margin-right: 25px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding 0px 20px 0px 20px;

    @media only screen and (max-width: ${MobileBiggerWidth}px) {
        gap: 0px;
    }

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        max-width: 240px;
        margin: 0px;
        gap: 0px;
    }
`;

export const IconContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        margin: 0px 0px 20px 0px;
        justify-content: flex-start;
    }
`;
export const Icon = styled.img`
    height: 80px;
    width: 80px;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        height: 50px;
        width: 50px;
    }
`;

export const DefaultText = styled.p`
    font-weight: normal;
    font-size: clamp(16px, 2vw, 20px);
    line-height: 137%;

    color: white;
    text-align: center;

    line-height: 22px;
    letter-spacing: 0.3px;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        text-align: start;
    }
`;
