import { useKeycloak } from '@react-keycloak/web';
import { GTM_Page_Realizando_Escolhas } from 'components/GoogleTagManager';
import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import {
    Container,
    TopContent,
    Byline,
    Title,
    InnerTopContent,
    BottomContent,
    ButtonInitialPage,
    Content,
    RightContainer,
    NavyLabel,
    ColunmContainer,
    PrimaryButtonCustom,
    Icon,
    LinkEmail,
} from './Instrumento.styled';
import UsuarioDTO from 'models/UsuarioDTO';
import { KeycloakService } from 'core/http/service/Keycloak.service';
import { AppStorage } from 'storage/AppStorage';
import { RoleEnum } from 'enums/RoleEnum';
import { getUserRole } from 'pages/autoavaliacao/utils/externalRequests';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import PerfilUsuario from 'models/perfil/PerfilUsuario';
import { ToastError, ToastSuccess } from 'components/Toaster';
import OnError from 'core/onError/OnError';
import { instrumentoIntegrationUrl, linkInstrucoesRE } from 'constants/EnvironmentData';
import { Iframe } from 'pages/autoavaliacao/Autoavaliacao.styled';
import Loading from 'components/Loading';
import { IsMobileMaxWidth } from 'core/util/WindowSize';
import Row from 'components/Row';
import { FiDownload } from 'react-icons/fi';
import { AiOutlinePlayCircle } from 'react-icons/ai';
import letterIcon from '../../../../images/letter.svg';
import ReactTooltip from 'react-tooltip';
import { EmailService } from 'core/http/service/Email.service';
import Email from 'models/Email';
import { Information } from 'constants/Informations';
import toast from 'react-hot-toast';
import { RealizandoEscolhasService } from 'core/http/service/RealizandoEscolhas.service';
import { buscarCategoriaPorPerfil, redirectToInstrumento } from 'pages/realizandoEscolhas/utils/RealizandoEscolhasUtils';
import { base64PDFToFile } from 'pages/admin/utils/base64ToFile';
import TomticketAvaliacao from '../components/TomticketAvaliacao';
import ImgTomTicket from '../components/assets/images/imgTomTicket.svg';

export interface IInstrumento {}

const Instrumento = ({}: IInstrumento) => {
    const history = useHistory();
    const { keycloak } = useKeycloak();
    const userProfile: PerfilUsuario | null = useAppSelector(state => state.profileReducer.userProfile);
    const usuarioLogado: UsuarioDTO = useAppSelector(state => state.authenticationReducer.usuarioLogado);
    const [postSentToggle, setPostSentToggle] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const serviceEmail = new EmailService();
    const [loadingDownload, setLoadingDownload] = useState<boolean>(false);
    const [showTomtick, setTomTicket] = useState<boolean>(false);

    const service = new RealizandoEscolhasService();

    const CODIGO_TIPO_CONTEUDO: number = 4;

    const registrarAcesso = async () => {
        service.registrarAcesso(CODIGO_TIPO_CONTEUDO);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        keycloak.updateToken(7200);
    }, []);

    const goToHomePage = () => {
        GTM_Page_Realizando_Escolhas('instrumento_realizando_escolhas', 'voltar_home_realizando_escolhas');
        history.push('/realizandoEscolhas/home');
        return;
    };

    const handleTomTicket = () => setTomTicket(true);

    const downloadInstructions = async () => {
        setLoadingDownload(true);
        const service = new RealizandoEscolhasService();
        try {
            let response;
            response = await service.buscarOrientacoesDeUsoBase64Encoded();

            if (response.status === 200) {
                base64PDFToFile(response.data, 'Orientações de Uso - Realizando Escolhas.pdf');
            } else {
                ToastError('Aconteceu algum erro ao tentar baixar o material');
            }
        } catch (ex: any) {
            ToastError('Aconteceu algum erro ao tentar baixar o material');
        } finally {
            setLoadingDownload(false);
        }
    };

    const gotoPlaylist = () => {
        const urlPlaylist = 'https://www.youtube.com/playlist?list=PLGYL0Ph3TnLU7kTs66Ss-FHP0n-UdOpoJ';
        window.open(urlPlaylist, '_blank');
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        keycloak.updateToken(7200);
    }, []);

    const loadData = async () => {
        redirectToInstrumento((await buscarCategoriaPorPerfil(usuarioLogado)) ?? ('A' as string), goToHomePage, usuarioLogado, registrarAcesso);
        try {
            //RECUERAR TOKEN SSO
            const accessToken: string | undefined = await AppStorage.getAccessToken();
            const response: any = await new KeycloakService().getUsuarioLogado();
            const output: UsuarioDTO = new UsuarioDTO().fromJSON(response.data);
            keycloak.tokenParsed;
            let role: RoleEnum = getUserRole(output.roles);

            if (role == RoleEnum.Unknown && userProfile) {
                role = getUserRole([userProfile.role]);
            }

            if (role != RoleEnum.Unknown) {
                const postReq = {
                    token: accessToken,
                };

                const iframe = document.getElementById('ifr_realizando_escolhas') as HTMLIFrameElement;

                if (iframe.contentWindow) {
                    iframe.contentWindow.postMessage(postReq, '*');
                    setPostSentToggle(true);
                }
            } else {
                ToastError('Você não tem permissão');
            }
        } catch (error) {
            OnError.sendToAnalytics('loadData.ts', String(error));
        }
    };

    const setupBackgroundColor = () => {
        const element: any = document.querySelector('#root > div');
        if (element) element.style.backgroundColor = '#FCFCFC';
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        loadData();
        setLoading(true);

        setupBackgroundColor();

        setTimeout(() => {
            setLoading(false);
        }, 4000);
    }, []);

    return (
        <Container>
            <TomticketAvaliacao
                img={ImgTomTicket}
                iframe="https://ias-suporte-ead.tomticket.com/formulario?id=EP07602&account=486528P30062023110559"
                show={showTomtick}
                handleHiden={() => setTomTicket(false)}
            />
            <TopContent>
                <InnerTopContent>
                    <Title>{'Avaliação'}</Title>
                </InnerTopContent>

                <RightContainer>
                    <ColunmContainer>
                        <NavyLabel>Precisa de ajuda?</NavyLabel>
                        <Row>
                            <PrimaryButtonCustom onClick={downloadInstructions} disabled={loadingDownload}>
                                <FiDownload />
                                {loadingDownload ? <span>Baixando...</span> : <span>Baixe as instruções</span>}
                            </PrimaryButtonCustom>

                            <PrimaryButtonCustom onClick={gotoPlaylist}>
                                <AiOutlinePlayCircle />
                                <span>Assista nossos vídeos</span>
                            </PrimaryButtonCustom>

                            <Icon src={letterIcon} onClick={handleTomTicket} data-for="tooltip-email" data-tip={'Fale com o Instituto'} />
                            <ReactTooltip
                                class="custom-tooltip"
                                id="tooltip-email"
                                effect="solid"
                                place="top"
                                delayShow={800}
                                backgroundColor={'#003C78'}
                            />
                        </Row>
                    </ColunmContainer>
                </RightContainer>
            </TopContent>

            {loading && <Loading />}
            <Content>
                <Iframe
                    visibile={!loading}
                    className="iframeRealizandoEscolhas"
                    src={`${instrumentoIntegrationUrl()}/realizando-escolhas`}
                    id="ifr_realizando_escolhas"
                    allow="clipboard-read; clipboard-write"
                ></Iframe>
            </Content>

            {IsMobileMaxWidth() && (
                <BottomContent>
                    <ButtonInitialPage onClick={goToHomePage}>{'Página inicial Realizando Escolhas'}</ButtonInitialPage>
                </BottomContent>
            )}
        </Container>
    );
};

export default Instrumento;
