import styled from 'styled-components';

const PesquisaHomeStyled = {
    Container: styled.div`
        margin-top: 86px;
        min-height: 100vh;
        padding: 40px 75px;
        background-color: white;

        @media only screen and (max-width: 760px) {
            padding: 40px 15px;
        }
    `,

    Title: styled.p`
        font-family: 'Barlow', sans-serif;
        color: #122b46;
        font-size: 1.77rem;
        font-weight: 500;
        margin-bottom: 30px;

        @media only screen and (max-width: 760px) {
            font-size: 1.4rem;
        }
    `,

    SideContainer: styled.div`
        min-width: 230px;

        background: #fbfbfc;
        border-right: 1px solid #e7e9ec;

        @media only screen and (max-width: 760px) {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
        }
    `,

    SideMenuContainer: styled.div<{ active?: boolean }>`
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 15px 15px;
        padding-right: 7px;
        cursor: pointer;
        margin-bottom: 2px;
        transition: 200ms ease;

        background-color: ${props => (props.active ? '#e9eaed' : 'transparent')};
        color: ${props => (props.active ? '#094b91' : '#999')};

        :hover {
            background-color: #e9eaed;
            color: #094b91;
        }

        @media only screen and (max-width: 760px) {
            width: fit-content;
        }
    `,

    SideCount: styled.div<{ active?: boolean }>`
        display: flex;
        justify-content: center;
        align-items: center;
        width: 28px;
        height: 28px;
        min-width: 28px;
        border-radius: 50%;

        background-color: ${props => (props.active ? '#003c78' : '#bbb')};

        @media only screen and (max-width: 760px) {
            margin-left: 10px;
            transform: scale(0.8);
        }

        span {
            color: white;
            transform: scale(0.8);
            width: fit-content;
        }
    `,

    SideMenuText: styled.p`
        font-size: 16px;
        font-family: 'Barlow', sans-serif;
    `,

    ContentContainer: styled.div`
        display: flex;
        border: 1px solid #e7e9ec;
        border-radius: 5px;

        @media only screen and (max-width: 760px) {
            flex-direction: column;
        }
    `,

    Content: styled.div`
        flex-grow: 1;
        padding: 20px 25px;
    `,

    TopContent: styled.div`
        display: flex;
        justify-content: space-between;
        padding-bottom: 18px;
        padding-top: 8px;
        border-bottom: 1px solid #ddd;
    `,

    VerticalSpace: styled.div<{ height: number }>`
        height: ${props => props.height}px;
    `,

    TitleContainer: styled.div`
        display: flex;
        position: relative;
        align-items: center;
        gap: 15px;
    `,

    TitleContent: styled.p`
        font-family: 'Barlow', sans-serif;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.25px;
        line-height: 1;
        margin: 0;
        text-transform: uppercase;
    `,

    SubTitleContent: styled.p`
        font-size: 14px;
        color: #122b46;
        letter-spacing: 0.25px;
        line-height: 1;
    `,

    ContainerItem: styled.div`
        display: flex;
        padding: 13px 0;
        align-items: center;
        border-bottom: 1px solid #ddd;
    `,

    ContainerText: styled.div`
        padding-left: 10px;
        height: 99px;
        flex-grow: 1;
    `,

    ImageItem: styled.img`
        width: 90px;
        height: 90px;
        border-radius: 4px;
        cursor: pointer;

        object-fit: cover;
    `,

    TitleItem: styled.p`
        font-size: 14px;
        color: #122b46;
        letter-spacing: -0.21px;
        line-height: 1.35;
        font-size: 18px;
        font-family: 'Barlow', sans-serif;
        cursor: pointer;
        opacity: 1;
        //#003C78

        :hover {
            color: #094b91;
        }
    `,

    TextItem: styled.p`
        font-size: 14px;
        color: #939597;
        letter-spacing: -0.24px;
        line-height: 1.3;
        margin-bottom: 6px;

        max-height: 72px;
        overflow: hidden;
    `,

    SeeMore: styled.p`
        width: 100%;
        text-align: center;
        margin-top: 20px;
        color: #122b46;
        cursor: pointer;

        :hover {
            color: #094b91;
            text-decoration: underline;
        }
    `,

    PaginationContainer: styled.div`
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 10px 0;
        padding-top: 20px;
        font-family: 'Barlow', sans-serif;
        color: #999;
        font-size: 13px;

        span {
        }
    `,

    PaginationButtons: styled.div``,

    PaginationButton: styled.span<{ clickable?: boolean }>`
        margin: 0 10px;
        font-size: 13px;
        cursor: ${props => (props.clickable ? 'pointer' : 'default')};
        color: ${props => (props.clickable ? '#094b91' : '#999')};
    `,

    NoResultsContainer: styled.div`
        display: flex;

        width: 100%;
        display: flex;
        justify-content: center;
        color: #094b91;
        font-size: 1.5rem;
        padding: 40px 0;

        span {
            margin: 0 10px;
        }
    `,
};

export default PesquisaHomeStyled;
