import { GTM_Page_Intermediaria } from 'components/GoogleTagManager';
import FaqContainer from 'pages/home/components/FAQ/FaqContainer';
import { PesquisaContext } from 'pages/home/context/PesquisaContext';
import Menu from 'pages/menu/Menu';
import BarraPesquisa from 'pages/pesquisa/barraPesquisa/BarraPesquisa';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import IntermediatePage from '../components/IntermediatePage';
import Doubt from './assets/doubt.svg';
import { ButtonContainer, GobackButton, SuporteContainer, TemporaryFrame } from './Suporte.styled';
import DynamicHeader from 'components/dynamic_header/DynamicHeader';

export interface ISuporte {}

const Suporte = ({}: ISuporte) => {
    const history = useHistory();
    const { isSearchView } = useContext(PesquisaContext.Context);

    const load = async () => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);
    };

    useEffect(() => {
        load();
    }, []);

    return (
        <>
            {/* <Menu homepage homeIntermediate /> */}
            <DynamicHeader />

            <IntermediatePage
                helmetTitle={'Suporte'}
                helmetMetaDescription={''}
                title1={''}
                title2={'Suporte'}
                icon={Doubt}
                breadCrumbName={'Suporte'}
            >
                <SuporteContainer>
                    <FaqContainer />

                    <ButtonContainer>
                        <GobackButton
                            onClick={() => {
                                GTM_Page_Intermediaria('suporte_intermediaria', 'voltar_pagina_btn');
                                history.push('/');
                            }}
                        >
                            Voltar página inicial
                        </GobackButton>
                    </ButtonContainer>
                </SuporteContainer>
            </IntermediatePage>

            {isSearchView && <BarraPesquisa />}
        </>
    );
};

export default Suporte;
