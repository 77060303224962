import React from 'react';
import AccessButton from '../button/AccessButton';
import ObjectiveItem from '../objectItem/ObjectiveItem';
import { Container, Wrapper } from './ObjectiveCard.styled';

interface IObjectiveCard {
    icon: any;
    description: string;
}

const ObjectiveCard = ({ ...props }: IObjectiveCard) => {
    return (
        <Wrapper>
            <Container>
                <ObjectiveItem icon={props.icon} description={props.description} />
                <div style={{ display: 'flex', justifyContent: 'center', position: 'absolute', bottom: 25, transform: 'translate(25%,0)' }}>
                    <AccessButton />
                </div>
            </Container>
        </Wrapper>
    );
};

export default ObjectiveCard;
