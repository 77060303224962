import { HomePageService } from 'core/http/service/HomePage.service';
import HomepageResult from 'models/homepage/HomepageResult';
import { barraPesquisaId } from 'pages/pesquisa/barraPesquisa/BarraPesquisa';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { PesquisaContext } from './PesquisaContext';

export interface IHomeProvider {}

export const queryParamBusca = 'termo';

const PesquisaProvider = ({ children }: React.PropsWithChildren<IHomeProvider>) => {
    const history = useHistory();
    const location = useLocation();
    const [term, _setTerm] = useState('');
    const [isSearchView, setIsSearchView] = useState(false);
    const [isResultView, setIsResultView] = useState(false);
    const [results, setResults] = useState<HomepageResult[]>([]);
    const [resultFor, setResultFor] = useState<string>('');
    const homeService = new HomePageService();

    const inativesBiblioCategories = ['audio', 'atividade'];

    const closeSearch = () => {
        _setTerm('');
        setIsSearchView(false);
    };

    const openSearch = () => {
        setIsSearchView(true);

        const barElement = document.getElementById(barraPesquisaId);
        if (barElement) {
            barElement.focus();
            barElement.click();
        }
    };

    const search = async () => {
        const serachparams = new URLSearchParams(location.search);

        if (serachparams.has(queryParamBusca)) {
            const termPesquisa = serachparams.get(queryParamBusca) ?? '';
            const { data } = await homeService.pesquisa(termPesquisa);
            const _results = Array.from(data)
                .map((r: any) => new HomepageResult().fromJSON(r))
                .filter(r => r.categoria != 'BIBLIOTECA' || !inativesBiblioCategories.includes(r.subcategoria ?? ''));

            setResults(_results);
            setIsResultView(true);
            setIsSearchView(false);
            setResultFor(termPesquisa);
        } else {
            closeResults();
            closeSearch();
        }
    };

    const closeResults = () => {
        setResults([]);
        setIsResultView(false);
    };

    useEffect(() => {
        search();
    }, [location.search]);

    const context: PesquisaContext.IContext = {
        isSearchView,
        closeSearch,
        openSearch,

        term,
        setTerm: (term: string) => _setTerm(term),
        results,
        resultFor,

        isResultView,
        showResults: () => setIsResultView(true),
        closeResults,
    };

    return <PesquisaContext.Context.Provider value={context}>{children}</PesquisaContext.Context.Provider>;
};

export default PesquisaProvider;
