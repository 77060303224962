import { useKeycloak } from '@react-keycloak/web';
import { GTM_Page_Biblioteca } from 'components/GoogleTagManager';
import BiblioConteudoService from 'core/http/service/biblioteca/BiblioConteudo.service';
import BiblioNotificacaoService from 'core/http/service/biblioteca/BiblioNotificacao.service';
import { DateHelp } from 'core/util/DateHelp';
import useLogged from 'hooks/useLogged';
import { AdminBiblioAnexoType } from 'pages/admin/components/newContent/submitAttach';
import { base64XLSToFile } from 'pages/admin/utils/base64ToFile';
import BiblioFavoriteButtonItem from 'pages/biblioteca/components/favorites/BiblioFavoriteButtonItem';
import BiblioRateModal from 'pages/biblioteca/components/rates/BiblioRateModal';
import BiblioShareModal from 'pages/biblioteca/components/share/BiblioShareModal';
import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import IBiblioPropsItem from './BiblioPropsItem';
import { BiblioDownloadButton } from './CommonStyled';
import PlusCircle from './assets/plusCircle.svg';
import {
    Container,
    CoverImage,
    TextContainer,
    Title,
    SubTitle,
    Description,
    BottomContainer,
    ActionContainer,
    InfoContainer,
    InfoItemContainer,
    Label,
    Value,
    Separator,
    Column,
    TitleContainer,
    SeeMoreContainer,
    SeeMoreButton,
    Icon,
    Date,
    FlexBox,
    MiddleBox,
} from './DefaultItem.styled';
import { BiblioItemCategoria } from 'models/biblioteca/BiblioItemCategoria';
import DefaultVideoFrame from 'components/DefaultVideoFrame';
import cover from '../../../../images/biblioteca/cover2-exemple.png';
import { IsCustomMaxWidth } from 'core/util/WindowSize';
import { ResizeMediumWidth } from 'constants/Dimensions';
import ItemBiblitoecaConteudoRelacionado from 'models/admin/ItemBibliotecaConteudoRelacionado';
import { FetchUtils } from 'core/util/FetchUtils';
import RelatedContent from 'components/RelatedContent';
import { ItemBibliotecaConteudoRelacionadoService } from 'core/http/service/ItemBibliotecaConteudoRelacionado.service';
import { RelatedContentAnexoEnum } from 'components/RelatedContentEnum';
import { BibliotecaContext } from 'pages/biblioteca_v2/context/BibliotecaContext';

function RateModal(props: any) {
    return (
        <BiblioRateModal
            prerate={props.data.rateUser}
            item_id={props.data.id}
            show={props.showRateModal}
            onShow={() => {
                GTM_Page_Biblioteca(
                    `${props.data.id}`,
                    `rate_${props.data.category}_${props.data.title.replace(/\s+/g, '_').toLowerCase()}`,
                    'open_rate_modal_btn'
                );
                props.setShowRateModal(true);
            }}
            onHide={() => {
                GTM_Page_Biblioteca(
                    `${props.data.id}`,
                    `rate_${props.data.category}_${props.data.title.replace(/\s+/g, '_').toLowerCase()}`,
                    'close_rate_modal_btn'
                );
                props.setShowRateModal(false);
            }}
        />
    );
}

function TitleBox(props: any) {
    return (
        <>
            <TitleContainer>
                <Title>{props.data.title}</Title>
                <ActionContainer>
                    <BiblioFavoriteButtonItem width="35px" data={props.data} isFavorited={props.data?.isFavorited} />
                    <BiblioShareModal
                        width="35px"
                        item_id={props.data.id}
                        show={props.showShareModal}
                        onShow={() => {
                            GTM_Page_Biblioteca(
                                `${props.data.id}`,
                                `share_${props.data.category}_${props.data.title.replace(/\s+/g, '_').toLowerCase()}`,
                                'open_share_modal_btn'
                            );
                            props.setShowShareModal(true);
                        }}
                        onHide={() => {
                            GTM_Page_Biblioteca(
                                `${props.data.id}`,
                                `share_${props.data.category}_${props.data.title.replace(/\s+/g, '_').toLowerCase()}`,
                                'close_share_modal_btn'
                            );
                            props.setShowShareModal(false);
                        }}
                    />
                </ActionContainer>
            </TitleContainer>
        </>
    );
}

const DefaultItem = ({ data }: IBiblioPropsItem) => {
    const [showRateModal, setShowRateModal] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);
    const [prepareDownload, setPrepareDownload] = useState(false);
    const [isDropped, setDropped] = useState<boolean>(false);
    const { login } = useLogged();
    const { keycloak } = useKeycloak();
    const location = useLocation();
    const IsMobile = () => IsCustomMaxWidth(ResizeMediumWidth);
    const [loadingRelatedContent, setLoadingRelatedContent] = useState<boolean>(true);
    const [conteudosRelacionados, setConteudosRelacionados] = useState<ItemBiblitoecaConteudoRelacionado[]>([]);
    const conteudosService = new ItemBibliotecaConteudoRelacionadoService();

    const { categorias } = useContext(BibliotecaContext.Context);

    const getCompontamento = (): string => {
        for (const categoria of categorias) {
            if (categoria.descricao == data.category) {
                return categoria.comportamento;
            }
        }

        return 'livro';
    };

    const [comportamento] = useState<string>(getCompontamento());

    const sanitizeLink = (): string => {
        if (data.linkSrc) {
            //data.linkSrc[0] = 'https://www.youtube.com/watch?v=mPdEkpATqss&t=4s&ab_channel=EscoladeTempoIntegral';
            let idxChar = data.linkSrc[0].lastIndexOf('/');
            const len = data.linkSrc[0].length;

            let link = 'www.youtube.com/embed/' + data.linkSrc[0].substring(idxChar + 1, len);

            if (link.includes('&')) {
                idxChar = link.indexOf('&');
                link = link.substring(0, idxChar);
            }

            return link.replace('watch?v=', '');
        }
        return '';
    };

    const imageSanitize = (img: string) => {
        if (!img) return cover;
        else return 'data:image/*;base64,' + img ?? cover;
    };

    const downloadAttach = async () => {
        if (!keycloak.authenticated) {
            login(location.pathname, `download=1`);
            return;
        }

        setPrepareDownload(true);
        const service = new BiblioConteudoService();

        let response: any;

        response = await service.buscarAnexoTipo(data.id, AdminBiblioAnexoType.CONTEUDO);

        base64XLSToFile(response.data.base64Content, response.data.file);
        GTM_Page_Biblioteca(`${data.id}`, `download_item${data.id}`, 'download_btn');
        setPrepareDownload(false);
    };

    const checkLastSession = () => {
        const value = location.pathname.split('download=')[1];

        if (value && value == '1') {
            downloadAttach();
        }
    };

    const marcarNotificacaoLida = async () => {
        if (keycloak.authenticated) {
            const service = new BiblioNotificacaoService();
            await service.updateItemByUserItem(keycloak.subject!, data.id);
        }
    };

    const fetchRelatedContent = async () => {
        const responseRelatedContent: any = await conteudosService.listaPorItemPublico(data.id);
        let output = responseRelatedContent.data.map((item: any) => new ItemBiblitoecaConteudoRelacionado().fromJSON(item));
        setConteudosRelacionados(output);
    };

    useEffect(() => {
        FetchUtils.loadingHof(setLoadingRelatedContent, fetchRelatedContent);
    }, []);

    useEffect(() => {
        checkLastSession();
        marcarNotificacaoLida();
    }, []);

    return (
        <>
            <Container>
                <Column isVideo={comportamento == BiblioItemCategoria.VIDEO}>
                    {IsMobile() ? <TitleBox showShareModal={showShareModal} setShowShareModal={setShowShareModal} data={data} /> : null}
                    {comportamento != BiblioItemCategoria.VIDEO ? (
                        <CoverImage src={imageSanitize(data.cover ?? '')} />
                    ) : (
                        <>
                            <DefaultVideoFrame url={sanitizeLink()} width={400} height={250} />
                            <Date>Publicado em {DateHelp.formatDateFull(data.create_date)}</Date>
                            <RateModal showRateModal={showRateModal} setShowRateModal={setShowRateModal} data={data} />
                        </>
                    )}

                    {comportamento != BiblioItemCategoria.VIDEO ? (
                        <FlexBox direction="column-reverse">
                            {IsMobile() ? <BiblioDownloadButton onClick={downloadAttach} preparing={prepareDownload} /> : null}

                            <InfoContainer>
                                <InfoItemContainer>
                                    <Label>Ano</Label>
                                    <Value>{data.yearPublication ? DateHelp.getYear(data.yearPublication) : ''}</Value>
                                </InfoItemContainer>

                                <InfoItemContainer>
                                    <Label>Páginas</Label>
                                    <Value>{data.numberPages}</Value>
                                </InfoItemContainer>

                                <InfoItemContainer>
                                    <Label>Idioma</Label>
                                    <Value>{data.language}</Value>
                                </InfoItemContainer>
                            </InfoContainer>
                        </FlexBox>
                    ) : null}
                </Column>

                <Column style={{ width: '100%' }}>
                    <TextContainer>
                        {!IsMobile() ? <TitleBox showShareModal={showShareModal} setShowShareModal={setShowShareModal} data={data} /> : null}
                        <SeeMoreContainer isDropped={isDropped || (data.content != null && data.content.length < 430)}>
                            <Description dangerouslySetInnerHTML={{ __html: data.content?.toString() ?? '' }}></Description>
                            {data.content && data.content.length > 430 && !isDropped ? (
                                <SeeMoreButton
                                    isDropped={isDropped}
                                    onClick={() => {
                                        setDropped(!isDropped);
                                    }}
                                >
                                    <Icon src={PlusCircle} />
                                    {'Ler mais'}
                                </SeeMoreButton>
                            ) : null}
                        </SeeMoreContainer>
                    </TextContainer>

                    <BottomContainer>
                        {comportamento != BiblioItemCategoria.VIDEO ? (
                            <FlexBox direction="row-reverse">
                                {!IsMobile() ? <BiblioDownloadButton onClick={downloadAttach} preparing={prepareDownload} /> : null}
                                <RateModal showRateModal={showRateModal} setShowRateModal={setShowRateModal} data={data} />
                            </FlexBox>
                        ) : null}
                    </BottomContainer>
                </Column>
            </Container>

            <Separator />

            <MiddleBox hasContent={conteudosRelacionados.length > 0}>
                <Title>{'Conteúdos relacionados'}</Title>
                {RelatedContent.ConteudosRelacionados(
                    { loadingRelatedContent, IsMobile },
                    RelatedContentAnexoEnum.BIBLIOTECA,
                    conteudosRelacionados
                )}
                <Separator style={{ marginTop: IsMobile() ? '20px' : '' }} />
            </MiddleBox>
        </>
    );
};

export default DefaultItem;
