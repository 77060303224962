import React, { useState, useEffect, useContext } from 'react';
import Styled from './AdminUsers.styled';
import { MdOutlineNavigateNext } from 'react-icons/md';
import { transform } from 'lodash';

interface IAdminUsersPagination {
    page: number;
    totalPages: number;

    onSelectPage: (page: number) => void;
}

const AdminUsersPagination = ({ page, totalPages, onSelectPage }: IAdminUsersPagination) => {
    const arrayRange = (stop: number) => Array.from({ length: stop }, (value, index) => index);

    const prevPage = () => {
        onSelectPage(page - 1);
    };

    const nextPage = () => {
        onSelectPage(page + 1);
    };

    return (
        <Styled.ContanerPagination>
            {page > 1 && (
                <Styled.ButtonNextPagination onClick={prevPage}>
                    <MdOutlineNavigateNext style={{ transform: 'rotate(-180deg)' }} />
                </Styled.ButtonNextPagination>
            )}

            {arrayRange(totalPages - 1).map(i => {
                return (
                    <Styled.ButtonPagination key={i} active={i + 1 == page} onClick={() => onSelectPage(i + 1)}>
                        {i + 1}
                    </Styled.ButtonPagination>
                );
            })}

            {page != totalPages - 1 && (
                <Styled.ButtonNextPagination onClick={nextPage}>
                    <MdOutlineNavigateNext />
                </Styled.ButtonNextPagination>
            )}
        </Styled.ContanerPagination>
    );
};

export default AdminUsersPagination;
