import React, { useEffect, useState } from 'react';
import FullDashboardJornadas from 'models/perfil/FullDashboardJornadas';
import { ButtonsContainer, Container, Content, IconSvg, RedirectButton, Status, Text, TitleContainer } from './JornadaFrame.styled';
import PlayIcon from '../../assets/PlayIcon.svg';
import { JornadaStatusEnum } from 'enums/JornadaStatusEnum';
import { JornadaEnum } from 'enums/JornadaEnum';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { IsCustomMaxWidth } from 'core/util/WindowSize';
import Sharebox from '../Sharebox';
import { GTM_Page_Meu_Painel } from 'components/GoogleTagManager';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import { buscarOcupacaoPorPerfil } from 'pages/realizandoEscolhas/utils/RealizandoEscolhasUtils';

export interface IJornadaFrame {
    jornadas: FullDashboardJornadas[];
}

const JornadaFrame = ({ jornadas }: IJornadaFrame) => {
    const history = useHistory();
    const profile = useAppSelector(state => state.profileReducer.userProfile);
    const [listJornadas, setListJornadas] = useState<FullDashboardJornadas[]>([]);

    const getJornadaText = (value: JornadaEnum): string => {
        if (value == JornadaEnum.SOCIOEMOCIONAL_PROFESSORES) {
            return 'Socioemocional de Professores';
        } else if (value == JornadaEnum.REALIZANDO_ESCOLHAS) {
            return 'Realizando Escolhas';
        }

        return '';
    };

    const getJornadaStatus = (value: JornadaStatusEnum): string => {
        if (value == JornadaStatusEnum.INICIADA) {
            return 'Iniciada';
        } else if (value == JornadaStatusEnum.FINALIZADA) {
            return 'Finalizada';
        } else if (value == JornadaStatusEnum.NAO_INICIADA) {
            return 'Não Iniciada';
        } else {
            return 'Em Breve';
        }
    };

    const goToPage = (value: JornadaEnum) => {
        GTM_Page_Meu_Painel('jornadas_redirect_button', 'acessar_jornada', value.toLowerCase());
        if (value == JornadaEnum.SOCIOEMOCIONAL_PROFESSORES) {
            history.push('/painelSocioemocional');
        } else if (value == JornadaEnum.REALIZANDO_ESCOLHAS) {
            history.push('/realizando-escolhas');
        }
    };

    const verifyOccupationUser = () => {
        const response = buscarOcupacaoPorPerfil(profile);

        if (response === 'C') {
            const newJornadas = jornadas.filter(j => j.jornada !== JornadaEnum.REALIZANDO_ESCOLHAS);
            setListJornadas(newJornadas);
            return;
        }

        setListJornadas(jornadas);
    };

    useEffect(() => {
        verifyOccupationUser();
    }, [jornadas]);

    return (
        <Container>
            {listJornadas.map((item, index) => {
                return (
                    <Content key={index}>
                        <TitleContainer>
                            <Text isStatus={false}>{getJornadaText(item.jornada)}</Text>
                        </TitleContainer>
                        <Status BGcolor={item.status}>
                            <Text isStatus={true}>{getJornadaStatus(item.status)}</Text>
                        </Status>
                        <ButtonsContainer>
                            <Sharebox location={item.jornada} />
                            <RedirectButton onClick={() => goToPage(item.jornada)}>
                                <IconSvg src={PlayIcon} />
                            </RedirectButton>
                        </ButtonsContainer>
                    </Content>
                );
            })}

            {/* Others */}

            {!IsCustomMaxWidth(1200) && (
                <ReactTooltip className="max-toptip" effect="solid" place="top" delayShow={0} backgroundColor={'#003C78'} multiline={true} />
            )}
        </Container>
    );
};

export default JornadaFrame;
