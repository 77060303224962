import React, { useContext, useEffect } from 'react';
import Styled from './AdminHome.styled';

import img1 from '../../../../images/admin/img-home1.png';
import img3 from '../../../../images/admin/img-home3.png';
import img5 from '../../../../images/admin/img-home5.png';
import img6 from '../../../../images/admin/img-home6.png';
import img7 from '../../../../images/admin/img_gestao_certificados.jpg';
import AdminTopBar from 'pages/admin/components/barraSuperior/AdminTopBar';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { AdminContext } from 'pages/admin/context/AdminContext';
import AllowedGroupsValidator from 'pages/admin/components/allowedGroupsValidator/AllowedGroupsValidator';
import { KcAdminGroups } from 'models/kc/KcUserInfo';

const imgSocio =
    'https://plataformaestruturante.s3.us-east-2.amazonaws.com/homePage/anexos/049_DiegoVillamarin.JPG_2022-07-11-1657543987038-min.jpg_2022-10-03-1664808874224-min (1).jpg_2022-10-04-1664884031934.jpg';
const imgBiblio =
    'https://plataformaestruturante.s3.us-east-2.amazonaws.com/homePage/anexos/baixados.png_2022-07-13-1657735825378-min.png_2022-10-04-1664884415773.png';
const imgHome = 'https://plataformaestruturante.s3.us-east-2.amazonaws.com/homePage/anexos/image.jpg_2022-07-12-1657633831195.jpg';
export interface IAdminHome {
    contractMenu?: boolean;
}

const AdminHome = ({ contractMenu }: IAdminHome) => {
    const history = useHistory();

    const gotoRoute = (route: string) => {
        history.push(route);
    };

    // Espaço horizontal esquerdo (Isso serve para o menu lateral não ficar por cima do conteúdo).
    const SpaceLeft = styled.div`
        margin-left: 100px;
    `;

    return (
        <SpaceLeft>
            <AdminTopBar title={'Painel Administrador'} goBack={false} />

            <Styled.Container
                style={{
                    marginBottom: 50,
                }}
            >
                <br />
                <Styled.Title>Olá Administrador!</Styled.Title>
                <Styled.Subtitle>Gerencie as principais áreas da Plataforma humane</Styled.Subtitle>

                <Styled.Row>
                    <AllowedGroupsValidator allowedGroups={[KcAdminGroups.MASTER, KcAdminGroups.GESTAO_CONTEUDO]}>
                        <Styled.TileContainer contract={contractMenu}>
                            <Styled.ImageBackground src={imgHome} />
                            <Styled.GradientOpacity />
                            <Styled.ContentCointener>
                                <Styled.TileTitle>Home da Plataforma Estruturante</Styled.TileTitle>
                                <Styled.GradientButtonz onClick={() => gotoRoute('/admin/paginas')}>Acessar</Styled.GradientButtonz>
                            </Styled.ContentCointener>
                        </Styled.TileContainer>
                    </AllowedGroupsValidator>

                    <AllowedGroupsValidator allowedGroups={[KcAdminGroups.MASTER, KcAdminGroups.GESTAO_CONTEUDO]}>
                        <Styled.TileContainer contract={contractMenu}>
                            <Styled.ImageBackground src={imgSocio} />
                            <Styled.GradientOpacity />
                            <Styled.ContentCointener>
                                <Styled.TileTitle>Gestão do Painel Sociemocional</Styled.TileTitle>
                                <Styled.GradientButtonz onClick={() => gotoRoute('/admin/socioemocional')}>Acessar</Styled.GradientButtonz>
                            </Styled.ContentCointener>
                        </Styled.TileContainer>
                    </AllowedGroupsValidator>

                    <AllowedGroupsValidator allowedGroups={[KcAdminGroups.MASTER, KcAdminGroups.GESTAO_CONTEUDO]}>
                        <Styled.TileContainer contract={contractMenu}>
                            <Styled.ImageBackground src={imgBiblio} />
                            <Styled.GradientOpacity />
                            <Styled.ContentCointener>
                                <Styled.TileTitle>Gestão da Biblioteca</Styled.TileTitle>
                                <Styled.GradientButtonz onClick={() => gotoRoute('/admin/biblioteca')}>Acessar</Styled.GradientButtonz>
                            </Styled.ContentCointener>
                        </Styled.TileContainer>
                    </AllowedGroupsValidator>

                    <AllowedGroupsValidator allowedGroups={[KcAdminGroups.MASTER, KcAdminGroups.GESTAO_CONTEUDO]}>
                        <Styled.TileContainer contract={contractMenu}>
                            <Styled.ImageBackground src={imgSocio} />
                            <Styled.GradientOpacity />
                            <Styled.ContentCointener>
                                <Styled.TileTitle>Cursos Livres</Styled.TileTitle>
                                <Styled.GradientButtonz onClick={() => gotoRoute('/admin/cursos')}>Acessar</Styled.GradientButtonz>
                            </Styled.ContentCointener>
                        </Styled.TileContainer>
                    </AllowedGroupsValidator>

                    <AllowedGroupsValidator allowedGroups={[KcAdminGroups.MASTER, KcAdminGroups.GESTAO_CONTEUDO]}>
                        <Styled.TileContainer contract={contractMenu}>
                            <Styled.ImageBackground src={img1} />
                            <Styled.GradientOpacity />
                            <Styled.ContentCointener>
                                <Styled.TileTitle>Gestão de Páginas</Styled.TileTitle>
                                <Styled.GradientButtonz onClick={() => gotoRoute('/admin/gestao-paginas')}>Acessar</Styled.GradientButtonz>
                            </Styled.ContentCointener>
                        </Styled.TileContainer>
                    </AllowedGroupsValidator>

                    <AllowedGroupsValidator allowedGroups={[KcAdminGroups.MASTER, KcAdminGroups.GESTAO_CONTEUDO]}>
                        <Styled.TileContainer contract={contractMenu}>
                            <Styled.ImageBackground src={img6} />
                            <Styled.GradientOpacity />
                            <Styled.ContentCointener>
                                <Styled.TileTitle>Gestão do Header</Styled.TileTitle>
                                <Styled.GradientButtonz onClick={() => gotoRoute('/admin/header')}>Acessar</Styled.GradientButtonz>
                            </Styled.ContentCointener>
                        </Styled.TileContainer>
                    </AllowedGroupsValidator>

                    <AllowedGroupsValidator allowedGroups={[KcAdminGroups.MASTER, KcAdminGroups.GESTAO_CONTEUDO]}>
                        <Styled.TileContainer contract={contractMenu}>
                            <Styled.ImageBackground src={img3} />
                            <Styled.GradientOpacity />
                            <Styled.ContentCointener>
                                <Styled.TileTitle>FAQ</Styled.TileTitle>
                                <Styled.GradientButtonz onClick={() => gotoRoute('/admin/faq')}>Acessar</Styled.GradientButtonz>
                            </Styled.ContentCointener>
                        </Styled.TileContainer>
                    </AllowedGroupsValidator>

                    <AllowedGroupsValidator allowedGroups={[KcAdminGroups.MASTER, KcAdminGroups.GESTAO_USUARIO]}>
                        <Styled.TileContainer contract={contractMenu}>
                            <Styled.ImageBackground src={img5} />
                            <Styled.GradientOpacity />
                            <Styled.ContentCointener>
                                <Styled.TileTitle>Gestão de Usuários</Styled.TileTitle>
                                <Styled.GradientButtonz onClick={() => gotoRoute('/admin/gestao-usuarios')}>Acessar</Styled.GradientButtonz>
                            </Styled.ContentCointener>
                        </Styled.TileContainer>
                    </AllowedGroupsValidator>
                    
                    <AllowedGroupsValidator allowedGroups={[KcAdminGroups.MASTER, KcAdminGroups.GESTAO_CONTEUDO]}>
                        <Styled.TileContainer contract={contractMenu}>
                            <Styled.ImageBackground src={img7} />
                            <Styled.GradientOpacity />
                            <Styled.ContentCointener>
                                <Styled.TileTitle>Gestão de Certificados</Styled.TileTitle>
                                <Styled.GradientButtonz onClick={() => gotoRoute('/admin/gestao-certificados')}>Acessar</Styled.GradientButtonz>
                            </Styled.ContentCointener>
                        </Styled.TileContainer>
                    </AllowedGroupsValidator>
                </Styled.Row>
            </Styled.Container>
        </SpaceLeft>
    );
};

export default AdminHome;
