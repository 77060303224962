import React, { useState, useEffect, useCallback, useContext } from 'react';

import { Route, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import Menu from '../menu/Menu';
import Loading from '../../components/Loading';
import OnError from '../../core/onError/OnError';
import Atuacao from './components/atuacao/Atuacao';
import { ProgressConsumer } from './ProgressContext';
import { MyProgressContext } from './ProgressContext';
import { useAppSelector } from '../../hooks/LocalReduxThunk';
import * as profileActions from '../../store/actions/Profile';
import * as authenticationActions from '../../store/actions/Authentication';
import PerfilUsuario from '../../models/perfil/PerfilUsuario';
import ProgressBar from './components/progressBar/ProgressBar';
import Identificacao from './components/identificacao/Identificacao';
import { ProfileStyle, MainContainer, ContentContainer } from './Profile.styled';
import RegrasConvivencia from './components/regrasConvivencia/RegrasConvivencia';
import CameraCaptura from './components/identificacaoImagem/subpages/CameraCaptura';
import IdentificacaoImagem from './components/identificacaoImagem/IdentificacaoImagem';
import EditImagemPerfil from './components/identificacaoImagem/subpages/EditImagemPerfil';
import IdentificacaoImagemConfirmar from './components/identificacaoImagem/subpages/ConfirmarImagemPerfil';
import { useKeycloak } from '@react-keycloak/web';
import { AppStorage } from 'storage/AppStorage';
import { KeycloakProfile, KeycloakTokenParsed } from 'keycloak-js';
import UsuarioDTO from 'models/UsuarioDTO';
import { RoleEnum } from 'enums/RoleEnum';
import Tomticket from 'components/tomticket/Tomticket';
import ProfileProvider from './context/ProfileProvider';
import InformacoesPessoais from './components/informacoesPessoais/InformacoesPessoais';
import DynamicHeader from 'components/dynamic_header/DynamicHeader';

const Profile = () => {
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();
    const history = useHistory();
    const { keycloak } = useKeycloak();

    const userProfile: PerfilUsuario | null = useAppSelector(state => state.profileReducer.userProfile);
    const usuarioLogado: UsuarioDTO = useAppSelector(state => state.authenticationReducer.usuarioLogado);

    const loadData = async () => {
        await dispatch(profileActions.fetchParametrosCadastro());
    };

    const location: any = useLocation();

    const getPostProfilePath = () => {
        return location && location.state ? location?.state.postProfilePath : '/';
    };

    const initialize = async () => {
        try {
            setIsLoading(true);

            if (userProfile) {
                history.push('/');
            } else if (
                usuarioLogado &&
                !usuarioLogado.roles.includes(RoleEnum.Pesquisador) &&
                !usuarioLogado.roles.includes(RoleEnum.Estudante)
            ) {
                await loadData();

                history.push({
                    pathname: '/profile/informacoes',
                    state: { postProfilePath: getPostProfilePath() },
                });
            } else {
                history.push('/');
            }
        } catch (error) {
            OnError.sendToAnalytics('initialize', String(error));
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        initialize();
    }, []);

    return (
        <ProfileStyle>
            <MyProgressContext>
                <>
                    {/*  <Menu profile /> */}
                    <DynamicHeader />

                    <MainContainer>
                        <ProgressConsumer>
                            {value => {
                                const { progresso } = value;
                                return <ProgressBar percentualConcluido={progresso} />;
                            }}
                        </ProgressConsumer>
                        <ContentContainer id="profile-contents-container">
                            {isLoading ? (
                                <Loading />
                            ) : (
                                <>
                                    <ProfileProvider>
                                        <Route path="/profile/identificacao" component={Identificacao} />
                                        <Route path="/profile/atuacao" component={Atuacao} />
                                        <Route path="/profile/identificacaoImagem" component={IdentificacaoImagem} />
                                        <Route path="/profile/identificacaoImagemEditar" component={EditImagemPerfil} />
                                        <Route path="/profile/identificacaoImagemConfirmar" component={IdentificacaoImagemConfirmar} />
                                        <Route path="/profile/identificacaoImagemCameraCaptura" component={CameraCaptura} />
                                        <Route path="/profile/regrasConvivencia" component={RegrasConvivencia} />
                                        <Route path="/profile/informacoes" component={InformacoesPessoais} />
                                    </ProfileProvider>
                                    <Tomticket />
                                </>
                            )}
                        </ContentContainer>
                    </MainContainer>
                </>
            </MyProgressContext>
        </ProfileStyle>
    );
};

export default Profile;
