import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { IoMdClose } from 'react-icons/io';
import styled from 'styled-components';

const FloatingContainer = styled.div`
    position: fixed;
    width: 100px;
    height: 100px;
    bottom: 0;
    right: 0;
    margin: 35px 25px;
    vertical-align: middle;
    font-size: 16px;

    &:hover .floating-button {
        box-shadow: 0 10px 25px rgba(44, 179, 240, 0.6);
        -webkit-transform: translatey(5px);
        transform: translatey(5px);
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
    }
`;

const FloatingButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 65px;
    height: 65px;
    background: #2cb3f0;
    bottom: 0;
    border-radius: 50%;
    left: 0;
    right: 0;
    margin: auto;
    color: white;
    line-height: 65px;
    text-align: center;
    font-size: 23px;

    box-shadow: 0 10px 25px -5px rgba(44, 179, 240, 0.6);
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
`;

const SvgFloatingButton = styled.svg`
    color: #fff;
    fill: currentColor;
    width: 32px;
    height: 32px;
    display: inline-block;
    font-size: 32px;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
`;

const TomticketContainer = styled.div`
    .frameStyle {
        width: 100%;
        height: 80%;
        border: none;
    }
`;

export const IFrameStyle = styled.iframe``;

export const SvgContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const CloseButtonContainer = styled.div`
    padding: 0 0 10px 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const CloseButton = styled.button`
    background: transparent;
    outline: none;
    border: none;
    padding: 0;
`;

export const IFrameContainer = styled.div`
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 100%;

    iframe {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
    }
`;

interface ITomticketAvaliacao {
    img: string;
    iframe: string;
    show: boolean;
    handleHiden: () => void;
}

const TomticketAvaliacao = ({ img, iframe, show, handleHiden }: ITomticketAvaliacao) => {
    return (
        <TomticketContainer>
            <Modal show={show} onHide={handleHiden} size="lg" backdrop="static" keyboard={false}>
                <Modal.Body style={{ height: '700px' }}>
                    <CloseButtonContainer>
                        <CloseButton onClick={handleHiden}>
                            <IoMdClose />
                        </CloseButton>
                    </CloseButtonContainer>
                    <SvgContainer>
                        <img src={img} />
                    </SvgContainer>
                    <IFrameContainer>
                        <iframe src={iframe} className="frameStyle"></iframe>
                    </IFrameContainer>
                </Modal.Body>
            </Modal>
        </TomticketContainer>
    );
};

export default TomticketAvaliacao;
