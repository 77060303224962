const solucoesEducionaisTexts: Map<string, string> = new Map();

solucoesEducionaisTexts.set(
    'socioemocional de professores',
    'Mergulhe nessa jornada usando nossas ferramentas de autoavaliação e diagnóstico dessas habilidades, Plano de Desenvolvimento Pessoal e cursos de Competências Socioemocionais de Educadores.'
);
solucoesEducionaisTexts.set(
    'alfabetização 360°',
    'Encontre uma visão 360° para apoiar a gestão e a recuperação da aprendizagem a partir de materiais, seus conceitos fundamentais e os caminhos para implementação na escola.'
);
solucoesEducionaisTexts.set(
    'criatividade e pensamento crítico',
    'Aqui você conhece um pouco mais sobre criatividade e pensamento crítico e encontra maneiras de trazer tais habilidades ao seu desenvolvimento e de seus estudantes.'
);
solucoesEducionaisTexts.set(
    'realizando escolhas',
    'Conteúdos formativos para apoiar seus estudantes a escolherem seu itinerário formativo no Ensino Médio e a traçarem objetivos alinhados aos seus interesses, valores e projetos de vida.'
);

export default solucoesEducionaisTexts;
