import { IsMobileMaxWidth } from 'core/util/WindowSize';
import Colors from 'enums/Colors';
import { Form } from 'react-bootstrap';
import styled, { keyframes } from 'styled-components';

const loopAnimation = keyframes`
 from { transform: rotate(0deg); }
 to { transform: rotate(360deg) }
`;

const StyleFormSolucoes = {
    Container: styled.div<{isBlue?: boolean}>`
        display: flex;
        padding-left: 70px;
        justify-content: center;
        align-items: center;
        min-height: 750px;
        background-color: ${({isBlue}) => isBlue ? Colors.Blue : '#fcfcfc'} ;

        @media screen and (max-width: 620px) {
            height: auto;
            padding-top: 24px;
            padding-bottom: 24px;
            padding-left: 20px;
        }

    `,

    Content: styled.div`
        display: flex;
        align-items: center;
        justify-content: end;
        height: 100%;
        width: 50%;
        margin-top: 20px;
        margin-bottom: 20px;

        @media screen and (max-width: 940px) {
            width: 100%;
        }
    `,

    Img: styled.img`
        width: 50%;
        height: 850px;
        object-fit: cover;

        @media screen and (max-width: 940px) {
            display: none;
        }
    `,

    Form: styled.div`
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 650px;
        border-radius: 24px 0px 0px 24px;
        box-shadow: 0px 4px 4px 0px #00000040;
        padding: 32px;
        background-color: #ffffff;
        position: relative;
    `,

    Title: styled.h1`
        font-family: 'Barlow';
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: -0.019em;
        text-align: left;
        color: ${Colors.Blue};
        background-color: ${Colors.Yellow};
        max-width: 540px;
        padding: 10px;
        border-radius: 10px;
    `,

    Description: styled.p`
        font-family: 'Barlow';
        font-size: 20px;
        font-weight: 400;
        line-height: 34px;
        letter-spacing: -0.019em;
        text-align: left;
        margin-top: 24px;
        color: #201e1e;
    `,

    LabelForm: styled.label`
        font-family: 'Barlow';
        font-size: 16px;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: 0.15000000596046448px;
        text-align: left;
        margin-bottom: 5px;
        margin-top: 20px;
    `,

    LoadingCitiesContainer: styled.div`
        width: 320px;
        height: 50px;
        border-bottom: 2px #5f758b solid;

        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.5;

        span {
            font-size: 1.5em;

            animation-name: ${loopAnimation};
            animation-timing-function: linear;
            animation-duration: 1.25s;
            animation-iteration-count: infinite;
        }
    `,

    InputForm: styled(Form.Control)`
        width: 300px;
        height: 50px;
    `,

    InputFormSelect: styled(Form.Control)`
        width: 300px;
        height: 50px;
        border: 1px solid #ced4da;
        border-radius: 5px;

        &:focus {
            outline: none;
        }
    `,

   
};

export default StyleFormSolucoes;
