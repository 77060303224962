import { useKeycloak } from '@react-keycloak/web';
import Loading from 'components/Loading';
import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as profileActions from './store/actions/Profile';
import * as pdpActions from './store/actions/Pdp';
import * as diaryActions from './store/actions/Diary';
import * as authenticationActions from './store/actions/Authentication';
import ABTest, { keyLocalStorage } from 'components/ab_tests/ABTest';
import { cacheShowSliderDialogKey } from 'pages/painelSocioemocional/home/context/PainelSocioProvider';

const Logout = () => {
    const { keycloak } = useKeycloak();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(profileActions.clearStore());
        dispatch(pdpActions.clearStore());
        dispatch(pdpActions.clearMetas());
        dispatch(pdpActions.clearMacro());
        dispatch(diaryActions.clearStore());
        dispatch(authenticationActions.logout());
        sessionStorage.clear();

        //não excluir versao do test A/B
        const versionTest = ABTest.getCurrentVersion();
        localStorage.clear();

        localStorage.setItem(keyLocalStorage, versionTest);

        keycloak.logout({ redirectUri: window.location.origin });
    }, []);
    return <Loading />;
};

export default Logout;
