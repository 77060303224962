import React from 'react';
import ObjectiveItem from './objectItem/ObjectiveItem';
import connectedBrain from '../../../../images/homepage/objectives/connected-brain.svg';
import hood from '../../../../images/homepage/objectives/hood.svg';
import laptop from '../../../../images/homepage/objectives/laptop.svg';
import puzzle from '../../../../images/homepage/objectives/puzzle.svg';

import {
    ObjectivesContainerStyle,
    ContentContainer,
    TitleContainer,
    TitleText,
    ItemContainer,
    CarouselDots,
    CarouselCustomPaging,
    SliderContainer,
} from './ObjectivesContainer.styled';
import Slider from 'react-slick';
import { IsMobileMaxWidth } from 'core/util/WindowSize';
import ObjectiveCard from './objectiveCard/ObjectiveCard';
import AccessButton from './button/AccessButton';

const ObjectivesContainer = () => {
    const IsMobile = () => {
        return IsMobileMaxWidth();
    };

    const mobileCarouselSettings = {
        dots: true,
        infinite: true,
        focusOnSelect: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        // eslint-disable-next-line react/display-name
        appendDots: (dots: any) => (
            <CarouselDots>
                <ul style={{ margin: '0px' }}> {dots} </ul>
            </CarouselDots>
        ),
        // eslint-disable-next-line react/display-name
        customPaging: (i: number) => <CarouselCustomPaging />,
    };

    const mobileContent = [
        {
            id: 1,
            icon: connectedBrain,
            description: 'Desenvolver competências no aspecto individual e coletivo',
        },
        {
            id: 2,
            icon: hood,
            description: 'Fortalecer o desenvolvimento integral dos estudantes',
        },
        {
            id: 3,
            icon: laptop,
            description: 'Atualizar conhecimentos para enfrentar os desafios da educação contemporânea',
        },
        {
            id: 4,
            icon: puzzle,
            description: 'Complementar sua formação e apoiar sua rotina de trabalho',
        },
    ];

    const Content = () => {
        return (
            <>
                {IsMobile() ? (
                    <SliderContainer>
                        <Slider {...mobileCarouselSettings}>
                            {mobileContent.map(item => {
                                return <ObjectiveCard key={item.id} icon={item.icon} description={item.description} />;
                            })}
                        </Slider>
                    </SliderContainer>
                ) : (
                    <ItemContainer>
                        <ObjectiveItem icon={connectedBrain} description="Desenvolver competências no aspecto individual e coletivo" />
                        <ObjectiveItem icon={hood} description="Fortalecer o desenvolvimento integral dos estudantes" />
                        <ObjectiveItem
                            icon={laptop}
                            description="Atualizar conhecimentos para enfrentar os desafios da educação contemporânea"
                        />
                        <ObjectiveItem icon={puzzle} description="Complementar sua formação e apoiar sua rotina de trabalho" />
                    </ItemContainer>
                )}
            </>
        );
    };

    return (
        <ObjectivesContainerStyle id="objetivos">
            <ContentContainer>
                <TitleContainer>
                    <TitleText>{`Para Educadores que buscam:`}</TitleText>
                </TitleContainer>

                <Content />

                {IsMobile() ? <></> : <AccessButton />}
            </ContentContainer>
        </ObjectivesContainerStyle>
    );
};

export default ObjectivesContainer;
