import { GTM_Page_Home } from 'components/GoogleTagManager';
import { MobileMaxWidth } from 'constants/Dimensions';
import { mediaOnlyScreenMaxWidthStr } from 'core/util/cssHelp';
import FullCss, { MediaQuery } from 'core/util/FullCss';
import Colors from 'enums/Colors';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';

export const EntrarHumaneButton = styled.button(() => {
    const mq = [
        new MediaQuery(mediaOnlyScreenMaxWidthStr(MobileMaxWidth), {
            transform: 'translate(0px,0px)',
            borderRadius: '6px',
        }),
        new MediaQuery('&:hover', {
            background: Colors.White,
            color: Colors.Blue,
        }),
    ];
    return new FullCss(
        {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '150px',
            height: '40px',
            border: '2px solid #FCFCFC',
            borderRadius: '50px',
            background: 'transparent',
            transform: 'translate(41px,50px)',
            color: Colors.White,
            fontSize: '14px',
            fontWeight: '700',
            letterSpacing: '0.3px',
        },
        mq
    ).getCss();
});

export const ButtonContainer = styled.div(() => {
    return new FullCss({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
    }).getCss();
});

interface IAccessButton {}

const AccessButton: FunctionComponent<IAccessButton> = ({ ...props }: IAccessButton) => {
    const history = useHistory();
    const login = () => {
        GTM_Page_Home('secao_objetivos', 'btn_click_entrar_humane');
        history.push('/login');
    };

    return (
        <ButtonContainer>
            <EntrarHumaneButton onClick={login}>{`Entrar na humane`}</EntrarHumaneButton>
        </ButtonContainer>
    );
};

export default AccessButton;
