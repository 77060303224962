import Colors from 'enums/Colors';
import { BlueButton } from 'pages/intermediarias/criatividadeDetail/versions/components/sections/Material.styled';
import styled from 'styled-components';

const StyledPromoter = {
    Container: styled.div`
        display: flex;
        justify-content: space-between;
        background-color: #003c78;
        max-height: 950px;
        color: #ffffff;

        @media screen and (max-width: 1000px) {
            max-height: max-content;
        }
        @media screen and (max-width: 760px) {
            max-height: max-content;
        }
    `,

    Img: styled.img`
        width: 30%;
        object-fit: cover;

        @media screen and (max-width: 760px) {
            display: none;
        }
    `,

    Content: styled.div`
        display: flex;
        flex-direction: column;
        width: 65%;
        padding: 32px 0px 32px 0px;

        @media screen and (max-width: 1000px) {
            width: 100%;
            padding: 24px;
        }
    `,

    Title: styled.p`
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
    `,

    SubTitle: styled.p`
        max-width: 1000px;
        line-height: 24px;
        margin-top: 24px;
        font-size: 18px;
    `,

    Info: {
        Container: styled.div`
            margin-top: 25.5px;
            display: flex;
            flex-direction: column;
            gap: 1rem;
        `,

        Title: styled.div`
            display: flex;
            align-items: center;
            gap: 0.5rem;
            font-weight: bold;
        `,

        Details: styled.p`
            display: flex;
            gap: 1.5rem;
            margin-top: 20px;
        `,

        Content: styled.div`
            display: flex;
            align-items: center;
            gap: 0.5rem; ;
        `,
    },

    Row: styled.div<{ isMobile: boolean }>`
        display: flex;
        gap: ${({ isMobile }) => !isMobile && '10rem'};
        flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
    `,

    CardExclusive: {
        Container: styled.div<{ isMobile: boolean }>`
            width: ${({ isMobile }) => (isMobile ? '100%' : '380px')};
            height: 100%;
            background-color: #ffffff;
            margin-top: 40px;
            height: 280px;
            margin-left: ${({ isMobile }) => (isMobile ? '0px' : '105px')};
            padding: 40px 20px 0px 20px;
            display: flex;
            flex-direction: column;
        `,

        Title: styled.p`
            color: ${Colors.Blue};
            font-weight: 700;
        `,

        Description: styled.p`
            margin-top: 20px;
            color: ${Colors.Blue};
            font-size: 18px;
        `,

        Center: styled.div`
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
        `,

        ButtonBlue: styled(BlueButton) <{ isMobile: boolean }>`
            width: ${({ isMobile }) => isMobile ? "80%" : "200px"};
            max-height: 100px;
            height: ${({ isMobile }) => isMobile ? "50px" : "50px"} !important;
            gap: 0.5rem;
        `,
    },
};

export default StyledPromoter;
