import styled, { keyframes } from 'styled-components';
import Colors from '../../../../enums/Colors';
import { MobileMaxWidth } from '../../../../constants/Dimensions';
import { isMobileDevice } from 'react-select/src/utils';

export const EducationalSolutionsContainerStyle = styled.div`
    width: 100%;
    padding-bottom: 30px;
    background-color: ${Colors.Blue};
`;

export const TematicaContainerStyle = styled.div<{isMobile: boolean}>`
    padding-bottom: 30px;
    background-color: ${Colors.White};
`;

export const HeaderContainer = styled.div`
    display: flex;
    padding-top: 20px;
    min-height: 200px;

    @media only screen and (max-width: ${MobileMaxWidth + 150}px) {
        min-height: 0px;
        flex-direction: column;
    }
`;

export const TitleContainer = styled.div<{ auth?: boolean }>`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 100px;
    padding: ${props => (props.auth ? '20px 0 0 104px' : '40px 20px 20px 104px')};

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        padding: ${props => (props.auth ? '0 20px 20px 20px' : '40px 20px 20px')};
    }
`;

export const DescriptionContainer = styled.div`
    flex: 1;
    padding-left: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media only screen and (max-width: ${MobileMaxWidth + 150}px) {
        margin: 20px 0 60px 0;
        padding-left: 85px;
        padding-right: 40px;
    }
`;

export const DescriptionTitle = styled.p`
    color: ${Colors.DarkBlue};
    font-weight: 700;
    font-size: 24px;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        font-size: 18px;
        line-height: 42px;
    }
`;

export const DescriptonText = styled.p`
    margin-top: 24px;
    line-height: 137%;
    letter-spacing: 0.3px;
    font-size: 20px;
    margin-top: 10px;
    white-space: pre-wrap;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        font-size: 14px;
        line-height: 22px;
        white-space: unset;
    }
`;

const fadeIn = keyframes`
  0% { opacity: 0.6; }
  80% {opacity: 0.8;}
  100% {opacity: 1;}

`;

export const CardsContainer = styled.div`
    @media only screen and (min-width: ${MobileMaxWidth - 1}px) {
        display: flex;
        align-items: center;
        margin: 0px 0px 3vh 0px;
        padding: 20px 0px 20px 9vw;
        overflow-x: auto;
        gap: 25px;

        scroll-behavior: smooth;

        &::-webkit-scrollbar {
            width: 12px;
            box-sizing: content-box;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #d8d8d8; /* color of the scroll thumb */
            border-radius: 20px; /* roundness of the scroll thumb */
            margin: 10px;
            border: 4px solid white;
            box-sizing: content-box;
        }

        &::-webkit-scrollbar-track {
            background: transparent; /* color of the tracking area */
        }
    }

    animation: ${fadeIn} 0.3s linear;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        .slick-slide {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
`;

export const TypeSelectionContainer = styled.div`
    display: flex;
    padding-left: 9vw;
    align-items: center;
    justify-content: flex-start;
    height: 50px;
    width: 100%;
    border-bottom: 1px solid ${Colors.Grey8};
`;

export const CarouselDots = styled.div`
    bottom: -40px;
    .slick-active div {
        background: ${Colors.BlueGrey};
    }
`;

export const CarouselCustomPaging = styled.div`
    width: 12px;
    height: 12px;
    border: 1px solid ${Colors.Grey5};
    border-radius: 50%;
`;

export const TitleText = styled.p`
    font-size: 32px;
    color: ${Colors.Blue};
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    line-height: 42px;
    letter-spacing: 0.1px;

    @media only screen and (max-width: 720px) {
        width: 100%;
        justify-content: center;
    }

    @media only screen and (max-width: 320px) {
        font-size: 26px;
        word-break: break-word;
    }
`;
