import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { AdminContext } from './AdminContext';
import { AdminBarraLateralItemType } from '../components/barraLateral/AdminBarraLateral';
import { KcAdminGroups } from 'models/kc/KcUserInfo';
import { useKeycloak } from '@react-keycloak/web';
import { getUserGroups } from 'core/util/KeycloakHelp';

export interface IAdminProvider {}

const AdminProvider = ({ children }: React.PropsWithChildren<IAdminProvider>) => {
    const [menu, _setMenu] = useState<AdminBarraLateralItemType>(AdminBarraLateralItemType.HOME);
    const [section, setSection] = useState<string | null>(null);
    const [userAdminGroups, setUserAdmimGroups] = useState<KcAdminGroups[]>([]);

    const { keycloak } = useKeycloak();
    const history = useHistory();

    const checkAllowedGroups = (allowedGroups: KcAdminGroups[]): boolean => {
        return userAdminGroups.some(value => allowedGroups.includes(value));
    };

    const loadGroups = async () => {
        if (keycloak.authenticated) {
            setUserAdmimGroups(await getUserGroups(keycloak));
        }
    };

    const setMenu = (value: AdminBarraLateralItemType) => {
        _setMenu(value);

        if (value === AdminBarraLateralItemType.HOME) {
            history.push('/admin');
        }
    };

    const context: AdminContext.IContext = {
        menu,
        setMenu,
        subPage: section,
        setSubPage: setSection,
        checkAllowedGroups: checkAllowedGroups,
    };

    useEffect(() => {
        loadGroups();
    }, []);

    useEffect(() => {
        if (menu == AdminBarraLateralItemType.HOME) history.push('/admin');
        else if (menu == AdminBarraLateralItemType.SOCIO) history.push('/admin/socioemocional/edit');
        else if (menu == AdminBarraLateralItemType.BIBLIO) history.push('/admin/biblioteca/posts');
        else if (menu == AdminBarraLateralItemType.CURSOS) history.push('/admin/cursos');
        else if (menu == AdminBarraLateralItemType.GESTAO_PAGINAS) history.push('/admin/gestao-paginas');
        else if (menu == AdminBarraLateralItemType.HEADER) history.push('/admin/header');
        else if (menu == AdminBarraLateralItemType.FAQ) history.push('/admin/faq');
        else if (menu == AdminBarraLateralItemType.GESTAO_USUARIOS) history.push('/admin/gestao-usuarios');
        else if (menu == AdminBarraLateralItemType.GESTAO_CERTIFICADOS) history.push('/admin/gestao-certificados');
    }, [menu]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [menu]);

    return <AdminContext.Context.Provider value={context}>{children}</AdminContext.Context.Provider>;
};

export default AdminProvider;
