import { SelectOptionType } from 'components/Select';

export const GP_defaultOption: SelectOptionType = { label: 'Selecione', value: '' };

export const GP_optionsOrder: SelectOptionType[] = [
    {
        label: 'Mais recentes',
        value: '',
    },
    {
        label: 'Ordem Alfabética',
        value: 'rc.nome:asc',
    },
];

export const GP_optionOrderNota: SelectOptionType[] = [
    {
        label: 'Todos',
        value: '',
    },
    {
        label: '0',
        value: '0',
    },
    {
        label: '1',
        value: '1',
    },
    {
        label: '2',
        value: '2',
    },
    {
        label: '3',
        value: '3',
    },
    {
        label: '4',
        value: '4',
    },
    {
        label: '5',
        value: '5',
    },
];

export const GP_optionOrderType: SelectOptionType[] = [
    {
        label: 'Todos',
        value: '',
    },
    {
        label: 'Imagem',
        value: 1,
    },
    {
        label: 'Aúdio',
        value: 2,
    },
    {
        label: 'Vídeo',
        value: 3,
    },
    {
        label: 'PDF',
        value: 4,
    },
];
