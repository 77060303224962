import DynamicHeader from 'components/dynamic_header/DynamicHeader';
import { ToasterDefault } from 'components/Toaster';
import ContactContainer from 'pages/home/components/contact/ContactContainer';
import { PesquisaContext } from 'pages/home/context/PesquisaContext';
import Menu from 'pages/menu/Menu';
import BarraPesquisa from 'pages/pesquisa/barraPesquisa/BarraPesquisa';
import React, { useContext } from 'react';

import Contato from './components/sections/Contato';
import CursoLivre from './components/sections/CursoLivre';
import Inicio from './components/sections/Inicio';
import Lancamento from './components/sections/Lancamento';
import Material from './components/sections/Material';
import Outros from './components/sections/Outros';
import { Section, Main } from './CriatividadeUpdated.styled';

export interface ICriatividadeUpdated {}

const CriatividadeUpdated = ({}: ICriatividadeUpdated) => {
    const { isSearchView } = useContext(PesquisaContext.Context);

    return (
        <>
            {/*  <Menu homepage homeIntermediate /> */}
            <DynamicHeader />

            <Main>
                <Section id="inicio">
                    <Inicio />
                </Section>

                {/* <Section id="contato" color="#003C78;">
                    <Contato />
                </Section> */}

                <Section id="lancamento" padding="0">
                    <Lancamento />
                </Section>

                <Section id="curso_livre">
                    <CursoLivre />
                </Section>

                <Section id="material" color="#FFCA00;" padding="0">
                    <Material />
                </Section>

                <Section id="contato" color="#003C78;" style={{ height: 'fit-content' }}>
                    <Contato />
                </Section>

                <Section id="outros">
                    <Outros />
                </Section>

                <ContactContainer />
                {ToasterDefault()}
            </Main>

            {isSearchView && <BarraPesquisa />}
        </>
    );
};

export default CriatividadeUpdated;
