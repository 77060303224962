import TipoRedirecionamento from 'enums/TipoRedirecionamento';
import CardModulos, { StatusCardModulo } from 'models/secoes/CardModulos';
import instanciaCardModulo from 'pages/intermediarias/alfabetizacao360Detail/subcomponents/TrilhaApronfundadaInfoCard';

export const ItensSecaoJornadasCS: CardModulos[] = [
    instanciaCardModulo({
        title: 'Benefícios do Desenvolvimento Socioemocional para os estudantes',
        description:
            'Esse primeiro curso é uma introdução para os demais. Nele você conhecerá todos os conceitos e teoria que embasam os demais cursos dessa série.',
        ordem: 1,
        time: '1h30',
        status: StatusCardModulo.DISPONIVEL,
        isCertificated: true,
        ordemOculto: true,
        tipoRedirecionamento: TipoRedirecionamento.IGNORAR,
        link: '/recursos/cursos/beneficios-do-desenvolvimento-socioemocional-para-os-estudantes',
    }),
    instanciaCardModulo({
        title: 'Violência escolar e bullying',
        description:
            'Apresentaremos os conceitos de violência escolar e bullying, seus impactos na vida dos estudantes e como o desenvolvimento de competências socioemocionais podem influenciar nesses impactos. O curso traz exemplos de boas práticas escolares para trabalhar o tema.',
        ordem: 2,
        time: '1h30',
        isCertificated: true,
        status: StatusCardModulo.DISPONIVEL,
        ordemOculto: true,
        tipoRedirecionamento: TipoRedirecionamento.IGNORAR,
        link: '/recursos/cursos/violencia-escolar-e-bullying',
    }),
    instanciaCardModulo({
        title: 'Benefícios do Desenvolvimento Socioemocional para a Saúde Mental dos Estudantes',
        description:
            'A saúde mental pode impactar diretamente na vida escolar dos estudantes. Nesse curso explicaremos quais podem ser esses impactos e qual o papel do desenvolvimento das competências socioemocionais para a saúde mental dos estudantes.',
        ordem: 3,
        time: '1h30',
        isCertificated: true,
        status: StatusCardModulo.DISPONIVEL,
        ordemOculto: true,
        tipoRedirecionamento: TipoRedirecionamento.IGNORAR,
        link: '/recursos/cursos//beneficios-do-desenvolvimento-socioemocional-para-a-saude-mental-dos-estudantes',
    }),
    instanciaCardModulo({
        title: 'Pertencimento Escolar',
        description:
            'O sentimento de pertencimento escolar está intimamente relacionado ao desenvolvimento de competências socioemocionais pelos estudantes. O curso analisa alguns relatos para entendermos mais como esses dois temas se conectam.',
        ordem: 4,
        time: '1h30',
        isCertificated: true,
        ordemOculto: true,
        link: '/competencias-socioemocionais',
    }),
    instanciaCardModulo({
        title: 'Desempenho Escolar',
        description:
            'Autogestão, abertura ao novo e amabilidade são competências socioemocionais associadas à maior ou menor capacidade dos estudantes alcançarem um bom desempenho escolar. Esse curso vai explorar essa relação a partir da análise de boas práticas.',
        ordem: 5,
        time: '1h30',
        isCertificated: true,
        ordemOculto: true,
        link: '/competencias-socioemocionais',
    }),
];

export default ItensSecaoJornadasCS;
