import { jsonProperty, Serializable } from 'ts-serializable';
import { SecaoBanner } from './SecaoBanner';
import { SecaoCarrossel } from './SecaoCarrossel';
import { SecaoCardCategoria } from './SecaoCardCategoria';
import { SecaoSimplesComOuSemImagem } from './SecaoSimplesComOuSemImagem';
import { TipoModeloSecao } from './TipoModeloSecao';

class PaginaIntermediariaModeloSecaoDTO extends Serializable {
    @jsonProperty(Number)
    codigo: number = -1;

    @jsonProperty(Number)
    codigoPaginaIntermediaria: number = -1;

    @jsonProperty(TipoModeloSecao)
    tipoModeloSecao: TipoModeloSecao | undefined;

    @jsonProperty(SecaoBanner, null)
    secaoBanner: SecaoBanner | null = null;

    @jsonProperty(SecaoSimplesComOuSemImagem, null)
    secaoSimplesImagem: SecaoSimplesComOuSemImagem | null = null;

    @jsonProperty(SecaoSimplesComOuSemImagem, null)
    secaoSimples: SecaoSimplesComOuSemImagem | null = null;

    @jsonProperty(SecaoCardCategoria, null)
    secaoCardCategoria: SecaoCardCategoria | null = null;

    @jsonProperty(SecaoCarrossel, null)
    secaoCarrossel: SecaoCarrossel | null = null;

    @jsonProperty(Number)
    ordem: number = -1;

    constructor() {
        super();
    }
}

export default PaginaIntermediariaModeloSecaoDTO;
