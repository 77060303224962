import DynamicHeader from 'components/dynamic_header/DynamicHeader';
import React, { useEffect } from 'react';

import Styled from './Solucoes.styled';
import Apresentation from './components/solucoes/Apresentation';
import MultiSolucoes from './components/solucoes/MultiSolucoes';
import ParceirosImplementadores from './components/solucoes/ParceirosImplementadores';
import { ContactItem } from 'pages/home/components/contact/ContactContainer.styled';
import FormSolucoes from './components/solucoes/FormSolucoes';
import ContactContainer from 'pages/home/components/contact/ContactContainer';
import { IsCustomMaxWidth } from 'core/util/WindowSize';

const Solucoes = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const isMobile = IsCustomMaxWidth(480);


    return (
        <>
            <DynamicHeader />
            <Styled.Container>
                <Apresentation />
                <MultiSolucoes height={'1000px'} />
                <ParceirosImplementadores />
                <FormSolucoes isBlue preDefinition={false} />
                <ContactContainer isLight />
            </Styled.Container>
        </>
    );
};

export default Solucoes;
