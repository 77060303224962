import { PrimaryButton, OutlineButtonInvert, OutlineButton } from 'components/UI/Buttons';
import { MobileMaxWidth } from 'constants/Dimensions';
import styled from 'styled-components';

const Alfabetizacao360DetailStyled = {
    Main: styled.main`
        @media only screen and (max-width: 1200px) {
            padding: 60px 0 0 0;
            margin-top: 0px;
        }
    `,

    Section: styled.section<{ color?: string; padding?: string }>`
        display: flex;
        flex-direction: column;
        height: 600px;
        padding-top: 10px;
        padding-left: ${props => (props.padding ? props.padding : '10%')};
        background-color: ${props => (props.color ? props.color : '#FFFFFF')};
        position: relative;

        @media only screen and (max-width: 1200px) {
            padding-left: 0;
            height: 100%;
        }
    `,

    SectionTematica: {
        Container: styled.div`
            color: white;
            background-color: #003c78;
            padding: 50px 10%;

            @media only screen and (max-width: ${MobileMaxWidth}px) {
                padding: 30px 20px;
            }
        `,

        Content: styled.div`
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: fit-content;
            color: #fff;
            margin: 0 auto;
        `,

        ContentTexts: styled.div`
            margin: 20px 0;
            height: fit-content;

            @media only screen and (max-width: ${MobileMaxWidth}px) {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        `,

        PreTitle: styled.div`
            font-family: Barlow;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            margin-bottom: 15px;

            @media only screen and (max-width: ${MobileMaxWidth}px) {
                margin-top: 20px;
                width: 100%;
                text-align: left;
            }
        `,

        Title: styled.div`
            font-family: Barlow;
            font-size: 48px;
            font-style: normal;
            font-weight: 700;
            margin: 10px 0;
            margin-bottom: 15px;

            @media only screen and (max-width: ${MobileMaxWidth}px) {
                width: 100%;
                text-align: left;
                margin: 0;
                margin-bottom: 15px;
                font-size: 36px;
            }
        `,

        Text: styled.p`
            font-family: Barlow;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: white;
            max-width: 630px;
            margin-bottom: 40px;
        `,

        PrimaryButton: styled(PrimaryButton)`
            width: fit-content;
            padding: 0 15px;

            @media only screen and (max-width: ${MobileMaxWidth}px) {
                margin: 0 auto;
            }
        `,

        Image: styled.img`
            height: 350px;
            width: auto;

            @media only screen and (max-width: ${MobileMaxWidth}px) {
                height: 250px;
                margin: 20px auto;
            }
        `,
    },

    SectionGuia: {
        Container: styled.div`
            color: white;
            background-color: #ffca00;
            padding: 50px 10%;

            @media only screen and (max-width: ${MobileMaxWidth}px) {
                padding: 30px 20px;
            }
        `,

        Content: styled.div`
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: fit-content;
            color: #fff;
            margin: 0 auto;

            @media only screen and (max-width: ${MobileMaxWidth}px) {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                max-width: 100%;
            }
        `,

        ContentTexts: styled.div`
            margin: 20px 0;
            height: fit-content;

            iframe {
                border: 7px #00a8e9 solid;
                border-radius: 4px;
            }

            @media only screen and (max-width: ${MobileMaxWidth}px) {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                iframe {
                    margin-bottom: 15px;
                }
            }
        `,

        ContentBottom: styled.div`
            display: flex;
            align-items: center;
            margin-top: 10px;
            cursor: pointer;

            span {
                margin-right: 15px;
                font-family: Barlow;
                font-size: 18px;
                font-style: normal;
                font-weight: bolder;
            }
        `,

        PreTitle: styled.div`
            background-color: #003c78;
            color: white;
            width: fit-content;
            padding: 8px 12px;
            border-radius: 15px;
            font-family: Barlow;
            font-size: 18px;
            font-weight: 400;
            max-height: 100%;
        `,

        Title: styled.div`
            font-family: Barlow;
            font-size: 48px;
            font-style: normal;
            font-weight: 700;
            margin: 10px 0;
            margin-bottom: 15px;
            color: #003c78;

            @media only screen and (max-width: ${MobileMaxWidth}px) {
                max-width: 100%;
                text-align: left;
                margin: 0;
                margin-bottom: 15px;
                font-size: 36px;
            }
        `,

        Text: styled.p`
            font-family: Barlow;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            color: #003c78;
            max-width: 630px;
            margin-bottom: 40px;
        `,

        PrimaryButton: styled(PrimaryButton)`
            width: fit-content;
            padding: 0 15px;

            @media only screen and (max-width: ${MobileMaxWidth}px) {
                margin: 0 auto;
            }
        `,

        Cards: styled.div`
            padding-top: 190px;
            padding-left: 50px;

            @media only screen and (max-width: ${MobileMaxWidth}px) {
                padding: 0;
            }
        `,

        Icon: styled.img``,

        CardContainer: styled.div`
            display: flex;
            justify-content: space-between;
            width: 600px;
            padding: 12px 15px;
            align-items: center;

            border-radius: 8px;
            background: #fcfcfc;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            color: #003c78;
            margin-bottom: 15px;

            @media only screen and (max-width: ${MobileMaxWidth}px) {
                display: flex;
                width: 340px;
                flex-direction: column;
                margin-bottom: 15px;
            }
        `,

        CardTitle: styled.h1`
            font-family: Barlow;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            //margin-right: 15px;
            min-width: 160px;

            @media only screen and (max-width: ${MobileMaxWidth}px) {
                flex-grow: 1;
            }
        `,

        CardText: styled.p`
            font-family: Barlow;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            margin-right: 15px;
            margin-bottom: 20px;

            @media only screen and (max-width: ${MobileMaxWidth}px) {
                width: 100%;
                margin-top: 10px;
                padding: 0 10px;
            }
        `,
    },

    GoBackButton: styled(OutlineButtonInvert)`
        border-color: white;
        font-size: 14px;
        padding: 10px 15px;
        width: fit-content;
        height: fit-content;
        box-shadow: none;

        span {
            margin-right: 7px;
        }

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            padding: 15px;
        }
    `,
};

export default Alfabetizacao360DetailStyled;
