import React from 'react';
import AdminHomeConteudo from 'models/admin/AdminHomeConteudo';
import Image_1 from '../../../../../images/homepage/statements/Image_1.svg';

import {
    StatementCarouselCardStyle,
    ProfileContainer,
    DescriptionContainer,
    DescriptionText,
    IdenficationContainer,
    NameText,
    FunctionText,
    AvatarSvg,
    HumaneButton,
    ButtonContainer,
} from './StatementCarouselCard.styled';
import { useHistory } from 'react-router-dom';
import { IsMobileMaxWidth } from 'core/util/WindowSize';
import { CSSProperties } from 'styled-components';
import { GTM_Page_Home } from 'components/GoogleTagManager';

interface IStatementCarouselCard {
    statement: AdminHomeConteudo;
    isActive: boolean;
}

const StatementCarouselCard = ({ statement, isActive }: IStatementCarouselCard) => {
    const history = useHistory();

    const goToHumane = () => {
        GTM_Page_Home('secao_depoimentos', 'btn_click_acessar_humane');
        history.push('/login');
    };

    function StylishComponent(isActive: boolean): CSSProperties {
        let style: CSSProperties;
        if (!isActive && !IsMobileMaxWidth()) {
            style = {
                display: 'none',
            };
        } else {
            style = {};
        }
        return style;
    }

    return (
        <StatementCarouselCardStyle>
            <ProfileContainer>
                <AvatarSvg src={statement.precover ?? ''} />

                <IdenficationContainer>
                    <NameText>{statement.title}</NameText>
                    <FunctionText>{statement.occupation}</FunctionText>
                </IdenficationContainer>
            </ProfileContainer>
            <DescriptionContainer>
                <DescriptionText>&quot;{statement.content}&quot;</DescriptionText>
            </DescriptionContainer>
            <ButtonContainer>
                <HumaneButton style={StylishComponent(isActive)} onClick={goToHumane}>{`Acesse a humane`}</HumaneButton>
            </ButtonContainer>
        </StatementCarouselCardStyle>
    );
};

export default StatementCarouselCard;
