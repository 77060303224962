import React, { useContext, useEffect, useState } from 'react';

import styled from 'styled-components';

import Menu from '../../menu/Menu';
import { MobileMaxWidth } from '../../../constants/Dimensions';
import ContactContainer from '../../home/components/contact/ContactContainer';
import SocioemocionaisEducadores from './components/SocioemocionaisEducadores';
import { Helmet } from 'react-helmet';
import { PesquisaContext } from 'pages/home/context/PesquisaContext';
import BarraPesquisa from 'pages/pesquisa/barraPesquisa/BarraPesquisa';
import DynamicHeader from 'components/dynamic_header/DynamicHeader';

const EducationalSolutionsDetailStyle = styled.div``;
const Container = styled.section`
    margin-top: 86px;
    position: relative;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        margin-top: 60px;
    }
`;

const EducationalSolutionsDetail = () => {
    const [socioemocionalEducador, setSocioemocionalEducador] = useState<boolean>(true); //TODO: está sempre true
    const { isSearchView } = useContext(PesquisaContext.Context);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <EducationalSolutionsDetailStyle>
            <Helmet>
                <title>Competências Socioemocionais de Professores - Avaliação</title>
                <meta
                    name="description"
                    content="Acesse o instrumento para fazer sua autoavaliação e traçar um Plano de Desenvolvimento com foco nas suas habilidades socioemocionais"
                    data-react-helmet="true"
                />
            </Helmet>

            {/* <Menu homepage homeIntermediate /> */}
            <DynamicHeader />

            <Container>{socioemocionalEducador && <SocioemocionaisEducadores />}</Container>

            {isSearchView && <BarraPesquisa />}
        </EducationalSolutionsDetailStyle>
    );
};

export default EducationalSolutionsDetail;
