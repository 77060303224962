import { GradientButton, GradientSquareButton } from 'components/UI/Buttons';
import { MobileMaxWidth } from 'constants/Dimensions';
import Colors from 'enums/Colors';
import styled from 'styled-components';

const SimpleWithImageStyled = {
    Container: styled.div<{ side?: string }>`
        height: 530px;
        width: 100%;

        display: flex;
        flex-direction: ${props => (props.side === 'E' ? 'row' : 'row-reverse')};

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            height: 100%;
        }
    `,

    PaddingImage: styled.img`
        object-fit: cover;
        height: 100%;
        width: 30%;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            display: none;
        }
    `,

    ContentContainer: styled.div`
        background: #f6f6f6;

        flex-grow: 1;

        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        width: 70%;
        padding: 30px 0px 30px 0px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            width: 100%;
            padding: 50px 15px 50px 15px;
            background: rgba(246, 246, 246, 0.85);
        }
    `,

    Title: styled.p`
        color: #183f73;
        font-family: Barlow;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 43px;
        text-align: center;
        width: 100%;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            font-size: 1.5rem;
            text-align: center;
            max-width: 315px;
            padding-bottom: 30px;
        }
    `,

    TextBox: styled.div`
        width: 60%;
        max-width: 750px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            height: 100%;
            width: 100%;
            max-width: 315px;
            overflow: auto;
            text-align: center;
        }

        &::-webkit-scrollbar {
            width: 12px;
            box-sizing: content-box;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #d8d8d8; /* color of the scroll thumb */
            border-radius: 20px; /* roundness of the scroll thumb */
            margin: 10px;
            border: 4px solid white;
            box-sizing: content-box;
        }

        &::-webkit-scrollbar-track {
            background: transparent; /* color of the tracking area */
        }
    `,

    Text: styled.p`
        text-align: center;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: #2e2e2e;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            font-size: 14px;
            max-width: 315px;
            text-align: center;
            width: 100%;
        }
    `,

    ButtonContainer: styled.div`
        width: 100%;
        text-align: center;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            display: flex;
            padding-top: 30px;
            align-items: flex-start;
            max-width: 315px;
            display: flex;
            justify-content: center;
        }
    `,

    GradientButton: styled(GradientSquareButton)`
        width: 358px;

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            width: 185px;
            height: 35px;
            transform: translate(-65px, -50px);
        }
    `,

    Button: styled.button`
        color: white;
        font-weight: 700;
        font-size: clamp(12px, 3vw, 14px);
        line-height: 18px;
        letter-spacing: 0.3px;
        width: 358px;
        padding: 12px 13px;
        gap: 10px;
        background: ${Colors.Blue};
        border: 1px solid ${Colors.Blue};
        border-radius: 50px;

        &:hover {
            background: ${Colors.DarkBlue3};
            color: ${Colors.White};
            border: 1px solid ${Colors.DarkBlue3};
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }

        @media only screen and (max-width: ${MobileMaxWidth}px) {
            width: 250px;
            height: 100%;
            line-height: unset;
        }
    `,
};

export default SimpleWithImageStyled;
