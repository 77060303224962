import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Slider from 'react-slick';
import Styled from './CursoLivre.styled';

import { SvgIcon, SliderContainer, ModuloContainer, Container, ArrowButton } from './CursoLivre.styled';
import { CardCursoLivre } from './CardCursoLivre';
import { CardTrilha } from './TrilhaAprofundada';
import { TbCurrencyDollarOff } from 'react-icons/tb';
import { AiOutlineClockCircle } from 'react-icons/ai';

export interface ICursoLivre {}

const CursoLivre = ({}: ICursoLivre) => {
    const [isMobile, setIsMobile] = useState(false);
    const history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0);
        setIsMobile(window.screen.width <= 1200);

        window.addEventListener('resize', () => {
            setIsMobile(window.screen.width <= 1200);
        });

        return () => window.removeEventListener('resize', () => {});
    }, []);

    const NextArrow = ({ ...props }: any) => {
        return (
            <ArrowButton next={true} onClick={props.onClick}>
                <SvgIcon>
                    <path
                        d="M1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L5.58579 7L0.292893 12.2929C-0.0976311 12.6834 -0.0976311 13.3166 0.292893 13.7071C0.683417 14.0976 1.31658 14.0976 1.70711 13.7071L7.70711 7.70711C8.09763 7.31658 8.09763 6.68342 7.70711 6.29289L1.70711 0.292893Z"
                        fill="#00A8E9"
                    />
                </SvgIcon>
            </ArrowButton>
        );
    };

    const PreviousArrow = ({ ...props }: any) => {
        return (
            <ArrowButton next={false} onClick={props.onClick}>
                <SvgIcon>
                    <path
                        d="M6.29289 13.7071C6.68342 14.0976 7.31658 14.0976 7.70711 13.7071C8.09763 13.3166 8.09763 12.6834 7.70711 12.2929L2.41421 7L7.70711 1.70711C8.09763 1.31658 8.09763 0.683417 7.70711 0.292891C7.31658 -0.0976325 6.68342 -0.0976325 6.29289 0.292891L0.292894 6.29289C-0.0976308 6.68342 -0.0976309 7.31658 0.292894 7.7071L6.29289 13.7071Z"
                        fill="#00A8E9"
                    />
                </SvgIcon>
            </ArrowButton>
        );
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <NextArrow />,
        prevArrow: <PreviousArrow />,
    };

    const openUrl = () => {
        history.push('/recursos/trilhas/trilha-de-alfabetizacao');
    };

    return (
        <Container>
            <Styled.Title>{'Trilha de Alfabetização - INTRODUTÓRIA'.toUpperCase()}</Styled.Title>
            <Styled.SubTitle>
                Saiba mais sobre a importância das políticas públicas de alfabetização, os processos de aprendizagem através de diferentes
                perspectivas técnicas e estratégias de ensino e a importância da gestão de política pública de alfabetização, através do olhar de
                diferentes especialistas e de forma gratuita.
            </Styled.SubTitle>
            <Styled.Status.Cotainer>
                <Styled.Status.Content>
                    <TbCurrencyDollarOff color="#FFCA00" /> Gratuito
                </Styled.Status.Content>
                <Styled.Status.Content>
                    <AiOutlineClockCircle color="#FFCA00" /> 7 horas
                </Styled.Status.Content>
            </Styled.Status.Cotainer>
            {!isMobile ? (
                <ModuloContainer>
                    {CardCursoLivre.map((card, index) => (
                        <CardTrilha key={index} dados={card} />
                    ))}
                </ModuloContainer>
            ) : (
                <SliderContainer>
                    <Slider {...settings}>
                        {CardCursoLivre.map((card, index) => (
                            <CardTrilha key={index} dados={card} />
                        ))}
                    </Slider>
                </SliderContainer>
            )}
        </Container>
    );
};

export default CursoLivre;
