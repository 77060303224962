import TipoRedirecionamento from 'enums/TipoRedirecionamento';
import { jsonProperty, Serializable } from 'ts-serializable';

export class SecaoBanner extends Serializable {
    @jsonProperty(Number)
    codigo: number = -1;

    @jsonProperty(String)
    titulo: string = '';

    @jsonProperty(String)
    descricao: string = '';

    @jsonProperty(String)
    link: string = '';

    @jsonProperty(String)
    textoBotaoA: string = '';

    @jsonProperty(String)
    textoBotaoB: string = '';

    @jsonProperty(Boolean)
    paginaLateral: boolean = false;

    @jsonProperty(String)
    tipoRedirecionamento: TipoRedirecionamento = TipoRedirecionamento.LOGIN;

    @jsonProperty(String)
    usuarioResponsavel: string = '';

    @jsonProperty(String)
    anexoBase64: string = '';

    @jsonProperty(String)
    filePath: string = '';
}

export default SecaoBanner;
