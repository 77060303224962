import React, { useEffect } from 'react';
import GlobalStyled from '../../Solucoes.styled';
import DynamicHeader from 'components/dynamic_header/DynamicHeader';
import Overview from 'pages/solucoes/components/generics/Overview';
import InPractice, { TypeCard } from 'pages/solucoes/components/generics/InPractice';
import SupportMaterial from 'pages/solucoes/components/generics/SupportMaterial';
import FormSolucoes from 'pages/solucoes/components/solucoes/FormSolucoes';
import TipoSolucoesEnum from 'enums/TipoSolucoesEnum';
import Imagem from '../../assets/imagemTeste.svg';
import ImagemAcelera from '../../assets/aceleraBrasil/logo.png';

//Material
import imagem_01 from '../../assets/aceleraBrasil/material/imagem_01.png';

//Material InPratice
import imagem_inpratice_01 from '../../assets/aceleraBrasil/inPratice/img_01.jpg';
import imagem_inpratice_02 from '../../assets/aceleraBrasil/inPratice/img_02.jpg';
import imagem_inpratice_03 from '../../assets/aceleraBrasil/inPratice/img_03.jpg';
import imagem_inpratice_04 from '../../assets/aceleraBrasil/inPratice/img_04.jpg';
import imagem_inpratice_05 from '../../assets/aceleraBrasil/inPratice/img_05.jpg';

import ContactContainer from 'pages/home/components/contact/ContactContainer';

const AceleraBrasil = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <DynamicHeader />
            <GlobalStyled.Container>
                <Overview
                    dados={{
                        title: 'Acelera Brasil',
                        descricao: `
                          O Acelera Brasil foi o primeiro programa de educação formal implementado pelo Instituto Ayrton Senna em municípios brasileiros, 
                          que tiveram início em 1997 com o objetivo de combater a distorção idade-série e corrigir o fluxo escolar dos alunos. 
                          A solução educacional passou por um processo de atualização em 2023, considerando o cenário atual da educação e a integração com a BNCC.
                        `,
                        objetivo: `
                         O objetivo do Acelera Brasil é corrigir ou reduzir a distorção idade-ano com
                            a aceleração no fluxo escolar de estudantes matriculados do 3º ao 5º
                            ano, prioritariamente (salto possível de até dois anos escolares).
                         `,
                        publicoAlvo: '3º ao 5º do Ensino Fundamental.',
                        avaliacao: 'Testes Diagnósticos de Alfabetização (enturmação).',
                        formacao: `
                        Encontros formativos presenciais para professores e mediadores (carga
                        horária – 24 horas).
                        `,
                        acompanhamento: `
                            Dados quantitativos e qualitativos, ancorados em indicadores e
                            metas previamente definidas.
                        `,
                        atores: `
                        Estudante, Professor (gestão da sala de aula: ensino/ aprendizagem),
                        Mediador, Coordenador Pedagógico / Diretor (gestão da rotina escolar), Secretaria
                        de Educação (gestão da política educacional).
                        `,
                        elementos: `
                           (I) Material didático do aluno e do professor, com fundamentos da
                            proposta e orientações específicas para o trabalho aula a aula, e base em currículo
                            para promover o desenvolvimento integral; (II) Coordenação e gerenciamento; (III)
                            Formação presencial e a distância (EAD), inicial e continuada; (IV) Sistema de
                            monitoramento e avaliação, gerenciamento dos indicadores de aprendizagem; e (V)
                            Apoio de agentes técnicos.
                        
                        `,
                        img: ImagemAcelera,
                    }}
                />
                <InPractice
                    items={[
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_01,
                            credits: {
                                credits: 'Diego Villamarim',
                                local: 'Ribeirão Pires',
                                ano: '2019',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_02,
                            credits: {
                                credits: 'Diego Villamarim',
                                local: 'Ribeirão Pires',
                                ano: '2019',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_03,
                            credits: {
                                credits: 'Diego Villamarim',
                                local: 'Ribeirão Pires',
                                ano: '2019',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_04,
                            credits: {
                                credits: 'Diego Villamarim',
                                local: 'Ribeirão Pires',
                                ano: '2019',
                            },
                        },
                        {
                            type: TypeCard.IMAGEM,
                            url: imagem_inpratice_05,
                            credits: {
                                credits: 'Flávio Araújo',
                                local: 'Santarém',
                                ano: '2018',
                            },
                        },
                    ]}
                    description={`
                    Quer conhecer um pouco mais sobre como o Acelera Brasil acontece na prática?<br/><br/>
                    Navegue pelas imagens e vídeos e conheça algumas das redes onde a solução é implementada atualmente.<br/><br/>
                    Sua rede pode ser a próxima a implementar a solução Acelera Brasil e ter as imagens compartilhadas aqui na humane. Que tal? 
                    `}
                />
                <SupportMaterial
                    description="
                 No Acelera Brasil o professor recebe cinco livros: Livro 1, 2, 3 e 4 e o Livro do Professor e os estudantes recebem quatro livros: Livro 1, 2, 3 e 4. 
                    Também são disponibilizados: Subsídios para o professor para o período que antecede o início das aulas; Orientações para aplicação de Teste Diagnóstico e Sistemática de Acompanhamento para monitorar 
                    e gerenciar o ensino e a aprendizagem por meio de dados.
                "
                    img={imagem_01}
                />

                <FormSolucoes isBlue tipoSolucao={TipoSolucoesEnum.ACELERA_BRASIL} preDefinition />
                <ContactContainer isLight />
            </GlobalStyled.Container>
        </div>
    );
};

export default AceleraBrasil;
