import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import VideoDialog from './dialog/VideoDialog';
import ArrowDown from '../../../../images/homepage/arrow-down-blue.svg';
import ArrowDownWhite from '../../../../images/homepage/arrow-down-white.svg';
import HumaneLogo from '../../../../images/homepage/humaneLogo.svg';
import BlueHumaneLogo from '../../../../images/homepage/blueHumaneLogo.svg';
import {
    BannerContainerStyle,
    BannerStyle,
    ContentContainer,
    TitleStyle,
    ContentStyle,
    ButtonsContainer,
    ArrowSvg,
    Layer,
    SvgContainer,
    ImgSvgLogo,
    SaibaMaisButton,
    AcessarPlataformaButton,
    CirculoBanner,
    TextContainer,
    NavHashLinkStyle,
    BannerSection,
    SplitContainer,
    TransparentButton,
    YellowButton,
    ButtonsBox,
    LogoContainer,
    LogoImg,
    ImgLateral,
    LeftContainer,
    ImgMobile,
    BlueCircle,
} from './BannerContainer.styled';
import AdminHomeConteudoService from 'core/http/service/admin/AdminHomeConteudo.service';
import { bucketS3, caminhoAnexosHomepage } from 'constants/EnvironmentData';
import { GTM_Page_Home } from 'components/GoogleTagManager';
import TextoApresentacao from 'components/TextoApresentacao';

const scrollWithOffset = (el: any) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -102;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
};

const BannerContainer = () => {
    const history = useHistory();

    const login = (location: string) => {
        GTM_Page_Home('secao_banner', `btn_click_acessar_plataforma`);
        history.push(location);
    };

    const [title, setTitle] = useState<string | null>(null);
    const [imgBanner, setImgBanner] = useState<string | null>(null);
    const [contentBanner, setContentBanner] = useState<string | null>(null);
    const [videoDialogVisible, setVideoDialogVisible] = useState(false);
    const [texto_botao_a, setTextoBotaoA] = useState(null);
    const [texto_botao_b, setTextoBotaoB] = useState(null);
    const [link_botao_b, setLinkBotaoB] = useState(null);
    const [isPaginaLateral, setIsPaginaLateral] = useState(false);

    const onCloseVideoDialog = () => {
        setVideoDialogVisible(false);
    };

    const onShowVideoDialog = () => {
        GTM_Page_Home('secao_banner', 'btn_click_saiba_mais');
        setVideoDialogVisible(true);
    };

    const getS3BaseUrl = (): string => {
        return bucketS3() + caminhoAnexosHomepage();
    };

    const fetchContent = async () => {
        const service = new AdminHomeConteudoService();
        let { data, status } = await service.buscaConteudo('CAPA');
        data = data[0];

        if (status == 200 && data) {
            setTitle(data.titulo);
            setContentBanner(data.conteudo);
            setTextoBotaoA(data.textoBotaoA);
            setTextoBotaoB(data.textoBotaoB);
            setLinkBotaoB(data.linkInterno);
            setIsPaginaLateral(data.paginaLateral);

            const response = await service.buscarAnexoConteudo(data.id, false);
            setImgBanner(getS3BaseUrl() + response.data.filePath);
        }
    };

    useEffect(() => {
        fetchContent();
    }, []);

    return (
        <>
            {!isPaginaLateral ? (
                <BannerContainerStyle id="inicio">
                    <BannerStyle svgSource={imgBanner ?? ''}>
                        <Layer />
                        <ContentContainer>
                            <SvgContainer>
                                <ImgSvgLogo src={HumaneLogo} />
                            </SvgContainer>

                            <TextContainer>
                                <TitleStyle>{title}</TitleStyle>
                                <ContentStyle>{contentBanner}</ContentStyle>
                            </TextContainer>

                            <ButtonsContainer>
                                <SaibaMaisButton onClick={onShowVideoDialog}>{texto_botao_a ?? `Saiba mais`}</SaibaMaisButton>

                                <AcessarPlataformaButton
                                    onClick={() => {
                                        login(link_botao_b ?? '/login');
                                    }}
                                >
                                    {texto_botao_b ?? `Acessar a plataforma`}
                                </AcessarPlataformaButton>
                            </ButtonsContainer>
                        </ContentContainer>
                        <NavHashLinkStyle
                            smooth
                            scroll={el => {
                                scrollWithOffset(el);
                            }}
                            to="#objetivos"
                        >
                            <CirculoBanner>
                                <ArrowSvg src={ArrowDown} />
                            </CirculoBanner>
                        </NavHashLinkStyle>
                    </BannerStyle>
                </BannerContainerStyle>
            ) : (
                <BannerContainerStyle id="inicio">
                    <BannerSection>
                        <SplitContainer>
                            <LeftContainer>
                                <ImgMobile src={imgBanner ?? ''} />
                                <LogoContainer>
                                    <LogoImg src={BlueHumaneLogo} />
                                </LogoContainer>
                                <TextoApresentacao
                                    style={{
                                        container: { maxWidth: '650px' },
                                        titulo: { fontSize: 'clamp(24px, 6.5vw, 36px)' },
                                        subtitulo: { fontSize: '16px', color: 'black' },
                                    }}
                                    tipo=""
                                    titulo={title ?? ''}
                                    subtitulo={contentBanner ?? ''}
                                >
                                    <ButtonsBox>
                                        <YellowButton
                                            onClick={() => {
                                                login(link_botao_b ?? '/login');
                                            }}
                                        >
                                            {texto_botao_b ?? `Acessar a plataforma`}
                                        </YellowButton>

                                        <TransparentButton onClick={onShowVideoDialog}>{texto_botao_a ?? `Saiba mais`}</TransparentButton>
                                    </ButtonsBox>
                                </TextoApresentacao>
                            </LeftContainer>
                            <ImgLateral src={imgBanner ?? ''} />
                        </SplitContainer>
                    </BannerSection>

                    <NavHashLinkStyle
                        smooth
                        scroll={el => {
                            scrollWithOffset(el);
                        }}
                        to="#objetivos"
                    >
                        <BlueCircle>
                            <ArrowSvg src={ArrowDownWhite} />
                        </BlueCircle>
                    </NavHashLinkStyle>
                </BannerContainerStyle>
            )}
            <VideoDialog videoUrl="mYujPJc7dwQ" visible={videoDialogVisible} onCloseCallback={onCloseVideoDialog} />
        </>
    );
};

export default BannerContainer;
