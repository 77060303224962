import { findLastIndex } from 'lodash';
import styled from 'styled-components';
import Colors from '../../../../enums/Colors';
import { MobileMaxWidth } from '../../../../constants/Dimensions';
import { PrimaryButton } from 'components/UI/Buttons';

export const ContactContainerStyle = styled.div<{isLight?: boolean}>`
    background-color: ${({isLight}) => isLight ? Colors.White :Colors.Blue};
    display: flex;
    flex-direction: column;
    padding-right: 8vw;
    padding-left: 8vw;
    padding-top: 86px;
    padding-bottom: 50px;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        padding: 50px 12vw 60px 12vw;
    }
`;

export const Contents = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10vw;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        flex-direction: column;
        gap: 0vw;
    }
`;

export const ContentContainer = styled.div`
    display: flex;
    padding-top: 86px;
    flex-direction: column;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        padding-top: 60px;
        min-height: unset;
    }
`;

export const ContentItemsContainer = styled.div`
    margin-top: 20px;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 300;
    line-height: 120%;
    letter-spacing: 0.01em;
`;

interface IDefaultText {
    fontSize: string;
    color: Colors;
}

export const DefaultText = styled.p<IDefaultText>`
    color: ${props => props.color};
    font-size: ${props => props.fontSize};
`;

export const Title = styled(DefaultText).attrs({
    fontSize: '24px',
})`
    font-weight: 700;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        font-size: 18px;
        line-height: 22px;
    }
`;

export const ItemText = styled(DefaultText).attrs({
    fontSize: '16px',
})`
    font-weight: 300;
    margin-left: 20px;
    white-space: pre-wrap;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        font-size: 16px;
        line-height: 19px;
    }
`;

export const ContactItem = styled.div<{isLight?: boolean}>`
    color: ${({isLight}) => isLight ? Colors.Blue : Colors.White};
    display: flex;
    align-items: center;

    > p {
        margin-left: 23px;
    }
`;

export const Img = styled.img`
    height: 70%;
    width: 70%;
    margin-bottom: 45px;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        margin-top: 60px;
        height: 90%;
        width: 90%;
    }
`;

export const HumaneLogoStyle = styled.img`
    height: 111px;
    width: 618px;

    @media only screen and (min-width: 900px) and (max-width: 1250px) {
        width: 421px;
    }

    @media only screen and (min-width: ${MobileMaxWidth}px) and (max-width: 900px) {
        width: 326px;
    }

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        width: 321px;
    }

    @media only screen and (max-width: 320px) {
        width: 250px;
    }
`;

export const Link = styled.a<{isLight?: boolean}>`
    margin-right: 50px;
    font-size: 16px;
    font-weight: 300;
    cursor: pointer;
    color: ${({isLight}) => isLight ? Colors.Blue : Colors.White};
    text-decoration: underline;
    &:hover {
        color: white;
    }

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        margin-right: unset;
    }
`;

export const LinkFooter = styled.a<{isLight?: boolean}>`
    margin-right: 50px;
    font-size: 16px;
    font-weight: 300;
    cursor: pointer;
    color: ${({isLight}) => isLight ? Colors.Blue : Colors.White};
    text-decoration: underline;
    &:hover {
        color: white;
    }

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        margin-right: unset;
        white-space: nowrap;
        font-size: 10px;
        > p {
            white-space: nowrap;
            font-size: 10px;
        }
    }
`;

export const SocialIcon = styled(Link)`
    display: flex;
    align-items: center;
    font-size: 16px;

    margin-right: 20px;
    margin-bottom: 17px;
    text-decoration: none !important;

    > p {
        margin-left: 23px;
    }
`;

export const AcessButton = styled(PrimaryButton)`
    width: 200px;
    height: 65px;
    font-size: 18px;
    line-height: 20px;
    border-radius: 72px;
    border: none;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        height: 57px;
        letter-spacing: 0.3px;
        display: flex;
        border-radius: 50px;
        font-size: 14px;
        line-height: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0px;
        width: 213px;

        @media only screen and (max-width: 320px) {
            width: 220px;
        }
    }
`;

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10vw;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        align-items: center;
        flex-direction: column;
        gap: 4vw;
    }
`;

export const FlexContainerFooter = styled.div`
    display: flex;
    justify-content: center;
`;

export const FlexContainerSpaceBetween = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @media only screen and (max-width: ${MobileMaxWidth}px) {
        margin-top: 32px;
        flex-direction: column-reverse;
        align-items: center;
    }
`;

export const LogRodapeContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    @media only screen and (max-width: ${MobileMaxWidth}px) {
        align-items: center;
    }
`;

export const LinkContainer = styled.a``;
