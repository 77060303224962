import React, { useEffect, useRef, useState } from 'react';
import { OutlineButton } from 'components/UI/Buttons';
import Styled from './AdminImageUploader.styled';

import editIcon from '../../../../images/admin/edit-image.svg';
import AdminEditorImage from '../editorImage/AdminEditorImage';
import { MAX_IMAGE_SIZE_IN_BYTES } from 'constants/Dimensions';
import { ToastError } from 'components/Toaster';

type DimensionType = {
    w: number;
    h: number;
};

export interface IAdminImageUploader {
    size: 'sm' | 'lg';
    title: string;
    onSelect: (base64: string, file: File) => void;
    preImageB64?: string | null;
    recommendedDimension: DimensionType;
}

const AdminImageUploader = ({ size, title, onSelect, preImageB64, recommendedDimension }: IAdminImageUploader) => {
    const [encodedImage, setEncodedImage] = useState<string | null>(null);
    const [showEdit, setShowEdit] = useState<boolean>(false);
    const imageElement = useRef<HTMLImageElement>(null);
    const inputElement = useRef<HTMLInputElement>(null);

    const [showMenu, setShowMenu] = useState(false);

    useEffect(() => {
        if (preImageB64) setEncodedImage(preImageB64);
    }, [preImageB64]);

    const chooseFile = () => {
        if (inputElement?.current) {
            inputElement.current.onchange = (e: Event) => {
                const target = e.target as HTMLInputElement;

                if (target.files && target.files[0]) {
                    if (target.files[0].size > MAX_IMAGE_SIZE_IN_BYTES) {
                        ToastError('O arquivo de imagem excede o tamanho máximo de 1MB');
                        return;
                    }

                    const reader = new FileReader();
                    reader.onloadend = (ev: any) => {
                        const base64 = ev.target.result;
                        setEncodedImage(base64);

                        if (target.files) onSelect(base64, target.files[0]);
                    };
                    reader.readAsDataURL(target.files[0]);
                }
            };

            inputElement.current.click();
        }

        setShowMenu(false);
    };

    function dataURItoBlob(dataURI: string) {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        var byteString = atob(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        var bb = new Blob([ab]);
        return bb;
    }

    return (
        <>
            <AdminEditorImage
                show={showEdit}
                onHide={() => setShowEdit(false)}
                inputImage={encodedImage}
                onSave={async (desc: string, img?: string) => {
                    if (img) {
                        setEncodedImage(img);
                        const blob = dataURItoBlob(img);
                        const myFile = new File([blob], 'image.jpg');
                        onSelect(img, myFile);
                        setShowEdit(false);
                    }
                }}
            />

            <Styled.Wrapper>
                <Styled.Container small={size == 'sm'} dash={encodedImage == null}>
                    {encodedImage == null ? (
                        <>
                            <Styled.TitleSoftBlue>{title}</Styled.TitleSoftBlue>
                            <Styled.DescText style={{ marginTop: '10px' }}>Tamanho: máximo 1MB</Styled.DescText>
                            <Styled.DescText>Formato: JPG</Styled.DescText>
                            <br />

                            <OutlineButton onClick={chooseFile}>Adicionar Imagem</OutlineButton>
                        </>
                    ) : (
                        <Styled.ImageContainer>
                            <p>
                                <Styled.EditImageIcon src={editIcon} onClick={() => setShowMenu(!showMenu)} />
                            </p>
                            {showMenu && (
                                <Styled.MenuContainer>
                                    <Styled.MenuItem onClick={chooseFile}>Substituir Imagem</Styled.MenuItem>
                                    <Styled.MenuItem onClick={() => setShowEdit(true)}>Editar Imagem</Styled.MenuItem>
                                    {/* <Styled.MenuItem
                                    onClick={() => {
                                        setEncodedImage(null);
                                        onSelect('', null);
                                    }}
                                >
                                    Excluir Imagem
                                </Styled.MenuItem> */}
                                </Styled.MenuContainer>
                            )}

                            <Styled.PreviewImage ref={imageElement} src={encodedImage} />
                        </Styled.ImageContainer>
                    )}

                    <Styled.Hidden ref={inputElement} type="file" id="file-input" accept=".png, .jpg, .jpeg" />
                </Styled.Container>

                <Styled.BottomTextContainer>
                    <Styled.BottomText>
                        A dimensão recomendada é de <b>{`${recommendedDimension?.w ?? 0}`}</b> &#10005;{' '}
                        <b>{`${recommendedDimension?.h ?? 0}`}</b>
                    </Styled.BottomText>

                    <Styled.BottomText>
                        Formato <b>JPG</b> e no máximo <b>1 MB</b>.
                    </Styled.BottomText>

                    <Styled.BottomText>Imagens com dimensões diferentes serão redimensionadas.</Styled.BottomText>
                </Styled.BottomTextContainer>
            </Styled.Wrapper>
        </>
    );
};

export default AdminImageUploader;
