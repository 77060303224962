import { useKeycloak } from '@react-keycloak/web';
import PopupQuestion from 'components/popupQuestion/PopupQuestion';
import { PrimaryButton } from 'components/UI/Buttons';
import { ProfileService } from 'core/http/service/Profile.service';
import { isUsuarioLms, isAutoCadastrado } from 'core/util/KeycloakHelp';
import {
    possuiRedirecionamentoPosLogin,
    limparRedirecionamentosPosLogin,
    isViaRealizandoTrilhaMaker,
    isViaRealizandoEscolhas,
    isViaRealizandoAlfabetizacao,
    isViaCompetenciasSocioemocionais,
    isViaViolenciaBully,
    isViaAlfabetizacaoAprofundada,
    isViaBDS,
} from 'core/util/StorageRedirectRulesHelper';
import { FormikValues, FormikProps, FormikHelpers, Formik } from 'formik';
import { useAppSelector } from 'hooks/LocalReduxThunk';
import { cloneDeep, isBoolean } from 'lodash';
import LocalidadeDTO from 'models/perfil/LocalidadeDTO';
import ParametrosCadastroPerfilDTO from 'models/perfil/ParametrosCadastroPerfilDTO';
import ProfileData from 'models/ProfileData';
import UsuarioDTO from 'models/UsuarioDTO';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import etapasEnsino from '../../../../../data/etapasEnsino.json';
import * as profileActions from '../../../../../store/actions/Profile';
import * as authenticationActions from '../../../../../store/actions/Authentication';
import FormikReactSelect from '../../formik/FormikReactSelect';
import Styled from '../InformacoesPessoais.styled';
import { FiLoader } from 'react-icons/fi';
import { AccessPermissionContext } from 'core/context/AccessPermissionContext';
import { ProfileContext } from 'pages/profile/context/ProfileContext';
import { GTM_GA4_Ecommerce_Purchase } from 'components/GoogleTagManager';
import { KeycloakService } from 'core/http/service/Keycloak.service';
import { cpf } from 'cpf-cnpj-validator';
import PerfilTipoDeficienciaDTO from 'models/perfil/PerfilTipoDeficienciaDTO';
import { PerfilDeficienciaService } from 'core/http/service/PerfilDeficiencia.service';

export interface IInformacoesPessoaisForm2 { }

const InformacoesPessoaisForm2 = ({ }: IInformacoesPessoaisForm2) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const profileService = new ProfileService();
    const perfilDeficienciaService = new PerfilDeficienciaService();
    const [isLoadingCities, setIsLoadingCities] = useState(false);
    const [cidades, setCidades] = useState<LocalidadeDTO[]>([]);
    const [submitting, setSubmitting] = useState(false);
    const [isCatedraIEA, setIsCatedraIEA] = useState<number>(-1);

    const { keycloak } = useKeycloak();
    const service = new KeycloakService();

    const profileData: ProfileData | null = useAppSelector(state => state.profileReducer.profileData);
    const usuarioLogado: UsuarioDTO = useAppSelector(state => state.authenticationReducer.usuarioLogado);
    const profileParameterData: ParametrosCadastroPerfilDTO = useAppSelector(state => state.profileReducer.parametrosCadastro);
    const cloneData = profileData == null ? new ProfileData() : cloneDeep(profileData);
    const [showErrorModal, setShowErrorModal] = useState(false);

    const [listCatedraIEA] = useState([
        { id: 1, nome: 'Sim', value: 1 },
        { id: 0, nome: 'Não', value: 0 },
    ]);

    const { redirectBehindRegister } = useContext(AccessPermissionContext.Context);
    const { postProfilePath } = useContext(ProfileContext.Context);

    const initialErrors = {
        erroestado: '',
        errotipoRede: '',
        erromunicipio: '',
        erroetapasEnsino: '',
        errorcatedraIEA: '',
    };

    const [erros, setErros] = useState(initialErrors);

    interface IValues {
        //Form 2
        tipoRede: number;
        estado: string;
        municipio: number;
        etapasEnsino: number[];
        catedraOrIea: number;
    }

    let formikInitial: IValues = {
        tipoRede: profileData?.tipoEnsino ?? 0,
        estado: profileData?.estado ?? '',
        municipio: profileData?.municipio ?? -1,
        etapasEnsino: profileData?.etapasEnsino ?? [],
        catedraOrIea: -1,
    };

    const saveProfile = async (data: ProfileData) => {
        try {
            const response = await profileService.saveProfile(data);

            if (response.status === 201) {
                const codigoPerfil = response.data;

                if (profileData && profileData.tipoDeficiencia.length > 0) {
                    const dados = new PerfilTipoDeficienciaDTO();
                    dados.codigoPerfil = codigoPerfil;
                    dados.deficiencias = profileData.tipoDeficiencia;

                    await perfilDeficienciaService.insert(dados);
                }
            }
        } catch (error) {
            console.log('saveProfile', error);
            throw error;
        }
    };

    const onSubmitHandler = async (e: any, formik: any): Promise<any> => {
        e.preventDefault();
        const values = formik.values;

        if (!isValid(formik)) {
            return updateErrors(values);
        }

        setSubmitting(true);

        cloneData.tipoEnsino = values.tipoRede;

        cloneData.estado = values.estado;
        cloneData.municipio = values.municipio;

        cloneData.etapasEnsino = values.etapasEnsino;

        cloneData.estadoDescricao = profileParameterData.listaEstados.find(e => e.id == cloneData.estado)?.nome ?? '';
        cloneData.municipioDescricao = cidades.find(c => c.id == cloneData.municipio)?.nome ?? '';

        cloneData.etapasEnsino = values.etapasEnsino;

        cloneData.email = usuarioLogado.email ?? '';
        cloneData.username = usuarioLogado.username;
        cloneData.cpf = isUsuarioLms(usuarioLogado) ? cloneData.cpf : usuarioLogado?.username;
        cloneData.keycloakId = usuarioLogado?.id ?? keycloak.subject;
        cloneData.autoCadastrado = isAutoCadastrado(usuarioLogado);
        cloneData.catedraOrIea = values.catedraOrIea ?? 0;
        
        let cpfJaCadastrado: boolean = false;

        if (!cpf.isValid(cloneData.cpf)) {
            cloneData.cpf = '';
        }else {
            const response: any = await profileService.existePorCpf(cloneData.cpf);
            cpfJaCadastrado = response.data;
        }


        if (!cpfJaCadastrado) {
            await dispatch(profileActions.storeProfileData(cloneData));
            await saveProfile(cloneData);
            await dispatch(authenticationActions.getUsuarioLogado());
            await dispatch(profileActions.findUserProfileByUsername(usuarioLogado?.username ?? ''));

            keycloak.updateToken(-1).then(async refreshed => {
                //forcing token refresh after saving the profile to update the realm access roles
                console.log('TOKEN REFRESHED? ', refreshed);
                let usuario: UsuarioDTO | null = null;
                const response: any = await service.getUsuarioLogado();
                if (response.data) {
                    usuario = new UsuarioDTO().fromJSON(response.data);
                    await dispatch(authenticationActions.setUsuarioLogado(usuario));
                }

                //After token refresh, get the loggedin user again, now with the role

                if (possuiRedirecionamentoPosLogin()) {
                    redirectBehindRegister('/');
                } else if (postProfilePath) {
                    if (postProfilePath !== '/cursos-livres') {
                        sendGTMEvents();
                    }
                    redirectBehindRegister(postProfilePath);
                } else {
                    sendGTMEvents();
                    history.push('/');
                }
            });
        } else setShowErrorModal(true);

        setSubmitting(false);
    };

    const eventPurchase = (): string => {
        if (isViaRealizandoEscolhas()) {
            return 'realizando_escolhas';
        } else if (isViaRealizandoTrilhaMaker()) {
            return 'trilha_maker_criatividade';
        } else if (isViaRealizandoAlfabetizacao()) {
            return 'trilha-de-alfabetizacao';
        } else if (isViaCompetenciasSocioemocionais()) {
            return 'competências_socioemocionais';
        } else if (isViaViolenciaBully()) {
            return 'violencia_e_bullying';
        } else if (isViaAlfabetizacaoAprofundada()) {
            return 'alfabetização_aprofundada';
        } else if (isViaBDS()) {
            return 'beneficios_desenvolvimento_socioemocional';
        }

        return 'jornada_socioemocional';
    };

    const sendGTMEvents = () => {
        GTM_GA4_Ecommerce_Purchase(keycloak.subject ?? '', cloneData.cpf, eventPurchase());

        limparRedirecionamentosPosLogin();
    };

    const isValid = (formik: FormikProps<IValues>): boolean => {
        const values = formik.values;

        if (values.estado == '') return false;
        if (values.municipio == -1) return false;
        if (values.tipoRede == 0) return false;
        if (values.etapasEnsino.length == 0) return false;
        if (values.catedraOrIea == -1) return false;

        return true;
    };

     const loadCidadesPorUf = async (uf: string) => {
        try {
            setIsLoadingCities(true);
            const response: any = await profileService.consultarCidadesPorUF(uf);
            const _cidades: LocalidadeDTO[] = response.data.map((item: any) => new LocalidadeDTO().fromJSON(item));
            setCidades(_cidades);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoadingCities(false);
        }
    };

    const setupEtapa = (formik: FormikProps<any>, etapaId: number, value: boolean) => {
        clearError();

        let etapas = formik.values['etapasEnsino'] as number[];

        if (value && !etapas.includes(etapaId)) {
            etapas.push(etapaId);
        } else etapas = etapas.filter(e => e != etapaId);

        formik.setFieldValue('etapasEnsino', etapas);
    };

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 300);
    }, []);

    const updateErrors = (values: FormikValues) => {
        const _errors = {
            erroestado: values.estado == -1 ? 'Campo não preenchido' : '',
            errotipoRede: values.tipoRede == 0 ? 'Campo não preenchido' : '',
            erromunicipio: values.municipio == -1 ? 'Campo não preenchido' : '',
            erroetapasEnsino: values.etapasEnsino.length == 0 ? 'Campo não preenchido' : '',
            errorcatedraIEA: values.catedraOrIea == -1 ? 'Campo não preenchido' : '',
        };

        setErros(_errors);
    };

    const clearError = () => setErros(initialErrors);

    const getSomeError = () => {
        const { erroestado, errotipoRede, erromunicipio, erroetapasEnsino, errorcatedraIEA } = erros;

        return !!erroestado || !!errotipoRede || !!erromunicipio || !!erroetapasEnsino || errorcatedraIEA;
    };

    return (
        <Styled.Container>
            <Styled.Title>Informações pessoais</Styled.Title>
            <Styled.Subtitle>Nos conte um pouco mais sobre sua atuação!</Styled.Subtitle>
            <br />
            <br />

            <Formik initialValues={formikInitial} onSubmit={() => { }}>
                {formik => {
                    useEffect(() => clearError(), [formik.values]);

                    return (
                        <form>
                            <Styled.Row error={!!erros.errotipoRede}>
                                <Styled.Label error={!!erros.errotipoRede}>Principal rede de atuação</Styled.Label>
                                <FormikReactSelect
                                    size={200}
                                    name="tipoRede"
                                    key={`tipoRede-${erros.errotipoRede}`}
                                    options={profileParameterData.listaRedeEnsino}
                                    formik={formik}
                                    onChangeHandler={async (value: number) => { }}
                                />
                            </Styled.Row>

                            <Styled.Row error={!!erros.erroestado}>
                                <Styled.Label error={!!erros.erroestado}>Estado de atuação</Styled.Label>
                                <FormikReactSelect
                                    size={100}
                                    name="estado"
                                    key={`estado-${erros.erroestado}`}
                                    options={profileParameterData.listaEstados}
                                    formik={formik}
                                    onChangeHandler={async (value: string) => {
                                        loadCidadesPorUf(value);
                                    }}
                                />
                            </Styled.Row>

                            <Styled.Row error={!!erros.erromunicipio}>
                                <Styled.Label error={!!erros.erromunicipio}>Cidade de atuação</Styled.Label>

                                {isLoadingCities ? (
                                    <Styled.LoadingCitiesContainer>
                                        <span>
                                            <FiLoader />
                                        </span>
                                    </Styled.LoadingCitiesContainer>
                                ) : (
                                    <FormikReactSelect
                                        size={350}
                                        name="municipio"
                                        key={`municipio-${erros.erromunicipio}-${cidades.length}`}
                                        options={cidades}
                                        formik={formik}
                                        onChangeHandler={() => { }}
                                    />
                                )}
                            </Styled.Row>

                            <Styled.Column error={!!erros.errorcatedraIEA}>
                                <Styled.Label style={{ textAlign: 'start' }} error={!!erros.errorcatedraIEA}>
                                    Você faz parte do curso de Alfabetização Integral da Cátedra do Instituto Ayrton Senna <br /> e Instituto de
                                    Estudos Avançamos (Cátedra IAS/IEA)?
                                </Styled.Label>
                                <FormikReactSelect
                                    size={300}
                                    name="catedraOrIea"
                                    key={`catedraOrIea-${erros.errorcatedraIEA}`}
                                    options={listCatedraIEA}
                                    formik={formik}
                                    onChangeHandler={async (value: number) => { }}
                                />
                            </Styled.Column>

                            <br />

                            <Styled.Row error={!!erros.erroetapasEnsino}>
                                <Styled.Label error={!!erros.erroetapasEnsino}>Você atua em quais etapas de ensino?</Styled.Label>
                            </Styled.Row>

                            <Styled.TwoColumns>
                                {etapasEnsino.listaEtapasEnsino.map((e, idx) => {
                                    return (
                                        <Styled.Row key={e.id}>
                                            <Styled.CheckBox
                                                type="checkbox"
                                                id={`etapa-${idx}`}
                                                onChange={evt => setupEtapa(formik, e.id, evt.target.checked)}
                                            />
                                            <Styled.LabelCheck htmlFor={`etapa-${idx}`}>{e.descricao}</Styled.LabelCheck>
                                        </Styled.Row>
                                    );
                                })}
                            </Styled.TwoColumns>

                            <br />
                            <br />
                            <input type="email" style={{ visibility: 'hidden' }} />

                            <Styled.Row>
                                <PrimaryButton
                                    style={{ padding: '10px 10px 10px 10px' }}
                                    type="submit"
                                    disabled={submitting}
                                    onClick={e => onSubmitHandler(e, formik)}
                                >
                                    {submitting ? 'Enviando...' : 'Finalizar cadastro'}
                                </PrimaryButton>

                                {getSomeError() && <Styled.ErrorText>Atenção: preencha todos os campos antes de avançar.</Styled.ErrorText>}
                            </Styled.Row>
                        </form>
                    );
                }}
            </Formik>

            <PopupQuestion
                onButton={() => setShowErrorModal(false)}
                okButtonText={'Ok'}
                cancelButtonText={'Ok'}
                infoOnly={true}
                question={'O CPF informado já possui uma conta cadastrada em nossa base de dados.'}
                visible={showErrorModal}
            />
        </Styled.Container>
    );
};

export default InformacoesPessoaisForm2;
